import { useEffect, useState } from "react";
import { columnsDataCheck } from "./variables/columnsData";
import Subresllertable from "views/admin/Reseller/components/SubResellerTable";
import { useSelector } from "react-redux";
import { instance } from "common/Instance";
import Switch from "components/switch";
import CommonBackdrop from "common/CommonBackdrop";
import toast from "react-hot-toast";

const label = { inputProps: { "aria-label": "Switch demo" } };

const SubResellerTable = () => {
  return (
    <div className="grid grid-cols-1 gap-5  md:grid-cols-1">
      <Subresllertable />
    </div>
  );
};

export default SubResellerTable;
