import {useState, useEffect } from "react";
import Card from "components/card";
import { useDispatch } from "react-redux";
import { instance } from "common/Instance";
import { useSelector } from "react-redux";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import {
  SET_MULTISCENE_DATA,

} from "../../../../redux/reducerSlice/arSlice";
import { MdOutlineClear } from "react-icons/md";

const style = {
  position: "absolute",
  top: "0%",
  right: "0%",
  width: 300,
  height: "100vh",
  bgcolor: "background.paper",
  
  boxShadow: 24,
};

const MultisceneHistory = ({ open, handleClose }) => {


  const dispatch = useDispatch();
  const userid = useSelector((state) => state.auth.auth.user._id);
  const userToken = useSelector((state) => state.auth.auth.token);

const [historyData,setHistoryData]=useState([])



  const SelectedData = useSelector((state) => state.ar.multisceneData);

  console.log(userToken, "userToken");

  const getHistoryData = async () => {
    try {
      const response = await instance.get(`api/ar/ar_history/${userid}?page=${1}&limit=5000`, {
        headers: {
          authorization: `Bearer ${userToken}`,
        },
      });
      if (response.status === 200) {
       setHistoryData(response.data.data);
        console.log(historyData);
      }
    } catch (error) {}
  };

  useEffect(() => {
    getHistoryData();
  }, []);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={open}>
        <Box sx={style}>
        
            <div className="flex items-center justify-between  bg-gray-100 px-4 py-2">
              <p className="text-sm">Select Project</p>
              <span onClick={handleClose} className="cursor-pointer">
                <MdOutlineClear size={15} />
              </span>
            </div>
            <Card
                 extra={"w-full h-[90vh]  p-2 overflow-auto bg-white "}
              
            >
              <div className="flex items-center justify-center   ">
                <div className=" mt-4 flex flex-wrap items-center justify-center gap-4  ">
                  {historyData?.filter((item) => item.status === "published")?.map((item,index) => {
                      return (
                        <div
                        
                          className={` border-2 ${
                            SelectedData.find((item1) => {
                              return (
                                JSON.stringify(item1) === JSON.stringify(item)
                              );
                            })
                              ? "bg-blue-200"
                              : "bg-white"
                          } group relative h-full w-[200px] cursor-pointer rounded-lg  pb-2 shadow-lg `}
                          key={index}
                        >
                          <div className="flex justify-center  ">
                            <img
                              src={
                                item.targetImage && item.targetImage.src
                                  ? item.targetImage.src
                                  : "https://liftlearning.com/wp-content/uploads/2020/09/default-image.png"
                              }
                              alt="hello"
                              className="mt-2 h-[120px] w-[180px] rounded-md object-cover"
                              onClick={() =>
                                dispatch(SET_MULTISCENE_DATA(item))
                              }
                            />
                          </div>
                          <div className="mt-2 flex items-center justify-between px-8 ">
                            <p
                              className="text-xs font-semibold text-gray-900"
                              style={{ fontSize: "12px" }}
                            >
                              {item.name}
                            </p>
                            <span
                              style={{ fontSize: "10px" }}
                              className={`text-xs font-semibold ${
                                item.status === "published"
                                  ? "text-green-600"
                                  : item.status === "in-progress"
                                  ? "text-yellow-400"
                                  : "text-green-600"
                              }`}
                            >
                              {item?.status?.toUpperCase()}
                            </span>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            </Card>
          
        </Box>
      </Fade>
    </Modal>
  );
};

export default MultisceneHistory;
