import * as React from 'react';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Autocomplete from '@mui/material/Autocomplete';
import { useEffect } from 'react';
import { instance } from 'common/Instance';
import { useSelector } from 'react-redux';
import { useState } from 'react';

export default function CustomerName({setFormData, isLoadingUsers,setIsLoadingUsers,  selectedCustomer, setSelectedCustomer}) {
  const userToken = useSelector((state) => state.auth.auth.token);
  const [totalUsers, setTotalUsers] =useState(0);
  const [users, setUsers] = useState([])
  const userList = async () => {
    
    try {
      const response = await instance.get(
        `/api/reseller/user_list`,
        {
          headers: {
            authorization: `Bearer ${userToken}`,
          },
        }
      );

      if (response.status === 200) {
      console.log(response, "this is response ")
      setTotalUsers(response?.data?.data?.totalCount)
      }
    } catch (error) {
      console.log("error", error);
    
    }
  };

  
  // const alluserList = async () => {
  //   setLoading(true);
  //   try {
  //     const response = await instance.get(
  //       `/api/reseller/user_list?page=${currentPage}&limit=${itemsPerPage}&searchText=${searchParam}`,
  //       {
  //         headers: {
  //           authorization: `Bearer ${authToken}`,
  //         },
  //       }
  //     );

  //     if (response.status === 200) {
  //       setTablesData(response?.data?.data?.data);
  //       setLoading(false);
  //       setTotalPages(response?.data?.data?.totalPages);
  //     }
  //   } catch (error) {
  //     console.log("error", error);
  //     toast.error(error && error?.response?.message);
  //     setLoading(false);
  //   }
  // };


  useEffect(() => {
    const userList = async () => {
    
      try {

        setIsLoadingUsers(true)
        
        const response = await instance.get(
          `/api/invoices/get_invoice_all`,
          {
            headers: {
              authorization: `Bearer ${userToken}`,
            },
          }
        );
  console.log(response,"this is user of the user")
        if (response.status === 200) {
          setIsLoadingUsers(false)
        console.log(response?.data?.data, "this is response ")
       setUsers(response?.data?.data)

        }
        setIsLoadingUsers(false)

      } catch (error) {
        console.log("error", error);
        setIsLoadingUsers(false)

      }
    };

    userList()
  
  }, [])
  

  useEffect(() => {
userList()
  }, [])


  const handleCustomerSelect = (event, value) => {
    if (value && value._id) {

      setSelectedCustomer(value)

      console.log(value, "this is select")
      // onCustomerSelect(value._id);
      setFormData((prev)=>{
      return {...prev, userId:value?._id}
      })
    }
  };


  console.log(users, "this is suer")
  return (
     isLoadingUsers ? <p>Loading users</p> :
   <>{(users && users?.length > 0) ? <Stack spacing={2} >
      {/* <Autocomplete
        id="free-solo-demo"
        freeSolo
        options={top100Films.map((option) => option.title)}
        renderInput={(params) => <TextField {...params} label="freeSolo" />}
      /> */}
        <Autocomplete
         value={selectedCustomer}
        size='small'
        freeSolo
        id="customer-name-autocomplete"
        disableClearable
        options={users}
    
        getOptionLabel={(option) => option.fullName || ''}
        onChange={handleCustomerSelect}
        renderInput={(params) => (
          <TextField
            {...params}
            onChange={()=>{setFormData((prev)=>{return{...prev, userId:""}})}}
            size='small'
            InputProps={{
              ...params.InputProps,
              type: 'search',
            }}
            label="Search users"
          />
        )}
      />
    </Stack> : <p>Oops users not found</p>}</>
  );
}

