import React from "react";

const Logo = ({ color, height, width }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 79 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M76.9923 35.4197V11.0324C76.9923 5.16135 73.5176 2.45162 69.1413 2H10.5066C7.58952 2.30113 2.54274 5.25165 2.19354 11.0324C1.84302 16.8131 2.04749 29.6996 2.19354 35.4197C2.04749 37.9788 3.50536 43.2771 10.5066 44H30.4414C30.8411 44 31.2195 43.8145 31.4717 43.4942L38.3309 34.7724C38.3747 34.7161 38.4225 34.6638 38.4756 34.6157L38.5181 34.5759C39.0612 34.0728 39.895 34.1168 40.3863 34.6748L48.1815 43.5506C48.4324 43.8365 48.7896 44 49.164 44H69.1413C73.2985 44 76.6644 40.986 76.9923 35.4197ZM76.9923 35.4197C76.9923 35.7276 77.0096 35.1256 76.9923 35.4197Z"
        stroke={color}
        stroke-width="3.5"
      />
      <path
        d="M19.5 25C23.0899 25 26 22.0899 26 18.5C26 14.9101 23.0899 12 19.5 12C15.9101 12 13 14.9101 13 18.5C13 22.0899 15.9101 25 19.5 25Z"
        stroke={color}
        stroke-width="2.5"
      />
      <path
        d="M58.5 25C62.0899 25 65 22.0899 65 18.5C65 14.9101 62.0899 12 58.5 12C54.9101 12 52 14.9101 52 18.5C52 22.0899 54.9101 25 58.5 25Z"
        stroke={color}
        stroke-width="2.5"
      />
    </svg>
  );
};

export default Logo;
