import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import InputField from "components/fields/InputField";
import toast from "react-hot-toast";
import { instance } from "common/Instance";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { SET_FORGET_EMAIL } from "../../redux/reducerSlice/authSlice";
import OtpInput from "react-otp-input";

import { FaEye, FaEyeSlash } from "react-icons/fa";

export default function ResetPassword() {
  const navigate = useNavigate();
  const forgetEmail = useSelector((state) => state.auth.forgetEmail);
  const [timer, setTimer] = useState(120);
  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [passwordStrength,setPasswordStrength]=useState("")

  useEffect(() => {
    if (timer > 0) {
      const id = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
      return () => clearInterval(id);
    }
  }, [timer]);

  const minutes = Math.floor(timer / 60);
  const seconds = timer % 60;

  const validationSchema = Yup.object({
    resetPassword: Yup.string()
      .min(4, "Password must be at least 4 characters")
      .required("Password is required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("resetPassword"), null], "Passwords must match")
      .required("Confirm Password is required"),
    otp: Yup.string().required("OTP is required"),
  });

  const formik = useFormik({
    initialValues: {
      resetPassword: "",
      confirmPassword: "",
      otp: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (value) => {
      console.log(value, "valuehai");
      try {
        const response = await instance.post("api/auth/reset_password", {
          ...value,
          email: forgetEmail,
        });
        if (response.status === 200) {
          toast.success(response?.data?.message);
          navigate("/auth/sign-in");
          SET_FORGET_EMAIL("");
        }
      } catch (error) {
        toast.error(error?.response?.data?.message ?? error.message);
      }
    },
  });

  const handleResendOTP = async (email) => {
    try {
      const response = await instance.post("api/auth/resend-otp", {
        email,
      });
      if (response.status === 200) {
        toast.success("OTP sent successfully");
        setTimer(120);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message ?? error.message);
    }
  };



  const checkPasswordStrength = (password) => {
    let strength = 'Weak';

    // Check for complexity using regular expressions
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasNumbers = /\d/.test(password);
    const hasSpecialChars = /[@$!%*?&#]/.test(password);

    const complexityCount = [hasUpperCase, hasLowerCase, hasNumbers, hasSpecialChars].filter(Boolean).length;

    // Determine password strength based on complexity
    if (complexityCount >= 4) {
        strength = 'Strong';
    } else if (complexityCount >= 3) {
        strength = 'Medium';
    } else {
        strength = 'Weak';
    }

    return strength;
};

const handlePasswordChange = (event) => {
  const { id, value } = event.target;
  
  formik.handleChange(event); 

  if (id === 'resetPassword') {
      setPasswordStrength(checkPasswordStrength(value));
  }
};



  return (
    <div className="mb-16 mt-16 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-start">
      <div className="mt-[2vh] w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
        <p className="mb-2.5 text-center text-4xl font-bold text-navy-700 ">
          Reset password
        </p>
        <p className="mb-2 ml-1 text-center  text-sm text-gray-700">
          OTP is sent to{" "}
          <span className="font-bold text-gray-800 ">{forgetEmail}</span>, Enter
          The Proper Details And Click On The Change Password
        </p>

        <div className="mb-2 mt-6 w-full ">
          <OtpInput
            value={formik.values.otp}
            onChange={(otp) => formik.setFieldValue("otp", otp)}
            error={formik.touched.otp && formik.errors.otp}
            numInputs={5}
            isInputNum={true}
            separator={<span>-</span>}
            containerStyle={{
              display: "flex",
              justifyContent: "space-evenly",
              gap: "20px",
              width: "100%",
            }}
            inputStyle={{
              textAlign: "center",
              borderRadius: "5px",
              border: "1px solid darkgray",
              padding: "10px",
              width: "40px",
              height: "40px",
            }}
            renderInput={(props) => <input {...props} />}
          />
        </div>

        {timer > 0 && (
          <p className="my-2 px-6 text-right text-xs text-red-500">
            OTP timeout{" "}
            {`${minutes.toString().padStart(2, "0")}:${seconds
              .toString()
              .padStart(2, "0")}`}
          </p>
        )}

        {timer === 0 && (
          <p
            className="my-4 cursor-pointer px-6 text-right text-xs font-semibold text-gray-900"
            onClick={() => handleResendOTP(forgetEmail)}
          >
            Resend OTP
          </p>
        )}

        <div className="relative">
          <InputField
            variant="auth"
            extra="mb-3"
            label="Enter New Password"
            id="resetPassword"
            type={showPassword1 ? "text" : "password"}
            onChange={handlePasswordChange}
            onBlur={formik.handleBlur}
            value={formik.values.resetPassword}
            error={formik.touched.resetPassword && formik.errors.resetPassword}
          />
          <button
            type="button"
            onClick={() => setShowPassword1(!showPassword1)}
            className="absolute right-3 top-[54px] -translate-y-1/2 transform"
          >
            {showPassword1 ? <FaEyeSlash /> : <FaEye />}
          </button>


        </div>

        <p className={` -mt-2 text-sm text-gray-700 font-medium px-1  ${passwordStrength==='Weak'?`text-red-500`:passwordStrength==='Strong'?`text-green-700`:`text-yellow-600`} `}>{passwordStrength}</p> 
        <div className="relative">
          <InputField
            variant="auth"
            extra="mb-3"
            label="Confirm New Password"
            id="confirmPassword"
            type={showPassword2 ? "text" : "password"}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.confirmPassword}
            error={
              formik.touched.confirmPassword && formik.errors.confirmPassword
            }
          />
          <button
            type="button"
            onClick={() => setShowPassword2(!showPassword2)}
            className="absolute right-3 top-[54px] -translate-y-1/2 transform"
          >
            {showPassword2 ? <FaEyeSlash /> : <FaEye />}
          </button>
        </div>

        <button
          className="linear mt-2 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
          type="submit"
          onClick={formik.handleSubmit}
        >
          Change Password
        </button>
      </div>
    </div>
  );
}
