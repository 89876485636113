import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    lables: {
        importance: [{ _id: 1, name: "Lead", type: "importance", color: "#FF0000", default: true },],
        status: [{ _id: 2, name: "Check", type: "status", color: "#00FF00", default: true },],
        source: [{ _id: 3, name: "Facebook", type: "source", color: "#0000FF", default: true },]
    }


};

const enquirySlice = createSlice({
    name: 'enquiry',
    initialState,
    reducers: {
        SET_LABLES: (state, action) => {
            console.log("called the reducer")
            state.lables = action.payload;

        },
    },
});

export const { SET_LABLES } = enquirySlice.actions;
export default enquirySlice.reducer;
