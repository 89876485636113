import React, { useState } from "react";
import { CiCircleCheck, CiCircleRemove } from "react-icons/ci";
import { CircularProgress } from "@mui/material";
import { TbAugmentedReality2 } from "react-icons/tb";
import { GiUpgrade } from "react-icons/gi";
import { MdOutlineWorkspacePremium } from "react-icons/md";





const PlanCard = ({
  loading,
  planId,
  planIndex,
  planType,
  user,
  price,
  features,
  handleCheckOut,
}) => {
  const [selectedAddOn, setSelectedAddOn] = useState("option1"); // Default to option 1

  const handleAddOnChange = (event) => {
    setSelectedAddOn(event.target.value);
  };

  // Logic to check if the plan is lower than the current user's subscription
  const PLAN_HIERARCHY = {
    'Platinum': 3,
    'Gold': 2,
    'Silver': 1
  };
  const checkPlanActive = (currentPlan, cardPlan) => {
    console.log('currentPlan: ', currentPlan);
    console.log('cardPlan: ', cardPlan);
    // If current plan and card plan are the same, return false (disabled)
    if (currentPlan === cardPlan) {
      return false;
    }
    // Get the hierarchy levels for the current plan and card plan
    const currentPlanLevel = PLAN_HIERARCHY[currentPlan];
    const cardPlanLevel = PLAN_HIERARCHY[cardPlan];
    // If current plan level is higher than card plan level, return false (disabled)
    if (currentPlanLevel > cardPlanLevel) {
      return false;
    }else{
        return true;
    }
}
  
  // Usage:
  const isDowngrade = checkPlanActive(user?.subscriptionType, planType);
 
  const isCurrentPlan= user?.subscriptionType === planType

  return (
    <>
      {planType === "Gold" ? (
        <div className="-mt-6 w-full lg:-mt-0 lg:w-[30%]">
          <div className="relative mx-auto max-w-sm rounded-lg bg-blue-600 px-7 py-5">
            <div className="absolute left-1/2 top-0 inline-flex -translate-x-1/2 -translate-y-1/2 transform items-center justify-center rounded-full bg-blue-600 p-2">
              <div className="flex-shrink-0 rounded-full bg-white px-5 py-4 text-sm font-semibold uppercase text-blue-600">
                Popular
              </div>
            </div>
            <span className="mb-2 block pt-4 text-sm font-semibold text-white">
              {planType}
            </span>
            <span className="flex items-end text-white">
              <span className="text-3xl font-extrabold leading-none">
                {selectedAddOn === "option1" ? (
                  <span>Rs {price + 999}</span>
                ) : (
                  `Rs ${price}`
                )}
              </span>
              <span className="text-sm font-semibold">/Year</span>
            </span>
            <p className="py-2 text-xs text-white">
              360-Degree Personal Contact Details Showcase
            </p>
            <div className="border-t border-gray-100 pt-5">
              <ul className="mb-10">
                {features.length > 0 &&
                  features.map((list, key) => (
                    <li key={key} className="mb-2 flex items-center">
                      <span className="ml-2 flex justify-between gap-1 text-sm text-white">
                        <span>
                          {list?.check ? (
                            <CiCircleCheck size={22} color="white" />
                          ) : (
                            <CiCircleRemove size={22} color="red" />
                          )}
                        </span>
                        {list?.text}
                      </span>
                    </li>
                  ))}
              </ul>
              <div className="w-full">
                <div className="flex items-center justify-center mb-2 text-sm font-medium text-white">
                  <hr className="h-[2px] rounded-full w-2/12 bg-gray-600" />
                  <span className="mx-2 text-white">Add-Ons</span>
                  <hr className="h-[2px] rounded-full w-2/12 bg-gray-600" />
                </div>
                <div className="mb-4 text-sm text-white">
                  {!isDowngrade ? (
                    <div className={`relative p-3 rounded-lg border-2 ${
                      isCurrentPlan 
                        ? 'border-green-400 from-green-100 to-blue-100'
                        : 'border-blue-300 from-blue-50 to-purple-50'
                    } bg-gradient-to-r shadow-lg overflow-hidden transition-all duration-300 hover:shadow-xl`}>
                      <div className="absolute top-0 left-0 w-full h-full">
                        <div className="absolute top-2 left-2 animate-bounce">
                          {/* {isCurrentPlan && <span className="text-green-500 text-2xl">✨</span>} */}
                        </div>
                        <div className="absolute bottom-2 right-2 animate-pulse" style={{ animationDelay: '0.5s' }}>
                          <TbAugmentedReality2 className={`${isCurrentPlan ? 'text-green-500' : 'text-blue-500'} text-4xl`} />
                        </div>
                      </div>
                      
                      <div className="relative z-10 flex items-center">
                        <div className={`mr-4 ${isCurrentPlan ? 'bg-green-200' : 'bg-blue-100'} p-2 rounded-full`}>
                          {isCurrentPlan ?<MdOutlineWorkspacePremium  className={`${isCurrentPlan ? 'text-green-600' : 'text-blue-500'} text-3xl ${!isCurrentPlan ? 'animate-pulse' : ''}`} />: <GiUpgrade  className={`${isCurrentPlan ? 'text-green-600' : 'text-blue-500'} text-3xl ${!isCurrentPlan ? 'animate-pulse' : ''}`} /> }
                        </div>
                        <div>
                          <p className={`${isCurrentPlan ? 'text-green-600' : 'text-blue-500'}`}>
                            <span className="font-semibold"> 
                              {isCurrentPlan ? `Active plan ${user?.subscriptionType}` : 'Always Moving Up!'}
                            </span>
                          </p>
                          <p className={`${isCurrentPlan ? 'text-green-500' : 'text-blue-400'} mt-2`}>
                            {isCurrentPlan ? 'Enjoying premium features!' : 'Upgrades only - no going back!'}
                          </p>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <>
                      <label
                        htmlFor={`option1-${planId}`}
                        className={`mb-2 flex items-center flex-row-reverse justify-between p-3 rounded-md font-semibold border-[1px] border-gray-300 ${
                          selectedAddOn === "option1" &&
                          "bg-[#d7e3ff] border-[1px] border-blue-300 text-black"
                        }`}
                      >
                        <input
                          id={`option1-${planId}`}
                          type="radio"
                          name={`addOn-${planId}`}
                          value="option1"
                          checked={selectedAddOn === "option1"}
                          onChange={handleAddOnChange}
                          className="mr-2"
                        />
                        <div className="flex flex-col">
                          <span className="text-base">{planType} + OCR</span>
                          <span>₹{price + 999}</span>
                        </div>
                      </label>
                      <label
                        htmlFor={`option2-${planId}`}
                        className={`mb-2 flex items-center flex-row-reverse justify-between p-3 rounded-md font-semibold border-[1px] border-gray-300 ${
                          selectedAddOn === "option2" &&
                          "bg-[#d7e3ff] border-[1px] border-blue-300 text-black"
                        }`}
                      >
                        <input
                          id={`option2-${planId}`}
                          type="radio"
                          name={`addOn-${planId}`}
                          value="option2"
                          checked={selectedAddOn === "option2"}
                          onChange={handleAddOnChange}
                          className="mr-2"
                        />
                        <div className="flex flex-col">
                          <span className="text-base">{planType}</span>
                          <span>₹{price}</span>
                        </div>
                      </label>
                    </>
                  )}
                </div>
              </div>
              {isDowngrade &&  <button
                type="button"
                className="w-full rounded-md border border-black bg-white px-3 py-2 text-sm font-semibold text-[#222] shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-black"
                onClick={() =>
                  handleCheckOut(
                    planId,
                    planIndex,
                    selectedAddOn === "option1" ? true : false
                  )
                }
                disabled={!isDowngrade} // Disable if downgrading
              >
                {loading ? <CircularProgress size={24} /> : "Choose Plan"}
              </button>}
             
            </div>
          </div>
        </div>
      ) : (
        <div className="flex h-full w-full flex-col justify-center lg:w-[30%]">
          <div className="mx-auto max-w-sm rounded-md border border-gray-200 bg-white pb-20 pl-5 pr-8 pt-6 lg:pb-8">
            <div>
              <span className="mb-2 block text-sm font-semibold text-gray-400">
                {planType}
              </span>
              <span className="flex items-end">
                <span className="text-3xl font-extrabold leading-none">
                  {selectedAddOn === "option1" ? (
                    <span>Rs {price + 999}</span>
                  ) : (
                    `Rs ${price}`
                  )}
                </span>
                <span className="text-sm font-semibold">/Year</span>
              </span>
              <p className="px-1 py-2 text-xs">
                360-Degree Personal Contact Details Showcase
              </p>
            </div>
            <div className="border-t border-gray-100 pt-5">
              <ul className="mb-4">
                {features.length > 0 &&
                  features.map((list, key) => (
                    <li key={key} className="mb-2 flex items-center">
                      <span className="ml-2 flex justify-between gap-1 text-sm text-gray-900">
                        <span>
                          {list?.check ? (
                            <CiCircleCheck size={22} color="blue" />
                          ) : (
                            <CiCircleRemove size={22} color="red" />
                          )}
                        </span>
                        {list?.text}
                      </span>
                    </li>
                  ))}
              </ul>
              <div className="w-full">
                <div className="mb-2 flex items-center justify-center text-sm font-semibold">
                  <hr className="h-[2px] w-2/12 rounded-full bg-gray-600 text-black" />
                  <span className="mx-2">Add-Ons</span>
                  <hr className="h-[2px] w-2/12 rounded-full bg-gray-600 text-black" />
                </div>
                <div className="mb-4 text-sm text-gray-800">
                  {!isDowngrade ? (
                      <div className={`relative p-3 rounded-lg border-2 ${
                        isCurrentPlan 
                          ? 'border-green-400 from-green-100 to-blue-100'
                          : 'border-blue-300 from-blue-50 to-purple-50'
                      } bg-gradient-to-r shadow-lg overflow-hidden transition-all duration-300 hover:shadow-xl`}>
                        <div className="absolute top-0 left-0 w-full h-full">
                          <div className="absolute top-2 left-2 animate-bounce">
                            {/* {isCurrentPlan && <span className="text-green-500 text-2xl">✨</span>} */}
                          </div>
                          <div className="absolute bottom-2 right-2 animate-pulse" style={{ animationDelay: '0.5s' }}>
                            <TbAugmentedReality2 className={`${isCurrentPlan ? 'text-green-500' : 'text-blue-500'} text-4xl`} />
                          </div>
                        </div>
                        
                        <div className="relative z-10 flex items-center">
                          <div className={`mr-4 ${isCurrentPlan ? 'bg-green-200' : 'bg-blue-100'} p-2 rounded-full`}>
                            {isCurrentPlan ?<MdOutlineWorkspacePremium  className={`${isCurrentPlan ? 'text-green-600' : 'text-blue-500'} text-3xl ${!isCurrentPlan ? 'animate-pulse' : ''}`} />: <GiUpgrade  className={`${isCurrentPlan ? 'text-green-600' : 'text-blue-500'} text-3xl ${!isCurrentPlan ? 'animate-pulse' : ''}`} /> }
                          </div>
                          <div>
                            <p className={`${isCurrentPlan ? 'text-green-600' : 'text-blue-500'}`}>
                              <span className="font-semibold"> 
                                {isCurrentPlan ? `Active plan ${user?.subscriptionType}` : 'Always Moving Up!'}
                              </span>
                            </p>
                            <p className={`${isCurrentPlan ? 'text-green-500' : 'text-blue-400'} mt-2`}>
                              {isCurrentPlan ? 'Enjoying premium features!' : 'Upgrades only - no going back!'}
                            </p>
                          </div>
                        </div>
                      </div>
                  ) : (
                    <>
                      <label
                        htmlFor={`option1-${planId}`}
                        className={`mb-2 flex items-center flex-row-reverse justify-between p-3 rounded-md font-semibold border-[1px] border-gray-300 ${
                          selectedAddOn === "option1" &&
                          "bg-[#d7e3ff] border-[1px] border-blue-300 text-black"
                        }`}
                      >
                        <input
                          id={`option1-${planId}`}
                          type="radio"
                          name={`addOn-${planId}`}
                          value="option1"
                          checked={selectedAddOn === "option1"}
                          onChange={handleAddOnChange}
                          className="mr-2"
                        />
                        <div className="flex flex-col">
                          <span className="text-base">{planType} + OCR</span>
                          <span>₹{price + 999}</span>
                        </div>
                      </label>
                      <label
                        htmlFor={`option2-${planId}`}
                        className={`mb-2 flex items-center flex-row-reverse justify-between p-3 rounded-md font-semibold border-[1px] border-gray-300 ${
                          selectedAddOn === "option2" &&
                          "bg-[#d7e3ff] border-[1px] border-blue-300 text-black"
                        }`}
                      >
                        <input
                          id={`option2-${planId}`}
                          type="radio"
                          name={`addOn-${planId}`}
                          value="option2"
                          checked={selectedAddOn === "option2"}
                          onChange={handleAddOnChange}
                          className="mr-2"
                        />
                        <div className="flex flex-col">
                          <span className="text-base">{planType}</span>
                          <span>₹{price}</span>
                        </div>
                      </label>
                    </>
                  )}
                </div>
              </div>
         {isDowngrade &&
          <button
          type="button"
          className="w-full rounded-md border border-black bg-white px-3 py-2 text-sm font-semibold text-[#222] shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-black"
          onClick={() =>
            handleCheckOut(
              planId,
              planIndex,
              selectedAddOn === "option1" ? true : false
            )
          }
          disabled={!isDowngrade}
        >
          {loading ? <CircularProgress size={24} /> : "Choose Plan"}
        </button>}    
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PlanCard;
