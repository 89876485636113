import RecycleBinUserTable from "./components/RecycleBinUserTable";

const RecycleBinUserList = () => {
  return (
    <div className="grid grid-cols-1 gap-5 px-4 md:grid-cols-1">
      <RecycleBinUserTable />
    </div>
  );
};

export default RecycleBinUserList;
