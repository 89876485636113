import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import UploadVideo from "./UploadVideo";
import { SET_VIDEO_PROPERTIES,UPDATE_ICONS

 } from "../../../../../redux/reducerSlice/arSlice";
import Box from '@mui/material/Box';
import { v4 as uuidv4 } from "uuid";

import Modal from '@mui/material/Modal';
import { instance } from "common/Instance";

import { Backdrop, CircularProgress, TextField } from "@mui/material";
import { MdDeleteForever } from "react-icons/md";
import CommonBackdrop from "common/CommonBackdrop";
import { MdOutlineClear } from "react-icons/md";
import imageCompression from "browser-image-compression";
import toast from "react-hot-toast";
import { loadTexture } from "../EditorUtils";


const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  outline: "none",
  borderRadius: 2,
  border: "none",
};

export default function VideoEditorSection({ whatsClicked }) {
  const dispatch = useDispatch();
  const ardata = useSelector((state) => state.ar.data);

  const [openVideoModal, setVideoModal] = useState(false);
  const [animationDelay, setAnimationDelay] = useState(0);
  const [animationDuration, setAnimationDuration] = useState(0);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    const videoData = ardata?.videos?.find(
      (data) => data?.id === whatsClicked?.userData?.id
    );
    if (videoData) {
      setAnimationDelay(videoData?.animation?.delay || 0);
      setAnimationDuration(videoData?.animation?.duration || 0);
    }
  }, [ardata, whatsClicked?.userData?.id]);

  const handleOpenVideoModal = () => setVideoModal(true);
  const handleCloseVideoModal = () => setVideoModal(false);



  const isAnimationVideo = useSelector((state) => {
    const video = state?.ar?.data?.videos?.find(
      (data) => data.id === whatsClicked?.userData?.id
    );
    return video?.isAnimation;
  });

  const Vsettings = useSelector((state) => {
    const video = state?.ar?.data?.videos?.find(
      (data) => data.id === whatsClicked?.userData?.id
    );
    return video?.vsettings;
  });

  const handleCheckboxChange = (key) => {
    dispatch(
      SET_VIDEO_PROPERTIES({
        parentKey: "Vsettings",
        key,
        value: !Vsettings?.[key],
        id: whatsClicked?.userData?.id,
      })
    );
  };

  const handleAnimationChange = (key, value) => {
    dispatch(
      SET_VIDEO_PROPERTIES({
        parentKey: "Animation",
        key,
        value,
        id: whatsClicked?.userData?.id,
      })
    );
  };

  return (
    <div>
      {whatsClicked?.userData?.type === "video" && (
        <div className="mt-2 px-2">
          <div className="rounded-md bg-white px-4 py-2 shadow-md">
            <p className="py-2">Edit Video</p>
            <div>
              <div className='flex justify-between items-center gap-1 w-full'>
              <button
                className="w-full cursor-pointer rounded-xs bg-brand-main p-1 py-2 text-center text-white rounded-md"
                onClick={handleOpenVideoModal}
              >
                Replace Video
              </button>


           
            <button  className="w-full cursor-pointer rounded-xs bg-brand-main p-1 py-2 text-center text-white rounded-md" onClick={handleOpen}>Set Icon</button>
      
              </div>
             

              <UploadVideo
                open={openVideoModal}
                handleClose={handleCloseVideoModal}
                id={whatsClicked?.userData?.id}
              />
            </div>
            <div className="flex w-full items-center">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={Vsettings?.removeBg}
                    onChange={() => handleCheckboxChange("removeBg")}
                  />
                }
                label={<span style={{ fontSize: 14 }}>Remove Background</span>}
                sx={{ paddingTop: -1 }}
              />
            </div>

            

          </div>
        </div>
      )}


      {whatsClicked?.userData?.type === "video" && (
        <div className="mt-2 px-2">
          <div className="rounded-md bg-white px-4 py-2 shadow-md">
            <p className="py-1">Video Settings</p>
            <div className="-mt-2 flex flex-col rounded-lg bg-white">
              {["muted", "autoplay", "loop", "playpause", "fullscreen"].map(
                (key) => (
                  <FormControlLabel
                    key={key}
                    control={
                      <Checkbox
                        checked={Vsettings?.[key]}
                        onChange={() => handleCheckboxChange(key)}
                      />
                    }
                    label={
                      <span style={{ fontSize: 13 }}>
                        {key.charAt(0).toUpperCase() + key.slice(1)}
                      </span>
                    }
                    sx={{ paddingTop: -1 }}
                  />
                )
              )}
            </div>
          </div>
        </div>
      )}

      {whatsClicked?.userData?.type === "video" && (
        <div className="mt-2 px-2">
          <div className="rounded-md bg-white px-4 py-2 shadow-md">
            <p className="py-1">Animation</p>

            {/* CheckBox for Video animation enable    */}
            {whatsClicked?.userData?.type === "video" && (
              <div className="border-t-2">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isAnimationVideo}
                      onChange={() => {
                        const action = {
                          key: "isAnimation",
                          value: !isAnimationVideo,
                          id: whatsClicked?.userData?.id,
                        };
                        dispatch(SET_VIDEO_PROPERTIES(action));
                      }}
                    />
                  }
                  label={<span style={{ fontSize: 14 }}>Apply</span>}
                />
              </div>
            )}

            {/* Animation Editor Bar */}
            {whatsClicked?.userData?.type === "video" && isAnimationVideo && (
              <div className="mx-2 my-1 flex flex-col gap-4 bg-white text-sm ">
                <div className="flex flex-col justify-center ">
                  <label className="text-gray-900">Duration (ms)</label>
                  <input
                    id="duration"
                    type="number"
                    className="w-full border p-1"
                    value={animationDuration}
                    onChange={(event) => {
                      setAnimationDuration(event.target.valueAsNumber);
                    }}
                    onBlur={() => {
                      const action = {
                        parentKey: "Animation",
                        key: "duration",
                        value: animationDuration,
                        id: whatsClicked?.userData?.id,
                      };
                      dispatch(SET_VIDEO_PROPERTIES(action));
                    }}
                  />
                </div>
                <div className="flex flex-col justify-center ">
                  <label className="text-gray-900">Delay (ms)</label>
                  <input
                    id="delay"
                    type="number"
                    className="w-full border p-1"
                    value={animationDelay}
                    onChange={(event) => {
                      setAnimationDelay(event.target.valueAsNumber);
                    }}
                    onBlur={() => {
                      const action = {
                        parentKey: "Animation",
                        key: "delay",
                        value: animationDelay,
                        id: whatsClicked?.userData?.id,
                      };
                      dispatch(SET_VIDEO_PROPERTIES(action));
                    }}
                  />
                </div>
                <div className="flex flex-col justify-center gap-2 ">
                  <label className="text-gray-900">Direction</label>
                  <select
                    name=""
                    id=""
                    value={
                      ardata?.videos?.find(
                        (data) => data?.id === whatsClicked?.userData?.id
                      )?.animation.direction || ""
                    }
                    className="p-1"
                    onChange={(event) => {
                      const action = {
                        parentKey: "Animation",
                        key: "direction",
                        value: event.target.value,
                        id: whatsClicked?.userData?.id,
                      };
                      dispatch(SET_VIDEO_PROPERTIES(action));
                    }}
                  >
                    <option value="topToBottom">Top to Bottom</option>
                    <option value="leftToRight">Left to Right</option>
                    <option value="rightToLeft">Right to Left</option>
                    <option value="bottomToTop">Bottom to Top</option>
                  </select>
                </div>
              </div>
            )}
          </div>
        </div>
      )}

      <AddIcon open={open} handleClose={handleClose} whatsClicked={whatsClicked}/>
    </div>
  );
}



const AddIcon=({open,handleClose,whatsClicked})=>{
  const dispatch=useDispatch();
  const userToken = useSelector((state) => state.auth.auth.token);
  const user = useSelector((state) => state.auth.auth.user);
  const arData = useSelector((state) => state.ar.data);
  
const [preview,setPreview]=useState(false)
const [loading,setLoading]=useState(false)

  const handleFileUpload = async (e) => {
    try {
      setLoading(true);
      const files = e.target.files;

      if (files && files.length > 0) {
        const imageFile = files[0];
        const allowedTypes = ["image/jpeg", "image/png", "image/jpg", '.glb' ,'model/gltf-binary'];

        if (!allowedTypes.includes(imageFile.type)) {
          setLoading(false);
          toast.error("Please select a .jpg, .jpeg, .png, .glb or .gltf file");
          return;
        }

        const fileSizeInMB = imageFile.size / (1024 * 1024);
        if (fileSizeInMB > 2) {
          setLoading(false);
          toast.error("File size should be less than or equal to 2 MB");
          return;
        }

        const formData = new FormData();
        const options = {
          maxSizeMB: 1,
          maxWidthOrHeight: 1920,
          useWebWorker: true,
        };

        try {

          const compressedBlob = await imageCompression(imageFile, options);
          console.log("Compressed file:", compressedBlob);
          console.log(
            `Compressed file size: ${compressedBlob.size / 1024 / 1024} MB`
          );

          
          const compressedFile = new File([compressedBlob], imageFile.name, {
            type: imageFile.type,
            lastModified: Date.now(),
          });

          formData.append("file", compressedFile);
          formData.append("assetType", "icons");
          const fileName = compressedFile.name
            .split(".")
            .slice(0, -1)
            .join(".");
          console.log("File name:", fileName);

          const response = await instance.post(
            `/api/ar/upload/${user.username}/${arData.name}`,
            formData,
            {
              headers: {
                authorization: `Bearer ${userToken}`,
                "Content-Type": "multipart/form-data",
              },
            }
          );

          if (response.status === 200) {
            const texture = await loadTexture(response?.data?.url);
          const textureWidth = texture.image.width;
          const textureHeight = texture.image.height;
          const aspectRatio = textureWidth / textureHeight;
          const baseSize = 0.3;

            dispatch(
              UPDATE_ICONS({
                parentId:whatsClicked?.userData?.id,
                id: uuidv4(),
                type: "icons",
                name: fileName,
                iconType:'video',
                src: response?.data?.url,
                position: { x: 0, y: 0, z: 0 },
                rotation: { x: -Math.PI / 2, y: 0, z: 0 },
                scale: { x: aspectRatio * baseSize, y: baseSize, z: baseSize },
                isAnimation: false,
                linkType: "",
                isLink: false,
                iconLink: "",
                animation: {
                  duration: 0,
                  delay: 0,
                  direction: "topToBottom",
                },
                isvCard: false,
                vCard: "",
                vCardJson: {
                  firstName: user?.fullName?.split(" ")[0] ?? "",
                  lastName: user?.fullName?.split(" ")[1] ?? "",
                  photo: user?.image ?? "",
                  organization: "",
                  email: user?.email || "",
                  workPhone: user?.phoneNumber || "",
                  title: "",
                  url: "",
                  note: user?.category?.join(', '),
                  cellPhone: user?.phoneNumber || "",
                  socialUrls: {
                    custom: "",
                    facebook: "",
                    linkedIn: "",
                    twitter: "",
                  },
                  homeAddress: {
                    street: "",
                    city: "",
                    state: "",
                  },
                  workAddress: {
                    street: "",
                    city: "",
                    state: "",
                  },
                  workUrl: "",
                },

                positionFullscreen: 'topRight'
              })
            )

            

         
            toast.success("File is uploaded successfully");
            handleClose();
          } else {
            throw new Error(`Unexpected response status: ${response.status}`);
          }
        } catch (compressionError) {
          console.error("Compression error:", compressionError);
          toast.error("File compression failed");
        }
      }
    } catch (error) {
      console.error("File upload error:", error);
      toast.error("File is not uploaded");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
    open={open}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
  >
    <Box sx={style}>
  <div className="flex items-center justify-between rounded-t-lg bg-gray-200 p-2">
    <p className="text-sm font-bold">Immarsify</p>
    <span onClick={handleClose} className="cursor-pointer">
      <MdOutlineClear size={20} />
    </span>
  </div>

  <div className="flex h-full w-full flex-col items-center justify-center gap-2 py-6">
    <label
      htmlFor="fileInput2"
      className="flex items-center justify-center rounded-md px-2 pb-2 text-white dark:!border-navy-700 lg:pb-0"
    >
      <input
        type="file"
        id="fileInput2"
        accept="image/png, image/jpeg, image/jpg, .glb,model/gltf-binary"
        className="hidden"
        onChange={(e) => {
          handleFileUpload(e);
        }}
      />

      {preview.length === 0 ? (
        <button
          type="button"
          onClick={() => document.getElementById("fileInput2").click()}
          className="rounded-md bg-[#3d2fa9] px-3 py-2 text-sm text-white"
        >
          Select Image
        </button>
      ) : (
        <button
          type="button"
          onClick={() => document.getElementById("fileInput2").click()}
          className="rounded-md bg-[#3d2fa9] px-3 py-2 text-sm text-white"
        >
          Upload Icon
        </button>
      )}
    </label>

    <p className="text-sm mt-2 font-bold">
      Supported Format: JPEG, JPG, PNG
    </p>
    <span className="text-xs">
      File Should be less than or equal to 2 MB
    </span>
  </div>

  <CommonBackdrop
    title="Uploading Icon "
    description={"Please don't refresh the page, creating target image"}
    loading={loading}
  />
</Box>

  </Modal>
  );
}

