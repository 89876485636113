// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { browserPopupRedirectResolver, browserSessionPersistence, getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCy7_ahBjgltLMekQ1lv5lcUq5haB5VvFc",
  authDomain: "immarsify-f7aa4.firebaseapp.com",
  projectId: "immarsify-f7aa4",
  storageBucket: "immarsify-f7aa4.appspot.com",
  messagingSenderId: "90360172725",
  appId: "1:90360172725:web:2516ab6480ab1670416efc",
  measurementId: "G-TZ6RV1NHV1",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const auth = getAuth(app, {
  persistence: browserSessionPersistence,
  popupRedirectResolver: browserPopupRedirectResolver,
});
