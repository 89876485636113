import React, { useState, useEffect } from "react";
import {
  MdOutlineQrCodeScanner,
  MdDelete,
  MdNavigateNext,
} from "react-icons/md";

import { GrCaretNext, GrCaretPrevious } from "react-icons/gr";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { instance } from "common/Instance";

import { MdOutlineClear } from "react-icons/md";
import Skeleton from "@mui/material/Skeleton";

import toast from "react-hot-toast";
import { TbEdit } from "react-icons/tb";
import CommonBackdrop from "common/CommonBackdrop";
import { useSelector } from "react-redux";
import { IoArrowBackCircleSharp } from "react-icons/io5";

import { Drawer } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { FaCopy } from "react-icons/fa";
import QRCode from "qrcode.react";
import { useTheme } from "@mui/material/styles";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  height: 150,
  bgcolor: "background.paper",
  boxShadow: 24,
};

const MultisceneList = ({ multiscenehistoy, getMultisceneData, loading }) => {
  const [name, setName] = useState(null);
  const [openDelete, setOpenDelete] = useState(null);
  const [experiences, setExperiences] = useState([]);
  const [Scanner, setScanner] = useState(false);

  const handleScannerOpen = (name, experiencesList) => {
    setName(name);
    setExperiences(experiencesList);
    setScanner(true);
  };
  const handleScannerClose = () => {
    setScanner(false);
    setName(null);
  };

  const handleDeleteClose = () => setOpenDelete(null);

  return (
    <div className="mt-2 flex flex-col items-center justify-center gap-2">
      {loading ? (
        <div className="flex w-full flex-col gap-4">
          {[...Array(2)].map((element) => (
            <Skeleton
              variant="rectangular"
              width="full"
              height={50}
              className="rounded-md"
            />
          ))}
        </div>
      ) : (
        <>
          {" "}
          {multiscenehistoy?.map((element, index) => (
            <div
              key={index}
              className="z-0 flex h-[10px] w-full items-center justify-between  rounded-md border bg-white bg-gradient-to-br bg-cover px-2 py-6"
            >
              <p className="text-xs font-semibold ">{element?.name}</p>
              <div className="flex items-center justify-between gap-4 px-2">
                <MdOutlineQrCodeScanner
                  size={18}
                  className="cursor-pointer text-blue-400"
                  onClick={() =>
                    handleScannerOpen(element?.name, element?.experiences)
                  }
                />

                <MdDelete
                  size={18}
                  className="cursor-pointer  text-red-400"
                  onClick={() => setOpenDelete(element?._id)}
                />
              </div>
            </div>
          ))}
        </>
      )}

      <Delete
        _id={openDelete}
        handleClose={handleDeleteClose}
        getMultisceneData={getMultisceneData}
      />

      <ShowScanner
        open={Scanner}
        handleClose={handleScannerClose}
        name={name}
        experiences={experiences}
      />
    </div>
  );
};

const Delete = ({ _id, handleClose, getMultisceneData }) => {
  const [loading, setLoading] = useState(false);
  const userToken = useSelector((state) => state.auth.auth.token);

  const handleDelete = async (_id) => {
    setLoading(true);
    console.log(_id);
    try {
      const response = await instance.delete(`api/multi/delete_multi/${_id}`, {
        headers: {
          authorization: `Bearer ${userToken}`,
        },
      });

      if (response.status === 200) {
        handleClose();
        setLoading(false);
        toast.success(response?.data?.message);
        getMultisceneData();
      }
    } catch (error) {
      handleClose();
      setLoading(false);

      toast.error(error?.response?.data?.message ?? error.message);
    }
  };

  

  return (
    <>
      <Modal
        open={_id}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="rounded-lg">
          <div className="flex items-center justify-between rounded-t-lg  bg-gray-200 p-2">
            <p className="text-lg font-bold ">Immarsify</p>
            <span onClick={handleClose} className="cursor-pointer">
              <MdOutlineClear size={15} />
            </span>
          </div>
          <p className="text-md p-4">Are you sure, you want to delete ?</p>

          <div className="absolute bottom-2 right-2 mt-2 flex items-center justify-center gap-2 p-2 ">
            <button
              className="rounded-lg border bg-blue-500 px-4 py-2 text-white "
              onClick={() => handleDelete(_id)}
            >
              yes
            </button>
            <button
              className="rounded-lg border border-gray-500 px-4 py-2 text-gray-900 "
              onClick={handleClose}
            >
              No
            </button>
          </div>
        </Box>
      </Modal>
      <CommonBackdrop
        title="Deleting Multiscene"
        description={"Please wait do not refresh page..."}
        loading={loading}
      />
    </>
  );
};

const ShowScanner = ({ open, handleClose, name, experiences }) => {
  const user = useSelector((state) => state.auth.auth.user);
  const userid = useSelector((state) => state.auth.auth.user._id);
  const userToken = useSelector((state) => state.auth.auth.token);
  const [currentPhotoIndex, setCurrentPhotoIndex] = useState(0);
  const [historyData, setHistoryData] = useState([]);

  const getHistoryData = async () => {
    try {
      const response = await instance.get(`api/ar/ar_history/${userid}?page=${1}&limit=5000`, {
        headers: {
          authorization: `Bearer ${userToken}`,
        },
      });
      if (response.status === 200) {
        setHistoryData(response.data.data);
       
      }
    } catch (error) {}
  };

  useEffect(() => {
    getHistoryData();
  }, []);

 

  const photos = historyData
    .filter((element) => experiences.includes(element._id))
    .map((element) => element?.targetImage?.src);


    console.log(photos,'as1')

  const handlePrev = () => {
    setCurrentPhotoIndex((prevIndex) =>
      prevIndex > 0 ? prevIndex - 1 : photos.length - 1
    );
  };
  const handleNext = () => {
    setCurrentPhotoIndex((prevIndex) =>
      prevIndex < photos.length - 1 ? prevIndex + 1 : 0
    );
  };

  const copyToClipboard = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        toast.success("Link is copied ");
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message ?? error.message);
      });
  };

  const downloadQRCode = () => {
    const qrCodeElement = document
      .getElementById("qrCode")
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");

    if (qrCodeElement) {
      let aEl = document.createElement("a");
      aEl.href = qrCodeElement;
      aEl.download = "QR_Code.png";
      document.body.appendChild(aEl);
      aEl.click();
      document.body.removeChild(aEl);
    }
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const qrCodeSize = 200;

  return (
    <Drawer anchor="right" open={open} onClose={handleClose}>
      <Box
        sx={{
          width: isMobile ? "100%" : "100%",
          height: "100%",

          [theme.breakpoints.up("md")]: {
            width: isMobile ? "100%" : "100%",
          },
        }}
        className="shadow-4xl relative flex flex-col justify-between gap-4 overflow-y-auto px-2 outline-none md:flex-row"
      >
        <div className="mt-4 flex h-full w-full flex-col items-center  gap-1 p-0">
          <div>
            <h2 className="flex items-center gap-2 p-2 text-3xl font-bold text-gray-700">
              {" "}
              <IoArrowBackCircleSharp
                size={35}
                color="#0D47A1"
                onClick={handleClose}
                className="cursor-pointer"
              />{" "}
              Scan Your Target Image
            </h2>
            <p className="p-2 text-sm font-semibold text-gray-900">
              Scan the Image using our AR Mobile Application or Web AR Link{" "}
              <a>Learn More</a>
            </p>
          </div>

          <div className="flex h-full w-[100%] flex-col items-center  justify-between gap-1 rounded-lg border-2">
            <div className="mx-5 mt-2 md:mt-5  max-w-[350px] ">
              <img
                src={photos[currentPhotoIndex]}
                alt={`number ${currentPhotoIndex + 1}`}
                className=" w-[350px]  object-cover "
              />
              <div className="mt-2 flex items-center justify-center gap-5">
                <button
                  className="flex items-center justify-center rounded-md border bg-blue-900 p-1 text-white "
                  onClick={handlePrev}
                >
                  <GrCaretPrevious />
                </button>
                <button
                  className="flex items-center justify-center rounded-md border bg-blue-900 p-1 text-white "
                  onClick={handleNext}
                >
                  <GrCaretNext />
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="h-full w-full ">
          <div>
            <div className="flex flex-col  items-center gap-4">
              <div className=" text-md  flex w-full items-center justify-center  rounded-md border border-blue-400  bg-blue-900 py-2 text-white ">
                WEB AR
              </div>

              <div className="-mx-2 -mt-2  h-full w-full  rounded-md border-2 ">
                <h2 className="mt-1 text-center text-2xl font-bold text-gray-900">
                  Experience AR in Web
                </h2>
                <p className="px-4 py-4 text-center text-xs ">
                  Kindely Copy the Following link and paste it into your Phone
                  Browser
                </p>

                <div className="mt-2 flex items-center justify-center gap-1 px-4 ">
                  <input
                    type="text"
                    className="text-md w-full rounded-md border  bg-gray-200 px-2 py-2 "
                    value={`https://ar.immarsify.com/multi/${user.username}/${name}`}
                  />
                  <span>
                    <FaCopy
                      size={20}
                      className="cursor-pointer"
                      onClick={() =>
                        copyToClipboard(
                          `https://ar.immarsify.com/multi/${user.username}/${name}`
                        )
                      }
                    />
                  </span>
                </div>

                <div className="mb-1 mt-1  ">
                  <div className=" flex flex-col items-center justify-center gap-2">
                    <p className=" w-full py-2 pb-10 text-center text-lg">
                      Scan the QR code with your mobile camera
                    </p>

                    <QRCode
                      value={`https://ar.immarsify.com/multi/${user.username}/${name}`}
                      fgColor="#1A3066"
                      className="text-[#1A3066]"
                      id="qrCode"
                      size={qrCodeSize}
                    />
                    <div className="flex items-center justify-between gap-4">
                      <button
                        onClick={downloadQRCode} // Call the download function on button click
                        className="mt-4  rounded-lg  bg-blue-900 px-4 py-2 text-xs text-white"
                      >
                        Download QR Code
                      </button>
                      <a
                        href={`https://ar.immarsify.com/multi/${user.username}/${name}`}
                        target="_blank"
                        className="mt-4  rounded-md  border border-gray-900 px-4 py-2 text-xs text-gray-900"
                      >
                        Open via link
                      </a>
                    </div>

                    <ol className="mt-2 flex flex-col gap-1" type="1">
                      <li className="text-sm">
                        Open the Camera on your Mobile and Tablet
                      </li>
                      <li className="text-sm">Point your camera to QR code</li>
                      <li className="text-sm">
                        Copy the URL and paste it to your Mobile web browser
                      </li>
                      <li className="text-sm">
                        Scan your target image using Immarsify viewer
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Box>
    </Drawer>
  );
};

export default MultisceneList;
