import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useSelector } from "react-redux";
import { instance } from "common/Instance";
import moment from "moment";
import { Button } from "@mui/material";
import { useNavigate, useRouteError } from "react-router-dom";

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
  createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
  createData("Eclair", 262, 16.0, 24, 6.0),
  createData("Cupcake", 305, 3.7, 67, 4.3),
  createData("Gingerbread", 356, 16.0, 49, 3.9),
];

// Subscirption name, started date, end date, , status, amount action

export default function BillingHistory() {
  const navigate = useNavigate();
  const [isInvoiceLoading, setIsInvoiceLoading] = React.useState(false);
  const [history, setHistory] = React.useState(null);
  const authToken = useSelector((state) => state?.auth?.auth?.token);
  const getPurchaseDetails = async () => {
    try {
      setIsInvoiceLoading(true);
      const { data } = await instance.get(`api/user/user_subs_history`, {
        headers: {
          authorization: `Bearer ${authToken}`,
        },
      });
      console.log(data?.data, "data");

      const udpatedData = data?.data[0]?.subscriptionsList?.map((sub) => {
        if (sub?.subscriptionDetail?.subscription?.entity) {
          const data = sub?.subscriptionDetail?.subscription?.entity;
          data.PlanName = data?.notes?.planType;
          data.PlanAmount =
            sub?.subscriptionDetail?.payment?.entity?.amount / 100;
          console.log(
            data,
            sub?.subscriptionDetail?.subscription?.payment?.entity?.amount,
            "this isdata"
          );
          data._id = sub?._id;
          return data;
        } else {
          const data = sub?.subscriptionDetail;
          data._id = sub?._id;
          return data;
        }
      });
      setHistory(udpatedData, "this isudpatd data");
      console.log(udpatedData);

      setIsInvoiceLoading(false);
    } catch (error) {
      setIsInvoiceLoading(false);

      console.log(error);
    }
  };

  React.useEffect(() => {
    getPurchaseDetails();
  }, []);
  return (
    <TableContainer component={Paper}>
      {isInvoiceLoading ? (
        <div className="flex  items-center justify-center">
          <h3 className="p-2 text-center">Loading...</h3>
        </div>
      ) : (
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Subscription Name</TableCell>
              <TableCell>Started on</TableCell>
              <TableCell>Ends on</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Amount</TableCell>
              <TableCell align="center">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {history && history.length > 0 ? (
              history.map((row) => (
                <TableRow key={row?._id}>
                  <TableCell component="th" scope="row">
                    {row?.PlanName}
                  </TableCell>
                  <TableCell>
                    {moment.unix(row?.current_start).format("DD-MM-YYYY")}
                  </TableCell>
                  <TableCell>
                    {moment.unix(row?.current_end).format("DD-MM-YYYY")}
                  </TableCell>
                  <TableCell>
                    <span
                      className={`${
                        row?.status === "active"
                          ? "text-green-600"
                          : "text-red-600"
                      }`}
                    >
                      {row?.status}
                    </span>
                  </TableCell>
                  <TableCell>{row?.PlanAmount}</TableCell>
                  <TableCell align="center">
                    <button
                      onClick={() => {
                        navigate(`/admin/billing/${row?._id}`);
                      }}
                      className="rounded-md bg-brand-main p-2 text-white"
                    >
                      View
                    </button>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={6} align="center">
                  <h3 className="p-2 text-center">No subscription found</h3>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      )}
    </TableContainer>
  );
}
