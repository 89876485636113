import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useNavigate, useParams } from "react-router-dom";
import { instance } from "common/Instance";
import toast from "react-hot-toast";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import CircularProgress from "@mui/material/CircularProgress";
import { Link } from "react-router-dom";

function UserSignUp() {
  const [passwordStrength, setPasswordStrength] = useState("");
  const [loading, setLoading] = useState(false);
  const [checkToken, setCheckToken] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const navigate = useNavigate();
  const { token } = useParams();

  console.log(token);

  useEffect(() => {
    confirmToken();
  }, []);

  const confirmToken = async () => {
    try {
      const response = await instance.get(
        `/api/auth/check_create_password_token/${token}`
      );

      if (response.status === 200) {
        setCheckToken(response?.data?.valid);
      }
    } catch (error) {
      setCheckToken(false);
      toast.error(error?.response?.data?.message ?? error.message);
    }
  };

  const handleSubmit = async (values) => {
    // Handle form submission
    setLoading(true);
    console.log(values);

    const data = { password: values.newPassword };
    try {
      if (checkToken) {
        const response = await instance.post(
          `/api/auth/set_password/${token}`,
          data
        );
        if (response.status === 200) {
          setLoading(false);
          toast.success(response.data?.message);
          if (response) {
            navigate("/auth/sign-in");
          }
        }
      }
    } catch (error) {
      setLoading(false);
      toast.error(error?.response?.data?.message ?? error.message);
    }
  };

  const handlePasswordChange = (event, setFieldValue) => {
    const password = event.target.value;
    let strength = "Weak";

    // Check for complexity using regular expressions
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasNumbers = /\d/.test(password);
    const hasSpecialChars = /[@$!%*?&#]/.test(password);

    const complexityCount = [
      hasUpperCase,
      hasLowerCase,
      hasNumbers,
      hasSpecialChars,
    ].filter(Boolean).length;

    // Determine password strength based on complexity
    if (complexityCount >= 4) {
      strength = "Strong";
    } else if (complexityCount >= 3) {
      strength = "Medium";
    } else {
      strength = "Weak";
    }

    // Update the strength
    setPasswordStrength(strength);

    // Update the newPassword field value
    setFieldValue("newPassword", password);
  };
  const validationSchema = Yup.object().shape({
    newPassword: Yup.string()
      .min(4, "Password must be at least 4 characters")
      .required("New password is required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
      .required("Confirm password is required"),
  });

  return (
    <>
      {checkToken ? (
        <div className="mb-16 mt-16 md:mt-32 xl:max-w-[420px]">
          <h1 className="text-4xl font-semibold text-blue-600">Set Password</h1>
          <Formik
            initialValues={{ newPassword: "", confirmPassword: "" }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ setFieldValue, handleSubmit }) => (
              <>
                <div className="relative mt-4">
                  <label
                    htmlFor="newPassword"
                    className="block text-sm font-medium text-gray-700"
                  >
                    New Password
                  </label>
                  <Field
                    id="newPassword"
                    name="newPassword"
                    type={showPassword1 ? "text" : "password"}
                    autoComplete="new-password"
                    onChange={(e) => handlePasswordChange(e, setFieldValue)} // Add onChange event handler
                    className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  />
                  <button
                    type="button"
                    onClick={() => setShowPassword1(!showPassword1)}
                    className="absolute right-3 top-[42px] -translate-y-1/2 transform"
                  >
                    {showPassword1 ? <FaEyeSlash /> : <FaEye />}
                  </button>

                  <ErrorMessage
                    name="newPassword"
                    component="div"
                    className="text-xs text-red-600"
                  />
                  {/* Display password strength */}
                  {passwordStrength && (
                    <div className="my-1 flex items-center gap-1 text-xs">
                      Password Strength:{" "}
                      <p
                        className={`${
                          passwordStrength === "Strong"
                            ? "text-green-600"
                            : "text-orange-600"
                        }`}
                      >
                        {passwordStrength}
                      </p>{" "}
                    </div>
                  )}
                </div>
                <div className="relative mt-4">
                  <label
                    htmlFor="confirmPassword"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Confirm Password
                  </label>
                  <Field
                    id="confirmPassword"
                    name="confirmPassword"
                    type={showPassword2 ? "text" : "password"}
                    autoComplete="confirm-password"
                    className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  />
                  <ErrorMessage
                    name="confirmPassword"
                    component="div"
                    className="text-xs text-red-600"
                  />

                  <button
                    type="button"
                    onClick={() => setShowPassword2(!showPassword2)}
                    className="absolute right-3 top-[42px] -translate-y-1/2 transform"
                  >
                    {showPassword2 ? <FaEyeSlash /> : <FaEye />}
                  </button>
                </div>

                <div className="mt-4">
                  <button
                    onClick={() => {
                      handleSubmit();
                    }}
                    className="flex items-center justify-center  rounded-md bg-blue-600 px-6 py-2 text-white hover:bg-blue-500 focus:bg-blue-600 focus:outline-none"
                  >
                    {loading ? (
                      <>
                        <CircularProgress sx={{ color: "white" }} size={20} />
                      </>
                    ) : (
                      "Submit"
                    )}
                  </button>
                </div>
              </>
            )}
          </Formik>
        </div>
      ) : (
        <div className="flex h-screen xl:max-w-[420px]">
          <div className="m-auto flex flex-col items-center justify-center gap-2 ">
            <h1 className="text-2xl font-semibold text-[#222]">
              OOPS, This link has already been used
            </h1>
            <p className="text-md">link is expired now</p>
            <Link
              to="/auth/sign-in"
              className="linear mt-2 flex w-full justify-center rounded-xl bg-brand-500 px-10 
                py-[6px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
            >
              Back to Login
            </Link>
          </div>
        </div>
      )}
    </>
  );
}
export default UserSignUp;
