import { useFocusEffect } from "@chakra-ui/hooks";
import { useItemHighlighted } from "@mui/x-charts";
import { instance } from "common/Instance";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import InvoicePDFGenerator from "./InvoiceGenerator";
import InvoiceComponent from "./GeneratePDF";
import Preview from "./Preview";

const InvoiceView = () => {
  const navigate = useNavigate();
  const userToken = useSelector((state) => state.auth.auth.token);
  const [isLoading, setIsLoading] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const [invoiceData, setInoviceData] = useState(null);

  const getInvoice = async () => {
    try {
      setIsLoading(true);
      const response = await instance.get(
        `/api/invoices/get_invoice/${searchParams.get("id")}`,
        {
          headers: {
            authorization: `Bearer ${userToken}`,
          },
        }
      );

      if (response.status === 200) {
        setIsLoading(false);

        console.log(response?.data);
        setInoviceData(response?.data);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };
  useEffect(() => {
    getInvoice();
  }, []);

  return (
    <div className="mx-auto max-w-5xl rounded-lg bg-white  shadow-lg">



    {/* { invoiceData && <Preview invoiceData={invoiceData}/>} */}
    { invoiceData && <InvoiceComponent invoiceData={invoiceData}/>}
    {/* { invoiceData && <InvoicePDFGenerator invoiceData={invoiceData}/>} */}

    </div>
  );
};

export default InvoiceView;
