import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

const rootSlice = createSlice({
  name: 'root',
  initialState,
  reducers: {
    LOGOUT: (state) => {
      Object.keys(state).forEach((key) => {
        state[key] = initialState[key];
      });

     
    },
  },
});

export const { LOGOUT } = rootSlice.actions;
export default rootSlice.reducer;
