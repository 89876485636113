import moment from "moment";
import React from "react";
import { IoIosCheckmarkCircle } from "react-icons/io";
import { useSelector } from "react-redux";

const SummaryDetails = ({ invoiceDetails, planAmount, features }) => {
  console.log(features, "this is features");
  const userData = useSelector((state) => state?.auth?.auth?.user);

  return (
    <div>
      <div>
        <div className="flex justify-between text-xl font-bold">
          <h3>Description</h3>
          <p className="hidden sm:block">Amount</p>
        </div>
        <div className="my-2">
          <hr />
        </div>
        <div className="">
          <div>
            <h4 className="font-semibold">Your purchased plan details</h4>
            <p>
              Subscripton end date:{" "}
              {moment(invoiceDetails?.subscriptionsExpired).format(
                "DD/MM/YYYY"
              )}
            </p>
          </div>

          <div className="mt-4 flex  justify-between">
            <h3 className="text-2xl font-bold text-[#2152FF]">
              {invoiceDetails?.subscriptionDetails[0]?.PlanName}
            </h3>
            <p className="hidden font-bold sm:block ">₹{planAmount}/year</p>
          </div>
          <div>
            <p>Contagratulations!!! Now you have access for below points</p>
          </div>

          <div className="mt-2 text-sm">
            {features &&
              features?.map((feature) => {
                return (
                  <p className="flex items-center gap-2">
                    <span>
                      {feature?.check === true ? (
                        <IoIosCheckmarkCircle className="text-green-600" />
                      ) : (
                        <IoIosCheckmarkCircle className="text-red-600" />
                      )}
                    </span>
                    <span>{feature?.text}</span>
                  </p>
                );
              })}
          </div>

          <hr className="my-2" />
          <div className="">
            <h2>Add-Ons</h2>
            <p className="flex items-center gap-2">
              {(userData?.allowedFeature &&
                userData?.allowedFeature?.ocr?.allowed) ||
              userData?.type !== "user" ? (
                <>
                  <span>
                    <IoIosCheckmarkCircle className="text-green-600" />
                  </span>
                  <span>OCR</span>
                </>
              ) : (
                <>
                  <span>
                    <IoIosCheckmarkCircle className="text-red-600" />
                  </span>
                  <span>OCR</span>
                </>
              )}
            </p>
          </div>
        </div>

        <div className="my-2">
          <hr className="border-y-1 border-black" />
        </div>

        <div className="flex justify-between font-bold">
          <p>Total Paid</p>
          <p>₹{planAmount}</p>
        </div>
      </div>
    </div>
  );
};

export default SummaryDetails;
