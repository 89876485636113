import React from "react";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import referral from "../../../assets/img/profile/refer.png";
import login from "../../../assets/Refer/p1.png";
import p2 from "../../../assets/Refer/p3.jpg";
import p3 from "../../../assets/Refer/p2.jpg";

export default function Refer() {
  const userName = useSelector((state) => state?.auth?.auth?.user?.username);

  const copyToClipboard = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        toast.success("Refer Link is copied ");
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message ?? error.message);
      });
  };

  return (
    <div className=" w-full rounded-lg bg-white p-4">
      <div className="relative w-full ">
        <di v className="mx-auto lg:grid lg:grid-cols-12 ">
          <div className="flex  flex-col justify-center px-4 lg:col-span-7 xl:col-span-6">
            <div className="flex max-w-max items-center space-x-2 rounded-full bg-gray-100 p-1 px-2.5">
              <div className="rounded-full bg-white p-1 px-2">
                <p className="text-2xl font-medium">😊</p>
              </div>
              <p className="text-sm font-medium">Sharing is Caring</p>
            </div>
            <h1 className=" mt-8 text-3xl font-bold tracking-tight text-[#001766] md:text-4xl lg:text-6xl">
              Sharing is Winning
            </h1>
            <p className="text-yolg mt-4 text-gray-900">
              Unlock your earning potential by referring others to our sub
              reseller program, let's embark on a rewarding journey filled with
              growth, success, and abundant earnings
            </p>
            <div className="mt-8 flex w-full flex-wrap items-start gap-2 space-x-2">
              <div className="w-8/12">
                <input
                  className="border-black/30 bg-transparent focus:ring-black/30 text-md flex w-full rounded-md border px-4 py-2 text-gray-800 placeholder:text-gray-600 focus:outline-none focus:ring-1 focus:ring-offset-1 disabled:cursor-not-allowed disabled:opacity-50"
                  type="email"
                  placeholder="Enter your email"
                  id="email"
                  value={`${window.location.origin}/#/auth/sign-up?refer=${userName}`}
                ></input>
              </div>

              <div>
                <button
                  type="button"
                  className="text-md  rounded-lg bg-brand-main px-4 py-2 font-semibold text-white"
                  onClick={() =>
                    copyToClipboard(
                      `${window.location.origin}/#/auth/sign-up?refer=${userName}`
                    )
                  }
                >
                  Copy Invite link
                </button>
              </div>
            </div>


            <div className='flex justify-start text-md py-5 mt-10'>
              <p>Create Experience By Referall</p>
            </div>

            <div className="flex w-full flex-wrap items-start gap-2 space-x-2">
              <div className="w-8/12">
                <input
                  className="border-black/30 bg-transparent focus:ring-black/30 text-md flex w-full rounded-md border px-4 py-2 text-gray-800 placeholder:text-gray-600 focus:outline-none focus:ring-1 focus:ring-offset-1 disabled:cursor-not-allowed disabled:opacity-50"
                  type="email"
                  placeholder="Enter your email"
                  id="email"
                  value={`${window.location.origin}/#/admin/form?reseller=${userName}`}
                ></input>
              </div>

              <div>
                <button
                  type="button"
                  className="text-md  rounded-lg bg-brand-main px-4 py-2 font-semibold text-white"
                  onClick={() =>
                    copyToClipboard(
                      `${window.location.origin}/#/admin/form?reseller=${userName}`
                    )
                  }
                >
                  Copy Invite link
                </button>
              </div>
            </div>



          </div>
          <div className="relative rounded-t-2xl  lg:col-span-5 xl:col-span-6">
            <img className="p-10   " src={referral} alt="" />
          </div>
        </di>
      </div>

      <div className="mx-auto mb-20 mt-10 px-2 lg:px-8">
        <h4 className="my-5 py-2 text-center text-2xl font-semibold text-gray-700">
          How Does it work ?
        </h4>
        <div className="grid grid-cols-1 gap-y-8 text-center sm:grid-cols-2 sm:gap-12 lg:grid-cols-3">
          <div className=" rounded-lg border  px-4 text-center  shadow-xl ">
            <div className="flex items-center justify-center">
              <img src={login} alt="login" className="w-[150px] " />
            </div>

            <div>
              <h5 className="px-10 py-2 text-xs font-medium  text-gray-800">
                Login to immarsify app and check your referall link and Copied
              </h5>
            </div>
          </div>

          <div className=" rounded-lg border  px-4 text-center  shadow-xl ">
            <div className="flex items-center justify-center">
              <img src={p2} alt="login" className="w-[200px] " />
            </div>

            <div className="mt-4">
              <h5 className="py-2 text-xs font-medium text-gray-800 ">
                {" "}
                Share this with your friends{" "}
              </h5>
            </div>
          </div>
          <div className=" rounded-lg border  px-4 text-center  shadow-xl ">
            <div className="flex items-center justify-center">
              <img src={p3} alt="login" className="w-[150px] " />
            </div>

            <div className="mt-4">
              <h5 className="py-2 text-xs font-medium text-gray-800 ">
                now sit back and earn rewards and commissions from them
              </h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
