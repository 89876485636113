/* eslint-disable */

import { HiX } from "react-icons/hi";
import Links from "./components/Links";
import logo from "../../assets/svg/logo.svg";

import PaymentCard from "components/sidebar/components/SidebarCard";
import routes from "routes.js";

const Sidebar = ({ open, onClose }) => {
  return (
    <div
      className={`sm:none duration-175 linear fixed !z-50 flex min-h-full -translate-x-96 flex-col border-r-2 border-r-gray-200 bg-white pb-10 shadow-2xl shadow-white/5 transition-all dark:!bg-navy-800 dark:text-white md:!z-50 lg:!z-50 xl:!z-0 xl:flex  xl:translate-x-0 ${
        open ? "translate-x-0 " : "-translate-x-96 "
      }`}
    >
      <span
        className="absolute right-4 top-4 block cursor-pointer xl:hidden"
        onClick={onClose}
      >
        <HiX />
      </span>

      <div className="-my-4 mx-auto  max-w-[250px]">
        <img
          src={logo}
          alt="Logo"
          className="h-15 w-15  max-w-[250px] shadow-gray-700"
        />
      </div>

      <div className=" mb-7 h-[2px] bg-gray-300 " />

      <ul className="mb-auto pt-1 px-2 overflow-y-scroll h-[calc(100vh-110px)]">
        <Links routes={routes} />
      </ul>

      <div className="flex justify-center">{/* <PaymentCard /> */}</div>

      {/* Nav item end */}
    </div>
  );
};

export default Sidebar;
