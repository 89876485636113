import { useEffect, useState } from "react";
import { columnsDataCheck } from "./variables/columnsData";
import CheckTable from "views/admin/Reseller/components/CheckTable";
import { useSelector } from "react-redux";
import { instance } from "common/Instance";
import Switch from "components/switch";
import CommonBackdrop from "common/CommonBackdrop";
import toast from "react-hot-toast";

const label = { inputProps: { "aria-label": "Switch demo" } };

const Userlist = () => {
  return (
    <div className=" grid grid-cols-1 gap-5  md:grid-cols-1">
      <CheckTable />
    </div>
  );
};

export default Userlist;
