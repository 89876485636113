import React, { useEffect, useRef, useState } from "react";
import BillingDetails from "./billingComponents/billingDetails";
import { Paper } from "@mui/material";
import PlanDetails from "./billingComponents/planDetails";
import ReactToPrint from "react-to-print";
import SummaryDetails from "./billingComponents/summaryDetails";
import CompanyDetails from "./billingComponents/companyDetails";
import { instance } from "common/Instance";
import { useSelector } from "react-redux";
import { FaPrint } from "react-icons/fa";
import { useParams } from "react-router-dom";

const Billing = () => {
  const [isInvoiceLoading, setIsInvoiceLoading] = useState(false);
  const componentRef = useRef();
  const [planAmount, setPlanAmount]=  useState(0)
  const [features, setFeatures] = useState([])
  const [invoiceDetails, setInvoiceDetails] = useState(null);
  const authToken = useSelector((state) => state?.auth?.auth?.token);
const {id} = useParams()
  const getPurchaseDetails = async () => {

    try {
      setIsInvoiceLoading(true);
      const { data } = await instance.get(`api/user/user_invoice`, {
        headers: {
          authorization: `Bearer ${authToken}`,
        },
      });
      console.log(data?.data, "data");
      
      if(data?.data[0]?.planDetails && data?.data[0]?.planDetails?.length>0){


        console.log("cam here in plan details", data?.data[0]?.planDetails[0]?.subscriptionDetail)

        if(data?.data[0]?.planDetails[0]?.subscriptionDetail?.payment_method==="Cash"){
          console.log(data?.data[0]?.planDetails[0]?.subscriptionDetail?.PlanAmount, "cam in cash"
          )
          setPlanAmount(data?.data[0]?.planDetails[0]?.subscriptionDetail?.PlanAmount);
        

        }else if(data?.data[0]?.planDetails[0]?.subscriptionDetail?.subscription && data?.data[0]?.planDetails[0]?.subscriptionDetail?.payment){
          const amount  =    data?.data[0]?.planDetails[0]?.subscriptionDetail?.payment?.entity?.amount
          console.log("came in online ", amount)
          setPlanAmount(amount/100);


        }
      
console.log(data?.data[0]?.subscriptionDetails[0], "this is this is subscirpiton")

        if(data?.data[0]?.subscriptionDetails[0]?.features){
          setFeatures(data?.data[0]?.subscriptionDetails[0]?.features);

        }


        setInvoiceDetails(data?.data[0])
      }else{
        setInvoiceDetails(null)
      }




      // setInvoiceDetails(data?.data[0]);

   
      setIsInvoiceLoading(false);
    } catch (error) {
      setIsInvoiceLoading(false);

      console.log(error);
    }
  };

  useEffect(() => {
    getPurchaseDetails();
  }, [id]);
  return (
    <Paper className=" md:container md:px-8 py-4">
      {isInvoiceLoading ? (
        <div className="mt-8">
          <h2 className="text-center">Generatting invoice </h2>
        </div>
      ) : invoiceDetails &&
        invoiceDetails?.isSubscriptionActive === "active" ? (
        <div>
          <div className="">
            <h2 className="text-center text-2xl">Thank you for purchase</h2>
            <p className="text-center">
              Below your details about the subscription
            </p>
          </div>

          <div className="px-2 md:px-8" ref={componentRef}>
            <div className="">
              <BillingDetails
                componentRef={componentRef}
                invoiceDetails={invoiceDetails}
                planAmount={planAmount}
              />{" "}
            </div>
            <div>
              <PlanDetails invoiceDetails={invoiceDetails}   planAmount={planAmount}/>
            </div>
            <div>
              <SummaryDetails invoiceDetails={invoiceDetails}   planAmount={planAmount}  features={features}/>
            </div>

            <div>
              <CompanyDetails />
            </div>
          </div>

          <p className="flex items-center justify-center gap-2 ">
            Print/Download
            <span className="cursor-pointer" onClick={() => {}}>
              <ReactToPrint
                trigger={() => <FaPrint />}
                content={() => componentRef.current}
              />
            </span>
          </p>
        </div>
      ) : (
        <div>
          <h2 className="mt-8 text-center">
            Something went wrong while generating invoice{" "}
          </h2>
        </div>
      )}
    </Paper>
  );
};

export default Billing;
