import { useState, useEffect } from "react";
import Widget from "components/widget/Widget";
import { MdBarChart } from "react-icons/md";
import { instance } from "common/Instance";
import toast from "react-hot-toast";
import Pagination from "@mui/material/Pagination";
import { useSelector } from "react-redux";

const Commission = () => {
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [itemsPerPage, setSelectedValue] = useState(5);
  const authToken = useSelector((state) => state?.auth?.auth?.token);
  const [search, setSearch] = useState("");
  const [searchParam, setSearchParam] = useState("");
  const [totalCommission, setTotalCommission] = useState();
  const [totalUserCount, setTotalUserCount] = useState(0);
  const [totalAmount, setTotalAmount] = useState();
  const [totalExpCount, setTotalExpCount] = useState();
  const [listOfUser, setListOfUser] = useState();
  const [years, setYears] = useState([]);
  const [selectedYear, setSelectedYear] = useState("");
  const [selectedMonth, setSelectedMonth] = useState("");
  const [commission,setCommission]=useState('')
  const [totalUsers,setTotalUsers]=useState(0)

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  useEffect(() => {
    // Get the current year and month
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth(); // Returns month index (0 for January, 11 for December)

    // Generate an array of the last 5 years
    const yearList = [];
    for (let i = 0; i <= 5; i++) {
      yearList.push(currentYear - i);
    }
    setYears(yearList);

    // Set default selections to the current year and month
    // setSelectedYear(currentYear);
    // setSelectedMonth(months[currentMonth]);
  }, []);

  const handleYearChange = (e) => {
    setSelectedYear(e.target.value);
  };

  // Handle month change
  const handleMonthChange = (e) => {
    setSelectedMonth(e.target.value);
  };

  const handleSearch = (e) => {
    const inputValue = e.target.value;
    setSearch(inputValue);
    if (!inputValue || inputValue.trim() === "") {
      setSearchParam("");
    } else {
      setSearchParam(inputValue.trim());
    }
  };

  const handleChanges = (event, value) => {
    setCurrentPage(value);
  };

  const handleChange = (e) => {
    setSelectedValue(e.target.value);
  };

  const fetchCommissionData = async () => {
    setLoading(true);

    try {
      const response = await instance.get(
        `/api/reseller/calculate_commission?page=${currentPage}&limit=${itemsPerPage}&year=${selectedYear}&month=${selectedMonth}`,
        {
          headers: {
            authorization: `Bearer ${authToken}`,
          },
        }
      );
      setListOfUser(response?.data?.data[0]?.users);
      setTotalCommission(response?.data?.data[0]?.totalCommission);
      setTotalUserCount(response?.data?.totalCount ?? 0);
      setTotalAmount(response?.data?.data[0]?.totalPlanAmount);
      setTotalExpCount(response?.data?.data[0]?.totalQuantity);
      setTotalPages(response?.data?.totalPages);
      setCommission(response?.data?.data[0]?.commissionPercentage)
      setTotalUsers(response?.data?.totalCount)

      setLoading(false);
    } catch (error) {
      toast.error(error?.data?.message);
      setLoading(false);
      setListOfUser(0);
    }
  };

  useEffect(() => {
    fetchCommissionData();
  }, [currentPage, itemsPerPage, searchParam, selectedMonth, selectedYear]);

  return (
    <div>
      <div className="flex justify-end">
        <div className="flex w-[360px] items-center justify-end gap-2 pb-2">
          <div className=" w-full ">
            <select
              id="year"
              value={selectedYear}
              onChange={handleYearChange}
              className="block h-10 w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            >
              <option value={""}>Select Year</option>
              {years.map((year) => (
                <option key={year} value={year}>
                  {year}
                </option>
              ))}
            </select>
          </div>

          <div className="w-full max-w-sm">
            <select
              id="month"
              value={selectedMonth}
              onChange={handleMonthChange}
              className="block h-10 w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            >
              <option value={""}>Select Month</option>
              {months.map((month, index) => (
                <option key={index} value={month}>
                  {month}
                </option>
              ))}
            </select>
          </div>
          {(selectedMonth !== "" || selectedMonth !== "") && (
            <button
              onClick={() => {
                setSelectedMonth("");
                setSelectedYear("");
              }}
              className="text-md w-[300px] rounded-md bg-brand-main px-2 py-1 text-center font-semibold text-white"
            >
              Clear filter
            </button>
          )}
        </div>
      </div>
      <div className="grid grid-cols-1 gap-5 md:grid-cols-4 lg:grid-cols-4 2xl:grid-cols-4 3xl:grid-cols-4">
        <Widget
          icon={<MdBarChart className="h-7 w-7" />}
          title="Total User"
          subtitle={totalUserCount}
          loading={loading}
        />

        <Widget
          icon={<MdBarChart className="h-7 w-7" />}
          title="Total Commission"
          subtitle={totalCommission}
          loading={loading}
        />
        <Widget
          icon={<MdBarChart className="h-7 w-7" />}
          title="Total Amount"
          subtitle={totalAmount}
          loading={loading}
        />

        <Widget
          icon={<MdBarChart className="h-7 w-7" />}
          title="Total Exp. count"
          subtitle={totalExpCount}
          loading={loading}
        />
      </div>

      <div className="mt-4 rounded-lg bg-white p-2 shadow-lg ">
        <div className=" flex w-full flex-col justify-center gap-2 py-4">
          {/* <div className="relative flex  items-center justify-between">
            <div className="text-md font-bold text-navy-700 ">User list</div>
          </div> */}
          <div className="relative flex  w-full items-center py-1">
            <input
              className="w-[500px] rounded-lg border-2 p-2.5 text-sm outline-none"
              type="text"
              value={search}
              onChange={handleSearch}
              placeholder="Search"
            />

            <div className="flex w-full items-center justify-end ">
              <p className="text-sm">List per page:</p>
              <select
                class="ml-2 block h-8 w-12 rounded-md  border bg-gray-200  shadow-sm outline-none "
                placeholder="List"
                value={itemsPerPage}
                onChange={handleChange}
              >
                {[...Array(11).keys()].map((_, index) => (
                  <>
                    {index > 0 && (
                      <option key={index} value={index * 5}>
                        {index * 5}
                      </option>
                    )}
                  </>
                ))}
              </select>
            </div>
          </div>
        </div>

        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  className="w-2/12 px-4 py-2 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                >
                  Name
                </th>
                <th
                  scope="col"
                  className="w-2/12 px-4 py-2 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                >
                  Username
                </th>
                <th
                  scope="col"
                  className="w-3/12 px-4 py-2 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                >
                  Email
                </th>
                <th
                  scope="col"
                  className="px-4 py-2 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                >
                  Plan
                </th>
                <th
                  scope="col"
                  className="px-4 py-2 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                >
                  Commission
                </th>
                <th
                  scope="col"
                  className="px-4 py-2 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                >
                  Commission %
                </th>
                <th
                  scope="col"
                  className="px-4 py-2 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                >
                  Payment
                </th>
                <th
                  scope="col"
                  className="px-4 py-2 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                >
                  Exp. Count
                </th>
              </tr>
            </thead>

            <tbody className="divide-y divide-gray-200 bg-white">
              {loading ? (
                [...Array(7)].map((_, index) => (
                  <tr key={index}>
                    {Array(7)
                      .fill(null)
                      .map((_, cellIndex) => (
                        <td
                          key={cellIndex}
                          className="whitespace-nowrap px-4 py-3"
                        >
                          <div role="status" className="animate-pulse">
                            <div className="h-4 rounded-full bg-gray-200 dark:bg-gray-800"></div>
                          </div>
                        </td>
                      ))}
                  </tr>
                ))
              ) : listOfUser?.length > 0 ? (
                listOfUser.map((item, index) => (
                  <tr key={index}>
                    <td className="whitespace-nowrap px-4 py-3">
                      <div className="text-start text-sm font-medium text-gray-900 ">
                        {item?.fullName}
                      </div>
                    </td>
                    <td className="whitespace-nowrap px-4 py-3">
                      <div className="text-sm text-gray-900">
                        {item?.username}
                      </div>
                    </td>
                    <td className="whitespace-nowrap px-4 py-3 text-start">
                      <div className="text-sm text-gray-900">{item?.email}</div>
                    </td>
                    <td className="whitespace-nowrap px-4 py-3 text-start">
                      <div className="text-start text-sm text-gray-900">
                        {item?.subscriptionDetails[0]?.subscriptionDetail?.PlanAmount &&
                          item?.subscriptionDetails[0]?.subscriptionDetail?.PlanAmount *
                            (item?.allowedExperiencesCount ?? 1)}
                      </div>
                    </td>
                    <td className="whitespace-nowrap px-4 py-3">
                      <div className="text-sm text-gray-900">
                   {
                            item?.subscriptionDetails[0]?.subscriptionDetail?.totalCommission
                          }
                      </div>
                    </td>

                    <td className="whitespace-nowrap px-4 py-3 text-start">
                      <div
                        className={`text-start text-sm font-semibold text-gray-900`}
                      >
                        {
                            item?.subscriptionDetails[0]?.subscriptionDetail?.commissionPercentage && `${item?.subscriptionDetails[0]?.subscriptionDetail?.commissionPercentage } %`
                          } 
                      </div>
                    </td>

                    <td className="whitespace-nowrap px-4 py-3 text-start">
                      <div
                        className={`text-start text-sm font-semibold text-gray-900 ${
                          item.payment === "paid"
                            ? `text-green-400`
                            : `text-red-400`
                        }`}
                      >
                        {item?.payment}
                      </div>
                    </td>
                    <td className="whitespace-nowrap px-4 py-3 text-center">
                      <div className="text-center text-sm text-gray-900">
                        {item?.allowedExperiencesCount ?? 1}
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="12" className="max-w-full py-4 text-center">
                    <p className="bg-blue-100 px-10 py-5 text-sm text-blue-600">
                      Data Not Found!
                    </p>
                  </td>
                </tr>
              )}
            </tbody>
          </table>

          <div className="my-5 flex w-full items-center justify-end  text-sm">
            <Pagination
              count={totalPages}
              color="primary"
              page={currentPage}
              onChange={handleChanges}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Commission;
