import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { BsThreeDots } from "react-icons/bs";
import { Card, Divider, responsiveFontSizes } from "@mui/material";
import { FaEye } from "react-icons/fa";
import { CiEdit } from "react-icons/ci";
import { RiDeleteBin7Fill } from "react-icons/ri";
import InfoDrawer from "./drawer/Drawer";
import { IoChevronForwardSharp } from "react-icons/io5";
import { ConfirmDeleteModal } from "./modal/DeleteLead";
import { useSelector } from "react-redux";
import { instance } from "common/Instance";
import toast from "react-hot-toast";
import Fade from "@mui/material/Fade";
import { FiCalendar, FiFilter } from "react-icons/fi";
import { Calendar } from "react-calendar";
export default function ActionMenu({ leadInfo, fetchEnquiry, setCurrentPage }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [drawerType, setDrawerType] = React.useState("view");
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
  const open = Boolean(anchorEl);
  const authToken = useSelector((state) => state?.auth?.auth?.token);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleOpenDrawer = (type) => {
    setAnchorEl(null); // Close the menu when opening the drawer
    setDrawerType(type);
    setOpenDrawer(true);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null); // Just close the menu
  };

  const handleDeleteEnquiry = async () => {
    try {
      const response = await instance.delete(
        `api/inquiries/delete_inquiry/${leadInfo?._id}`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );

      if (response?.data?.code) {
        toast.success("Enquiry deleted successfully!");
        setOpenDeleteModal(false);

        fetchEnquiry();
        setCurrentPage(1);
      } else {
        toast.error("Something went wrong");
      }
    } catch (error) {
      console.error("Failed to delete enquiry", error);
      toast.error(error.response?.data?.message + "Something went wrong");
    }
  };

  return (
    <div className="flex justify-center">
      <BsThreeDots
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        className="cursor-pointer"
      />

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseMenu} // Close the menu without opening the drawer
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <MenuItem
          onClick={(e) => {
            e.stopPropagation();
            handleOpenDrawer("view"); // Open the drawer with 'view' type
          }}
          sx={{ fontSize: "14px" }}
        >
          <FaEye className="mr-2" /> View
        </MenuItem>

        <MenuItem
          onClick={(e) => {
            e.stopPropagation();
            handleOpenDrawer("edit"); // Open the drawer with 'edit' type
          }}
          sx={{ fontSize: "14px" }}
        >
          <CiEdit className="mr-2" /> Edit
        </MenuItem>
        <Divider />
        <MenuItem
          onClick={(e) => {
            e.stopPropagation();
            setAnchorEl(null); // Close the menu
            setOpenDeleteModal(true); // Open the delete modal
          }}
          sx={{ fontSize: "14px" }}
        >
          <RiDeleteBin7Fill className="mr-2" /> Delete
        </MenuItem>
      </Menu>

      <InfoDrawer
        openDrawer={openDrawer}
        setOpenDeleteModal={setOpenDeleteModal}
        setOpenDrawer={setOpenDrawer}
        drawerType={drawerType}
        setDrawerType={setDrawerType}
        leadInfo={leadInfo}
        fetchEnquiry={fetchEnquiry}
      />
      <ConfirmDeleteModal
        open={openDeleteModal}
        onClose={() => {
          setOpenDeleteModal(false);
        }}
        onConfirm={handleDeleteEnquiry}
      />
    </div>
  );
}

export function ActionBar({ setOpenLabel, handleOpen }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <button
        id="demo-positioned-button"
        aria-controls={open ? "demo-positioned-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        className="cursor-pointer rounded-md bg-brand-main px-2 py-2 text-white"
      >
        Actions
      </button>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <MenuItem onClick={handleClose}>
          <a
            className="cursor-pointer rounded-md bg-brand-main px-2 py-2 text-sm text-white"
            href="https://scan.immarsify.com/"
            target="_blank"
          >
            Create OCR
          </a>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          {" "}
          <button
            className="rounded-md bg-brand-main px-2 py-2 text-center text-sm  text-white"
            onClick={() => setOpenLabel(true)}
          >
            Manage Labels
          </button>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          {" "}
          <button
            className="rounded-md bg-brand-main px-2 py-2 text-center text-sm  text-white"
            onClick={handleOpen}
          >
            Add enquiry
          </button>
        </MenuItem>
      </Menu>
    </div>
  );
}

export function FilterDateMenu({
  setStartDateFilter,
  setEndDateFilter,
  handleFilter,
  startDate,
  endDate,
  setStartDate,
  setEndDate,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleApply = () => {
    if (!startDate) {
      return toast.error("Please select start date");
    }
    if (!endDate) {
      return toast.error("Please select end date");
    }
    handleFilter();
    handleClose();
  };

  return (
    <div className="relative">
      <button
        id="filter-button"
        aria-controls={open ? "filter-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        variant="contained"
        className="rounded-md bg-brand-main px-4 py-2 text-sm font-semibold text-white shadow-lg transition-colors  duration-200 hover:bg-blue-600"
        startIcon={<FiFilter className="h-5 w-5" />}
      >
        Filter by date
      </button>
      <Menu
        id="filter-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
        className="mt-2"
      >
        <div className="w-72 p-4">
          <div className="mb-4 flex items-center justify-between">
            <div className="flex items-center">
              <FiCalendar className="mr-2 h-5 w-5 text-gray-600" />
              <span className="font-medium">Select Date Range</span>
            </div>
          </div>
          <div className="space-y-4">
            <div>
              <label
                htmlFor="start-date"
                className="mb-1 block text-sm font-medium text-gray-700"
              >
                Start Date
              </label>
              <input
                type="date"
                id="start-date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                max={new Date().toISOString().split("T")[0]}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
              />
            </div>
            <div>
              <label
                htmlFor="end-date"
                className="mb-1 block text-sm font-medium text-gray-700"
              >
                End Date
              </label>
              <input
                type="date"
                id="end-date"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                min={startDate}
                max={new Date().toISOString().split("T")[0]}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
              />
            </div>
          </div>
          <div className="mt-4 flex justify-end gap-2">
            <button
              variant="outlined"
              onClick={handleClose}
              className="rounded-md border border-brand-main px-2 py-1 text-gray-900 hover:bg-gray-100"
            >
              Close
            </button>
            <button
              variant="contained"
              onClick={handleApply}
              className={`rounded-md ${
                startDate && endDate
                  ? "bg-brand-main hover:bg-blue-700"
                  : "bg-blue-400"
              } px-2 py-1 text-white `}
            >
              Apply
            </button>
          </div>
        </div>
      </Menu>
    </div>
  );
}
