import { instance } from "common/Instance";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { FaArrowLeft, FaCalendarAlt, FaChevronDown } from "react-icons/fa";
import { useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";

const ViewPaymentForm = () => {
  const [formData, setFormData] = useState({
    number: "1",
    date: "2024-05-10",
    amount: "",
    paymentMode: "",
    reference: "",
    description: "",
  });
  const userToken = useSelector((state) => state.auth.auth.token);

  const [invoiceId, setInvoiceId] = useState(null);
  const navigate = useNavigate();

  const [paymentData, setPaymentData] = useState("");

  const [searchParams, setSearchParams] = useSearchParams();

  const [errors, setErrors] = useState({});
  const [invoiceData, setInvoiceData] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const validate = () => {
    let tempErrors = {};
    if (!formData.number) tempErrors.number = "Number is required";
    if (!formData.date) tempErrors.date = "Date is required";
    if (!formData.amount) tempErrors.amount = "Amount is required";
    else if (parseFloat(formData.amount) <= 0)
      tempErrors.amount = "Amount must be greater than 0";
    if (!formData.paymentMode)
      tempErrors.paymentMode = "Payment mode is required";
    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      console.log(formData);
      // Handle form submission
    }
  };

  const getPaymentDetails = async () => {
    try {
      const response = await instance.get(
        `/api/paymentRecord/get_payment/${searchParams.get("id")}`,
        {
          headers: {
            authorization: `Bearer ${userToken}`,
          },
        }
      );
      console.log(response, "this is user of the user");
      if (response.status === 200) {
        console.log(response?.data, "this is response ");
        // const { createdAt, _id, resellerId, ...excluded } = response?.data;
        // setFormData(excluded);
        // setSelectedCustomer(excluded?.userDetails);
        setPaymentData(response?.data?.data);

        getInvoiceDetails(response?.data?.data?.invoice);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const getInvoiceDetails = async (id) => {
    try {
      const response = await instance.get(`/api/invoices/get_invoice/${id}`, {
        headers: {
          authorization: `Bearer ${userToken}`,
        },
      });
      console.log(response, "this is user of the user");
      if (response.status === 200) {
        console.log(response?.data, "this is response data");
        setInvoiceData(response?.data);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    getPaymentDetails();
  }, []);

  return (
    <div className="mx-auto max-w-3xl bg-gray-100 p-6">
      <div className="rounded-lg bg-white p-6 shadow-md">
        <div className="mb-6 flex items-center justify-between">
          <div className="flex items-center">
            <FaArrowLeft
              onClick={() => {
                navigate("/admin/payment");
              }}
              className="mr-2 h-5 w-5 cursor-pointer"
            />
            <h2 className="text-xl font-semibold">
              Payment Details # {searchParams.get("id")}
            </h2>
            {/* <span className="ml-2 rounded bg-purple-100 px-2 py-1 text-xs text-purple-700">
              Partially
            </span> */}
          </div>
          {/* <div>
            <button className="mr-2 rounded border border-gray-300 px-3 py-1">
              Cancel
            </button>
            <button className="rounded border border-gray-300 bg-brand-main px-3 py-1 text-white">
              Show Invoice
            </button>
          </div> */}
        </div>

        <form onSubmit={handleSubmit} className="grid grid-cols-2 gap-6">
          <div>
            {/* <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Number <span className="text-red-500">*</span>
              </label>
              <input 
                type="text" 
                name="number"
                value={formData.number}
                onChange={handleChange}
                className="w-full p-2 border border-gray-300 rounded"
              />
              {errors.number && <p className="text-red-500 text-xs mt-1">{errors.number}</p>}
            </div> */}
            <div className="mb-4">
              <label className="mb-1 block text-sm font-medium text-gray-700">
                Date <span className="text-red-500">*</span>
              </label>
              <div className="relative">
                {moment(paymentData?.created).format("DD/MM/YYYY")}
              </div>
            </div>
            <div className="mb-4">
              <label className="mb-1 block text-sm font-medium text-gray-700">
                Amount <span className="text-red-500">*</span>
              </label>
              <div className="relative">
                <p>₹{paymentData?.amount}</p>
              </div>
            </div>
            <div className="mb-4">
              <label className="mb-1 block text-sm font-medium text-gray-700">
                Payment Mode <span className="text-red-500">*</span>
              </label>
              <div className="relative">
                <p>{paymentData?.paymentMode}</p>
              </div>
            </div>

            <div className="mb-4">
              <label className="mb-1 block text-sm font-medium text-gray-700">
                Description
              </label>

              <p>{paymentData?.description}</p>
            </div>
            <button
              onClick={() => {
                navigate("/admin/payment");
              }}
              type="submit"
              className="rounded bg-brand-main px-4 py-1 text-white"
            >
              Go Back{" "}
            </button>
          </div>
          {invoiceData && (
            <div>
              <div className="space-y-6 rounded bg-gray-50 p-4">
                <h3 className="mb-2 font-semibold">
                  Client : {invoiceData?.userDetails?.fullName}
                </h3>
                <p className="mb-1 text-sm text-gray-900">
                  Email : {invoiceData?.userDetails?.email}
                </p>
                <p className="mb-1 text-sm text-gray-900">
                  Phone : {invoiceData?.userDetails?.Phone}
                </p>
                <hr />
                {/* <p className="text-sm text-gray-900 mb-1">
                Payment Status: <span className="px-2 py-1 text-xs bg-purple-100 text-purple-700 rounded">Partially</span>
              </p> */}
                <p className="mb-1 text-sm text-gray-900">
                  Sub Total: ₹ {invoiceData?.subTotal}
                </p>
                <p className="mb-1 text-sm text-gray-900">
                  Total: ₹ {invoiceData?.total}
                </p>
                <p className="mb-1 text-sm text-gray-900">
                  Extra: ₹ {invoiceData?.extra}
                </p>
                <p className="text-sm text-gray-900">
                  Paid: ₹ {invoiceData?.paid}
                </p>
              </div>
            </div>
          )}
        </form>
      </div>
    </div>
  );
};

export default ViewPaymentForm;
