export const services = [
    "Web Hosting",
    "Customer Support",
    "Data Backup",
    "Marketing Consultation",
    "SEO Optimization",
    "Email Hosting",
    "Graphic Design",
    "Content Writing",
    "Social Media Management",
    "IT Consulting",
    "Software Development",
    "Website Design",
    "Cloud Storage",
    "Network Security",
    "Database Management",
    "Virtual Assistant",
    "Online Advertising",
    "Project Management",
    "Accounting Services",
    "Payroll Management",
    "Legal Advice",
    "Business Coaching",
    "HR Consulting",
    "Public Relations",
    "Video Production",
    "Translation Services",
    "Technical Support",
    "Event Planning",
    "E-commerce Solutions",
    "Market Research",
    "Branding Services",
    "Copywriting",
    "Financial Planning",
    "Website Maintenance",
    "CRM Solutions",
    "Mobile App Development",
    "User Experience Design",
    "Business Analysis",
    "IT Support",
    "Cloud Computing",
    "Training and Development",
    "Sales Strategy",
    "Risk Management",
    "Recruitment Services",
    "Investment Advice",
    "Supply Chain Management",
    "Customer Relationship Management",
    "Inventory Management",
    "Business Development",
    "Voiceover Services",
    "Content Management"
  ]

  