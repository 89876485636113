import { QRCodeSVG } from "qrcode.react";
import React from "react";
import { LuNfc } from "react-icons/lu";
import Logo from "./logo/Logo";
import { IoLocationSharp } from "react-icons/io5";
import { SiGooglemaps } from "react-icons/si";
import { TbWorldWww } from "react-icons/tb";
import { MdEmail, MdOutlineMail } from "react-icons/md";
import { FaFacebook, FaInstagramSquare, FaLinkedin, FaMobileAlt, FaYoutube } from "react-icons/fa";
import { BsTwitterX } from "react-icons/bs";
import { FaPhoneFlip } from "react-icons/fa6";
const CardTwelve = React.forwardRef(
  ({ username, experiencename, name }, ref) => {
    return (
      <div
        ref={ref}
        className="relative   h-[250px] w-[400px] overflow-hidden  bg-gradient-to-br from-blue-500 to-purple-600 shadow-lg"
      >
        <div className="absolute right-2 top-[-5px]  z-10 flex items-center justify-end ">
          <LuNfc size={25} color="#DAA520" className="    top-3 " />
          {/* <img src={Logo} className="absolute left-4 top-2 w-[110px]" /> */}
          {/* <img src={Logo("#0067B1")} className=" right-1 top-4   h-4 w-7" /> */}

          <Logo color={"#DAA520"} height={35} width={35} />
        </div>
        {/* Complex SVG Background */}
        <svg
          className="absolute inset-0 h-full w-full"
          viewBox="0 0 400 225"
          xmlns="http://www.w3.org/2000/svg"
        >
          <defs>
            <filter id="noise" x="0%" y="0%" width="100%" height="100%">
              <feTurbulence
                type="fractalNoise"
                baseFrequency="0.65"
                numOctaves="3"
                stitchTiles="stitch"
              />
              <feBlend mode="overlay" in="SourceGraphic" />
            </filter>
          </defs>
          {/* <rect
            width="500px"
            height="100%"
            filter="url(#noise)"
            opacity="0.3"
            style={{ minWidth: "500px" }}
            className="mr-10"
          /> */}
          <path
            d="M0,50 Q100,25 200,75 T400,50"
            fill="none"
            stroke="rgba(255,255,255,0.3)"
            strokeWidth="2"
          />
          <path
            d="M0,100 Q100,150 200,100 T400,150"
            fill="none"
            stroke="rgba(255,255,255,0.2)"
            strokeWidth="3"
          />
          <circle cx="350" cy="50" r="20" fill="rgba(255,255,255,0.2)" />
          <circle cx="50" cy="150" r="30" fill="rgba(255,255,255,0.1)" />
          <rect
            x="100"
            y="50"
            width="60"
            height="60"
            fill="rgba(255,255,255,0.1)"
            transform="rotate(45 130 80)"
          />
        </svg>

        {/* Card Content */}
        <div className="relative z-10 flex h-full flex-col justify-between p-6 text-white">
          <div>
            <h2
              className={` mb-2 flex items-center justify-center break-words capitalize ${
                name.length > 20 ? "text-2xl" : "text-3xl"
              }  font-bold `}
            >
               {name.split(' ')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
            .join(' ')}
            </h2>
          </div>
          <div className="flex items-end justify-between">
            {/* Placeholder for QR Code */}
            <QRCodeSVG
              value={`https://ar.immarsify.com/${username}/${experiencename}`}
              size={100}
              bgColor="transparent"
              fgColor="#DAA520" // Golden color for the QR code
              level="Q"
              includeMargin={false}
            />
            {/* <p className="text-sm">www.janesmith.com</p> */}
          </div>
        </div>
      </div>
    );
  }
);

export default CardTwelve;



export const CardTwelveBackSide = React.forwardRef(
  ({ username, experiencename, name, cardData }, ref) => {
    return (
      <div
        ref={ref}
        className="relative   h-[250px] w-[400px] overflow-hidden  bg-gradient-to-br from-blue-500 to-purple-600 shadow-lg"
      >
        <div className="absolute right-2 top-[-5px]  z-10 flex items-center justify-end ">
          <LuNfc size={25} color="#DAA520" className="    top-3 " />
          {/* <img src={Logo} className="absolute left-4 top-2 w-[110px]" /> */}
          {/* <img src={Logo("#0067B1")} className=" right-1 top-4   h-4 w-7" /> */}

          <Logo color={"#DAA520"} height={35} width={35} />
        </div>
        {/* Complex SVG Background */}
        <svg
          className="absolute inset-0 h-full w-full"
          viewBox="0 0 400 225"
          xmlns="http://www.w3.org/2000/svg"
        >
          <defs>
            <filter id="noise" x="0%" y="0%" width="100%" height="100%">
              <feTurbulence
                type="fractalNoise"
                baseFrequency="0.65"
                numOctaves="3"
                stitchTiles="stitch"
              />
              <feBlend mode="overlay" in="SourceGraphic" />
            </filter>
          </defs>
          {/* <rect
            width="500px"
            height="100%"
            filter="url(#noise)"
            opacity="0.3"
            style={{ minWidth: "500px" }}
            className="mr-10"
          /> */}
          <path
            d="M0,50 Q100,25 200,75 T400,50"
            fill="none"
            stroke="rgba(255,255,255,0.3)"
            strokeWidth="2"
          />
          <path
            d="M0,100 Q100,150 200,100 T400,150"
            fill="none"
            stroke="rgba(255,255,255,0.2)"
            strokeWidth="3"
          />
          <circle cx="350" cy="50" r="20" fill="rgba(255,255,255,0.2)" />
          <circle cx="50" cy="150" r="30" fill="rgba(255,255,255,0.1)" />
          <rect
            x="100"
            y="50"
            width="60"
            height="60"
            fill="rgba(255,255,255,0.1)"
            transform="rotate(45 130 80)"
          />
        </svg>

        {/* Card Content */}
        <div className="relative  p-4  text-sm break-all text-white">
        <div className="mt-8">

    
<h2 className={` font-bold mb-2 text-center ${cardData?.companyName?.length > 20 ? `text-2xl` : `text-3xl`} }`}>
    {cardData?.companyName?.split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ')}
  </h2>

<div className="flex justify-center ">

<div className="flex flex-col gap-2 mt-4">


{cardData?.address && <p className="text-sm  flex gap-2"><IoLocationSharp className="min-w-5 min-h-5" />
{cardData?.address}</p>}

{cardData?.email && <p className="text-sm  flex gap-2"><MdOutlineMail className="min-w-5 min-h-5" />
{cardData?.email}</p>}

{cardData?.Phone && <p className="text-sm  flex gap-2"><FaMobileAlt className="min-w-5 min-h-5"/>
{cardData?.Phone}</p>}

</div>


</div>







</div>
        </div>
      </div>
    );
  }
);