import React, { useState, useEffect } from "react";
import RecycleBinDeletedExperienceTable from "./components/RecycleBinCardTable";


const RecycleBinForExperience = () => {
  return (
    <div className="grid grid-cols-1 gap-5 px-4 md:grid-cols-1">
      <RecycleBinDeletedExperienceTable />
    </div>
  );
};

export default RecycleBinForExperience;
