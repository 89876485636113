// Custom components
import React from "react";

function InputField(props) {
  const { label, id, extra, type, placeholder, variant, disabled, onChange, onBlur, value, error } =
    props;

  return (
    <div className={`${extra}`}>
      <label
        htmlFor={id}
        className={`text-sm font-medium text-navy-700 dark:text-white ${
          variant === "auth" ? "ml-1.5 font-medium" : "ml-3 font-bold"
        }`}
      >
        {label}
      </label>
      <input
        disabled={disabled}
        type={type}
        id={id}
        placeholder={placeholder}
        className={`mt-2 flex h-10  w-full items-center rounded-md justify-center border-2 border-gray-100 bg-white/0 p-3 text-md outline-none ${
          disabled === true
            ? "!border-none !bg-gray-100 dark:!bg-white/5 dark:placeholder:!text-[rgba(255,255,255,0.15)]"
            : error
            ? "border-gray-200"
            : "border-gray-200 dark:!border-white/10 dark:text-white"
        }`}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
      />
      {error && (
        <p className="text-sm text-red-500 mt-1.5 ml-1.5">
          {error}
        </p>
      )}
    </div>
  );
}

export default InputField;
