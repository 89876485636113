import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import { useFormik } from "formik";

import * as Yup from "yup";

import Divider from "@mui/material/Divider";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import "./FileUpload.css";
import Slide from "@mui/material/Slide";
import { IoMdClose } from "react-icons/io";
import { Switch, getLinearProgressUtilityClass } from "@mui/material";
import { useState } from "react";
import { useSelector } from "react-redux";
import { instance } from "common/Instance";
import imageCompression from "browser-image-compression";
import toast from "react-hot-toast";
import { IoCloseCircleSharp } from "react-icons/io5";
import { div } from "three/examples/jsm/nodes/Nodes";
import InputField from "components/fields/InputField";
import { isValidDateValue } from "@testing-library/user-event/dist/utils";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AddProductModal({
  isOpen,
  setIsOpen,
  getImmersifyProducts,
  category,
}) {
  const [productAvailableCheck, setProductAvailableCheck] = useState(false);
  const [variantsCheck, setVarientCheck] = useState(false);
  const [recommandCheck, setRecommandCheck] = useState(false);
  const [uploadedMainImage, setUploadedMainImage] = useState(null);
  const [selectedMainImage, setSelectedMainImage] = useState(null);
  const [selectedGallaryImages, setSelectedGallaryImages] = useState([]);
  const [uploadedGallaryImagesLink, setUploadedGallaryImagesLink] = useState(
    []
  );

  const [mainImageLoading, setMainImageLoading] = useState(false);
  const [gallaryImageLoading, setGallaryImageLoading] = useState(false);
  const [selectedMainImageBinary, setSelectedMainImageBinary] = useState(null);
  const [selectedGallaryImageBinary, setSelectedGallaryImageBinary] = useState(
    []
  );

  // const [productInputField, setProductInputField] = useState({
  //   name: "",
  //   description: "",
  //   price: 100,
  //   taxPercentage: 10,
  // });
  const validationSchema = Yup.object({
    name: Yup.string()
      .min(2, "Name must be at least 2 characters")
      .required("Name is required"),
    description: Yup.string()
      .min(10, "Description must be at least 10 characters")
      .required("Description is required"),
    price: Yup.number()
      .typeError("Price must be a number")
      .positive("Price must be positive")
      .required("Price is required"),
    taxPercentage: Yup.number()
      .typeError("Tax percentage must be a number")
      .min(0, "Tax percentage cannot be less than 0")
      .max(100, "Tax percentage cannot be more than 100")
      .required("Tax percentage is required"),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      description: "",
      price: 0,
      taxPercentage: 0,
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => {
      await createProductHandler(values, resetForm);
      console.log(values, "form is sumitted");
    },
  });

  const userToken = useSelector((state) => state.auth.auth.token);

  const handleClickOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };
  const updloadImageHandler = async (imageFile) => {
    try {
      if (!imageFile) {
        return toast.error("Please select image");
      }
      const formData = new FormData();
      const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
      };
      const compressedBlob = await imageCompression(imageFile, options);

      // Convert Blob to File
      const compressedFile = new File([compressedBlob], imageFile.name, {
        type: imageFile.type,
        lastModified: Date.now(),
      });

      formData.append("file", compressedFile);
      formData.append("assetType", "image");
      const fileName = compressedFile.name.split(".").slice(0, -1).join(".");

      const response = await instance.post(
        "api/products/upload_product_image",
        formData,
        {
          headers: {
            authorization: `Bearer ${userToken}`,
          },
        }
      );
      if (response.status === 200 && response?.data?.url) {
        return response.data?.url;
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  };

  const filterGallaryImages = (mainIndex) => {
    const imgs = selectedGallaryImages.filter(
      (img, index) => mainIndex !== index
    );
    setSelectedGallaryImages(imgs);
  };

  const updloadGallaryImagesHandler = async () => {
    try {
      if (selectedGallaryImages.length === 0) {
        toast.error("Please select images to upload");
        return;
      }

      setGallaryImageLoading(true);

      setUploadedGallaryImagesLink([]);

      for (let image of selectedGallaryImageBinary) {
        const link = await updloadImageHandler(image);

        setUploadedGallaryImagesLink((prev) => {
          return [...prev, link];
        });
      }
      // selectedGallaryImageBinary.forEach(async (image) => {
      //   const link = await updloadImageHandler(image);
      //   setUploadedGallaryImagesLink((prev) => {
      //     return [...prev, link];
      //   });
      // });

      setGallaryImageLoading(false);
      setSelectedGallaryImages([]);
      setSelectedGallaryImageBinary([]);
    } catch (error) {
      setGallaryImageLoading(false);
      setSelectedGallaryImages([]);
      setSelectedGallaryImageBinary([]);
    }
  };

  const uploadMainImageHandler = async () => {
    try {
      if (!selectedMainImageBinary) {
        toast.error("Please select image");
        return;
      }

      setMainImageLoading(true);
      const link = await updloadImageHandler(selectedMainImageBinary);
      setUploadedMainImage(link);
      setSelectedMainImage(null);
      setSelectedMainImageBinary(null);
      setMainImageLoading(false);
    } catch (error) {
      setSelectedMainImage(null);
      setSelectedMainImageBinary(null);
      setMainImageLoading(false);
    }
  };

  const handleMainImageChange = async (event) => {
    try {
      const files = Array.from(event.target.files);
      console.log(files);
      const validFiles = files.filter((file) =>
        ["image/jpeg", "image/png", "image/jpg"].includes(file.type)
      );
      console.log(files, validFiles);
      if (validFiles.length < 1) {
        toast.error("Please upload valid image");
        return;
      }
      const imageUrl = URL.createObjectURL(validFiles[0]);
      setSelectedMainImage(imageUrl);

      setSelectedMainImageBinary(files[0]);
    } catch (error) {}
  };

  const handleGallaryImageChange = async (event) => {
    try {
      const files = Array.from(event.target.files);

      // Filter valid image files based on file type
      const validFiles = files.filter((file) =>
        ["image/jpeg", "image/png", "image/jpg"].includes(file.type)
      );

      // Check if any invalid files were selected
      const invalidFiles = files.filter(
        (file) => !["image/jpeg", "image/png", "image/jpg"].includes(file.type)
      );

      // Display toast for invalid files
      if (invalidFiles.length > 0) {
        toast.error("Please select only JPEG, PNG, or JPG images");
        return;
      }

      // Map valid files to image URLs for preview
      const imageUrls = validFiles.map((file) => URL.createObjectURL(file));

      // Update selected gallery images in state
      setSelectedGallaryImages(imageUrls);
      console.log(validFiles, "uploading image");

      // Update selected gallery image binaries in state
      setSelectedGallaryImageBinary(validFiles);

      // Uncommented code for handling image upload and updating image links
      // validFiles.forEach(async (file) => {
      //   const link = await updloadImageHandler(file);
      //   console.log(link, "this is link");
      //   setSelectedGallaryImagesLink((prev) => {
      //     return [...prev, link];
      //   });
      // });

      // Uncommented code for updating gallery images in state with URLs
      // setSelectedGallaryImages((prevImages) => [...prevImages, ...imageUrls]);
    } catch (error) {
      console.error("Error handling gallery image change:", error);
    }
  };
  const featureHandler = (type) => {
    if (type === "productAvailable") {
      setProductAvailableCheck((prev) => !prev);
    }
    if (type === "recommand") {
      setRecommandCheck((prev) => !prev);
    }
    if (type === "variants") {
      setVarientCheck((prev) => !prev);
    }
  };

  const createProductHandler = async (values, resetForm) => {
    try {
      if (!uploadedMainImage) {
        toast.error("Please upload main image");
        return;
      }

      const { name, description, price, taxPercentage } = values;
      const response = await instance.post(
        "api/products/create_product",
        {
          name: name,
          description: description,
          price: price,
          taxPercentage: taxPercentage,
          mainImage: uploadedMainImage,
          gallaryImages: uploadedGallaryImagesLink,
          isProductAvailable: productAvailableCheck,
          category: category?._id,
        },
        {
          headers: {
            authorization: `Bearer ${userToken}`,
          },
        }
      );
      console.log(response, "this is repsonse");
      if (response.status === 201) {
        resetForm();
        toast.success(response?.data?.message);
        getImmersifyProducts();

        setSelectedMainImage(null);
        setUploadedMainImage(null);
        setSelectedMainImageBinary(null);

        setSelectedGallaryImages([]);
        setUploadedGallaryImagesLink([]);
        setSelectedGallaryImageBinary([]);

        setTimeout(() => {
          setIsOpen(false);
        }, 500);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || "Something went wrong");
      console.log("Dashboard Error: ", error);
    }
  };
  console.log(uploadedGallaryImagesLink, "this is uploaded");
  return (
    <React.Fragment>
      <Dialog
        fullScreen
        open={isOpen}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar
          sx={{ position: "relative", background: "white", color: "black" }}
        >
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <IoMdClose />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Add Product
            </Typography>
            <buttton
              onClick={formik.handleSubmit}
              className="   cursor-pointer  rounded-lg border border-none border-gray-300  bg-brand-main px-4 py-2 text-sm text-white outline-none transition duration-300 ease-in-out"
            >
              save
            </buttton>
          </Toolbar>
        </AppBar>
        {/* bg-editProductBackground  */}
        <div className="  w-full ">
          <div className="container mx-auto  mt-8 flex flex-col md:flex-row">
            <div className="w-full rounded-md 	shadow-lg  md:mx-5 md:w-10/12">
              <div className="header flex justify-between bg-editProductBackground p-4">
                <h3 className="text-xl">Product to Management</h3>
                <buttton
                  onClick={() => {
                    setIsOpen(false);
                  }}
                  className=" hidden cursor-pointer  rounded-lg  border border-none border-gray-300 bg-brand-main  px-4 py-2 text-sm text-white outline-none transition duration-300 ease-in-out md:block"
                >
                  Go back to products
                </buttton>
              </div>
              <div className="">
                <div className="inputsection">
                  {/* INPUT SECTION */}
                  <div className="relative mb-4 px-14">
                    <label
                      for="name"
                      className="text-sm leading-7 text-gray-600"
                    >
                      Name
                    </label>
                    <input
                      onChange={formik.handleChange}
                      value={formik.values.name}
                      onBlur={formik.handleBlur}
                      type="text"
                      id="name"
                      name="name"
                      className="w-full rounded border border-gray-300 bg-white px-3 py-1 text-base leading-8 text-gray-700 outline-none transition-colors duration-200 ease-in-out focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200"
                    />
                    {formik.touched.name && formik.errors.name ? (
                      <div className="text-sm text-red-500">
                        {formik.errors.name}
                      </div>
                    ) : null}
                  </div>
                  <div className="relative mb-4 px-14">
                    <label
                      for="description"
                      className="text-sm leading-7 text-gray-600"
                    >
                      Description
                    </label>
                    <textarea
                      onChange={formik.handleChange}
                      value={formik.values.description}
                      onBlur={formik.handleBlur}
                      rows={2}
                      id="description"
                      name="description"
                      className="w-full rounded border border-gray-300 bg-white px-3 py-1 text-base leading-8 text-gray-700 outline-none transition-colors duration-200 ease-in-out focus:border-brand-main focus:ring-2 focus:ring-brand-200"
                    />
                    {formik.touched.description && formik.errors.description ? (
                      <div className="text-sm text-red-500">
                        {formik.errors.description}
                      </div>
                    ) : null}
                  </div>

                  <div className="flex flex-col md:flex-row gap-4 px-14">
                    <div className="relative  w-full md:w-1/2 md:mb-4">
                      <label
                        for="price"
                        className="text-sm leading-7 text-gray-600"
                      >
                        Price
                      </label>
                      <input
                        onChange={formik.handleChange}
                        value={formik.values.price}
                        onBlur={formik.handleBlur}
                        id="price"
                        name="price"
                        type="number"
                        className="w-full rounded border border-gray-300 bg-white px-3 py-1 text-base leading-8 text-gray-700 outline-none transition-colors duration-200 ease-in-out focus:border-brand-main focus:ring-2 focus:ring-brand-200"
                      />
                      {formik.touched.price && formik.errors.price ? (
                        <div className="text-sm text-red-500">
                          {formik.errors.price}
                        </div>
                      ) : null}
                    </div>
                    <div className="relative mb-4 w-full md:w-1/2 ">
                      <label
                        for="taxPercentage"
                        className="text-sm leading-7 text-gray-600"
                      >
                        Tax Perccentage
                      </label>
                      <input
                        onChange={formik.handleChange}
                        value={formik.values.taxPercentage}
                        onBlur={formik.handleBlur}
                        id="taxPercentage"
                        name="taxPercentage"
                        type="number"
                        className="w-full rounded border border-gray-300 bg-white px-3 py-1 text-base leading-8 text-gray-700 outline-none transition-colors duration-200 ease-in-out focus:border-brand-main focus:ring-2 focus:ring-brand-200"
                      />
                      {formik.touched.taxPercentage &&
                      formik.errors.taxPercentage ? (
                        <div className="text-sm text-red-500">
                          {formik.errors.taxPercentage}
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>

                {/* FEATURE SECTION */}

                <div className="featurecontainer mt-8 px-14">
                  <div className="flex justify-between">
                    <p>Product available</p>
                    <span>
                      <Switch
                        checked={productAvailableCheck}
                        onChange={() => {
                          featureHandler("productAvailable");
                        }}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    </span>
                  </div>
                  {/* <div className="flex justify-between">
                    <p>Enable Variants</p>
                    <span>
                      <Switch
                        checked={variantsCheck}
                        onChange={() => {
                          featureHandler("variants");
                        }}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    </span>
                  </div>
                  <div className="flex justify-between">
                    <p>Recommand</p>
                    <span>
                      <Switch
                        checked={recommandCheck}
                        onChange={() => {
                          featureHandler("recommand");
                        }}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    </span>
                  </div> */}
                </div>
              </div>
              {/* IMAGE SECTION */}
              <div className="w-full md:m-0 ">
                <div className="shadow-md">
                  <div className="header flex justify-between rounded-md bg-editProductBackground p-2">
                    <h4 className="text-xl">MAIN IMAGE</h4>
                  </div>
                  <div className="imagesection flex flex-col items-center p-4">
                    {/* File Upload Section */}
                    <form
                      className="file-upload-form"
                      style={{ width: "100%" }}
                    >
                      <label htmlFor="mainImage" className="file-upload-label">
                        <div className="file-upload-design">
                          <svg
                            viewBox="0 0 640 512"
                            height="1em"
                            className="upload-icon"
                          >
                            <path d="M144 480C64.5 480 0 415.5 0 336c0-62.8 40.2-116.2 96.2-135.9c-.1-2.7-.2-5.4-.2-8.1c0-88.4 71.6-160 160-160c59.3 0 111 32.2 138.7 80.2C409.9 102 428.3 96 448 96c53 0 96 43 96 96c0 12.2-2.3 23.8-6.4 34.6C596 238.4 640 290.1 640 352c0 70.7-57.3 128-128 128H144zm79-217c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l39-39V392c0 13.3 10.7 24 24 24s24-10.7 24-24V257.9l39 39c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-80-80c-9.4-9.4-24.6-9.4-33.9 0l-80 80z"></path>
                          </svg>
                          <span className="browse-button">Browse file</span>
                        </div>
                        <input
                          id="mainImage"
                          type="file"
                          accept=".jpg, .jpeg, .png, .svg"
                          className="my-2 w-full"
                          placeholder="choose main image"
                          style={{ display: "none" }}
                          onChange={handleMainImageChange}
                        />
                      </label>
                    </form>
                    {/* Gallery Display Section */}
                    <div className="images p-2">
                      {/* Your existing main image display code */}
                      {selectedMainImage && (
                        <div className="selectedImage my-1">
                          <h4 className="text-center">Selected Image</h4>
                          <div className="relative mx-1 shadow-md">
                            <span
                              className="absolute right-0 top-0 cursor-pointer"
                              onClick={() => {
                                setSelectedMainImage(null);
                              }}
                            >
                              <IoCloseCircleSharp color="red" size={20} />{" "}
                            </span>
                            <img
                              className="shadow-md"
                              width={200}
                              src={selectedMainImage}
                              alt=""
                            />
                          </div>
                          <div className="my-1">
                            <hr />
                          </div>
                        </div>
                      )}

                      {uploadedMainImage && (
                        <div className="uploadedImage my-1">
                          <h4 className="text-center">Uploaded images</h4>
                          <img
                            width={200}
                            src={uploadedMainImage}
                            alt=""
                            className="shadow-md"
                          />
                          <div className="my-1">
                            <hr />
                          </div>
                        </div>
                      )}
                    </div>
                    {/* Upload button */}
                    <button
                      disabled={mainImageLoading}
                      onClick={uploadMainImageHandler}
                      className={`my-2 rounded-lg border border-none border-gray-300 bg-brand-main px-4 py-2 text-sm text-white outline-none transition duration-300 ease-in-out ${
                        mainImageLoading
                          ? "cursor-not-allowed"
                          : "cursor-pointer"
                      }`}
                    >
                      {mainImageLoading ? "Uploading" : "Upload"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full md:m-0 md:mx-3 md:w-4/12">
              <div className="shadow-md">
                <div className="header flex justify-between rounded-md bg-editProductBackground p-4">
                  <h3 className="text-xl">Gallery</h3>
                </div>
                <div className="flex flex-col items-center">
                  {/* File Upload Section */}
                  <form className="file-upload-form">
                    <label htmlFor="file" className="file-upload-label">
                      <div className="file-upload-design">
                        <svg
                          viewBox="0 0 640 512"
                          height="1em"
                          className="upload-icon"
                        >
                          <path d="M144 480C64.5 480 0 415.5 0 336c0-62.8 40.2-116.2 96.2-135.9c-.1-2.7-.2-5.4-.2-8.1c0-88.4 71.6-160 160-160c59.3 0 111 32.2 138.7 80.2C409.9 102 428.3 96 448 96c53 0 96 43 96 96c0 12.2-2.3 23.8-6.4 34.6C596 238.4 640 290.1 640 352c0 70.7-57.3 128-128 128H144zm79-217c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l39-39V392c0 13.3 10.7 24 24 24s24-10.7 24-24V257.9l39 39c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-80-80c-9.4-9.4-24.6-9.4-33.9 0l-80 80z"></path>
                        </svg>

                        <span className="browse-button">Browse file</span>
                      </div>
                      <input
                        id="file"
                        type="file"
                        accept=".jpg, .jpeg, .png, .svg"
                        style={{ display: "none" }}
                        onChange={handleGallaryImageChange}
                        multiple
                      />
                    </label>
                  </form>
                  {/* Gallery Display Section */}
                  <div className="images p-2">
                    {/* Your existing gallery display code */}
                    {selectedGallaryImages.length > 0 && (
                      <div className="selectedImage my-1">
                        <h4>Selected Images</h4>
                        <div className="flex flex-wrap gap-1">
                          {selectedGallaryImages.map((img, mainIndex) => (
                            <div className="relative" key={mainIndex}>
                              <span
                                className="absolute right-0 top-0 cursor-pointer"
                                onClick={() => {
                                  filterGallaryImages(mainIndex);
                                }}
                              >
                                <IoCloseCircleSharp color="red" size={20} />{" "}
                              </span>
                              <img
                                className="object-contain"
                                width={120}
                                height={120}
                                src={img}
                                alt="gallery image"
                              />
                            </div>
                          ))}
                        </div>
                        <div className="my-1">
                          <hr />
                        </div>
                      </div>
                    )}
                    {/* Uploaded images section */}
                    {uploadedGallaryImagesLink.length > 0 && (
                      <div className="uploadedImage my-1">
                        <h4>Uploaded Images</h4>
                        <div
                          className="flex flex-wrap gap-1 justify-center"
                          
                        >
                          {uploadedGallaryImagesLink.map((img, index) => (
                            <img
                              key={index}
                              className="object-contain"
                              width={120}
                              height={120}
                              src={img}
                              alt="uploaded gallery image"
                            />
                          ))}
                        </div>
                        <div className="my-1">
                          <hr />
                        </div>
                      </div>
                    )}
                  </div>
                  {/* Upload button */}
                  <button
                    disabled={gallaryImageLoading}
                    onClick={updloadGallaryImagesHandler}
                    className={`my-2 rounded-lg border border-none border-gray-300 bg-brand-main px-4 py-2 text-sm text-white outline-none transition duration-300 ease-in-out ${
                      gallaryImageLoading
                        ? "cursor-not-allowed"
                        : "cursor-pointer"
                    }`}
                  >
                    {gallaryImageLoading ? "Uploading" : "Upload"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Dialog>
    </React.Fragment>
  );
}
