import React from "react";
import { CiCircleCheck } from "react-icons/ci";
import { useNavigate } from "react-router-dom";

const PaymentSuccess = ({ price, name }) => {
  const navigate = useNavigate();

  return (
    <div className="flex  h-full w-full flex-col items-center justify-center ">
      <div className="w-full max-w-sm rounded-lg bg-white p-8 shadow-md">
        <div className="mb-4 flex justify-center">
          <CiCircleCheck className="h-16 w-16 text-green-500" />
        </div>
        <h2 className="mb-2 text-center text-2xl font-bold">
          Payment Successful!
        </h2>
        <p className="mb-4 text-center text-gray-600">
          Your payment has been completed.
        </p>

        <button
          onClick={() => {
            navigate("/admin/invoice");
          }}
          className="w-full rounded-md bg-purple-600 py-2 text-white transition duration-300 hover:bg-purple-700"
        >
          Back
        </button>
      </div>
    </div>
  );
};

export default PaymentSuccess;
