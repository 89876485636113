import authImg from "assets/img/auth/pexels-michelangelo-buonarroti-8728557.jpg";
import { Routes, Route, Navigate } from "react-router-dom";
import routes from "routes.js";
import MainLogo from "../../assets/img/logo_1.png";
import Logo from "../../assets/svg/logoBold.svg";
import LogoBackground from "../../assets/svg/background2.svg";
import { FaCheck } from "react-icons/fa";
import { useLocation } from "react-router-dom";

export default function Auth() {
  const location = useLocation();
  const Istryon = location.pathname.includes("/auth/tryon-signup");

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/auth" && true) {
        return (
          <Route path={`/${prop.path}`} element={prop.component} key={key} />
        );
      } else {
        return null;
      }
    });
  };

  return (
    <div>
      <div className="relative float-right h-full min-h-screen w-full overflow-y-scroll !bg-white dark:!bg-navy-900">
        {/* <FixedPlugin /> */}
        <main className={`mx-auto min-h-screen`}>
          <div className="relative flex">
            <div className="mx-auto flex min-h-full w-full flex-col justify-start pt-12 md:max-w-[75%] lg:h-screen lg:max-w-[1013px] lg:px-8 lg:pt-0 xl:h-[100vh] xl:max-w-[1380px] xl:px-0 xl:pl-[70px]">
              <div className="mb-auto flex flex-col pl-5 pr-5 md:pl-12 md:pr-0 lg:max-w-[30%] lg:pl-0 xl:max-w-full">
                <Routes>
                  {getRoutes(routes)}
                  <Route
                    path="*"
                    element={<Navigate to="/auth/sign-in" replace />}
                  />
                </Routes>

                {
                  Istryon ? <div className="fixed right-0 hidden h-full min-h-screen md:block lg:w-[49vw] 2xl:w-[44vw]">
                  <div
                    className=" flex h-full w-full items-end justify-center bg-cover bg-center lg:rounded-bl-[120px] xl:rounded-bl-[200px]"
                    style={{ backgroundImage: `url(${authImg})` }}
                  />
                </div>: 
                <div
                  className="fixed right-0 bottom-0 h-full hidden   flex-col items-center gap-4 p-8  pt-16 text-white lg:flex lg:w-[55vw] 2xl:w-[45vw]   bg-repeat-y"
                  style={{ backgroundImage: `url(${LogoBackground})` }}
                >


                  <img src={Logo} className=" w-[400px] object-contain" />
                  <div className="mt-8 flex flex-col gap-8">
                    <div className="flex flex-col gap-2">
                      <p className="text-2xl font-semibold ">
                        Enhance your business networking experience
                      </p>
                      <p className="text-xl font-semibold ">
                        with the power of AR business card
                      </p>
                    </div>
                    <div className="flex flex-col gap-2">
                      <div className="flex gap-2">
                        <FaCheck size={20} className="text-green-400 text-md font-semibold" />
                        <p>100% do it yourself platform</p>
                      </div>
                      <div className="flex gap-2">
                        <FaCheck size={20} className="text-green-400 text-md font-semibold" />
                        <p>No coding required</p>
                      </div>
                      <div className="flex gap-2">
                        <FaCheck size={20} className="text-green-400 text-md font-semibold" />
                        <p>No apps required</p>
                      </div>
                      <div className="flex gap-2">
                        <FaCheck size={20} className="text-green-400 text-md font-semibold" />
                        <p>24 X 7 technical support</p>
                      </div>
                    </div>
                  </div>

               
                </div>
                }


               
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}
