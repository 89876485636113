import React, { useState, useEffect, useRef } from "react";

import Card from "components/card";

import { useSelector, useDispatch } from "react-redux";
import { instance } from "common/Instance";
import toast from "react-hot-toast";

import Pagination from "@mui/material/Pagination";
import { SET_RECYCLE_BIN_DATA } from "../../../../redux/reducerSlice/tableDataSlice";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Tooltip,
} from "@mui/material";
import ReactToPrint from "react-to-print";
import CardOne from "views/admin/arExperience/tabcomponent/Cardtemplates/CardOne";

const RecycleBinUserTable = () => {
  const dispatch = useDispatch();
  const authToken = useSelector((state) => state?.auth?.auth?.token);
  const [openRestoreDialog, setOpenRestoreDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [user, setUserId] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingRestore, setLoadingRestore] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [search, setSearch] = useState("");
  const [searchParam, setSearchParam] = useState("");
  const [itemsPerPage, setSelectedValue] = useState(10);
  const [timeoutId, setTimeoutId] = useState(null);
  const recycleBinData = useSelector(
    (state) => state?.tableData?.data?.recycleBinData
  );

  console.log(recycleBinData, "this is recyclebin data");

  const handleChanges = (event, value) => {
    setCurrentPage(value);
  };

  const handleChange = (e) => {
    setCurrentPage(1);
    setSelectedValue(e.target.value);
  };

  const handleSearch = (e) => {
    const inputValue = e.target.value;
    setSearch(inputValue);

    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    const newTimeoutId = setTimeout(() => {
      if (!inputValue || inputValue.trim() === "") {
        setSearchParam("");
      } else {
        setSearchParam(inputValue.trim());
      }
    }, 1000);

    setTimeoutId(newTimeoutId);
  };

  const userDataList = async (perPage) => {
    if (recycleBinData?.length === 0) {
      setLoading(true);
    }

    try {
      console.log(currentPage);
      const response = await instance.get(
        `/api/reseller/deleted_user_list?page=${
          perPage ? perPage : currentPage
        }&limit=${itemsPerPage}&searchText=${searchParam}`,
        {
          headers: {
            authorization: `Bearer ${authToken}`,
          },
        }
      );

      if (response?.status === 200) {
        dispatch(SET_RECYCLE_BIN_DATA(response?.data?.data?.data));

        setTotalPages(response?.data?.data?.totalPages);
        setLoading(false);
        // toast.success(response && response?.data?.message);
      }
    } catch (error) {
      toast.error(error && error?.response?.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    userDataList();
  }, [currentPage, itemsPerPage]);

  useEffect(() => {
    userDataList(1);
  }, [searchParam]);

  const restoreUser = async (userId) => {
    try {
      setLoadingRestore(true);
      const response = await instance.post(
        `/api/reseller/restore_deleted_user`,
        {
          id: userId,
        },
        {
          headers: {
            authorization: `Bearer ${authToken}`,
          },
        }
      );
      if (response.status === 200) {
        toast.success(response.data.message);
        setOpenRestoreDialog(false);
        setLoadingRestore(false);
        userDataList(searchParam ? 1 : currentPage);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message ?? error.message);
      setLoadingRestore(false);
    }
  };

  const deleteUser = async (userId) => {
    try {
      setLoadingRestore(true);
      const response = await instance.delete(
        `/api/reseller/deleted_user/${userId}`,
        {
          headers: {
            authorization: `Bearer ${authToken}`,
          },
        }
      );
      if (response.status === 200) {
        toast.success(response.data.message);
        setOpenDeleteDialog(false);
        setLoadingRestore(false);
        userDataList(searchParam ? 1 : currentPage);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message ?? error.message);
      setLoadingRestore(false);
    }
  };


  return (
    <Card extra={"w-full h-full sm:overflow-auto px-3 py-4"}>
      <header className=" flex w-full flex-col justify-center gap-2">
        <div className="relative flex  items-center justify-between">
          <div className="text-md font-bold text-navy-700 dark:text-white">
            Recycle bin
          </div>
        </div>
        <div className="relative flex  w-full items-center py-1">
          <div className="flex w-full items-center">
            <input
              className="w-full max-w-[500px] rounded-lg border-2 p-2.5 text-sm outline-none"
              type="text"
              value={search}
              onChange={handleSearch}
              placeholder="Search"
            />
          </div>
          <div className="flex w-full items-center justify-end ">
            {/* <p className="text-sm">Rows per page:</p> */}
            <Tooltip title="Rows per page">
              <select
                class="ml-2 block h-8 w-12 rounded-md  border bg-gray-200  shadow-sm outline-none"
                placeholder="List"
                value={itemsPerPage}
                onChange={handleChange}
              >
                {[...Array(11).keys()].map((_, index) => (
                  <>
                    {index > 0 && (
                      <option key={index} value={index * 5}>
                        {index * 5}
                      </option>
                    )}
                  </>
                ))}
              </select>
            </Tooltip>
          </div>
        </div>
      </header>

      <div className="mt-4 overflow-x-scroll xl:overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th
                scope="col"
                className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
              >
                Name
              </th>
              <th
                scope="col"
                className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
              >
                Username
              </th>
              <th
                scope="col"
                className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
              >
                Phone Number
              </th>
              <th
                scope="col"
                className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
              >
                Email
              </th>

              <th
                scope="col"
                className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
              >
                Action
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white">
            {loading ? (
              [...Array(itemsPerPage)].map((_, index) => (
                <tr key={index}>
                  <td
                    className="whitespace-nowrap px-3 py-4  "
                    style={{ width: "25%" }}
                  >
                    <div role="status" className=" animate-pulse ">
                      <div className="h-4  rounded-full bg-gray-200 dark:bg-gray-800 "></div>
                    </div>
                  </td>
                  <td
                    className="whitespace-nowrap px-3 py-4 "
                    style={{ width: "25%" }}
                  >
                    <div role="status" className="animate-pulse">
                      <div className="h-4  rounded-full bg-gray-200 dark:bg-gray-800 "></div>
                    </div>
                  </td>
                  <td
                    className="whitespace-nowrap px-3 py-4 "
                    style={{ width: "25%" }}
                  >
                    <div role="status" className="animate-pulse">
                      <div className="h-4  rounded-full bg-gray-200 dark:bg-gray-800 "></div>
                    </div>
                  </td>
                  <td
                    className="whitespace-nowrap px-3 py-4 "
                    style={{ width: "25%" }}
                  >
                    <div role="status" className="animate-pulse">
                      <div className="h-4  rounded-full bg-gray-200 dark:bg-gray-800 "></div>
                    </div>
                  </td>
                  <td
                    className="whitespace-nowrap px-3 py-4"
                    style={{ width: "25%" }}
                  >
                    <div role="status" className="animate-pulse">
                      <div className="h-4  rounded-full bg-gray-200 dark:bg-gray-800 "></div>
                    </div>
                  </td>
                  <td
                    className="whitespace-nowrap px-3 py-4"
                    style={{ width: "25%" }}
                  >
                    <div role="status" className="animate-pulse">
                      <div className="h-4  rounded-full bg-gray-200 dark:bg-gray-800 "></div>
                    </div>
                  </td>
                </tr>
              ))
            ) : recycleBinData?.length > 0 ? (
              recycleBinData?.map((item) => (
                <tr key={item._id}>
                  <td className="whitespace-nowrap px-3 py-4">
                    <div className="text-sm font-medium text-gray-900">
                      {item?.fullName}
                    </div>
                  </td>
                  <td className="whitespace-nowrap px-3 py-4">
                    <div className="text-sm text-gray-900">
                      {item?.username}
                    </div>
                  </td>
                  <td className="whitespace-nowrap px-3 py-4">
                    <div className="text-sm text-gray-900">
                      {item?.phoneNumber}
                    </div>
                  </td>
                  <td className="whitespace-nowrap px-3 py-4">
                    <div className="text-sm text-gray-900">{item?.email}</div>
                  </td>

                  <td className="space-x-3 whitespace-nowrap px-3 py-4">
                    <button
                      className=" rounded-md bg-green-600 p-1 px-4 text-sm font-semibold text-white"
                      onClick={() => {
                        setOpenRestoreDialog(true);
                        setUserId(item);
                      }}
                    >
                      Restore
                    </button>
                    <button
                      className=" rounded-md bg-red-600 p-1 px-4 text-sm font-semibold text-white"
                      onClick={() => {
                        setOpenDeleteDialog(true);
                        setUserId(item);
                      }}
                    >
                      Delete Permanently
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="12" className="py-4 text-center">
                  <p className="bg-blue-100 px-10 py-5 text-sm text-blue-600">
                    Data Not Found!
                  </p>
                </td>
              </tr>
            )}
          </tbody>
        </table>
        <div className="my-4 flex w-full items-center justify-end text-sm ">
          <Pagination
            count={totalPages}
            color="primary"
            page={currentPage}
            onChange={handleChanges}
          />
        </div>
      </div>

      <Dialog
        open={openRestoreDialog}
        onClose={() => {
          setOpenRestoreDialog(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>Restore user</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to restore this user?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <button
            className="ml-5 rounded-md bg-gray-600 p-1 px-4 text-sm text-gray-900"
            onClick={() => {
              setOpenRestoreDialog(false);
            }}
          >
            Close
          </button>
          <button
            disabled={loadingRestore}
            className="ml-5 rounded-md bg-red-600 p-1 px-4 text-sm text-white"
            onClick={() => {
              restoreUser(user?._id);
            }}
            autoFocus
          >
            Ok
          </button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openDeleteDialog}
        onClose={() => {
          setOpenDeleteDialog(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{
          padding: "100px",
        }}
      >
        <DialogTitle>Delete user</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to restore this user? This action cannot be
            undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            padding: "1rem",
          }}
        >
          <button
            className="ml-5 rounded-md bg-gray-600 p-1 px-4 text-base text-gray-900"
            onClick={() => {
              setOpenDeleteDialog(false);
            }}
          >
            Close
          </button>
          <button
            disabled={loadingRestore}
            className="ml-5 rounded-md bg-red-600 p-1 px-4 text-base text-white"
            onClick={() => {
              deleteUser(user?._id);
            }}
            autoFocus
          >
            Delete
          </button>
        </DialogActions>
      </Dialog>
    </Card>
  );
};

export default RecycleBinUserTable;
