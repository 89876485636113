import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  UPDATE_PHOTO_FOR_EDITOR,
  UPDATE_VIDEO_FOR_EDITOR,
  UPDATE_TEXT_FOR_EDITOR,
  UPDATE_ICON_FOR_EDITOR,
  UPDATE_MODEL_FOR_EDITOR,
  UPDATE_DOCUMENT_FOR_EDITOR,
  UPDATE_TARGET_FOR_EDITOR,
  UPDATE_CAROUSEL_FOR_EDITOR,
  UPDATE_RESUME_FOR_EDITOR,
} from "../../../../../redux/reducerSlice/arSlice";
import { Box } from "@mui/material";

const InputField = ({ axis, value, onChange }) => (
  <div className="my-2 flex w-full items-center justify-center gap-2">
    <p className="font-semibold  ">
      {axis === "y" ? "Z" : axis === "z" ? "Y" : axis.toUpperCase()}
    </p>
    <div className=" w-full flex-1">
      <input
        type="range"
        min={-5.5}
        max={5.5}
        step={0.01}
        value={value}
        onChange={(e) => onChange(parseFloat(e.target.value))}
        // className="range-xs mb-3 h-1 w-full  cursor-pointer appearance-none rounded-lg bg-[#ffffff]  outline-none"
        style={{
          borderRadius: "6px",
          // border: "2px solid #3D2FA9",
          height: "0.5rem",
          width: "100%",
          // appearance: "none",
          transition: "border-color 0.3s ease-in-out",
          backgroundColor: "#dad9d9",
        }}
      />
      <input
        type="number"
        step={0.01}
        min={-5.5}
        max={5.5}
        className="w-full rounded-md border px-2 py-1 text-xs "
        value={parseFloat(value).toFixed(3)}
        onChange={(e) => {
          const newValue = parseFloat(e.target.value);
          if (!isNaN(newValue)) onChange(newValue);
        }}
      />
    </div>
  </div>
);

const handleUpdate = (type, data, dispatch) => {
  switch (type) {
    case "target_img":
      dispatch(UPDATE_TARGET_FOR_EDITOR(data));
      break;
    case "photo":
      dispatch(UPDATE_PHOTO_FOR_EDITOR(data));
      break;
    case "text":
      dispatch(UPDATE_TEXT_FOR_EDITOR(data));
      break;
    case "video":
      dispatch(UPDATE_VIDEO_FOR_EDITOR(data));
      break;
    case "icons":
      dispatch(UPDATE_ICON_FOR_EDITOR(data));
      break;
      case "documents":
        dispatch(UPDATE_DOCUMENT_FOR_EDITOR(data));
        break;  
    case "Model3d":
      dispatch(UPDATE_MODEL_FOR_EDITOR(data));
      break;
    case "resume":
      dispatch(UPDATE_RESUME_FOR_EDITOR(data));
      break;

    case "Group":
      dispatch(UPDATE_CAROUSEL_FOR_EDITOR(data));
      break;
    default:
      break;
  }
};

const Stats = ({ whatsClicked }) => {
  const dispatch = useDispatch();

  const [activeTab, setActiveTab] = useState("Position");

  useEffect(() => {
    console.log(whatsClicked?.userData?.type, "hello1");
    if (whatsClicked?.userData?.type === "target_img") {
      setActiveTab("Scale");
    } else {
      setActiveTab("Position");
    }
  }, [whatsClicked]);

  const tabNames = ["Position", "Rotation", "Scale"];
  const defaultData = {
    position: { x: 0, y: 0, z: 0 },
    rotation: { x: 0, y: 0, z: 0 },
    scale: { x: 0, y: 0, z: 0 },
  };

  const tabName = ["scale"];
  const defaultData1 = {
    scale: { x: 0, y: 0, z: 0 },
  };

  const [data, setData] = useState(defaultData);

  useEffect(() => {
    if (whatsClicked) {
      const newData = {
        id: whatsClicked.userData?.id,
        type: whatsClicked.userData?.type,
        position: whatsClicked.position || defaultData.position,
        rotation: {
          x: whatsClicked.rotation?.x || defaultData.rotation.x,
          y: whatsClicked.rotation?.y || defaultData.rotation.y,
          z: whatsClicked.rotation?.z || defaultData.rotation.z,
        },
        scale: whatsClicked.scale || defaultData.scale,
      };
      setData(newData);
    }
  }, [whatsClicked]);

  const handleChange = (property, axis, value) => {
    const newData = {
      ...data,
      [property]: {
        ...data[property],
        [axis]: value,
      },
    };
    setData(newData);
    handleUpdate(data.type, newData, dispatch);
  };

  const renderInputFields = () =>
    ["x", "z", "y"].map((axis) => (
      <InputField
        key={`${activeTab.toLowerCase()}-${axis}`}
        axis={axis}
        value={data[activeTab.toLowerCase()][axis]}
        onChange={(newValue) =>
          handleChange(activeTab.toLowerCase(), axis, newValue)
        }
      />
    ));

  return (
    <>
      {whatsClicked && (
        <Box className="mx-2 my-4 rounded-lg border-2 bg-white p-4 text-sm shadow-md">
          <p className="mb-4">Position Settings</p>

          {whatsClicked?.userData?.type === "target_img" ? (
            <div className="mb-4 flex justify-center ">
              {tabName.map((tab) => (
                <div
                  key={tab}
                  className={`w-full rounded-sm bg-brand-main px-2 py-2 text-center text-white focus:outline-none `}
                >
                  {tab}
                </div>
              ))}
            </div>
          ) : (
            <div className="mb-4 grid grid-cols-3">
              {tabNames.map((tab) => (
                <button
                  key={tab}
                  className={`px-2 py-2 focus:outline-none ${
                    activeTab === tab
                      ? "bg-brand-main text-white"
                      : "truncate border border-brand-main text-brand-main hover:bg-brand-main hover:text-white"
                  }`}
                  onClick={() => setActiveTab(tab)}
                >
                  {tab}
                </button>
              ))}
            </div>
          )}

          <div className="grid-row-3 grid gap-1">{renderInputFields()}</div>
        </Box>
      )}
    </>
  );
};

export default Stats;
