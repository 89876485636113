import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { forwardRef } from "react";
import { BiSkipNextCircle, BiSkipPreviousCircle } from "react-icons/bi";
import Magnifier from "react-magnifier";
import { MdOutlineSkipNext, MdOutlineSkipPrevious } from "react-icons/md";

const image = require("../../../../../assets/img/logo_1.png");
console.log(image, "this is image");
// const image = await require("https://images.unsplash.com/photo-1718809070374-e373e7c41414?q=80&w=987&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D");

// console.log(image, "This is image")

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const GuideModal = ({ isOpen, setIsOpen, guide, title }) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [fadeImage, setFadeImage] = useState(false);
  // const [image, setImage] = useState("");

  // useEffect(() => {
  //   const fetchImage = async () => {
  //     try {
  //       const response = await axios.get(
  //         "https://images.unsplash.com/photo-1718809070374-e373e7c41414?q=80&w=987&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  //         { responseType: "arraybuffer" }
  //       );
  //       const base64 = Buffer.from(response.data, "binary").toString("base64");
  //       setImage(`data:image/jpeg;base64,${base64}`);

  //     } catch (error) {

  //       console.error("Error fetching the image:", error);
  //     }
  //   };

  //   fetchImage();
  // }, []);

  useEffect(() => {
    setFadeImage(false);
  }, [currentStep]);
  const handleNext = () => {
    if (currentStep >= guide.length - 1) {
      return;
    }
    setFadeImage(true);
    setTimeout(() => {
      setCurrentStep((prevStep) => (prevStep + 1) % guide.length);
    }, 100);
  };
  const handlePrev = () => {
    if (currentStep <= 0) {
      return;
    }
    setFadeImage(true);
    setTimeout(() => {
      setCurrentStep(
        (prevStep) => (prevStep - 1 + guide.length) % guide.length
      );
    }, 100);
  };
  if (!isOpen) return null;
  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <Dialog
      open={isOpen}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      maxWidth="sm"
      aria-describedby="alert-dialog-slide-description"
      PaperProps={{
        style: {
          backgroundColor: "",
          boxShadow: "none",
        },
      }}
    >
      <DialogTitle>
        {" "}
        <div className="flex items-center justify-between border-b bg-gradient-to-r from-[#3D2FA9] to-blue-700 p-4 text-white">
          <h2 className="text-xl font-semibold">{title}</h2>
          <button onClick={handleClose} className="text-2xl">
            &times;
          </button>
        </div>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          <div className="flex-1 overflow-auto  ">
            {/* <img
            src={guide[currentStep].image}
            alt={`Step ${guide[currentStep].id}`}
            className="mx-auto mb-4 rounded-lg shadow-lg "
          /> */}
            <div className="guide_image_container  overflow-x-hidden overflow-y-hidden ">
              <Magnifier
                className={`object-cover transition-opacity duration-100 ${
                  fadeImage ? "opacity-30" : "opacity-100"
                }`}
                src={guide[currentStep].image}
                width="100%"
              />
              {/* <img
                src={guide[currentStep].image}
                className={`h-72 w-full transition-opacity duration-100 ${
                  fadeImage ? "opacity-30" : "opacity-100"
                }`}
                alt=""
              /> */}

              {/* <GuideImage src={guide[currentStep].image} /> */}
            </div>
            <div className="mb-4 text-gray-700">
              <p className="text-lg font-bold">
                Step {currentStep + 1} of {guide.length}
              </p>
              <div>
                <div>{guide[currentStep]?.description}</div>
              </div>
            </div>
          </div>
          <div className="flex items-center justify-center gap-2 ">
            <span
              onClick={handlePrev}
              className={`bg-${
                currentStep === 0 ? "gray-700" : "brand-main"
              } cursor-pointer  rounded-md border border-none  border-gray-300   p-1 text-sm text-white shadow-2xl  transition duration-300 ease-in-out`}
              disabled={currentStep === 0}
            >
              <MdOutlineSkipPrevious size={25} />
            </span>
            <span className="select-none">
              {currentStep + 1} / {guide.length}
            </span>
            <span
              onClick={handleNext}
              className={`bg-${
                currentStep === guide.length - 1 ? "gray-700" : "brand-main"
              } cursor-pointer  rounded-md border border-none  border-gray-300   p-1 text-sm text-white shadow-2xl transition duration-300 ease-in-out `}
              disabled={currentStep === guide.length - 1}
            >
              <MdOutlineSkipNext size={25} />
            </span>
          </div>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};
export default GuideModal;
