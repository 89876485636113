import React, { useEffect, useState } from "react";
import {
  Button,
  TextField,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Box,
  Grid,
  TextareaAutosize,
  Chip,
} from "@mui/material";
import { useSelector } from "react-redux";
import { instance } from "common/Instance";
import toast from "react-hot-toast";
import "react-phone-input-2/lib/style.css";

import { ConvolutionShader } from "three/examples/jsm/Addons";
import { IoAddOutline } from "react-icons/io5";
import LabelManager from "../modal/AddLabel";
import PhoneInput from "react-phone-input-2";
const staticOptions = {
  status: [
    { label: "New", color: "#059669" },
    { label: "In Progress", color: "#2563EB" },
    { label: "Completed", color: "#DC2626" },
  ],
  source: [
    { label: "Website", color: "#10B981" },
    { label: "Referral", color: "#8B5CF6" },
    { label: "Direct", color: "#FBBF24" },
  ],
  importance: [
    { label: "Low", color: "#4B5563" },
    { label: "Medium", color: "#D97706" },
    { label: "High", color: "#DC2626" },
  ],
};
const AddLead = ({ fetchEnquiry }) => {
  const [leadData, setLeadData] = useState({
    viewerName: "",
    status: "",
    source: "",
    importance: "",
    address: "",
    city: "",
    state: "",
    pincode: "",
    phone: "",
    alternatePhone: "",
    email: "",
    note: "",
  
  });

  const userData = useSelector((state) => state?.auth?.auth?.user);

  const [isLoading,setIsLoading] = useState(false)
const [openLabel, setOpenLabel] = useState(false)
  const authToken = useSelector((state) => state?.auth?.auth?.token);
  const [errors, setErrors] = useState({});

  const [enquiryLables, setEnquiryLables] = useState([])
  // const [staticOptions, setStaticOptions] = useState({
  //   importance:[],
  //   status:[],
  //   source:[]
  // })


  const staticOptions  =  useSelector((state) => state?.enquiry?.lables);




  console.log(staticOptions, "this is static options ")


  const handleInputChange = (e) => {

    const { name, value } = e.target;
    console.log(name, value, "this is name value")
    setLeadData((prevData) => ({ ...prevData, [name]: value }));
    validateField(name, value);
  };

  const validatePhoneNumber = (phone) => {
    const phoneRegex = /^(?:\+91|91)?(?:\d{10}|\d{5} \d{5}|\d{5} \d{6}|\d{11}|\d{12})$/;
    return phoneRegex.test(phone.replace(/\s+/g, ''));
  };
  const validateField = (name, value) => {
    let error = "";
    switch (name) {
      case "phone":
      case "alternatePhone":
        if (value && !/^\d{10}$/.test(value)) {
          error = "Phone number must be 10 digits";
        }
        break;
      case "email":
        if (value && !/\S+@\S+\.\S+/.test(value)) {
          error = "Invalid email address";
        }
        break;
      case "pincode":
        if (value && !/^\d{6}$/.test(value)) {
          error = "Pincode must be 6 digits";
        }
        break;
      default:
        break;
    }
    setErrors((prev) => ({ ...prev, [name]: error }));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    const validationErrors = {};

    

    // Validate required fields
    if (!leadData.viewerName.trim()) {
      validationErrors.viewerName = "Viewer Name is required";
    }
    if (!leadData.email.trim()) {
      validationErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(leadData.email)) {
      validationErrors.email = "Invalid email address";
    }
    // if (!leadData.phone.trim()) {
    //   validationErrors.phone = "Phone number is required";
    // } else if (!validatePhoneNumber(leadData.phone)) {
    //   validationErrors.phone = "Invalid phone number format";
    // }

    
    if ( leadData.phone.length < 12) {
      validationErrors.phone = "Invalid phone number format";
    } 

    if ( leadData.alternatePhone !== '' && leadData.alternatePhone.length < 12) {
      validationErrors.alternatePhone = "Invalid phone number format";
    } 

    // If there are validation errors, show toast and update error state
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      Object.values(validationErrors).forEach((error) => {
        toast.error(error);
      });
      return;
    }

    console.log(Object.values(errors))
    if (Object.values(errors).some((error) => error !== "")) {
      console.error("Form has errors. Please correct them before submitting.");

      Object.values(errors).some((error) =>{
        if(error!==""){

          toast.error(error)
        }
      })
      return;
    }
    try {
      console.log("Updating lead data:", leadData);
setIsLoading(true)
      const payload = {
        ...leadData,
      
        exprienceName: '',

        userId : userData?._id, 

        generatedFrom:"Reseller Panel"







      }
  
      
        const response = await instance.post('api/inquiries/add_inquiry', payload, {
          headers: {
            Authorization: 'Bearer ' + authToken
          }
        });


        console.log(response, "this is response ");
        if(response?.data?.code===201){
          setLeadData({
            viewerName: '',
            email: '',
            phone: '91',
            address:'',
            city:'',
            alternatePhone:'91',
            importance:'',
            note:'',
            pincode:'',
            source:'',
            state:'',
            status:''
        
          });

          toast.success("Enquiry added successfully")
fetchEnquiry()
        }

        setIsLoading(false);





      // Implement actual API call here
    } catch (error) {
      setIsLoading(false)
      console.error("Error updating lead data:", error);
    }
  };
  const renderTextField = (label, name, value, additionalClassName, type = 'text') => (
    // <TextField
    //   fullWidth
    //   label={label}
    //   name={name}
    //   value={value}
    //   onChange={handleInputChange}
    //   variant="standard"
    //   margin="normal"
    //   error={!!errors[name]}
    //   helperText={errors[name]}
    //   size="small"
    //   {...options}
    // />
    <div>
      <label className="block text-sm font-medium text-gray-700 mb-1">
        {label}{['viewerName', 'email', 'phone'].includes(name) && <span className="text-red-500">*</span>}
      </label>
      <input
        type={type}
        name={name}
        value={value}
        onChange={handleInputChange}
        className={`w-full border border-gray-300 rounded-md py-2 px-3 ${additionalClassName}`}
      />
        {errors[name] && <p className="text-red-500 text-sm">{errors[name]}</p>}
    </div>
  );
  const renderDropdown = (label, name, value, type) => (
   
    <div>
      <label className="block text-sm font-medium text-gray-700 mb-1">
        {label} 
      </label>
 
   <Select
        size="small"
        sx={{ width: '100%' }}
        name={name}
        value={value}
        onChange={(e) => setLeadData({ ...leadData, [name]: e.target.value })}
        renderValue={(selected) => {
          const option = staticOptions[name]?.find((opt) => opt?.name === selected);
          return (
            <Box 
              sx={{
                backgroundColor: option ? option.color : 'transparent',
                color: 'white',
                padding: '2px 8px',
                borderRadius: '4px'
              }}>
              {selected}
            </Box>
          );
        }}
      >
        {staticOptions[name]?.length > 0 ?   staticOptions[name].map((option) => (
          <MenuItem
            key={option._id}
            value={option.name}
            sx={{
              backgroundColor: option.color,
              color: 'white',
              '&:hover': {
                backgroundColor: option.color,
                opacity: 0.8
              },
              '&.Mui-selected': {
                backgroundColor: option.color,
                '&:hover': {
                  backgroundColor: option.color,
                  opacity: 0.8
                }
              }
            }}
          >
            {option.name}
          </MenuItem>
        )): <button onClick={()=>setOpenLabel(true)} className="w-full bg-brand-main text-white flex items-center justify-center p-1 "><IoAddOutline />
<span>Add Label</span></button>}
      </Select>
    </div>
  );
  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{ maxWidth: 800, margin: "auto", px: 2 }}
    >
      <h2 className=" font-semibold text-xl mb-4">Add Enquiry Details</h2>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          {renderTextField("Viewer Name", "viewerName", leadData.viewerName, 'py-[9px]')}
        </Grid>
        <Grid item xs={12} sm={6}>
          {renderDropdown(
            "Status",
            "status",
            leadData.status,
            staticOptions.status
          )}
        </Grid>
        <Grid item xs={12} sm={6}>
          {renderDropdown(
            "Source",
            "source",
            leadData.source,
            staticOptions.source
          )}
        </Grid>
        <Grid item xs={12} sm={6}>
          {renderDropdown(
            "Importance",
            "importance",
            leadData.importance,
            staticOptions.importance
          )}
        </Grid>
        <Grid item xs={12} sm={6}>
          {/* {renderTextField("Phone", "phone", leadData.phone, '', '')} */}

          <label className="block text-sm font-medium text-gray-700 mb-1">
            Phone
 <span className="text-red-500">*</span>
      </label>
          <PhoneInput
                    country={"in"}
                    inputStyle={{
                      width: "100%",
                      height: "40px",
                      borderRadius: "5px",
                    }}
                    name="phone"
                    id="phone"
                    onChange={(e) => {
                      setLeadData((prev)=>{
                        return {...prev, phone:e}
                      })
                    }}
                    
                    value={leadData.phone}
                  />

{errors["phone"] && <p className="text-red-500 text-sm">{errors["phone"]}</p>}
        </Grid>
        <Grid item xs={12} sm={6}>
          {/* {renderTextField(
            "Alternate Phone",
            "alternatePhone",
            leadData.alternatePhone,
            '', ''
          )} */}

<label className="block text-sm font-medium text-gray-700 mb-1">
            Alternate phone
      </label>
          <PhoneInput
                    country={"in"}
                    inputStyle={{
                      width: "100%",
                      height: "40px",
                      borderRadius: "5px",
                    }}
                    name="alternatePhone"
                    id="alternatePhone"
                    onChange={(e) => {
                      setLeadData((prev)=>{
                        return {...prev, alternatePhone:e}
                      })
                    }}
                    
                    value={leadData.alternatePhone}
                  />

{errors["alternatePhone"] && <p className="text-red-500 text-sm">{errors["alternatePhone"]}</p>}
        </Grid>
        <Grid item xs={12}>
          {renderTextField("Email", "email", leadData.email)}
        </Grid>
        <Grid item xs={12}>
          {renderTextField("Address", "address", leadData.address)}
        </Grid>
        <Grid item xs={12} sm={6}>
          {renderTextField("City", "city", leadData.city)}
        </Grid>
        <Grid item xs={12} sm={6}>
          {renderTextField("State", "state", leadData.state)}
        </Grid>
        <Grid item xs={12} sm={6}>
          {renderTextField("Pincode", "pincode", leadData.pincode, '', '')}
        </Grid>
        <Grid item xs={12}>
          <p>Note : </p>
          <TextareaAutosize
            minRows={3}
            placeholder="Note"
            name="note"
            label="Note"
            value={leadData.note}
            onChange={handleInputChange}
            style={{
              width: "100%",
              padding: "8px",
              marginTop: "4px",
              marginBottom: "8px",
              borderRadius: "4px",
              borderColor: "#ccc",
              border: "1px solid",
            }}
          />
        </Grid>
        {/* <Grid item xs={12}>
          <TextField
            fullWidth
            label="Created At"
            value={new Date(leadData.createdAt).toLocaleString()}
            disabled
            variant="outlined"
            size="small"
          />
        </Grid> */}
      </Grid>
      <Box sx={{ mt: 3 }}>
      { !isLoading ? <button type="submit"  className="w-full bg-brand-main text-white p-2 rounded-md" >
          Save Changes
        </button>
        
      :
      <button disabled className="w-full bg-brand-400 text-white p-2 rounded-md">
          Please wait 
        </button>
      }
      </Box>


      <LabelManager openLabel={openLabel} setOpenLabel={setOpenLabel} />

    </Box>
  );
};
export default AddLead;