import React, { useState } from 'react';
import { FaDownload, FaEnvelope, FaEye } from 'react-icons/fa';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { instance } from 'common/Instance';
import { useSelector } from 'react-redux';
import { responsiveFontSizes } from '@mui/material';
import toast from 'react-hot-toast';

const InvoiceComponent = ({ invoiceData }) => {
  const [pdfBlob, setPdfBlob] = useState(null);
  const [isLoading, setIsLoading] = useState(false)
  const userToken = useSelector((state) => state.auth.auth.token);

  const generatePDF = async () => {
    const element = document.getElementById('invoice');
    const canvas = await html2canvas(element);
    const data = canvas.toDataURL('image/png');

    const pdf = new jsPDF();
    const imgProperties = pdf.getImageProperties(data);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;

    pdf.addImage(data, 'PNG', 0, 0, pdfWidth, pdfHeight);
    const blob = pdf.output('blob');
    setPdfBlob(blob);
    return blob;
  };

  const previewPDF = async () => {
    const blob = await generatePDF();
    const pdfUrl = URL.createObjectURL(blob);
    window.open(pdfUrl, '_blank');
  };

  const downloadPDF = async () => {
    const blob = await generatePDF();
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'invoice.pdf';
    link.click();
  };

  const sendEmail = async () => {
    const blob = pdfBlob || await generatePDF();
    try {
      setIsLoading(true);

      // Create a FormData object
      const formData = new FormData();

      // Append the file to the FormData object
      formData.append('file', blob, 'invoice.pdf');

      // Append other data to the FormData object
      formData.append('fullName', invoiceData?.userDetails?.fullName);
      formData.append('email', invoiceData?.userDetails?.email);

      formData.append('invoiceId', invoiceData?._id);

      const response = await instance.post(
        `api/invoices/send_invoice_mail`,
        formData,
        {
          headers: {
            authorization: `Bearer ${userToken}`,
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      if (response.status === 200) {
        toast.success("Invoice sent via email");
      } else {
        toast.error("Something went wrong");
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      toast.error("Something went wrong");
      console.error(error);
    }
  };
  return (
    <div className="container mx-auto p-2">
      <div id="invoice" className="bg-white  rounded-lg overflow-hidden">
        <div className="p-8">
          <div className="flex justify-between items-start mb-8">
            <div>
              <h1 className="text-3xl font-bold mb-2">Invoice</h1>
              <p>Invoice #: {invoiceData._id}</p>
              <p>Date: {new Date(invoiceData.date).toLocaleDateString()}</p>
              <p>Expiry Date: {new Date(invoiceData.expireDate).toLocaleDateString()}</p>
              <p>Status: {invoiceData.status}</p>
            </div>
            <img src="https://assets.immarsify.com/website+data/images/brandLogo.png" alt="Company Logo" className="w-48" />
          </div>

          <div className="flex justify-between mb-8">
            <div>
              <h3 className="font-bold mb-2">Bill To:</h3>
              <p>{invoiceData.userDetails.fullName}</p>
              <p className='break-all'>{invoiceData.userDetails.email}</p>
            </div>
            <div className="text-right">
              <h3 className="font-bold mb-2">From:</h3>
              <p>IMMARSIFY</p>
              <p>Surat, Gujarat, India</p>
              {/* <p>Your Company Phone</p> */}
            </div>
          </div>

          <table className="w-full mb-8">
            <thead>
              <tr className="bg-gray-100">
                <th className="text-left p-2">Item</th>
                <th className="text-right p-2">Quantity</th>
                <th className="text-right p-2">Price</th>
                <th className="text-right p-2">Total</th>
              </tr>
            </thead>
            <tbody>
              {invoiceData.items.map((item) => (
                <tr key={item.id} className="border-b">
                  <td className="p-2">{item.name}</td>
                  <td className="text-right p-2">{item.quantity}</td>
                  <td className="text-right p-2">{invoiceData.currency} {item.price}</td>
                  <td className="text-right p-2">{invoiceData.currency} {item.total}</td>
                </tr>
              ))}
            </tbody>
          </table>

          <div className="flex justify-end">
            <div className="w-1/2">
              <div className="flex justify-between mb-2">
                <span>Subtotal:</span>
                <span>{invoiceData.currency} {invoiceData?.subTotal}</span>
              </div>
              <div className="flex justify-between mb-2">
                <span>Extras:</span>
                <span>{invoiceData.currency} {invoiceData?.extras}</span>
              </div>
              <div className="flex justify-between font-bold">
                <span>Total:</span>
                <span>{invoiceData.currency} {invoiceData?.total}</span>
              </div>
              <div className="flex justify-between mt-2">
                <span>Paid:</span>
                <span>{invoiceData.currency} {invoiceData?.paid}</span>
              </div>
            </div>
          </div>

          {invoiceData.note && (
            <div className="mt-8">
              <h3 className="font-bold mb-2">Note:</h3>
              <p>{invoiceData.note}</p>
            </div>
          )}
        </div>
      </div>

      <div className="mt-8 flex justify-end space-x-4">
        <button onClick={previewPDF} className="flex items-center bg-brand-main text-white px-4 py-1 rounded hover:bg-blue-600">
          <FaEye className="mr-2" /> Preview
        </button>
        <button onClick={downloadPDF} className="flex items-center bg-green-500 text-white px-4 py-1 rounded hover:bg-green-600">
          <FaDownload className="mr-2" /> Download
        </button>
        <button onClick={sendEmail} className="flex items-center bg-purple-500 text-white px-4 py-1 rounded hover:bg-purple-600">
          <FaEnvelope className="mr-2" /> {isLoading ? "Sending" : "Send to email"}
        </button>
      </div>
    </div>
  );
};

export default InvoiceComponent;




