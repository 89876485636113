import { Paper, TextField } from '@mui/material'
import React, { useState } from 'react'
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import SendToWhatsapp from './checkBox';
import {
    
    SET_USERDATA,
  } from "../../../redux/reducerSlice/authSlice";
import { useDispatch, useSelector } from 'react-redux';
import { instance } from 'common/Instance';
import toast from 'react-hot-toast';
const Index = () => {
    const [type, setType] = React.useState('Whatsapp');

const [loading, setLoading] = useState(false)

    


    const user = useSelector((state) => state.auth.auth.user);
    const userToken = useSelector((state) => state.auth.auth.token);
    const [message, setMessage] = useState({
        Whatsapp:user?.defaultMessage?.Whatsapp || "",
        Share:user?.defaultMessage?.Share || ""
    });
const dispatch = useDispatch()
const updateHandler  = async()=>{


    


    try {
        setLoading(true)
        const response = await instance.post(
          `/api/user/update_user_details/${user._id}`,
          {...user,  defaultMessage:message},
          {
            headers: {
              authorization: `Bearer ${userToken}`,
            },
          }
        );
        if (response.status === 200) {
          toast.success("Profile updated successfully");
          console.log(response?.data?.data)
          dispatch(SET_USERDATA(response?.data?.data));
        }
        setLoading(false)
      } catch (error) {
        setLoading(false)
        console.log(error)
        toast.error("Failed to update profile");
      } 
}


    const handleChange = (event) => {
      setType(event.target.value);
    };
  return (
    <div>


<Paper className="p-4">
<h2 className='text-2xl mb-4'>Enquiry</h2>
<p>Default message</p>
    <div className=' mt-4 space-y-4'>

 <div className='w-24 '>


<Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Type</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={type}
defaultValue={type}
          label="type"
          onChange={handleChange}
        >
          <MenuItem  value={"Whatsapp"}>Whatsapp</MenuItem>
          {/* <MenuItem value={"Share"}>Share</MenuItem> */}
        </Select>
      </FormControl>
    </Box>

    </div>
    <div>
        
    <TextField
          id="outlined-multiline-flexible"
          multiline
          minRows={4}
          
          className='w-full'

          value={message[type]}
          onChange={(e)=>{
            setMessage((prev)=>{
                return {...prev, [type]:e.target.value}
            })
          }}
          
        />



    </div>

<div>
    <button onClick={updateHandler} className='bg-brand-main p-2 text-white rounded-md'>{loading ? "updating" : "update"}</button>
</div>

    <div>
        <SendToWhatsapp/>
    </div>

    

    </div> 
</Paper>


    </div>
  )
}

export default Index