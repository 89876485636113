import { PricingOne } from "./components/Pricing";

import Dropdown from "components/dropdown";
import { FiAlignJustify } from "react-icons/fi";
import { Link, useNavigate } from "react-router-dom";

import { LOGOUT } from "../../../redux/reducerSlice/rootSlice";
import { useDispatch, useSelector } from "react-redux";
import Avatar from "react-avatar";
import logo from "../../../assets/img/logo_1.png";

const Pricing = ({ forUpgrade }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.auth.auth.user);

  return (
    <div>
      {!forUpgrade && (
        <nav className="sticky top-0 z-40 flex flex-row flex-wrap items-center justify-between rounded-xl   backdrop-blur-xl ">
          <div className="mx-auto  max-w-[200px]  ">
            <img
              src={logo}
              alt="Logo"
              className=" w-full "
              onClick={() => navigate("/admin/dashboard")}
            />
          </div>

          <div className="relative mt-[3px] flex  flex-grow items-center justify-end  gap-2 rounded-full   ">
            <Dropdown
              button={
                <Avatar
                  size={40}
                  round={true}
                  name={user?.username}
                  className="cursor-pointer"
                />
              }
              children={
                <div className="flex w-56 z-50 flex-col justify-start rounded-[20px] bg-white bg-cover bg-no-repeat shadow-xl shadow-shadow-500 dark:!bg-navy-700 dark:text-white dark:shadow-none ">
                  <div className="p-4">
                    <div className="flex items-center gap-2">
                      <p className="text-sm font-bold text-navy-700 dark:text-white ">
                        👋 Hey, {user?.username}
                      </p>{" "}
                    </div>
                  </div>
                  <div className="h-px w-full  border-t-gray-800  " />

                  <p
                    href=" "
                    className="mt-3 cursor-pointer px-6 py-1 text-sm font-medium text-red-500 hover:text-red-500"
                    onClick={() => {
                      navigate("/auth/sign-in");
                      dispatch(LOGOUT());
                    }}
                  >
                    Log Out
                  </p>
                </div>
              }
              classNames={"py-2 top-8 -left-[180px] w-max"}
            />
          </div>
        </nav>
      )}

      <div className="grid grid-cols-1 gap-5 md:grid-cols-1">
        <PricingOne />
      </div>
    </div>
  );
};

export default Pricing;
