import FormTable from "views/admin/Reseller/components/FormTable";
import Logo from "../../../assets/svg/logo1.svg";
import CardOne from "views/admin/arExperience/tabcomponent/Cardtemplates/CardOne";
const label = { inputProps: { "aria-label": "Switch demo" } };

const Form = () => {
  return (
    <div className=" mx-auto  flex max-w-7xl flex-col justify-center gap-4 overflow-y-scroll ">
      <div className="flex items-center justify-center px-5 md:justify-between">
        <img src={Logo} className="w-52 object-contain" />
        <h2 className=" hidden text-center text-2xl font-bold text-brand-main md:block">
          Create AR Experience
        </h2>
      </div>
      <FormTable />
    </div>
  );
};

export default Form;
