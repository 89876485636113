import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { instance } from "common/Instance";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import imageCompression from "browser-image-compression";

const validationSchema = Yup.object().shape({
  companyName: Yup.string(),
  address: Yup.string(),
  logo: Yup.mixed().nullable(),
  visitingCardImage: Yup.mixed().nullable(),
  websiteLink: Yup.string().url("Invalid URL"),
  ownerPhoto: Yup.mixed().nullable(),
  facebook: Yup.string().url("Invalid URL"),
  linkedIn: Yup.string().url("Invalid URL"),
  youtube: Yup.string().url("Invalid URL"),
  googleMaps: Yup.string().url("Invalid URL"),
  googleReview: Yup.string().url("Invalid URL"),
  twitter: Yup.string().url("Invalid URL"),
  instagram: Yup.string().url("Invalid URL"),
});

const CardForm = () => {
  const authToken = useSelector((state) => state?.auth?.auth?.token);
  const [loading,setLoading]=useState(false)
  const [verify, setVerify] = useState(false);
  const { id } = useParams();

  const VerifyUser = async () => {
    try {
      const response = await instance.get(`/api/card/verify_card_info/${id}`, {
        headers: {
          authorization: `Bearer ${authToken}`,
        },
      });

      setVerify(response?.data?.success);
    } catch (error) {
      setVerify(false);
    }
  };

  useEffect(() => {
    VerifyUser();
  }, []);


  return (
    <>
      {verify ? (
        <div className="flex h-screen items-center justify-center">
          <div className="m-auto flex flex-col items-center justify-center gap-2">
            <h1 className="text-2xl font-semibold text-[#222]">
              OOPS, This Form has already Filled
            </h1>
            <p className="text-md">link is expired now</p>
            <Link
              to="/auth/sign-in"
              className="linear mt-2 flex w-full justify-center rounded-xl bg-brand-500 px-10 
                py-[6px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
            >
              Back to Login
            </Link>
          </div>
        </div>
      ) : (
        <div className="mx-auto max-w-4xl rounded-lg bg-white px-8 pb-8 pt-6 shadow-lg">
          <h2 className="mb-8 text-center text-lg font-semibold">
            Business Card{" "}
          </h2>
          <Formik
            initialValues={{
              userId: id,
              companyName: "",
              address: "",
              logo: null,
              visitingCardImage: null,
              websiteLink: "",
              ownerPhoto: null,
              facebook: "",
              linkedIn: "",
              youtube: "",
              googleMaps: "",
              googleReview: "",
              twitter: "",
              instagram: "",
            }}
            validationSchema={validationSchema}
            onSubmit={async (values) => {
              setLoading(true)
              const formData = new FormData();
              const options = {
                maxSizeMB: 1,
                maxWidthOrHeight: 1920,
                useWebWorker: true,
              };


              const convertToBase64 = (file) => {
                return new Promise((resolve, reject) => {
                  const reader = new FileReader();  // Create a new FileReader instance
                  reader.readAsDataURL(file);       // Read the file as a data URL (base64)
                  reader.onload = () => resolve(reader.result);  // Resolve the promise with base64 string when done
                  reader.onerror = (error) => reject(error);     // Reject the promise if an error occurs
                });
              };
              
          
              for (let key in values) {
                if (values[key] !== null && values[key] !== undefined) {



                  if (key === 'logo' || key === 'visitingCardImage' || key === 'ownerPhoto') {
                    if (values[key] instanceof File) {
                     
                  
                     const compressedFile = await imageCompression(values[key], options);
                     const base64 = await convertToBase64(compressedFile);
                     console.log(base64,'base64')
                      formData.append(key,base64 );
                    } else {
                      formData.append(key, values[key]);
                    }
                  } else {
                    formData.append(key, values[key]);
                  }


                }
              }
          
              try {
                const response = await instance.post(`/api/card/add_card_info`, formData, {
                  headers: {
                    authorization: `Bearer ${authToken}`,
                    'Content-Type': 'multipart/form-data',
                  },
                });
                setLoading(false)
          
                toast.success("Form submitted successfully!");
                setTimeout(()=>{ VerifyUser()},2000)
               
              } catch (error) {
                toast.error("Failed to submit the form. Please try again.");
                
                setLoading(false)
              }
            }}
          >
            {({ setFieldValue }) => (
              <Form className="space-y-6">
                <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
                  <div>
                    <label className="block text-gray-700">Company Name</label>
                    <Field
                      name="companyName"
                      type="text"
                      className="mt-1 block w-full rounded border px-3 py-2"
                    />
                    <ErrorMessage
                      name="companyName"
                      component="div"
                      className="mt-1 text-sm text-red-500"
                    />
                  </div>

                  <div>
                    <label className="block text-gray-700">Address</label>
                    <Field
                      name="address"
                      type="text"
                      className="mt-1 block w-full rounded border px-3 py-2"
                    />
                    <ErrorMessage
                      name="address"
                      component="div"
                      className="mt-1 text-sm text-red-500"
                    />
                  </div>
                </div>

                <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
                  <div>
                    <label className="block text-gray-700">Logo</label>
                    <input
                      name="logo"
                      type="file"
                      className="mt-1 block w-full rounded border px-3 py-2"
                      onChange={(event) => {
                        setFieldValue("logo", event.currentTarget.files[0]);
                      }}
                    />
                    <ErrorMessage
                      name="logo"
                      component="div"
                      className="mt-1 text-sm text-red-500"
                    />
                  </div>
                  <div>
                    <label className="block text-gray-700">
                      Visiting Card Image{" "}
                    </label>
                    <input
                      name="visitingCardImage"
                      type="file"
                      className="mt-1 block w-full rounded border px-3 py-2"
                      onChange={(event) => {
                        setFieldValue(
                          "visitingCardImage",
                          event.currentTarget.files[0]
                        );
                      }}
                    />
                    <ErrorMessage
                      name="visitingCardImage"
                      component="div"
                      className="mt-1 text-sm text-red-500"
                    />
                  </div>
                </div>

                <div>
                  <label className="block text-gray-700">Website Link</label>
                  <Field
                    name="websiteLink"
                    type="url"
                    className="mt-1 block w-full rounded border px-3 py-2"
                  />
                  <ErrorMessage
                    name="websiteLink"
                    component="div"
                    className="mt-1 text-sm text-red-500"
                  />
                </div>

                <div>
                  <label className="block text-gray-700">
                    Owner Photo (Optional)
                  </label>
                  <input
                    name="ownerPhoto"
                    type="file"
                    className="mt-1 block w-full rounded border px-3 py-2"
                    onChange={(event) => {
                      setFieldValue("ownerPhoto", event.currentTarget.files[0]);
                    }}
                  />
                  <ErrorMessage
                    name="ownerPhoto"
                    component="div"
                    className="mt-1 text-sm text-red-500"
                  />
                </div>

                <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
                  <div>
                    <label className="block text-gray-700">Facebook</label>
                    <Field
                      name="facebook"
                      type="url"
                      className="mt-1 block w-full rounded border px-3 py-2"
                    />
                    <ErrorMessage
                      name="facebook"
                      component="div"
                      className="mt-1 text-sm text-red-500"
                    />
                  </div>
                  <div>
                    <label className="block text-gray-700">LinkedIn</label>
                    <Field
                      name="linkedIn"
                      type="url"
                      className="mt-1 block w-full rounded border px-3 py-2"
                    />
                    <ErrorMessage
                      name="linkedIn"
                      component="div"
                      className="mt-1 text-sm text-red-500"
                    />
                  </div>
                  <div>
                    <label className="block text-gray-700">YouTube</label>
                    <Field
                      name="youtube"
                      type="url"
                      className="mt-1 block w-full rounded border px-3 py-2"
                    />
                    <ErrorMessage
                      name="youtube"
                      component="div"
                      className="mt-1 text-sm text-red-500"
                    />
                  </div>
                  <div>
                    <label className="block text-gray-700">Google Maps</label>
                    <Field
                      name="googleMaps"
                      type="url"
                      className="mt-1 block w-full rounded border px-3 py-2"
                    />
                    <ErrorMessage
                      name="googleMaps"
                      component="div"
                      className="mt-1 text-sm text-red-500"
                    />
                  </div>
                  <div>
                    <label className="block text-gray-700">Google Review</label>
                    <Field
                      name="googleReview"
                      type="url"
                      className="mt-1 block w-full rounded border px-3 py-2"
                    />
                    <ErrorMessage
                      name="googleReview"
                      component="div"
                      className="mt-1 text-sm text-red-500"
                    />
                  </div>
                  <div>
                    <label className="block text-gray-700">Twitter</label>
                    <Field
                      name="twitter"
                      type="url"
                      className="mt-1 block w-full rounded border px-3 py-2"
                    />
                    <ErrorMessage
                      name="twitter"
                      component="div"
                      className="mt-1 text-sm text-red-500"
                    />
                  </div>
                  <div>
                    <label className="block text-gray-700">Instagram</label>
                    <Field
                      name="instagram"
                      type="url"
                      className="mt-1 block w-full rounded border px-3 py-2"
                    />
                    <ErrorMessage
                      name="instagram"
                      component="div"
                      className="mt-1 text-sm text-red-500"
                    />
                  </div>
                </div>

                <div className="text-center">
                  <button
                    type="submit"
                    className="rounded bg-blue-500 px-4 py-2 text-white hover:bg-blue-600 flex justify-center items-center"
                  >
                    {loading ? (
            <>
              <CircularProgress sx={{ color: "white" }} size={24} />
            </>
          ) : (
            "Submit"
          )}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      )}
    </>
  );
};

export default CardForm;
