import { useState } from "react";
import WooCommerceProducts from "./components/WooCommerceProducts";
import { PiShoppingBagFill } from "react-icons/pi";
import ShopfiyProducts from "./components/ShopfiyProducts";
import ImmersifyProducts from "./components/ImmersifyProducts";

const Products = () => {
  const [activeTab, setActiveTab] = useState(0);

  const tabs = ["Woocomerce", "Shopify", "Immarsify"];

  return (
    <div className="w-full ">
      <div className="container w-full  rounded-lg bg-white">
        <div className="hidden border-b sm:flex  ">
          {tabs.map((tab, index) => (
            <button
              key={index}
              className={`px-4 py-2 focus:outline-none ${
                activeTab === index
                  ? "border-b-2 border-brand-main text-brand-main"
                  : "text-gray-500"
              }`}
              onClick={() => setActiveTab(index)}
            >
              {tab}
            </button>
          ))}
        </div>

        <div className="tabs_mobile flex justify-center  p-2 sm:hidden">
         
   <select
            name=""
            id=""
            className="rounded-md w-full p-3 text-center bg-[#fff] text-brand-main  p-1 "
            style={{border:'1px solid #3d2fa9'}}
            onChange={(e) => {
              console.log(e.target.value);
              setActiveTab(Number(e.target.value));
            }}
          >
            {tabs.map((tab, index) => {
              return <option value={index}>{tab}</option>;
            })}
          </select>
        </div>
        <div className="p-4">
          {activeTab === 0 && <WooCommerceProducts />}
          {activeTab === 1 && <ShopfiyProducts />}
          {activeTab === 2 && <ImmersifyProducts />}
        </div>
      </div>
    </div>
  );
};

export default Products;
