// src/redux/joyrideSlice.js
import { createSlice } from "@reduxjs/toolkit";

const joyrideSlice = createSlice({
  name: "joyride",
  initialState: {
    run: true,

    completed: true,

    steps: [], // Added steps state
  },
  reducers: {
    setRun: (state, action) => {
      state.run = action.payload;
    },

    setSteps: (state, action) => {
      state.steps = action.payload;
    },
    setCompleted: (state, action) => {
      state.completed = action.payload;
    },
    resetJoyride: (state) => {
      state.run = false;
      state.steps = [];
      state.completed=false;
    },
  },
});

export const { setRun, setSteps, resetJoyride, setCompleted } =
  joyrideSlice.actions;

export default joyrideSlice.reducer;
