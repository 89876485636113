import React, { useEffect, useState } from "react";
import {
  Button,
  MenuItem,
  Select,
  Box,
  Grid,
  TextareaAutosize,
  Chip,
} from "@mui/material";
import axios from "axios";
import toast, { useToasterStore } from "react-hot-toast";
import { instance } from "common/Instance";
import "react-phone-input-2/lib/style.css";

import { useSelector } from "react-redux";
import { IoAddOutline } from "react-icons/io5";
import LabelManager from "../modal/AddLabel";
import PhoneInput from "react-phone-input-2";
// const staticOptions = {
//   status: [
//     { label: "New", color: "#059669" },
//     { label: "In Progress", color: "#2563EB" },
//     { label: "Completed", color: "#DC2626" },
//   ],
//   source: [
//     { label: "Website", color: "#10B981" },
//     { label: "Referral", color: "#8B5CF6" },
//     { label: "Direct", color: "#FBBF24" },
//   ],
//   importance: [
//     { label: "Low", color: "#4B5563" },
//     { label: "Medium", color: "#D97706" },
//     { label: "High", color: "#DC2626" },
//   ],
// };
const EditLead = ({  enquiry , fetchEnquiry}) => {
  const [leadData, setLeadData] = useState({
    viewerName: "",
    status: "",
    source: "",
    importance: "",
    address: "",
    city: "",
    state: "",
    pincode: "",
    phone: "",
    alternatePhone: "",
    email: "",
    note: "",
    createdAt: "",
  });

  const authToken = useSelector((state) => state?.auth?.auth?.token);
  const [openLabel, setOpenLabel] = useState(false)

  const [errors, setErrors] = useState({});
  const [options, setOptions] = useState({
    status: [],
    source: [],
    importance: [],
  });


  const [isLoading, setIsLoading] = useState(false)


  // const [defaultLables, setDefaultLabels] = useState({
  //   status:[],
  //   importance:[],
  //   sourcee:[]
  // });


  const staticOptions  =  useSelector((state) => state?.enquiry?.lables);

console.log(leadData, staticOptions, "this is data")
  useEffect(() => {
    if (enquiry) {
      setLeadData(enquiry);
    }
  }, [enquiry]);

//   const fetchInquiryLabels = async () => {
//     try {
//       const response = await instance.get('api/inquiries/get_inquiry_labels',    {
//         headers: {
//           authorization: `Bearer ${authToken}`,
//         },
//       });
//       if(response?.data?.data){
//         const data = response?.data?.data;

//        const importanceLables =  data.filter((data)=>data.type==="importance")
//        const statusLables =  data.filter((data)=>data.type==="status")
//        const sourceLables =  data.filter((data)=>data.type==="source")

//         const updatedLables  = {
//           status:statusLables, 
//           importance:importanceLables, 
//           source:sourceLables
//         }
// console.log(updatedLables, 'this s updated labels')
//         // setStaticOptions(updatedLables)
//         setDefaultLabels(updatedLables)
//       }
//       console.log(response)
//     } catch (error) {

//       console.error('Failed to fetch inquiry labels:', error);
//     }
//   };
//   useEffect(() => {
//    fetchInquiryLabels()
//   }, []);



  const validatePhoneNumber = (phone) => {
    const phoneRegex = /^(?:\+91|91)?(?:\d{10}|\d{5} \d{5}|\d{5} \d{6}|\d{11}|\d{12})$/;
    return phoneRegex.test(phone.replace(/\s+/g, ''));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = {};

    

    // Validate required fields
    if (!leadData?.viewerName?.trim()) {
      validationErrors.viewerName = "Viewer Name is required";
    }
    if (!leadData?.email?.trim()) {
      validationErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(leadData.email)) {
      validationErrors.email = "Invalid email address";
    }
    // if (!leadData?.phone?.trim()) {
    //   validationErrors.phone = "Phone number is required";
    // } else if (!validatePhoneNumber(leadData?.phone)) {
    //   validationErrors.phone = "Invalid phone number format";
    // }

    // if ( leadData.alternatePhone !== '' && leadData.alternatePhone.length < 12) {
    //   validationErrors.alternatePhone = "Invalid phone number format";
    // } 

    if ( leadData.phone.length < 12) {
      validationErrors.phone = "Invalid phone number format";
    } 

    if ( leadData.alternatePhone !== '' && leadData.alternatePhone.length < 12) {
      validationErrors.alternatePhone = "Invalid phone number format";
    } 

    // If there are validation errors, show toast and update error state
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      Object.values(validationErrors).forEach((error) => {
        toast.error(error);
      });
      return;
    }

    const { _id, createdAt, ...leadDataWithoutId} = leadData;

    try {
      setIsLoading(true)
      // Replace with your actual API endpoint
      const response = await instance.post(
        `api/inquiries/get_inquiry_update/${enquiry?._id}`,
        leadDataWithoutId,
        {
          headers : {
            Authorization : `Bearer ${authToken}`
          }
        }
      );

      if(response?.data?.code===200){

        toast.success("Enquiry updated successfully!");
        console.log("Lead updated:", response.data);
        
        fetchEnquiry()
      } else{
        toast.error("Something went wrong")
      }


      setIsLoading(false)
      // setDrawerType(''); // Close the drawer after successful update

    } catch (error) {
      setIsLoading(false)
      toast.error(error.response?.data?.message + " something went wrong");
      console.error("Error updating lead data:", error);
    }
  };
  const renderTextField = (label, name, value, additionalClassName, type = 'text') => (
    <div>
      <label className="block text-sm font-medium text-gray-700 mb-1">
        {label} {['viewerName', 'email', 'phone'].includes(name) && <span className="text-red-500">*</span>}
      </label>
      <input
        type={type}
        name={name}
        value={value}
        onChange={(e) => setLeadData({ ...leadData, [name]: e.target.value })}
        className={`w-full border border-gray-300 rounded-md py-2 px-3 ${additionalClassName}`}
      />
      {errors[name] && <p className="text-red-500 text-sm">{errors[name]}</p>}
    </div>
  );
  const renderDropdown = (label, name, value, type) => (
    <div>
      <label className="block text-sm font-medium text-gray-700 mb-1">
        {label}
      </label>
      <Select
        size="small"
        sx={{ width: '100%' }}
        name={name}
        value={value ? value : ''}
        onChange={(e) => setLeadData({ ...leadData, [name]: e.target.value })}
        renderValue={(selected) => {
          const option = staticOptions[type]?.find((opt) => opt.name.toLowerCase() === selected.toLowerCase());
          return (
            <Box
              sx={{
                backgroundColor: option ? option.color : 'transparent',
                color: 'white',
                padding: '2px 8px',
                borderRadius: '4px'
              }}
            >
              {selected || `Select ${label}`}
            </Box>
          );
        }}
      >
        { staticOptions[name]?.length > 0 ?  staticOptions[type].map((option) => (
          <MenuItem
            key={option._id}
            value={option.name.toLowerCase()}
            sx={{
              backgroundColor: option.color,
              color: 'white',
              '&:hover': {
                backgroundColor: option.color,
                opacity: 0.8
              },
              '&.Mui-selected': {
                backgroundColor: option.color,
                '&:hover': {
                  backgroundColor: option.color,
                  opacity: 0.8
                }
              }
            }}
          >
            {option.name}
          </MenuItem>
        )) :  <button onClick={()=>setOpenLabel(true)} className="w-full bg-brand-main text-white flex items-center justify-center p-1 "><IoAddOutline/>
<span>Add Label</span></button>}
      </Select>
    </div>
  );
  
  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{ maxWidth: 800, margin: "auto", px: 2 }}
    >
      <h2 className="font-semibold text-xl mb-4">Edit Enquiry Details</h2>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          {renderTextField("Viewer Name", "viewerName", leadData.viewerName, 'py-[9px]')}
        </Grid>
        <Grid item xs={12} sm={6}>
  {renderDropdown("Status", "status", leadData.status, 'status')}
</Grid>
<Grid item xs={12} sm={6}>
  {renderDropdown("Source", "source", leadData.source, 'source')}
</Grid>
<Grid item xs={12} sm={6}>
  {renderDropdown("Importance", "importance", leadData.importance, 'importance')}
</Grid>
        {/* <Grid item xs={12} sm={6}>
          {renderDropdown(
            "Status",
            "status",
            leadData.status,
            staticOptions.status
          )}
        </Grid>
        <Grid item xs={12} sm={6}>
          {renderDropdown(
            "Source",
            "source",
            leadData.source,
            staticOptions.source
          )}
        </Grid>
        <Grid item xs={12} sm={6}>
          {renderDropdown(
            "Importance",
            "importance",
            leadData.importance,
            staticOptions.importance
          )}
        </Grid> */}
        <Grid item xs={12} sm={6}>
          {/* {renderTextField("Phone", "phone", leadData.phone)} */}
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Phone
 <span className="text-red-500">*</span>
      </label>
          <PhoneInput
                    country={"in"}
                    inputStyle={{
                      width: "100%",
                      height: "40px",
                      borderRadius: "5px",
                    }}
                    name="phone"
                    id="phone"
                    onChange={(e) => {
                      setLeadData((prev)=>{
                        return {...prev, phone:e}
                      })
                    }}
                    
                    value={leadData.phone}
                  />

{errors["phone"] && <p className="text-red-500 text-sm">{errors["phone"]}</p>}
        </Grid>
        <Grid item xs={12} sm={6}>
          {/* {renderTextField(
            "Alternate Phone",
            "alternatePhone",
            leadData.alternatePhone
          )} */}



          
<label className="block text-sm font-medium text-gray-700 mb-1">
            Phone
      </label>
          <PhoneInput
                    country={"in"}
                    inputStyle={{
                      width: "100%",
                      height: "40px",
                      borderRadius: "5px",
                    }}
                    name="alternatePhone"
                    id="alternatePhone"
                    onChange={(e) => {
                      setLeadData((prev)=>{
                        return {...prev, alternatePhone:e}
                      })
                    }}
                    
                    value={leadData.alternatePhone}
                  />

{errors["alternatePhone"] && <p className="text-red-500 text-sm">{errors["alternatePhone"]}</p>}

        </Grid>
        <Grid item xs={12}>
          {renderTextField("Email", "email", leadData.email)}
        </Grid>
        <Grid item xs={12}>
          {renderTextField("Address", "address", leadData.address)}
        </Grid>
        <Grid item xs={12} sm={6}>
          {renderTextField("City", "city", leadData.city)}
        </Grid>
        <Grid item xs={12} sm={6}>
          {renderTextField("State", "state", leadData.state)}
        </Grid>
        <Grid item xs={12} sm={6}>
          {renderTextField("Pincode", "pincode", leadData.pincode)}
        </Grid>
        <Grid item xs={12}>
          <p>Note : </p>
          <TextareaAutosize
            minRows={3}
            placeholder="Note"
            name="note"
            value={leadData.note}
            onChange={(e) => setLeadData({ ...leadData, note: e.target.value })}
            style={{
              width: "100%",
              padding: "8px",
              marginTop: "4px",
              marginBottom: "8px",
              borderRadius: "4px",
              borderColor: "#ccc",
              border: "1px solid",
            }}
          />
        </Grid>
      </Grid>
      <Box sx={{ mt: 3 }}>
    { !isLoading ?    <button type="submit" className="w-full bg-brand-main text-white p-2 rounded-md">
          Save Changes
        </button> : 
        
        <button disabled className="w-full bg-brand-400 text-white p-2 rounded-md">
       Please wait 
      </button>
        
        
        }
      </Box>


      <LabelManager openLabel={openLabel} setOpenLabel={setOpenLabel} />

    </Box>
  );
};
export default EditLead;