import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import InputField from "components/fields/InputField";
import toast from "react-hot-toast";
import { instance } from "common/Instance";
import { useNavigate } from "react-router-dom";
import { SET_FORGET_EMAIL } from "../../redux/reducerSlice/authSlice";
import { useDispatch } from "react-redux";

export default function ForgetPassword() {
  const navigate = useNavigate();
  const dispatch=useDispatch()
  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (value) => {
    
      try {
        const response = await instance.post("api/auth/forget_password", value);

        if (response.status === 200) {
          navigate("/auth/resetpassword");
          dispatch(SET_FORGET_EMAIL(value.email));
        }
      } catch (error) {
        toast.error(error?.response?.data?.message ?? error.message);
      }
    },
  });

  return (
    <div className="mb-16 mt-16 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-start">
      <div className="mt-[2vh] w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
        <p className="mb-2.5  p-2 text-4xl font-bold text-navy-700 dark:text-white">
          Forgot your password?
        </p>
        <p className="mb-2 ml-2 mt-4 text-base  text-blue-900 ">
          No problem. Just let us know your email address and we'll email you,
        </p>

        <div className="mb-4 mt-1 flex items-center gap-3">
          <div className="h-px w-full bg-gray-200 dark:bg-navy-700" />
        </div>

        {/* Email */}
        <InputField
          variant="auth"
          extra="mb-3"
          label="Email"
          placeholder="mail@simple.com"
          id="email"
          type="text"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.email}
          error={formik.touched.email && formik.errors.email}
        />

        {/* password */}

        <button
          className="linear mt-2 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
          type="submit"
          onClick={formik.handleSubmit}
        >
          Verify OTP
        </button>
      </div>
    </div>
  );
}
