import { useState ,useEffect} from 'react';
import moment from 'moment'
import { useSelector ,useDispatch} from 'react-redux';
import Pagination from "@mui/material/Pagination";
import { instance } from 'common/Instance';
import * as React from 'react';


import {
    SET_INITIAL_STATE_ANALYTICS_REGION_WISE,
  } from "../../../../redux/reducerSlice/analytics";




const RegionAnalytics = ({experience,isMultiscene}) => {
    const dispatch=useDispatch()
    const [loading,setLoading]=useState(false)
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [itemsPerPage, setSelectedValue] = useState(5);
    const getRegionAnalytics = useSelector(
        (state) => state?.analytics?.data?.getRegionwiseVistor
      );

     
    const authToken = useSelector((state) => state?.auth?.auth?.token);

    const handleChanges = (event, value) => {
        setCurrentPage(value);
      };


      const getDashboardVisitorData = async () => {
        setLoading(true)
        try {
          const response = await instance.get(
            `api/visitors/visitors_detailed_data?page=${currentPage}&limit=${itemsPerPage}&experienceName=${experience}${isMultiscene?`&multiScene=true`:''}`,
            {
              headers: {
                authorization: `Bearer ${authToken}`,
              },
            }
          );
          if (response.status === 200) {
            setLoading(false)
            dispatch(
              SET_INITIAL_STATE_ANALYTICS_REGION_WISE(response?.data?.data?.data)
            
            );
            setTotalPages(response?.data?.data?.totalPages)
          }
        } catch (error) {
            setLoading(false)
          console.log("Dashboard Error: ", error);
        }
      };

      useEffect(()=>{
        getDashboardVisitorData();
      },[itemsPerPage,currentPage,experience,isMultiscene])





  return (
   
      <div className="   overflow-x-scroll xl:overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-brand-main py-10 rounded-2xl">
            <tr>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-white w-2/12"
              >
                No.
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-white"
              >
                Date
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-white"
              >
                Country
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-white"
              >
                State
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-white"
              >
                City
              </th>

              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-white"
              >
                Revisit
              </th>

             
            </tr>
          </thead>

          <tbody className="divide-y divide-gray-200 bg-white">
            {loading ? (
              [...Array(4)].map((_, index) => (
                <tr key={index}>
                  <td
                    className="whitespace-nowrap px-6 py-4"
                    style={{ width: "15%" }}
                  >
                    <div role="status" className="animate-pulse">
                      <div className="h-4 rounded-full bg-gray-200 dark:bg-gray-800"></div>
                    </div>
                  </td>
                  <td
                    className="whitespace-nowrap px-6 py-4"
                    style={{ width: "20%" }}
                  >
                    <div role="status" className="animate-pulse">
                      <div className="h-4 rounded-full bg-gray-200 dark:bg-gray-800"></div>
                    </div>
                  </td>
                  <td
                    className="whitespace-nowrap px-6 py-4"
                    style={{ width: "20%" }}
                  >
                    <div role="status" className="animate-pulse">
                      <div className="h-4 rounded-full bg-gray-200 dark:bg-gray-800"></div>
                    </div>
                  </td>
                  <td
                    className="whitespace-nowrap px-6 py-4"
                    style={{ width: "20%" }}
                  >
                    <div role="status" className="animate-pulse">
                      <div className="h-4 rounded-full bg-gray-200 dark:bg-gray-800"></div>
                    </div>
                  </td>
                  <td
                    className="whitespace-nowrap px-6 py-4"
                    style={{ width: "20%" }}
                  >
                    <div role="status" className="animate-pulse">
                      <div className="h-4 rounded-full bg-gray-200 dark:bg-gray-800"></div>
                    </div>
                  </td>
                  <td
                    className="whitespace-nowrap px-6 py-4"
                    style={{ width: "20%" }}
                  >
                    <div role="status" className="animate-pulse">
                      <div className="h-4 rounded-full bg-gray-200 dark:bg-gray-800"></div>
                    </div>
                  </td>

                 
                </tr>
              ))
            ) : getRegionAnalytics?.length > 0 ? (
                getRegionAnalytics?.map((item, index) => (
                <tr key={index+1}>
                
                  <td
                    className="whitespace-nowrap px-6 py-4 text-sm"
                    style={{ width: "15%" }}
                  >
                    {index + 1}
                  </td>
                  <td
                    className="whitespace-nowrap px-6 mr-2 py-4 text-sm"
                    style={{ width: "20%" }}
                  >
                     {moment(item?.createdAt).format("LL")}
                  </td>
                  <td
                    className="whitespace-nowrap px-6 py-4 text-sm capitalize"
                    style={{ width: "20%" }}
                  >
                    {item?.country ?? `Unknown`}
                  </td>
                  <td
                    className="whitespace-nowrap px-6 py-4 text-sm capitalize"
                    style={{ width: "20%" }}
                  >
                    {item?.state ?? `Unknown`}
                  </td>
                  <td
                    className="whitespace-nowrap px-6 py-4 text-sm capitalize"
                    style={{ width: "20%" }}
                  >
                    {item?.city ?? `Unknown`}
                  </td>
                  <td
                    className="whitespace-nowrap px-6 py-4 text-sm"
                    style={{ width: "20%" }}
                  >
                    {item?.revisit ? item?.revisit : 0}
                  </td>

                 
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="12" className="py-4 text-center">
                  <p className="bg-blue-100 px-10 py-5 text-sm text-blue-600">
                    Data Not Found!
                  </p>
                </td>
              </tr>
            )}
          </tbody>
        </table>


        <div className="pt-7 pb-5 flex w-full items-center justify-center bg-white  text-sm">
        <Pagination
            count={totalPages}
            color="primary"
            page={currentPage}
            onChange={handleChanges}
          />
        </div>
    

   
      </div>
   
  );
};

export default RegionAnalytics;








