import PieChartWithCenterLabel from "components/charts/PieChartWithCenterLabel";
import { useEffect,useState } from "react";
import Card from "components/card";
import { useSelector } from "react-redux";
import { instance } from "common/Instance";



const RegionChartCard = ({ data ,experience,isMultiscene}) => {
  const [loading,setLoading]=useState(false)
  const [chartData,setChartData]=useState([])

  const authToken = useSelector((state) => state?.auth?.auth?.token);

  const getDashboardVisitorData = async () => {
    setLoading(true)
    try {
      const response = await instance.get(
        `api/visitors/visitors_detailed_data?locationDash=true&country=true&experienceName=${experience}${isMultiscene?`&multiScene=true`:''}`,
        {
          headers: {
            authorization: `Bearer ${authToken}`,
          },
        }
      );
      if (response.status === 200) {
        setLoading(false)
       setChartData(response?.data?.data?.data)
    
      }
    } catch (error) {
      setLoading(false)
      console.log("Dashboard Error: ", error);
    }
  };


  useEffect(()=>{
    getDashboardVisitorData()
  },[experience,isMultiscene])


  
  return (

    <div>
    <Card extra="rounded-[20px] p-3 h-full ">
      <div className="flex flex-row justify-between px-3 pt-2">
        <div>
          <h4 className="text-lg font-bold text-navy-700 dark:text-white">
          Country
          </h4>
        </div>


      </div>

      <div className="mb-auto flex h-full w-full flex-col items-center justify-center">
      <div className="w-80 h-40 mb-10">
        {!loading ? <PieChartWithCenterLabel chartData={chartData}   /> : <div className=" rounded-lg p-4 w-full h-full">
          <div className="animate-pulse flex space-x-4 justify-center items-center w-full h-full">
            <div className="rounded-full bg-gray-500 h-40 w-40"></div>
          </div>
     
        </div>}
        </div>

        

       
       
        <div className="w-full mt-4">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-brand-main w-6/12"
              >
               Country
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider  text-brand-main"
              >
                TOTAL VIEWER
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider  text-brand-main"
              >
               Percentage
              </th>
            
            </tr>
          </thead>

          <tbody className="divide-y divide-gray-200 bg-white">


            {
              !loading ? <>  {
                chartData?.map((item, index) => (
                  <tr key={index}>
                    <td
                      className="whitespace-nowrap px-6 py-4"
                     
                    >
                      <div role="status" >
                        <div className="h-4 rounded-full  dark:bg-gray-800 capitalize">{item?.location}</div>
                      </div>
                    </td>
                    <td
                      className="whitespace-nowrap px-6 py-4"
                     
                    >
                      <div role="status" >
                        <div className="h-4 rounded-full  dark:bg-gray-800 ml-4">{item?.count}</div>
                      </div>
                    </td>
                    <td
                      className="whitespace-nowrap px-6 py-4"
                      
                    >
                      <div role="status">
                        <div className="h-4 rounded-full dark:bg-gray-800 ml-4 text-green-500">{item?.percentage.toFixed(2)} %</div>
                      </div>
                    </td>
                  
                  </tr>
                ))
              }</> : <>
              {
                [...Array(2)].map((index) => (
                  <tr key={index}>
                    <td
                      className="whitespace-nowrap px-6 py-4"
                     
                    >
                      <div role="status" >
                        <div className="h-4 rounded-full bg-gray-100 animate-pulse"></div>
                      </div>
                    </td>
                    <td
                      className="whitespace-nowrap px-6 py-4"
                     
                    >
                      <div role="status" >
                        <div className="h-4 rounded-full bg-gray-100 animate-pulse"></div>
                      </div>
                    </td>
                    <td
                      className="whitespace-nowrap px-6 py-4"
                      
                    >
                       <div role="status" >
                        <div className="h-4 rounded-full bg-gray-100 animate-pulse"></div>
                      </div>
                    </td>
                  
                  </tr>
                ))
              }
              </>
            }
         
        
          </tbody>
        </table>
      
      </div>


       

      </div>
    
    </Card>
    </div>

  );
};

export default RegionChartCard;
