import React, { useMemo, useState, useEffect, useRef } from "react";
import CardMenu from "components/card/CardMenu";
import Checkbox from "components/checkbox";
import Card from "components/card";
import { FaDownload } from "react-icons/fa6";
import { useFormik } from "formik";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import InputField from "components/fields/InputField";
import { CiMenuKebab } from "react-icons/ci";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import { loadTexture } from "views/admin/arExperience/tabcomponent/EditorUtils";
import CommonBackdrop from "common/CommonBackdrop";
import * as mind from "mind-ar/dist/mindar-image.prod";
import { toJpeg, toPng, toSvg } from "html-to-image";
import { MdOutlineClear } from "react-icons/md";
import { useSelector } from "react-redux";
import { instance } from "common/Instance";
import toast from "react-hot-toast";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { SET_AUTH } from "../../../../redux/reducerSlice/authSlice";
import { SET_HISTORY_DATA } from "../../../../redux/reducerSlice/arSlice";
import Pagination from "@mui/material/Pagination";
import { cardStaticData } from "../static/staticData";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import * as Yup from "yup";
import { GrPowerReset } from "react-icons/gr";
import moment from "moment";
import CardOne from "views/admin/arExperience/tabcomponent/Cardtemplates/CardOne";
import ReactToPrint from "react-to-print";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { AWS_STATIC_PREFIX } from "common/Instance";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  borderRadius: "10px",
  boxShadow: 24,
};

const plans = [
  {
    name: "Silver",
    price: 999,
    description: "Basic Plan",
    bgColor: "bg-gray-100 ",
    textColor: "text-gray-600",
    planId: "plan_Cash_1500",
    addOns: [
      { name: "Silver + OCR", price: 1998, ocr: true },
      { name: "Silver", price: 999 },
    ],
  },
  {
    name: "Gold",
    price: 1999,
    description: "Standard Plan",
    bgColor: "bg-yellow-100",
    textColor: "text-yellow-600",
    planId: "plan_Cash_2500",
    addOns: [
      { name: "Gold + OCR", price: 2998, ocr: true },
      { name: "Gold", price: 1999 },
    ],
  },
  {
    name: "Platinum",
    price: 2999,
    description: "Premium Plan",
    bgColor: "bg-indigo-100",
    textColor: "text-indigo-600",
    planId: "plan_Cash_3500",
    addOns: [
      { name: "Platinum + OCR", price: 3998, ocr: true },
      { name: "Platinum", price: 2999 },
    ],
  },
];

const CheckTable = () => {
  const authToken = useSelector((state) => state?.auth?.auth?.token);
  const userData = useSelector((state) => state?.auth?.auth?.user);
  console.log('userData: ', userData);
  const cardRef = useRef();
  const [loadingPayment, setLoadingPayment] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [search, setSearch] = useState("");
  const [user, setUserId] = React.useState("");
  const [searchParam, setSearchParam] = useState("");
  const [open, setOpen] = React.useState(false);
  const [openList, setOpenList] = React.useState(false);
  const handleOpenList = () => setOpenList(true);
  const handleCloseList = () => setOpenList(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [tableData, setTablesData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [itemsPerPage, setSelectedValue] = useState(5);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openAccordion, setOpenAccordion] = React.useState(null);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openPaymentDialog, setOpenPaymentDialog] = useState(false);
  const [openPrintDialog, setOpenPrintDialog] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const openActionMenu = Boolean(anchorEl);

  const handleAddOnClick = (plan, addOn) => {
    if (addOn?.ocr) {
      setSelectedPlan({
        ...plan,
        selectedAddOn: addOn.name,
        price: addOn.price,
        ocr: addOn.ocr,
      });
    } else {
      setSelectedPlan({
        ...plan,
        selectedAddOn: addOn.name,
        price: addOn.price,
      });
    }
  };

  const userList = async (page) => {
    setLoading(true);
    try {
      const response = await instance.get(
        `/api/reseller/user_list?page=${
          page ? page : currentPage
        }&limit=${itemsPerPage}&searchText=${searchParam}`,
        {
          headers: {
            authorization: `Bearer ${authToken}`,
          },
        }
      );

      if (response.status === 200) {
        setTablesData(response?.data?.data?.data);
        setLoading(false);
        setTotalPages(response?.data?.data?.totalPages);
      }
    } catch (error) {
      console.log("error", error);
      toast.error(error && error?.response?.message);
      setLoading(false);
    }
  };

  const deleteUser = async (userId) => {
    try {
      setLoadingDelete(true);
      const response = await instance.delete(
        `/api/reseller/delete_user/${userId}`,
        {
          headers: {
            authorization: `Bearer ${authToken}`,
          },
        }
      );
      if (response.status === 200) {
        toast.success(response.data.message);
        setOpenDeleteDialog(false);
        setLoadingDelete(false);
        userList(searchParam ? 1 : currentPage);
        setAnchorEl(null);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message ?? error.message);
      setLoadingDelete(false);
    }
  };

  useEffect(() => {
    userList();
  }, [itemsPerPage, searchParam, currentPage]);

  const handleChanges = (event, value) => {
    setCurrentPage(value);
  };

  const handleSearch = (e) => {
    const inputValue = e.target.value;
    setSearch(inputValue);
    if (!inputValue || inputValue.trim() === "") {
      setSearchParam("");
    } else {
      setSearchParam(inputValue.trim());
    }
  };

  const handleChange = (e) => {
    setSelectedValue(e.target.value);
  };

  const handleCloseAnchor = () => {
    setAnchorEl(null);
  };
  const toggleAccordion = (index) => {
    if (openAccordion === index) {
      setOpenAccordion(null);
    } else {
      setOpenAccordion(index);
    }
  };

  const handleClickAnchor = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleSelectUser = (item) => {
    setSelectedUser(item);
  };

  const handleCashPayment = async (plan) => {
    setLoadingPayment(true);
    try {
      const data = {
        planId: plan?.planId,
        userId: user._id,
        PlanName: plan?.name,
        PlanAmount: plan?.price,
        total_count: 12,
      };

      if (plan?.ocr) {
        data.ocr = plan?.ocr;
      }

      const response = await instance.post(
        "api/rezorpay/subscription_cash_reseller",
        data,
        {
          headers: {
            authorization: `Bearer ${authToken}`,
          },
        }
      );
      if (response.status === 200) {
        toast.success("Payment successful");
        setOpenPaymentDialog(false);
        userList(searchParam ? 1 : currentPage);
        setSelectedPlan(null);
        setLoadingPayment(false);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message ?? error.message);
      setLoadingPayment(false);
    }
  };


  return (
    <Card extra={"w-full h-full sm:overflow-auto px-6"}>
      <header className=" flex w-full flex-col justify-center gap-2 py-4">
        <div className="relative flex  items-center justify-between">
          <div className="text-md font-bold text-navy-700 ">User list</div>

          <div className="User listitems-center flex justify-between gap-4">
            <button
              className="text-md rounded-md bg-brand-main px-2 py-1 text-center font-semibold text-white"
              onClick={handleOpen}
            >
              Create User
            </button>
          </div>
        </div>
        <div className="relative flex  w-full items-center py-1">
          <input
            className="w-[500px] rounded-lg border-2 p-2.5 text-sm outline-none"
            type="text"
            value={search}
            onChange={handleSearch}
            placeholder="Search"
          />

          <div className="flex w-full items-center justify-end ">
            <p className="text-sm">List per page:</p>
            <select
              class="ml-2 block h-8 w-12 rounded-md  border bg-gray-200  shadow-sm outline-none "
              placeholder="List"
              value={itemsPerPage}
              onChange={handleChange}
            >
              {[...Array(11).keys()].map((_, index) => (
                <>
                  {index > 0 && (
                    <option key={index} value={index * 5}>
                      {index * 5}
                    </option>
                  )}
                </>
              ))}
            </select>
          </div>
        </div>
      </header>

      <div className="   overflow-x-scroll xl:overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
              >
                Name
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
              >
                Username
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
              >
                Email
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
              >
                SSO Access
              </th>

            
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
              >
                Payment
              </th>

              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
              >
                Experience Limit
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
              >
                Action
              </th>
            </tr>
          </thead>

          <tbody className="divide-y divide-gray-200 bg-white">
            {loading ? (
              [...Array(itemsPerPage)].map((_, index) => (
                <tr key={index}>
                  <td
                    className="whitespace-nowrap px-6 py-4"
                    style={{ width: "25%" }}
                  >
                    <div role="status" className="animate-pulse">
                      <div className="h-4 rounded-full bg-gray-200 dark:bg-gray-800"></div>
                    </div>
                  </td>
                  <td
                    className="whitespace-nowrap px-6 py-4"
                    style={{ width: "25%" }}
                  >
                    <div role="status" className="animate-pulse">
                      <div className="h-4 rounded-full bg-gray-200 dark:bg-gray-800"></div>
                    </div>
                  </td>
                  <td
                    className="whitespace-nowrap px-6 py-4"
                    style={{ width: "25%" }}
                  >
                    <div role="status" className="animate-pulse">
                      <div className="h-4 rounded-full bg-gray-200 dark:bg-gray-800"></div>
                    </div>
                  </td>
                  <td
                    className="whitespace-nowrap px-6 py-4"
                    style={{ width: "25%" }}
                  >
                    <div role="status" className="animate-pulse">
                      <div className="h-4 rounded-full bg-gray-200 dark:bg-gray-800"></div>
                    </div>
                  </td>
                  <td
                    className="whitespace-nowrap px-6 py-4"
                    style={{ width: "25%" }}
                  >
                    <div role="status" className="animate-pulse">
                      <div className="h-4 rounded-full bg-gray-200 dark:bg-gray-800"></div>
                    </div>
                  </td>
                  <td
                    className="whitespace-nowrap px-6 py-4"
                    style={{ width: "25%" }}
                  >
                    <div role="status" className="animate-pulse">
                      <div className="h-4 rounded-full bg-gray-200 dark:bg-gray-800"></div>
                    </div>
                  </td>
                </tr>
              ))
            ) : tableData.length > 0 ? (
              tableData.map((item) => (
                <tr key={item._id}>
                  {console.log(item)}
                  <td
                    className="whitespace-nowrap px-6 py-4"
                    style={{ width: "25%" }}
                  >
                    <div className="text-sm font-medium text-gray-900">
                      {item.fullName}
                    </div>
                  </td>
                  <td
                    className="whitespace-nowrap px-6 py-4"
                    style={{ width: "25%" }}
                  >
                    <div className="text-sm text-gray-900">{item.username}</div>
                  </td>
                  <td
                    className="whitespace-nowrap px-6 py-4"
                    style={{ width: "25%" }}
                  >
                    <div className="text-sm text-gray-900">{item.email}</div>
                  </td>
                  <td
                    className="whitespace-nowrap px-6 py-4"
                    style={{ width: "25%" }}
                  >
                    <button
                      className="ml-5 rounded-md bg-blue-600 p-2 px-2.5 text-sm font-semibold text-white"
                      onClick={() => {
                        handleOpenList();
                        setUserId(item);
                      }}
                    >
                      SSO Login
                    </button>
                  </td>
                  <td
                    className="whitespace-nowrap px-6 py-4"
                    style={{ width: "25%" }}
                  >
                    <div
                      className={`text-sm ${
                        item?.payment === "paid"
                          ? "text-green-600"
                          : "text-red-600"
                      } font-semibold `}
                    >
                      {item?.payment ? item?.payment : "Unpaid"}
                    </div>
                  </td>
                  <td
                    className="whitespace-nowrap px-6 py-4"
                    style={{ width: "25%" }}
                  >
                    {item?.allowedExperiencesCount ?? 0}
                  </td>
                  <td className="justify-content-around flex whitespace-nowrap px-3 py-4">
                    <IconButton
                      aria-label="more"
                      id="long-button"
                      aria-controls={openActionMenu ? "long-menu" : undefined}
                      aria-expanded={openActionMenu ? "true" : undefined}
                      aria-haspopup="true"
                      onClick={(e) => {
                        handleClickAnchor(e);
                        handleSelectUser(item);
                      }}
                    >
                      <CiMenuKebab className="text-black" />
                    </IconButton>
                    <Menu
                      id="long-menu"
                      MenuListProps={{
                        "aria-labelledby": "long-button",
                      }}
                      sx={{
                        "& .MuiPaper-root": {
                          boxShadow:
                            "rgb(50 50 93 / 9%) 0px 2px 5px -1px, rgb(0 0 0 / 0%) 0px 1px 3px -1px",
                        },
                      }}
                      anchorEl={anchorEl}
                      open={openActionMenu}
                      onClose={handleCloseAnchor}
                    >


{
  console.log(selectedUser,'userrohit')
}

                      <MenuItem
                        onClick={() => {
                          setOpenPaymentDialog(true);
                          setUserId(selectedUser);
                        }}
                        disabled={
                         selectedUser?.payment==='paid'
                          
                        }
                      >
                        Payment
                      </MenuItem>


                      <MenuItem
                        onClick={() => {
                          setOpenPrintDialog(true);
                          setUserId(selectedUser);
                        }}
                      >
                        View Card
                      </MenuItem>
                      {/* <MenuItem>Experience Limit</MenuItem> */}
                      <MenuItem
                        onClick={() => {
                          setOpenDeleteDialog(true);
                          setUserId(selectedUser);
                        }}
                      >
                        Delete
                      </MenuItem>
                    </Menu>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="4" className="py-4 text-center">
                  <p className="bg-blue-100 px-10 py-5 text-sm text-blue-600">
                    Data Not Found!
                  </p>
                </td>
              </tr>
            )}
          </tbody>
        </table>
        <div className="my-5 flex w-full items-center justify-end  text-sm">
          <Pagination
            count={totalPages}
            color="primary"
            page={currentPage}
            onChange={handleChanges}
          />
        </div>
      </div>
      <CreateAccount
        open={open}
        handleClose={handleClose}
        fetchUserList={userList}
      />
      <SSOLogin open={openList} handleClose={handleCloseList} user={user} />
      <Dialog
        open={openDeleteDialog}
        onClose={() => {
          setOpenDeleteDialog(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>Delete user</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this user from the database?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <button
            className="ml-5 rounded-md bg-gray-600 p-1 px-4 text-sm text-gray-900"
            onClick={() => {
              setOpenDeleteDialog(false);
            }}
          >
            Close
          </button>
          <button
            disabled={loadingDelete}
            className="ml-5 rounded-md bg-red-600 p-1 px-4 text-sm text-white"
            onClick={() => {
              deleteUser(user?._id);
            }}
            autoFocus
          >
            Ok
          </button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openPaymentDialog}
        onClose={() => setOpenPaymentDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Manual Cash Payment</DialogTitle>
        <DialogContent>
          <div className="flex w-full flex-col items-center rounded-lg bg-white py-6 shadow-lg">
            <p className="py-4 text-2xl font-semibold text-gray-800">
              Select Plan
            </p>
            <ul className="flex w-full justify-around gap-5 px-4">
              {plans.map((plan) => (
                <li
                  key={plan.name}
                  className={`flex flex-col items-center rounded-lg p-6 shadow-md transition-shadow duration-200 ease-in-out hover:shadow-lg ${
                    plan.bgColor
                  } ${
                    selectedPlan?.name === plan.name
                      ? "border-4 border-blue-500"
                      : ""
                  } w-1/4 min-w-[150px]`}
                >
                  <p className={`text-2xl font-bold ${plan.textColor}`}>
                    {plan.name}
                  </p>
                  <p className="py-2 text-lg font-semibold text-gray-700">
                    {plan.price}
                  </p>
                  <span className="text-sm text-gray-500">
                    {plan.description}
                  </span>

                  {/* Add-Ons Section */}
                  <div className="mt-4">
                    <p className="text-center text-sm font-semibold text-gray-600">
                      Add On
                    </p>
                    {plan.addOns.map((addOn, index) => (
                      <div
                        key={index}
                        className="mt-2 flex justify-start text-xs"
                      >
                        <input
                          type="radio"
                          id={`${plan.name}-${index}`}
                          name={`addOn-${plan.name}`}
                          value={addOn.price}
                          onChange={() => handleAddOnClick(plan, addOn)}
                          checked={
                            selectedPlan?.name === plan.name &&
                            selectedPlan?.selectedAddOn === addOn.name
                          }
                          className="mr-2"
                        />
                        <label
                          htmlFor={`${plan.name}-${index}`}
                          className="text-xs text-gray-700"
                        >
                          {addOn.name} - Rs {addOn.price}
                        </label>
                      </div>
                    ))}
                  </div>
                </li>
              ))}
            </ul>

            {selectedPlan && (
              <div className="mt-4 rounded-lg bg-blue-100 p-2 px-4 shadow-md">
                <p className="text-lg font-semibold text-blue-700">
                  Selected Plan: {selectedPlan.selectedAddOn} - Rs{" "}
                  {selectedPlan.price}
                </p>
              </div>
            )}
          </div>
        </DialogContent>
        <DialogActions>
          <button
            className="text-md ml-5 rounded-md bg-gray-600 p-1 px-4 text-gray-900"
            onClick={() => {
              setOpenPaymentDialog(false);
              setSelectedPlan(null);
            }}
          >
            Close
          </button>
          <button
            disabled={!selectedPlan || loadingPayment}
            className={`rounded-md p-1 px-4 text-white ${
              !selectedPlan || loadingPayment
                ? "cursor-not-allowed bg-gray-400"
                : "bg-green-500"
            }`}
            onClick={() => handleCashPayment(selectedPlan)}
          >
            Cash Payment
          </button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openPrintDialog}
        maxWidth="md"
        fullWidth
        onClose={() => {
          setOpenPrintDialog(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle sx={{ textAlign: "center" }}>AR card</DialogTitle>
        <DialogContent>
          <div className="flex flex-col items-center justify-center gap-2">
            <CardOne
              ref={cardRef}
              username={user?.username}
              name={user?.fullName}
            />
            <input
              className="w-full rounded-md border p-2"
              value={`https://ar.immarsify.com/${user?.username}/${user?.username}`}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <button
            className="text-md ml-5 rounded-md bg-gray-300 p-1 px-4 text-gray-900"
            onClick={() => {
              setOpenPrintDialog(false);
            }}
          >
            Close
          </button>

          <ReactToPrint
            trigger={() => (
              <button className="text-md ml-5 rounded-md bg-brand-main p-1 px-4 font-semibold text-white">
                Print this card
              </button>
            )}
            content={() => cardRef.current}
          />
        </DialogActions>
      </Dialog>
    </Card>
  );
};

function CreateAccount({ open, handleClose, fetchUserList }) {
  const cardRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [dynamicTitle, setDynamicTitle] = useState("Creating user");

  const [arData, setArData] = useState(cardStaticData);
  const [compliningProgress, setCompilingProgress] = useState(0);

  const compiler = new mind.Compiler();

  const authToken = useSelector((state) => state?.auth?.auth?.token);
  const userData = useSelector((state) => state?.auth?.auth?.user);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    username: Yup.string().required("Username is required"),
    email: Yup.string().email("Invalid email").required("Email is required"),
    phoneNumber: Yup.string()
      .test(
        "is-10-digits",
        "Phone number must be exactly 10 digits",
        function (value) {
          const numericValue = value.replace(/\D/g, ""); // Strip non-numeric characters
          return numericValue.length === 12;
        }
      )
      .required("Phone number is required"),
  });

  const initValue = {
    name: "",
    username: "",
    email: "",
    phoneNumber: "",
  };

  const [formData, setFormData] = useState(initValue);
  const processName = (name) => {
    const replacementChar = "";
    const cleanedName = name
      .replace(/[^a-zA-Z0-9]/g, replacementChar)
      .toLowerCase();

    return `${cleanedName}${Math.floor(1000 + Math.random() * 9000)}`;
  };

  const handleTarget = (token, userData) => {
    if (cardRef.current === null) {
      return;
    }
    setDynamicTitle("Generating card for Experience");
    setLoading(true);

    toJpeg(cardRef.current)
      .then((dataUrl) => {
        return fetch(dataUrl).then((res) => res.blob());
      })
      .then((blob) => {
        setLoading(true);
        const image = new Image();
        image.src = URL.createObjectURL(blob);
        setTimeout(async () => {
          await createTarget(blob, "create", [image], token, userData);
        }, 500);
      })

      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const createTarget = async (file, type, blobPreview, token, data) => {
    setLoading(true);
    setDynamicTitle("Creating Target image");
    try {
      const imageUrl =
        type === "upload"
          ? await uploadFile(file[0], "image", token, data)
          : await uploadFile(file, "image", token, data);

      const dataList = await compiler.compileImageTargets(
        blobPreview,
        (progress) => {
          setCompilingProgress(Math.round(progress));
        }
      );
      setCompilingProgress(0);
      const exportedBuffer = await compiler.exportData();
      const blob = new Blob([exportedBuffer]);
      const mindFile = new File([blob], "target.mind", { type: blob.type });
      const mindUrl = await uploadFile(mindFile, "mind", token, data);

      toast.success("Target Image Uploaded Successfully");

      console.log("data", data);
      await createARExperience(token, data, imageUrl, mindUrl);
    } catch (error) {
      toast.error(error?.response?.data?.message ?? error.message);
      setLoading(false);
    }
  };

  const uploadFile = async (file, assetType, token, data) => {
    console.log(file, "filetype");
    const formData = new FormData();
    formData.append("file", file);
    formData.append("assetType", assetType);
    try {
      const response = await instance.post(
        `/api/ar/upload/${data.username}/${data.username}`,
        formData,
        {
          headers: {
            authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return response.data.url;
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  const createARExperience = async (token, userData, imageUrl, mindUrl) => {
    console.log(userData, "userData");
    setLoading(true);
    const texture = await loadTexture(imageUrl);
    const textureWidth = texture.image.width;
    const textureHeight = texture.image.height;
    let targetImage;
    let aspectRatio;

    if (textureWidth >= textureHeight) {
      aspectRatio = textureWidth / textureHeight;
      targetImage = {
        src: imageUrl,
        scale: { x: 1.959, y: 1.098, z: 0.8 },
      };
    }
    if (textureHeight > textureWidth) {
      aspectRatio = textureHeight / textureWidth;
      targetImage = {
        src: imageUrl,
        scale: { x: 1.959, y: 1.098, z: 0.8 },
      };
    }

    const texture1 = await loadTexture(
      AWS_STATIC_PREFIX + "images/dummyProfile.jpg"
    );
    const textureWidthforPhoto = texture1.image.width;
    const textureHeightforPhoto = texture1.image.height;
    let profilePhoto;
    let aspectRatio1;
    let baseSize = 0.8;
    if (textureWidthforPhoto >= textureHeightforPhoto) {
      aspectRatio1 = textureWidth / textureHeight;
      profilePhoto = {
        src: AWS_STATIC_PREFIX + "images/dummyProfile.jpg",
        scale: { x: 1.959, y: 1.098, z: 0.8 },
      };
    }
    if (textureHeightforPhoto > textureWidthforPhoto) {
      aspectRatio1 = textureWidth / textureHeight;
      profilePhoto = {
        src: AWS_STATIC_PREFIX + "images/dummyProfile.jpg",
        scale: { x: baseSize, y: aspectRatio * baseSize, z: baseSize },
      };
    }

    try {
      const newIconLink = `https://wa.me/${userData?.phoneNumber}`;
      const newIconsArray = (arData?.icons ?? []).map((icon, index) => {
        if (index === 3) {
          return {
            ...icon,
            iconLink: `tel:+${userData?.phoneNumber}`,
            isLink: true,
          };
        }
        if (index === 4) {
          return {
            ...icon,
            vCardJson: {
              ...icon.vCardJson,
              firstName: userData?.fullName.split(" ")[0],
              lastName: userData?.fullName.split(" ")[1],
              photo: AWS_STATIC_PREFIX + "images/dummyProfile.jpg" ?? "",
              cellPhone: userData?.phoneNumber ?? "",
              email: userData?.email ?? "",
              workUrl: `https://ar.immarsify.com/${userData?.username}/${userData?.username}`,
              note: userData?.category?.join(", "),
            },
          };
        }
        if (index === 5 || index === 2) {
          return {
            ...icon,
            iconLink: `mailto:${userData?.email}`,
            isLink: true,
          };
        }
        if (index === 6) {
          return {
            ...icon,
            iconLink: newIconLink,
            isLink: true,
          };
        }
        return icon;
      });
      console.log("New icons array:", newIconsArray);

      let data = {
        ...arData,
        userId: userData._id,
        userName: userData?.username,
        arexperienceName: userData?.name,
        name: userData?.username,
        targetImage: targetImage,
        mind: {
          src: mindUrl,
        },
        text: [{ ...arData?.text[0], body: userData?.fullName }],
        photos: [{ ...arData?.photos[0], ...(profilePhoto ?? "") }],
        icons: newIconsArray,
      };
      const response = await instance.post(
        `/api/ar/create_experience`,
        { ...data },
        {
          headers: {
            authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        setArData(cardStaticData);
        toast.success(response?.data?.message ?? "Card created successfully");
        setLoading(false);
        handleClose();
        fetchUserList();
      }
    } catch (error) {
      setLoading(false);

      toast.error(error?.response?.data?.message ?? error.message);
    }
  };

  const handleSubmit = async (values) => {
    setDynamicTitle("Creating user");
    setLoading(true);
    const data = {
      fullName: values?.name,
      username: values?.username,
      email: values?.email,
      resellerId: userData?._id,
      phoneNumber: values?.phoneNumber,
      route: `${window.location.origin}/#/auth/user/setPassword`,
    };
    try {
      const response = await instance.post(`/api/reseller/create_user`, data, {
        headers: {
          authorization: `Bearer ${authToken}`,
        },
      });

      console.log(response);
      if (response.status === 200) {
        toast.success(response.data?.message);
        setFormData(initValue);
        handleTarget(response.data.token, response.data.userInfo);
      }
    } catch (error) {
      console.log("error", error);
      toast.error(error?.response?.data?.message ?? error.message);
      setLoading(false);
      setDynamicTitle("");
    }
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box
            className="w-[420px] bg-white md:w-[700px] lg:w-[800px]"
            sx={{ ...style }}
          >
            <div className="pb-8">
              <div className="flex items-center justify-between rounded-t-lg bg-gray-100 p-4">
                <p className="text-sm">Create User Account</p>
                <span onClick={handleClose} className="cursor-pointer">
                  <MdOutlineClear size={15} className="hover:text-red-500" />
                </span>
              </div>
              <div className="flex flex-col items-center justify-center gap-2 p-2 md:p-8">
                <Formik
                  initialValues={formData}
                  validationSchema={validationSchema}
                  onSubmit={handleSubmit}
                >
                  {({ errors, touched, setFieldValue, values, handleBlur }) => (
                    <div className="flex w-full flex-col  gap-3 md:flex-row">
                      <div className="w-[400px]">
                        <CardOne
                          ref={cardRef}
                          username={values.username}
                          experiencename={values.username}
                          name={values.name}
                        />
                      </div>
                      <Form className="flex w-full flex-col gap-2">
                        <div className="w-full">
                          <label
                            htmlFor="name"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Name
                          </label>
                          <Field
                            name="name"
                            type="text"
                            className="my-1 block w-full rounded-md border  px-3 py-2 shadow-sm focus:outline-none sm:text-sm"
                            placeholder="Enter Name"
                            onChange={(event) => {
                              setFieldValue("name", event.target.value);
                              setFieldValue(
                                "username",
                                processName(event.target.value)
                              );
                            }}
                          />
                          <ErrorMessage
                            name="name"
                            component="div"
                            className="text-xs text-red-600"
                          />
                        </div>
                        {/* <div className="w-full">
                          <label
                            htmlFor="username"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Username
                          </label>
                          <Field
                            name="username"
                            type="text"
                            className="mt-1 block w-full rounded-md  border px-3  py-2 shadow-sm focus:outline-none   sm:text-sm"
                            placeholder="Enter Username"
                          />
                          <ErrorMessage
                            name="username"
                            component="div"
                            className="text-xs text-red-600"
                          />
                        </div> */}
                        <div className="w-full">
                          <label
                            htmlFor="email"
                            className="block text-sm font-medium text-gray-700 "
                          >
                            Email
                          </label>
                          <Field
                            name="email"
                            type="email"
                            className="my-1 block w-full rounded-md border  px-3 py-2 shadow-sm focus:outline-none  sm:text-sm"
                            placeholder="Enter Email"
                          />
                          <ErrorMessage
                            name="email"
                            component="div"
                            className=" text-xs text-red-600"
                          />
                        </div>
                        <div className="w-full">
                          <label
                            htmlFor="phoneNumber"
                            className="block text-sm font-medium text-gray-700 "
                          >
                            Phone Number
                          </label>
                          <PhoneInput
                            country={"in"}
                            inputStyle={{
                              width: "100%",
                              height: "40px",
                              borderRadius: "5px",
                            }}
                            name="phoneNumber"
                            id="phoneNumber"
                            onChange={(e) => {
                              setFieldValue("phoneNumber", e);
                            }}
                            onBlur={handleBlur}
                            value={values.phoneNumber}
                          />
                          {touched.phoneNumber && errors.phoneNumber ? (
                            <div className="text-sm text-red-500">
                              {errors.phoneNumber}
                            </div>
                          ) : null}
                        </div>
                        <div className="flex items-center justify-center">
                          <button
                            type="submit"
                            className="text-md cursor-pointer rounded-md bg-blue-600 p-2 text-white"
                          >
                            Create User
                          </button>
                        </div>
                      </Form>
                    </div>
                  )}
                </Formik>
              </div>
            </div>
            <CommonBackdrop
              description={dynamicTitle}
              value={compliningProgress}
              loading={loading}
            />
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}

function SSOLogin({ open, handleClose, user }) {
  const [loading, setLoading] = useState(false);
  const authToken = useSelector((state) => state?.auth?.auth?.token);
  const userData = useSelector((state) => state?.auth?.auth?.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const SSOLogins = async () => {
    setLoading(true);
    try {
      const data = {
        email: user.email,
        resellerId: user.resellerId,
      };

      const response = await instance.post(`/api/auth/sso`, data, {
        headers: {
          authorization: `Bearer ${authToken}`,
        },
      });

      // if (response.status === 200) {
      //   dispatch(SET_AUTH(response.data.data));
      //   navigate("/admin/template");
      //   dispatch(SET_HISTORY_DATA());
      // }


      if (response.status === 200) {
        handleClose();
        dispatch(SET_HISTORY_DATA());

        const currentURL = window.location.href;

        if (currentURL.includes("https://admin.immarsify.com")) {
          window.open(
            `https://app.immarsify.com/#/auth/sign-in?token=${response.data.data.token}`,
            "_blank"
          );
        } else if (currentURL.includes("https://app.immarsify.com")) {
          window.open(
            `https://app.immarsify.com/#/auth/sign-in?token=${response.data.data.token}`,
            "_blank"
          );
        } else {
          window.open(
            `http://localhost:3000/#/auth/sign-in?token=${response.data.data.token}`,
            "_blank"
          ); // testing
        }
      }

    } catch (error) {
      toast.error(error && error?.response?.message);
      setLoading(false);
    }
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box
            sx={{
              ...style,
              width: "90%",
              maxWidth: "100%",
              maxHeight: "90vh",
              overflowY: "auto",
              margin: "auto",
              "@media (min-width: 768px)": {
                width: "auto",
              },
            }}
          >
            <div className="flex w-[360px] flex-col bg-white text-sm">
              <h1 className="w-full bg-gray-200 py-3 text-center font-semibold text-gray-700">
                SSO Login
              </h1>
              <div className="flex flex-col gap-2 p-1">
                <h1 className="py-3 text-center">{user.email}</h1>
                <div className="flex justify-center">
                  <button
                    className="rounded-md bg-blue-600 px-5 py-3 text-white"
                    onClick={SSOLogins}
                  >
                    Login
                  </button>
                </div>
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}

export default CheckTable;
