import React, { useMemo, useState, useEffect } from "react";
import CardMenu from "components/card/CardMenu";
import Checkbox from "components/checkbox";
import Card from "components/card";
import { FaDownload } from "react-icons/fa6";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import InputField from "components/fields/InputField";
import { MdOutlineClear } from "react-icons/md";
import { useSelector } from "react-redux";
import { instance } from "common/Instance";
import toast from "react-hot-toast";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { json, Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { SET_AUTH } from "../../../../../redux/reducerSlice/authSlice";
import { SET_HISTORY_DATA } from "../../../../../redux/reducerSlice/arSlice";
import Pagination from "@mui/material/Pagination";

import * as Yup from "yup";
import { GrPowerReset } from "react-icons/gr";
import ActionMenu from "./ActionMenu";
import { Chip } from "@mui/material";

import { IoIosOpen } from "react-icons/io";
import moment from "moment";
import PaymentForm from "./RecordPayment";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  borderRadius: "10px",
  boxShadow: 24,
};

const InvoiceTable = () => {
  const authToken = useSelector((state) => state?.auth?.auth?.token);
  const userData = useSelector((state) => state?.auth?.auth?.user);
  const [search, setSearch] = useState("");
  const [user, setUserId] = React.useState("");
  const navigate = useNavigate();

  const [openLabel, setOpenLabel] = useState(false);
  const [searchParam, setSearchParam] = useState("");
  const [open, setOpen] = React.useState(false);
  const [openList, setOpenList] = React.useState(false);
  const handleOpenList = () => setOpenList(true);
  const handleCloseList = () => setOpenList(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [tableData, setTablesData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [itemsPerPage, setSelectedValue] = useState(5);
  const [timeoutId, setTimeoutId] = useState(null);

  const userList = async () => {
    setLoading(true);
    try {
      const response = await instance.get(
        `/api/invoices/get_invoice?page=${currentPage}&limit=${itemsPerPage}&searchText=${searchParam}`,
        {
          headers: {
            authorization: `Bearer ${authToken}`,
          },
        }
      );

      if (response.status === 200) {
        console.log(response?.data);

        setTablesData(response?.data?.data);
        setLoading(false);

        setTotalPages(response?.data?.totalPages);
      } else {
        setTablesData([]);
      }
    } catch (error) {
      setTablesData([]);
      console.log("error", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    userList();
  }, [itemsPerPage, searchParam, currentPage]);

  const handleChanges = (event, value) => {
    console.log(value, "this is value");
    setCurrentPage(value);
  };

  const handleSearch = (e) => {
    const inputValue = e.target.value;
    setSearch(inputValue);

    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    const newTimeoutId = setTimeout(() => {
      if (!inputValue || inputValue.trim() === "") {
        setSearchParam("");
      } else {
        setSearchParam(inputValue.trim());
      }
    }, 1000);

    setTimeoutId(newTimeoutId);
  };

  const handleChange = (e) => {
    setSelectedValue(e.target.value);
  };

  return (
    <Card extra={"w-full h-full sm:overflow-auto px-6"}>
      <header className=" flex w-full flex-col justify-center gap-2 py-4">
        <div className="relative flex  items-center justify-between">
          <div className="text-md font-bold text-navy-700 ">Invoice list</div>

          <div className="flex items-center justify-between gap-4">
            <button
              className="text-md rounded-md bg-brand-main px-4 py-1 text-center font-semibold text-white shadow-md"
              onClick={() => {
                navigate("/admin/invoice/create");
              }}
            >
              Create
            </button>
          </div>
        </div>
        <div className="relative flex  w-full items-center py-1">
          <input
            className="w-[500px] rounded-lg border-2 p-2.5 text-sm outline-none"
            type="text"
            value={search}
            onChange={handleSearch}
            placeholder="Search"
          />

          <div className="flex w-full items-center justify-end ">
            <p className="text-sm">List per page:</p>
            <select
              class="ml-2 block h-8 w-12 rounded-md  border bg-gray-200  shadow-sm outline-none "
              placeholder="List"
              value={itemsPerPage}
              onChange={handleChange}
            >
              {[...Array(11).keys()].map((_, index) => (
                <>
                  {index > 0 && (
                    <option key={index} value={index * 5}>
                      {index * 5}
                    </option>
                  )}
                </>
              ))}
            </select>
          </div>
        </div>
      </header>

      <div className="   overflow-x-scroll xl:overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
              >
                Customer
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
              >
                Date
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
              >
                Expired Date
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
              >
                Total
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
              >
                Paid
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
              >
                Status
              </th>
              {/* <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
              >
                Remaining
              </th> */}
              <th
                scope="col"
                className="px-6 py-3 text-center text-xs font-medium uppercase tracking-wider text-gray-500"
              >
                Action
              </th>
            </tr>
          </thead>

          <tbody className="divide-y divide-gray-200 bg-white">
            {loading ? (
              [...Array(itemsPerPage)].map((_, index) => (
                <tr key={index}>
                  <td className="whitespace-nowrap px-6 py-4">
                    <div role="status" className="animate-pulse">
                      <div className="h-4 rounded-full bg-gray-200 dark:bg-gray-800"></div>
                    </div>
                  </td>
                  <td className="whitespace-nowrap px-6 py-4">
                    <div role="status" className="animate-pulse">
                      <div className="h-4 rounded-full bg-gray-200 dark:bg-gray-800"></div>
                    </div>
                  </td>
                  <td className="whitespace-nowrap px-6 py-4">
                    <div role="status" className="animate-pulse">
                      <div className="h-4 rounded-full bg-gray-200 dark:bg-gray-800"></div>
                    </div>
                  </td>
                  <td className="whitespace-nowrap px-6 py-4">
                    <div role="status" className="animate-pulse">
                      <div className="h-4 rounded-full bg-gray-200 dark:bg-gray-800"></div>
                    </div>
                  </td>
                  <td className="whitespace-nowrap px-6 py-4">
                    <div role="status" className="animate-pulse">
                      <div className="h-4 rounded-full bg-gray-200 dark:bg-gray-800"></div>
                    </div>
                  </td>
                  <td className="whitespace-nowrap px-6 py-4">
                    <div role="status" className="animate-pulse">
                      <div className="h-4 rounded-full bg-gray-200 dark:bg-gray-800"></div>
                    </div>
                  </td>
                  <td className="whitespace-nowrap px-6 py-4">
                    <div role="status" className="animate-pulse">
                      <div className="h-4 rounded-full bg-gray-200 dark:bg-gray-800"></div>
                    </div>
                  </td>
                </tr>
              ))
            ) : tableData.length > 0 ? (
              tableData.map((item) => (
                <tr key={item._id}>
                  <td className="whitespace-nowrap px-6 py-4">
                    <div className="text-sm font-medium text-gray-900">
                      {item?.userDetails ? (
                        <span>
                          {item?.userDetails?.fullName
                            ? item?.userDetails?.fullName
                            : item?.fullName}
                        </span>
                      ) : (
                        <span>{item?.fullName}</span>
                      )}
                    </div>
                  </td>
                  <td className="whitespace-nowrap px-6 py-4">
                    <div className="text-sm text-gray-900">
                      {" "}
                      {moment(item?.date).format("DD/MM/YYYY")}
                    </div>
                  </td>
                  <td className="whitespace-nowrap px-6 py-4">
                    <div className="text-sm  text-gray-900">
                      {moment(item?.expireDate).format("DD/MM/YYYY")}
                    </div>
                  </td>

                  <td className="whitespace-nowrap px-6 py-4">
                    <div className="text-sm text-gray-900">{item?.total}</div>
                  </td>
                  <td className="whitespace-nowrap px-6 py-4">
                    <div className="text-sm text-gray-900">{item?.paid}</div>
                  </td>
                  <td className="whitespace-nowrap px-6 py-4">
                    <div
                      className={`text-sm ${
                        item?.status === "Accepted"
                          ? "text-green-500"
                          : item?.status === "Pending"
                          ? "text-yellow-500"
                          : item?.status === "Rejected"
                          ? "text-red-500"
                          : "text-gray-900"
                      }`}
                    >
                      {item?.status}
                    </div>
                  </td>
                  {/* <td
                    className="whitespace-nowrap px-6 py-4"
                    style={{ width: "25%" }}
                  >
                    <div className="text-sm text-gray-900">{"1000"}</div>
                  </td> */}
                  <td className="mx-auto whitespace-nowrap px-6 py-4">
                    <ActionMenu
                      invoice={item}
                      fetchInvoice={userList}
                      setCurrentPage={setCurrentPage}
                    />
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="7" className="py-4 text-center">
                  <p className="bg-blue-100 px-10 py-5 text-sm text-blue-600">
                    Data Not Found!
                  </p>
                </td>
              </tr>
            )}
          </tbody>
        </table>

        <div className="my-5 flex w-full items-center justify-end  text-sm">
          <Pagination
            count={totalPages}
            color="primary"
            page={currentPage}
            onChange={handleChanges}
          />
        </div>
      </div>

      {/* <CreateAccount open={open} handleClose={handleClose} /> */}
    </Card>
  );
};

export default InvoiceTable;
