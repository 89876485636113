import { Divider, IconButton, Typography } from "@mui/material";
import Drawer from "@mui/material/Drawer";
import * as React from "react";
import { IoMdClose } from "react-icons/io";
import ViewLead from "./ViewLead";
import AddLead from "./AddLead";
import LabelManager from "./Label";
import EditLead from "./EditLead";
// import InboxIcon from '@mui/icons-material/MoveToInbox';
// import MailIcon from '@mui/icons-material/Mail';
export default function InfoDrawer({ openDrawer, setOpenDrawer , drawerType, setDrawerType, setOpenDeleteModal, leadInfo, fetchEnquiry}) {

  console.log(drawerType, "This is drawer tupe")
  const [state, setState] = React.useState(false);

  const toggleDrawer = (event) => {

    setState((prev) => !prev);

    setOpenDrawer(false);
  };




  return (
    <div>
      <React.Fragment>
        <Drawer
          anchor="right"
          open={openDrawer}
          onClose={toggleDrawer}
          sx={{
            width: {
              xs: '100%',   // Full width on extra-small screens
              sm: 500,      // Width for small screens and up
              md: 650       // Width for medium screens and up
            },
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: {
                xs: '100%',
                sm: 500,
                md: 650
              },
              boxSizing: "border-box",
              
            },
          }}
        >
          <div className="p-4">
            <div className="flex">
              <IconButton onClick={toggleDrawer}>
                <IoMdClose />
              </IconButton>
              <Typography variant="h6">Enquiry</Typography>
            </div>

            <Divider />
            <div className="p-4">
              {/* <ViewLead/> */}

             {drawerType === "view" && <ViewLead setDrawerType={setDrawerType} setOpenDeleteModal={setOpenDeleteModal} enquiry={leadInfo} />}
             {drawerType === "edit" && <EditLead  enquiry={leadInfo}  fetchEnquiry={fetchEnquiry}/>}
             {drawerType === "create" && <AddLead fetchEnquiry={fetchEnquiry}/>}

              {/* <LabelManager /> */}
            </div>
          </div>
        </Drawer>
      </React.Fragment>
    </div>
  );
}
