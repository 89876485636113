import { createSlice } from "@reduxjs/toolkit";
import { LOGOUT } from "./rootSlice";

const INITIAL_STATE = {
  auth: {},
  tokenexpiredLoader: false,
  signupEmail: "",
  forgetEmail: "",
  redirectTemplateURL: "",
  phoneNumber: "",
};

const authSlice = createSlice({
  name: "auth",
  initialState: INITIAL_STATE,
  reducers: {
    SET_AUTH: (state, action) => {
      state.auth = action.payload;
    },
    SET_USERDATA: (state, action) => {
      state.auth = {
        ...state.auth,
        user: action.payload,
      };
    },
    SET_TOKENEXPIRED_LOADER: (state, action) => {
      state.tokenexpiredLoader = action.payload;
    },
    SET_SIGNUP_EMAIL: (state, action) => {
      state.signupEmail = action.payload;
    },
    SET_SIGNUP_PHONE: (state, action) => {
      state.phoneNumber = action.payload;
    },
    SET_FORGET_EMAIL: (state, action) => {
      state.forgetEmail = action.payload;
    },
    SET_REDIRECT_TEMPLATE_URL: (state, action) => {
      state.redirectTemplateURL = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(LOGOUT, (state) => {
      state.auth = INITIAL_STATE.auth;
    });
  },
});

export const {
  SET_AUTH,
  SET_USERDATA,
  SET_TOKENEXPIRED_LOADER,
  SET_SIGNUP_EMAIL,
  SET_SIGNUP_PHONE,
  SET_FORGET_EMAIL,
  SET_REDIRECT_TEMPLATE_URL,
} = authSlice.actions;
export default authSlice.reducer;
