import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField } from '@mui/material';
import toast from 'react-hot-toast';
import { instance } from 'common/Instance';
import { useSelector } from 'react-redux';

export const   WhatsappModals = ({ open, onClose,   selectedEnquriy}) => {
    const [message, setMessagge] = useState(`Thank you for attending our event! We truly appreciate your time and support. If you have any questions or need further information, feel free to reach out.
We look forward to staying in touch!`);
const [sendingMessage, setSendingMessage] = useState(false)
const userToken = useSelector((state) => state.auth.auth.token);


const sendWhatsappMessage = async( )=>{

  try {
    setSendingMessage(true)
    const response = await instance.post(
      `/api/reseller/send_msg`,
      {    message: message,
        phoneNumber:selectedEnquriy?.phone,
        username:selectedEnquriy?.viewerName},
      {
        headers: {
          authorization: `Bearer ${userToken}`,
        },
      }
    );
    if (response.status === 200) {

      onClose()
      toast.success("Messege sent");
      // dispatch(SET_USERDATA(response?.data?.data));
    }else{
      toast.error("Something went wrong")
    }

    setSendingMessage(false)
  } catch (error) {
setSendingMessage(false)
    console.log(error)
    toast.error("Failed to update profile");
  } 

}
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="confirm-delete-title"
      aria-describedby="confirm-delete-description"
      classes={{
        paper: 'bg-white rounded-lg p-4 max-w-md w-full',
      }}
    >
      <DialogTitle id="confirm-delete-title" className="text-lg font-semibold text-gray-900">
        Send Messagge
      </DialogTitle>
      <DialogContent className="text-sm text-gray-600">



      <div className='text-gray-800'>
        <p className='mb-4'>Message will sent to: {selectedEnquriy?.phone}</p>

        <TextField
          id="outlined-multiline-flexible"
          multiline
          minRows={4}
          className='w-full '

          value={message}
          onChange={(e)=>setMessagge(e.target.value)}
          
        />
      


      </div>

      </DialogContent>
      <DialogActions className="flex justify-end mt-4">
        <button onClick={onClose} className="text-black bg-gray-200  px-2 py-1 rounded-md hover:text-gray-800">
          Cancel
        </button>
        <button
          onClick={()=>{sendWhatsappMessage()}}
          className="bg-green-600 px-2 py-1  rounded-md text-white hover:bg-green-700 ml-2"
        >
          {sendingMessage ? "Sending" : "Send"}
        </button>
      </DialogActions>
    </Dialog>
  );
};
