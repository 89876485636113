import { tab } from "@testing-library/user-event/dist/tab";
import React, { useEffect, useRef, useState } from "react";

const ContainerPreview = ({
  frontRef,
  backRef,
  tabValue,
  setTabValue,
  elements,
  elementsForBack,
}) => {
  const [frontPreview, setFrontPreview] = useState("");
  const [backPreview, setBackPreview] = useState("");
  const previewFrontRef = useRef(null);
  const previewBackRef = useRef(null);

  useEffect(() => {
    const updatePreviews = () => {
      if (frontRef.current) {
        setFrontPreview(frontRef.current.innerHTML);
      }
      if (backRef.current) {
        setBackPreview(backRef.current.innerHTML);
      }
    };

    // Initial update
    updatePreviews();

    // Set up a MutationObserver to watch for changes
    const observer = new MutationObserver(updatePreviews);
    if (frontRef.current) {
      observer.observe(frontRef.current, {
        subtree: true,
        childList: true,
        attributes: true,
      });
    }
    if (backRef.current) {
      observer.observe(backRef.current, {
        subtree: true,
        childList: true,
        attributes: true,
      });
    }

    return () => observer.disconnect();
  }, [frontRef, backRef, elements, elementsForBack]);

  useEffect(() => {
    if (previewFrontRef.current) {
      previewFrontRef.current.innerHTML = frontPreview;
    }
    if (previewBackRef.current) {
      previewBackRef.current.innerHTML = backPreview;
    }
  }, [frontPreview, backPreview]);

  return (
    <div className="flex flex-col items-center space-y-4">
      <div
        onClick={() => setTabValue("front")}
        className={`h-44 w-64 cursor-pointer overflow-hidden rounded border ${
          tabValue === "front" ? "border-black border-2 shadow-2xl" : ""
        }  bg-gray-300 shadow-lg`}
      >
        <div className="mb-1 bg-white px-2 py-4 text-xs font-bold">
          Front Preview
        </div>
        <div
          ref={previewFrontRef}
          className="ml-7 mt-[-3px] h-full w-full origin-top-left scale-50"
        />
      </div>
      <div
        onClick={() => setTabValue("black")}
        className={`h-44 w-64 cursor-pointer overflow-hidden rounded border ${
          tabValue !== "front" ? "border-black border-2 shadow-2xl" : ""
        }  bg-gray-300 shadow-lg`}
      >
        <div className="mb-1 bg-white px-2 py-4 text-xs font-bold">
          Back Preview
        </div>
        <div
          ref={previewBackRef}
          className="ml-7 mt-[-3px] h-full w-full origin-top-left scale-50"
        />
      </div>
    </div>
  );
};

export default ContainerPreview;
