/* eslint-disable */
import React from "react";
import { Link, useLocation } from "react-router-dom";
import DashIcon from "components/icons/DashIcon";
import { useSelector } from "react-redux";
import { FreeCard } from "./SidebarCard";

export function SidebarLinks(props) {
  // Chakra color mode
  let location = useLocation();
  const type = useSelector((state) => state?.auth?.auth?.user?.type);
  const isTryon = useSelector((state) => state?.auth?.auth?.user?.isTryon);
  const subType = useSelector((state) => state?.auth?.auth?.user?.subType);
  const subscriptionType = useSelector(
    (state) => state?.auth?.auth?.user?.subscriptionType
  );

  const { routes } = props;

  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) => {
    return location.pathname.includes(routeName);
  };

  let filteredRoutes = routes;

  // Filter routes only for reseller type
  if (type === "reseller") {
    if (subType === "subReseller") {
      filteredRoutes = routes.filter(
        (route) => route.path === "dashboard" || route.path === "users-list"
      );
    } else {
      filteredRoutes = routes.filter(
        (route) =>
          route.path === "dashboard" ||
          route.path === "users-list" ||
          route.path === "enquiry" ||
          route.path === "settings" ||
          route.path === "invoice" ||
          route.path === "invoice/create" ||
          route.path === "invoice/view" ||
          route.path === "invoice/edit" ||
          route.path === "invoice/record" ||
          route.path === "payment" ||
          route.path === "payment/view" ||
          route.path === "payment/edit" ||
          route.path === "commission" ||
          route.path === "subreseller-list" ||
          route.path === "refer" ||
          route.path === "form" ||
          route.path === "cardform" ||  route.path === "recyclebin"
        
      );
    }
  }

  if (type === "user") {
    if (isTryon) {
      filteredRoutes = routes.filter(
        (route) =>
          route.path === "dashboard" ||
          route.path === "products" ||
          route.path === "profile"
      );
    } else {
      if (subscriptionType !== "Platinum") {
        if (subscriptionType === "Silver") {
          filteredRoutes = routes.filter(
            (route) =>
              route.path !== "users-list" &&
              route.path !== "subreseller-list" &&
              route.path !== "invoice/create" &&
              route.path !== "invoice" &&
              route.path !== "invoice/view" &&
              route.path !== "invoice/edit" &&
              route.path !== "invoice/record" &&
              route.path !== "payment" &&
              route.path !== "payment/view" &&
              route.path !== "payment/edit" &&
              route.path !== "refer" &&
              route.path !== "multiscene" &&
              route.path !== "branding" &&
              route.path !== "dashboard" &&
              route.path !== "commission" &&  route.path !== "products"  && route.path !== "recyclebin"
          );
        } else {
          filteredRoutes = routes.filter(
            (route) =>
              route.path !== "users-list" &&
              route.path !== "subreseller-list" &&
              route.path !== "invoice/create" &&
              route.path !== "invoice" &&
              route.path !== "invoice/view" &&
              route.path !== "invoice/edit" &&
              route.path !== "invoice/record" &&
              route.path !== "payment" &&
              route.path !== "payment/view" &&
              route.path !== "payment/edit" &&
              route.path !== "refer" &&
              route.path !== "multiscene" &&
              route.path !== "branding" &&
              route.path !== "multitarget" &&
              route.path !== "commission" &&   route.path !== "products" && route.path !== "recyclebin"
          );
        }
      } else {
        filteredRoutes = routes.filter(
          (route) =>
            route.path !== "users-list" &&
            route.path !== "subreseller-list" &&
            route.path !== "invoice/create" &&
            route.path !== "invoice" &&
            route.path !== "invoice/view" &&
            route.path !== "invoice/edit" &&
            route.path !== "invoice/record" &&
            route.path !== "payment" &&
            route.path !== "payment/view" &&
            route.path !== "payment/edit" &&
            route.path !== "refer" &&
            route.path !== "commission" &&
            route.path !== "products" && route.path !== "recyclebin"
        );
      }
    }
  }

  const createLinks = (routes) => {
    return filteredRoutes.map((route, index) => {
      if (
        (route.layout === "/admin" || route.layout === "/auth") &&
        route?.isForSidebar
      ) {
        return (
          <Link key={index} to={route.layout + "/" + route.path}>
            <div
              className={`${
                activeRoute(route.path) ? "bg-[#3d2fa9]" : ""
              } relative mx-2 my-4 flex rounded-lg p-2 hover:cursor-pointer ${
                route.name
              }`}
            >
              <li
                className=" flex cursor-pointer items-start justify-start px-8"
                key={index}
              >
                <span
                  className={`${
                    activeRoute(route.path) === true
                      ? "font-bold text-white"
                      : "font-medium text-gray-600"
                  }`}
                >
                  {route.icon ? route.icon : <DashIcon />}{" "}
                </span>
                <p
                  className={`leading-1 ml-4 flex  ${route.name} ${
                    activeRoute(route.path) === true
                      ? "font-bold text-white "
                      : "font-medium  text-navy-700"
                  }`}
                >
                  {route.name}
                </p>
              </li>
              {/* {activeRoute(route.path) ? (
                <div class="absolute right-0 top-px h-9 w-1 rounded-lg bg-brand-500 dark:bg-brand-400" />
              ) : (
                ""
              )} */}
            </div>
          </Link>
        );
      }
    });
  };
  // BRAND
  return createLinks(routes);
}

export default SidebarLinks;
