import Card from "components/card";
import { instance } from "common/Instance";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

function TopCreatorTable({ experience, isMultiscene }) {
  const [loading, setLoading] = useState(false);
  const [videoData, setVideoData] = useState([]);
  const userToken = useSelector((state) => state?.auth?.auth?.token);

  const GetVideoData = async () => {
    setLoading(true);
    try {
      const response = await instance.get(
        `api/visitors/get_visitors_videos_count?experienceName=${experience}${
          isMultiscene ? "&multiScene=true" : ""
        }`,
        {
          headers: {
            authorization: `Bearer ${userToken}`,
          },
        }
      );

      console.log(response, "ipangram");
      if (response.status === 200) {
        setVideoData(response?.data?.data);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    GetVideoData();
  }, [isMultiscene, experience]);

  return (
    <Card extra={"max-h-[600px]  bg-white"}>
      <div className="flex  h-fit w-full items-center justify-between rounded-t-2xl bg-green-50 px-4 pb-[20px] pt-4  ">
        <div className="text-lg font-semibold text-navy-700">Total Video: <span className='font-normal'>{videoData?.length}</span> </div>
        <div className="text-lg font-semibold text-navy-700">Total Count: <span className='font-normal'>{videoData?.reduce((acc,e)=>acc+e?.totalCount,0)}</span> </div>
      </div>
      <div className="">
        <table className="h-full min-w-full divide-y divide-gray-200">
          <thead className="bg-white">
            <tr>
              <th
                scope="col"
                className="w-9/12 px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-600"
              >
                <span className="font-bold">Videos</span>
              </th>
              <th
                scope="col"
                className="w-3/12 px-6 py-3 text-right text-xs font-medium uppercase tracking-wider text-gray-600"
              >
                Count
              </th>
            </tr>
          </thead>

          <tbody className="max-h-[350px] overflow-y-auto">
            {loading ? (
              <tr>
                <td className="whitespace-nowrap px-6 py-4">
                  <div role="status" className="animate-pulse">
                    <div className="h-4 rounded-full bg-gray-200 dark:bg-gray-800"></div>
                  </div>
                </td>
                <td className="whitespace-nowrap px-6 py-4 text-right">
                  <div role="status" className="animate-pulse">
                    <div className="h-4 rounded-full bg-gray-200"></div>
                  </div>
                </td>
              </tr>
            ) : videoData.length > 0 ? (
              videoData.map((video, index) => (
                <tr key={index}>
                <td className="whitespace-nowrap  py-4">
                  <video
                    src={video?.src}
                    controls={false}
                    className="w-24 h-24 rounded-lg"
                  ></video>
                </td>
                
                <td className="whitespace-nowrap pr-10 py-4 text-md text-right">
                  {video?.totalCount}
                </td>
              </tr>
              
              ))
            ) : (
              <tr>
                <td colSpan="2" className="py-4 text-center">
                  <p className="bg-blue-100 px-10 py-5 text-sm text-blue-600">
                    Data Not Found!
                  </p>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </Card>
  );
}

export default TopCreatorTable;
