import { Pagination } from "@mui/material";
import React, { useEffect, useState } from "react";
import ProductCard from "./ProductCard";
import { MdDelete, MdEdit } from "react-icons/md";
import { IoIosAdd } from "react-icons/io";
import AddProductModal from "./Modal/AddProductModal";
import EditCategoryModal from "./Modal/EditCategoryModal";
import AddCategoryModal from "./Modal/AddCategoryModal";
import { useSelector } from "react-redux";
import { instance } from "common/Instance";
import Loader from "./Loader";
import { current } from "@reduxjs/toolkit";
import toast from "react-hot-toast";
import DeleteModal from "./Modal/DeleteModal";
import NoProductFound from "./NoProductFoun";


const WooCategorySection = ({
  category,
  search,
  setOpenEditCategoryModal,
  setOpenDeleteCateogryModal,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [editCategoryName, setEditCategoryName] = useState("");
  const [openAddProductModal, setOpenAddProductModal] = useState(false);
  const [openCategoryModal, setOpenCategoryModal] = useState(false);
  const [products, setProducts] = useState([]);
  const [timeoutId, setTimeoutId] = useState(null);

  const userToken = useSelector((state) => state.auth.auth.token);

  const editCategoryHandler = (category) => {
    setEditCategoryName(category);
    setOpenEditCategoryModal(true);
  };

  const openProductModalHandler = () => {
    console.log("click on product ");
    setOpenAddProductModal(true);
  };

  const getImmersifyProducts = async () => {
    try {
      console.log('category?._id?: ', category?._id)
      setIsLoading(true);
      //add filter for category
      const response = await instance.get(
        `api/products/get_product?page=${currentPage}&limit=10&category=${category?._id==='all'? "":category?._id }&searchText=${search}&type=woocommerce`,        {
          headers: {
            authorization: `Bearer ${userToken}`,
          },
        }
      );

      console.log(response.status);
      if (response.status === 200 && response?.data?.data?.products) {
        setProducts(response?.data?.data?.products);
        setTotalPages(response?.data?.data?.totalPages);
        setCurrentPage(response?.data?.data?.currentPage);
      } else if (response.status === 404) {
        setProducts([]);
        setTotalPages(1);
        setCurrentPage(1);
      }
      setIsLoading(false);
    } catch (error) {
      setProducts([]);
        setTotalPages(1);
        setCurrentPage(1);
      console.log("Dashboard Error: ", error);
      setIsLoading(false);
    }
  };
  const handleChanges = (event, value) => {
    setCurrentPage(value);
  };

  useEffect(() => {
    getImmersifyProducts();
  }, [category, currentPage, search]);
  return (
    <div>
     
      <div className="categoryName mt-8 h-14 rounded-md  ">
        <div className="flex h-full items-center justify-between rounded-md  bg-brand-main text-2xl text-white">
          <div className="flex">
            <span className="ml-4">{category?.category}</span>
       {/* {  (category?.category!=='All' ) &&    <span
              className="mx-2 cursor-pointer rounded-md bg-brand-400 p-1"
              onClick={editCategoryHandler}
            >
              <MdEdit size={20} />
            </span>} */}
          </div>
          
      {/* {    console.log('category?.id: ', category)}
          {(category?.category!=='All' ) &&  <div className="flex">
            <span
              className="mx-1 cursor-pointer rounded-md bg-brand-400 p-1"
              onClick={openProductModalHandler}
            >
              <IoIosAdd />
            </span>
            <span
              onClick={() => {
                setOpenDeleteCateogryModal(true);
              }}
              className="mx-1 cursor-pointer rounded-md bg-deleteBackground p-1"
            >
              <MdDelete size={20} />
            </span>
          </div>} */}
          
        </div>
      </div>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="products mt-8 flex flex-wrap gap-4 md:justify-start justify-center">
          {products && products.length>0 ?
            products.map((product) => {
              return (
                <ProductCard
                  product={product}
                  getImmersifyProducts={getImmersifyProducts}
                />
              );
            })
          :<div style={{display:'flex',alignItems:'center',justifyContent:'center',width:'100%'}}><NoProductFound content="To add products please connect with woocommerce and then sync products"/> </div> }
        </div>
      )}
     {products.length>0  && <div className="pagination mt-4 flex justify-center">
        <div className="pagination mt-4 flex justify-center">
          <Pagination
            count={totalPages}
            page={currentPage}
            onChange={handleChanges}
          />
        </div>
      </div> } 
      <AddProductModal
        isOpen={openAddProductModal}
        setIsOpen={setOpenAddProductModal}
        getImmersifyProducts={getImmersifyProducts}
        category={category}
      />
    </div>
  );
};

export default WooCategorySection;
