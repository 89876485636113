import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Avatar from "react-avatar";
import toast from "react-hot-toast";
import { instance} from "common/Instance";
import { SET_AUTH } from "../../../../redux/reducerSlice/authSlice";

import Card from "components/card";
import banner from "assets/img/profile/banner.png";
import avatar from "assets/img/avatars/avatar11.png";
import imageCompression from 'browser-image-compression';

const Banner = () => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.auth.user);
  const auth = useSelector((state) => state.auth.auth);
  const userToken = useSelector((state) => state.auth.auth.token);

  const handleImageChange = async (event) => {
    try {
      setLoading(true);
      const files = event.target.files;
      if (files) {
        const imageFile = files[0];
        const allowedTypes = ["image/jpeg", "image/png", "image/jpg"];
        if (!allowedTypes.includes(imageFile.type)) {
          setLoading(false);
          toast.error("Please select a .jpg, .jpeg, or .png file");
          return;
        }
        const fileSizeInMB = imageFile.size / (1024 * 1024);
        if (fileSizeInMB > 5) {
          setLoading(false);
          toast.error("File size should be less than 5 MB");
          return;
        }

        const options = {
          maxSizeMB: 1,
          maxWidthOrHeight: 1920,
          useWebWorker: true,
        }

        const compressedBlob = await imageCompression(imageFile, options);
        console.log('Compressed file:', compressedBlob);
        console.log(`Compressed file size: ${compressedBlob.size / 1024 / 1024} MB`);

        // Convert Blob to File
        const compressedFile = new File([compressedBlob], imageFile.name, {
          type: imageFile.type,
          lastModified: Date.now(),
        });
        
        const formData = new FormData();
        formData.append("file", compressedFile);
        formData.append("assetType", "image");

        const response = await instance.post(
          `/api/user/upload_profile`,
          formData,
          {
            headers: {
              authorization: `Bearer ${userToken}`,
              "Content-Type": "multipart/form-data",
            },
          }
        );

        if (response.status === 200) {
        
          const userImg = { ...user, image: response.data.url };
          dispatch(SET_AUTH({ ...auth, user: userImg }));
          try {
            await instance.post(
              `/api/user/update_user_details/${user._id}`,
              { image: response.data.url },
              {     
                headers: {
                  authorization: `Bearer ${userToken}`,
                },
              }
            );
            toast.success("Profile updated successfully");
          } catch (error) {
            toast.error("Failed to update profile");
          }
        }
      }
    } catch (error) {
      toast.error("Image upload failed");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Card extra={"items-center w-full h-full p-[16px] bg-cover"}>
      <div
        className="relative mt-1 flex h-40 w-full justify-center rounded-xl bg-cover"
        style={{ backgroundImage: `url(${banner})` }}
      >
        <div className="absolute -bottom-12 flex h-[150px] w-[150px] items-center justify-center rounded-full">
          {user?.image ? (
            <img
            src={user.image}
              className="h-[150px] w-[150px] rounded-full object-cover "
              alt="user avatar"
            />
          ) : (
            <Avatar
              size={70}
              round={true}
              name={user?.username ?? ''}
              className="h-[150px] w-[150px] rounded-full object-cover "
            />
          )}
        </div>
      </div>

      {/* Name and position */}
      <div className="mt-16 flex flex-col items-center">
        <p className="text-md font-semibold text-gray-900">#{user.username}</p>
      </div>

      <div className="mt-1 ">
        <input
          id="image"
          name="image"
          type="file"
          accept="image/*"
          onChange={handleImageChange}
          className="hidden"
        />

        <label
          htmlFor="image"
          className={`my-5 cursor-pointer ${
            loading ? "pointer-events-none opacity-50" : ""
          }`}
        >
          <div
            className={`flex mt-5 h-10  px-4 py-2 items-center justify-center rounded-lg border border-gray-300 bg-brand-main  text-white transition duration-300 ease-in-out text-sm  ${
              loading ? "cursor-not-allowed" : ""
            }`}
          >
           
            {loading ? (
              <div className="px-4 text-sm bg-brand-main block  ">
                Uploading image...
              </div>
            ) : (
              "Select Image"
            )}
          </div>
        </label>
      </div>
    </Card>
  );
};

export default Banner;
