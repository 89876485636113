import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';

export const   ConfirmDeleteModal = ({ open, onClose, onConfirm }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="confirm-delete-title"
      aria-describedby="confirm-delete-description"
      classes={{
        paper: 'bg-white rounded-lg p-4 max-w-md w-full',
      }}
    >
      <DialogTitle id="confirm-delete-title" className="text-lg font-semibold text-gray-900">
        Confirm Deletion
      </DialogTitle>
      <DialogContent className="text-sm text-gray-600">
        Are you sure you want to delete this item? This action cannot be undone.
      </DialogContent>
      <DialogActions className="flex justify-end mt-4">
        <button onClick={onClose} className="text-gray-600 px-2 rounded-md hover:text-gray-800">
          Cancel
        </button>
        <button
          onClick={onConfirm}
          className="bg-red-600 px-2  rounded-md text-white hover:bg-red-700 ml-2"
        >
          Delete
        </button>
      </DialogActions>
    </Dialog>
  );
};
