import React, { useState, useEffect, useCallback } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import InputField from "components/fields/InputField";
import { MdOutlineClear } from "react-icons/md";
import MultisceneHistory from "./Tabcomponents/multiscenehistoy";
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";
import * as mind from "mind-ar/dist/mindar-image.prod";
import { instance } from "common/Instance";
import MultisceneList from "./Tabcomponents/multisceneList";
import toast from "react-hot-toast";
import { useFormik } from "formik";
import * as Yup from "yup";
import debounce from "lodash.debounce";

import {
  SET_MULTISCENE_DATA,
  CLEAR_MULTISCENE_DATA,
} from "../../../redux/reducerSlice/arSlice";
import CommonBackdrop from "common/CommonBackdrop";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 450,
  bgcolor: "background.paper",
  borderRadius: "10px",
  boxShadow: 24,
};

const Multiscene = () => {
  const dispatch = useDispatch();
  const userToken = useSelector((state) => state.auth.auth.token);
  const user = useSelector((state) => state.auth.auth.user);
  const [multiscenehistoy, setMultisceneHistory] = useState([]);
  const [loading, setLoading] = useState(false);

  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    dispatch(CLEAR_MULTISCENE_DATA());
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    dispatch(CLEAR_MULTISCENE_DATA());
  };

  const getMultisceneData = async () => {
    setLoading(true);
    try {
      const response = await instance.get(
        `api/multi/multiscene_history/${user._id}`,
        {
          headers: {
            authorization: `Bearer ${userToken}`,
          },
        }
      );
      if (response.status === 200) {
        setMultisceneHistory(response.data.data);
        setLoading(false);
      }
    } catch (error) {
      toast.error(error?.response?.data?.error?.message ?? error.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    getMultisceneData();
  }, []);
  return (
    <>
      <div className="flex flex-col p-4">
        <div className="flex h-[10px] w-full items-center justify-between rounded-md bg-[#3d2fa9]  px-4 py-8">
          <p className="text-sm font-semibold text-white">
            Combine Several Projects into a Multiscene one to Recognize Multiple
            AR Enabled Images With Same Camera
          </p>
          <button
            className="rounded-md bg-white p-2 text-sm font-semibold"
            onClick={handleOpen}
          >
            Create a Multiscene Project
          </button>
        </div>
        <p className=" mt-4 text-left text-sm font-semibold text-gray-900">
          Multiscene Project{" "}
        </p>

        <MultisceneList
          multiscenehistoy={multiscenehistoy}
          getMultisceneData={getMultisceneData}
          loading={loading}
        />
      </div>
      <MulstisceneModal
        open={open}
        handleClose={handleClose}
        multiscenehistoy={multiscenehistoy}
        getMultisceneData={getMultisceneData}
      />
    </>
  );
};

function MulstisceneModal({ open, handleClose, getMultisceneData }) {
  const SelectedData = useSelector((state) => state.ar.multisceneData);
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const userToken = useSelector((state) => state.auth.auth.token);
  const user = useSelector((state) => state.auth.auth.user);
  const compiler = new mind.Compiler();
  const [allImagesData, setAllImagesData] = useState([]);
  const [compliningProgress, setCompilingProgress] = useState(0);
  const [usernameAvailable, setUsernameAvailable] = useState(true);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);
  const getUrlExtension = (url) => {
    return url.split(/[#?]/)[0].split(".").pop().trim();
  };
  const [multisceneName, setMultisceneName] = useState("");
  const [active, setActive] = useState(false);
  const uploadFile = async (file, assetType) => {

    console.log(`${file}--AssetType1-${assetType}`)
    const formData = new FormData();
    formData.append("file", file);
    formData.append("assetType", assetType);

    try {
      const response = await instance.post(
        `/api/multi/upload/${user.username}/${multisceneName}`,
        formData,
        {
          headers: {
            authorization: `Bearer ${userToken}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status === 200) {
        toast.success(response?.data?.message);
        getMultisceneData();
        handleClose();
      }
      return response.data.url;
    } catch (error) {
      toast.error(error?.response?.data?.message ?? error.message);
    }
  };

  const Create_multi = async () => {
    try {
      const response = await instance.post(
        `/api/multi/create_multi`,
        {
          name: multisceneName,
          userName: user.username,
          experiences: SelectedData.map((item) => item._id),
          userId: user._id,
        },
        {
          headers: {
            authorization: `Bearer ${userToken}`,
          },
        }
      );

      if (response.status === 200) {
        toast.success("Multiscene Project is Created");
        await compileImageForMind();
      }
    } catch (error) {
      toast.error(error?.response?.data?.message ?? error.message);
      setLoading(false);
    }
  };

  const createMindForAllImages = async (imgUrls) => {
    console.log(imgUrls,'imgurls1')
    setLoading(true);
    const allImages = [];
    const fetchPromises = imgUrls.map(async (url) => {
      const response = await fetch(url);
      const blob = await response.blob();
      const file = new File([blob], "targetImage." + blob?.type.split("/")[1], {
        type: blob?.type,
      });
      allImages.push(file);
    });

    await Promise.all(fetchPromises);
    const imageFiles = allImages.filter((file) =>
      file?.type?.startsWith("image/")
    );

    const imageElements = imageFiles.map((file) => {
      const image = new Image();
      image.src = URL.createObjectURL(file);
      return image;
    });

    
    setAllImagesData(imageElements);
    await Create_multi();
  };

  const compileImageForMind = async () => {
    setLoading(true);
    const dataList = await compiler.compileImageTargets(
      allImagesData,
      (progress) => {
        setCompilingProgress(Math.round(progress));
      }
    );
    setCompilingProgress(0);

    const exportedBuffer = await compiler.exportData();
    const blob = new Blob([exportedBuffer]);
    const mindFile = new File([blob], "target.mind", { type: blob.type });

   await uploadFile(mindFile, "mind");
  
    setLoading(false);
    // UpdateMind(mindUrl)
  };

  const imageList =
    SelectedData?.length > 0
      ? _.compact(
          SelectedData.map((list) =>
            list?.targetImage?.src
              ? list?.targetImage?.src + "?not-from-cache-please"
              : ""
          )
        )
      : [];

  const dispatch = useDispatch();

  const checkUsernameAvailability = async (value) => {
    try {
      const response = await instance.post(
        "/api/multi/checkname",
        {
          name: value,
          username: user.username,
        },
        {
          headers: {
            authorization: `Bearer ${userToken}`,
          },
        }
      );
      if (response.status === 200) {
        setActive(response.data.data);
        return response.data.data;
      }
    } catch (error) {
      return false;
    }
  };

  const debouncedCheckUsername = useCallback(
    debounce(async (value) => {
      const isAvailable = await checkUsernameAvailability(value);
      setUsernameAvailable(isAvailable);
    }, 500),
    []
  );

  const validationSchema = Yup.object({
    multiName: Yup.string()
      .required("Multiscene Name is required")
      .min(4, "Multiscene Name must be at least 4 characters")
      .matches(
        /^[a-zA-Z0-9_]*$/,
        "Valid characters:letters,numbers,and underscores. No spaces allowed"
      )
      .test("Ok", "Multiscene name already exists", async () => {
        return usernameAvailable;
      }),
  });

  const formik = useFormik({
    initialValues: {
      multiName: multisceneName || "",
    },
    validationSchema: validationSchema,

    onSubmit: async (values) => {
      await createMindForAllImages(imageList);
    },
  });

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <div className="pb-2">
              <div className="flex items-center justify-between rounded-t-lg bg-gray-100 p-4">
                <p className="text-sm">Create Multiscene</p>
                <span onClick={handleClose} className="cursor-pointer">
                  <MdOutlineClear size={15} />
                </span>
              </div>
              <div className="flex flex-col items-center justify-center gap-2 p-8">
                <div className="w-full">
                  <InputField
                    variant="auth"
                    extra="mb-3"
                    label="Multiscene Name*"
                    placeholder="Enter Multiscene Name"
                    id="multiName"
                    type="text"
                    onChange={(event) => {
                      setMultisceneName(event.target.value);
                      formik.handleChange(event);
                      debouncedCheckUsername(event.target.value);
                    }}
                    value={formik.values.multisceneName}
                    onBlur={formik.handleBlur}
                    error={formik.touched.multiName && formik.errors.multiName}
                  />
                </div>
              </div>

              <div
                className={`${
                  SelectedData?.length > 0
                    ? "flex h-[20vh] flex-wrap items-center justify-center  gap-2 overflow-y-auto pl-6"
                    : ""
                } `}
              >
                {SelectedData?.map((item, index) => {
                  return (
                    <div
                      className="group relative h-[150px] w-[180px] cursor-pointer rounded-lg bg-white pb-2 shadow-lg "
                      key={index}
                    >
                      <div
                        className="flex justify-end "
                        onClick={() => dispatch(SET_MULTISCENE_DATA(item))}
                      >
                        <MdOutlineClear
                          size={18}
                          className="rounded-md border p-1"
                        />
                      </div>
                      <div className="flex justify-center  ">
                        <img
                          src={
                            item.targetImage && item.targetImage.src
                              ? item.targetImage.src
                              : "https://liftlearning.com/wp-content/uploads/2020/09/default-image.png"
                          }
                          alt="hello"
                          className="mt-2 h-[90px] w-[150px] rounded-md object-cover"
                        />
                      </div>
                      <div className="mt-2 flex items-center justify-between px-6 ">
                        <p
                          className="text-xs font-semibold text-gray-900"
                          style={{ fontSize: "12px" }}
                        >
                          {item.name}
                        </p>
                      </div>
                    </div>
                  );
                })}
              </div>

              <div className="mt-4 flex items-center justify-center gap-4 ">
                <button
                  className="cursor-pointer rounded-md border p-2 text-xs font-semibold"
                  onClick={handleOpenModal}
                >
                  Add Projects
                </button>
                {SelectedData?.length > 0 ? (
                  <button
                    className={`cursor-pointer ${
                      !active ? `bg-blue-200` : `bg-brand-main`
                    } rounded-md  p-2 text-xs text-white`}
                    disabled={!active}
                    type="submit"
                    onClick={formik.handleSubmit}
                  >
                    Create
                  </button>
                ) : (
                  ""
                )}
              </div>
            </div>
            <MultisceneHistory
              open={openModal}
              handleClose={handleCloseModal}
            />
          </Box>
        </Fade>
      </Modal>

      <CommonBackdrop
        title="Creating multi scene"
        description={"Please wait do not refresh page..."}
        value={compliningProgress}
        loading={loading}
      />
    </div>
  );
}

export default Multiscene;
