import * as React from 'react';
import { PieChart } from '@mui/x-charts/PieChart';
import { useDrawingArea } from '@mui/x-charts/hooks';
import { styled } from '@mui/material/styles';







const size = {
  width: 400,
  height: 200,
};

const StyledText = styled('text')(({ theme }) => ({
  fill: theme.palette.text.primary,
  textAnchor: 'middle',

  fontSize: 15,
}));

function PieCenterLabel({ children }) {
  const { width, height, left, top } = useDrawingArea();
  return (
    <StyledText x={left + width / 2} y={top + height / 2}>
      {children}
    </StyledText>
  );
}

let data;
export default function PieChartWithCenterLabel({chartData}) {


 data = chartData?.map(item => ({
    value: item?.count, 
    label: item?.location      
  }));

  return (
    <PieChart 
      series={[{ data, innerRadius: 80 }]} 
      {...size} 
      slotProps={{ legend: { hidden: true } }}
    >

      {
        chartData?.length>0 ?    <PieCenterLabel>Country</PieCenterLabel> :''
      }
  
    </PieChart>
  );
}

