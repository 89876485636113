import React from 'react';

const Loader = () => {
  return (
    <div className="container mx-auto flex flex-wrap justify-center">
      {Array.from({ length: 8 }).map((_, index) => (
        <div key={index} className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/4 p-2">
          <div className="max-w-sm rounded overflow-hidden shadow-lg m-4">
            <div className="skeleton h-64 bg-gray-300 animate-pulse"></div>
            <div className="px-6 py-4">
              <div className="skeleton mb-4 h-6 bg-gray-200 animate-pulse"></div>
              <p className="skeleton mb-4 h-4 bg-gray-200 animate-pulse"></p>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Loader;
