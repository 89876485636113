import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  viewCreated: null,
  file:[],
};

const valueSlice = createSlice({
  name: "value",
  initialState,
  reducers: {
    setViewCreated: (state, action) => {
      state.viewCreated = action.payload;
    },
    updateFile: (state, action) => {
      state.file = action.payload;
    },
  },
});

export const { setViewCreated,updateFile } = valueSlice.actions;

export default valueSlice.reducer;
