import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { instance } from "common/Instance";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import OtpInput from "react-otp-input";
import { CircularProgress } from "@mui/material";

export default function Otp() {
  const email = useSelector((state) => state?.auth?.signupEmail);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const validationSchema = Yup.object({
    otp: Yup.string()
    .required("OTP is required")
    .matches(/^\d{5}$/, "OTP must be exactly 5 digits"),
  });

  const formik = useFormik({
    initialValues: {
      otp: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (value) => {
      setLoading(true);
      try {
        const response = await instance.post("api/auth/verify_otp", {
          otp: value.otp,
          email: email,
        });

        if (response.status === 200) {
          toast.success(response?.data?.message);
          navigate("/auth/sign-in");
          setLoading(false);
        }
      } catch (error) {
        toast.error(error?.response?.data?.message ?? error.message);
        setLoading(false);
      }
    },
  });

  const handleResendOTP = async () => {
    try {
      const response = await instance.post("api/auth/resend-otp", {
        email,
      });
      if (response.status === 200) {
        toast.success("OTP sent successfully");
      }
    } catch (error) {
      toast.error(error?.response?.data?.message ?? error.message);
    }
  };

  return (
    <div className="mb-16 mt-16 flex h-full w-full items-center justify-center px-2 md:mx-0 md:mt-32 md:px-0 lg:mb-10 lg:items-center lg:justify-start">
      <div className="mt-[2vh] w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
        <div className="mb-4 flex flex-col items-center ">
          <h4 className="mb-2.5 text-4xl font-bold text-navy-700 ">
            OTP Verification
          </h4>
          <p className="mb-2 ml-1  text-base text-gray-600">
            Enter the OTP sent to your registered email address
          </p>
          <p className="mb-4  ml-1 text-gray-900 font-semibold text-md">{email}</p>
        </div>
        <div className="flex flex-col items-center justify-center gap-4">
          <OtpInput
            value={formik.values.otp}
            onChange={(otp) => formik.setFieldValue("otp", otp)}
            numInputs={5}
            isInputNum={true}
            separator={<span>-</span>}
            containerStyle={{
              display: "flex",
              justifyContent: "space-evenly",
              gap: "20px",
              width: "100%",
            }}
            inputStyle={{
              textAlign: "center",
              borderRadius: "5px",
              border: "1px solid darkgray",
              padding: "10px",
              width: "40px",
              height: "40px",
            }}
            renderInput={(props) => <input {...props} />}
          />
          <p className="text-sm text-red-500">
            {formik.touched.otp && formik.errors.otp}
          </p>
          <div className="mt-2 flex w-full flex-col items-end gap-2 ">
            <button
              className="linear mt-2 w-full rounded-xl bg-brand-500 px-10 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
              type="submit"
              onClick={formik.handleSubmit}
            >
              {loading ? (
                <>
                  <CircularProgress sx={{ color: "white" }} size={30} />
                </>
              ) : (
                "Verify OTP"
              )}
            </button>
            <span className=" text-sm font-medium text-navy-700 dark:text-gray-600">
              Not received OTP yet?{" "}
              <button
                className="text-brand-500 hover:text-brand-600 dark:text-white"
                onClick={handleResendOTP}
                disabled={loading}
              >
                Resend OTP
              </button>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
