import {useSelector} from "react-redux"

import General from "./components/General";
// import Notification from "./components/Notification";


import AccountDetails from "./components/AccountDetails";


// import Storage from "./components/Storage";
// import Upload from "./components/Upload";

const ProfileOverview = () => {
  const userType = useSelector((state) => state?.auth?.auth?.user?.type);
  return (
    <div className="flex w-full flex-col gap-5 p-3">
      {/* <div className="mt-3 flex h-fit w-full flex-col gap-5 lg:grid lg:grid-cols-12">
        <div className="col-span-4 lg:!mb-0">
         
        </div>

        <div className="col-span-8 lg:!mb-0"> */}
          <AccountDetails />
        {/* </div>
      </div> */}


      {
        userType==='user' && <div className="mt-3 flex h-fit w-full flex-col gap-5 lg:grid lg:grid-cols-12">
        <div className="col-span-12 lg:!mb-0">
          <General />
        </div>
      </div>
      }

      
    </div>
  );
};

export default ProfileOverview;
