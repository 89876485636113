import React, { useState, useEffect } from "react";
import Joyride, { STATUS } from "react-joyride";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import AdminLayout from "layouts/admin";
import AuthLayout from "layouts/auth";
import { useSelector, useDispatch } from "react-redux";
import { instance } from "common/Instance";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { LOGOUT } from "./redux/reducerSlice/rootSlice";

import {
  SET_TOKENEXPIRED_LOADER,
  SET_USERDATA,
  SET_SIGNUP_EMAIL
 
} from "./redux/reducerSlice/authSlice";
import {
  SET_HISTORY_CURRENT_PAGE,
  SET_HISTORY_TOTAL_PAGE,
  SET_TEMPLATE_DATA
} from "./redux/reducerSlice/arSlice"
import toast from "react-hot-toast";
import step2 from "./assets/videos/step2-platform.mp4";
import step3 from "./assets/videos/step3-platform.mp4";
import step4 from "./assets/videos/step4-platform.mp4";
import step5 from "./assets/videos/step5-platform.mp4";
import { S } from "mind-ar/dist/controller-mGt1s8dJ";
const App = () => {
  const authToken = useSelector((state) => state?.auth?.auth?.token);
  const userData = useSelector((state) => state?.auth?.auth?.user);
  const dispatch = useDispatch();
  const checkActive = useSelector(
    (state) => state?.auth?.auth?.user?.isHomeTourDone
  );
  const isAuthenticated = useSelector(
    (state) => state?.auth?.auth?.token ?? ""
  );
  const userName = useSelector((state) => state?.auth?.auth?.user?.username);
  const tokenexpiredLoader = useSelector(
    (state) => state?.auth?.tokenexpiredLoader
  );
  const location = useLocation();

  const [run, setRun] = useState(true);

  const Homesteps = [
    {
      content: (
        <h3>
          {" "}
          Hii <b>{userName} </b>👋 Welcome to Immarsify Platform, let's begin
          our tour
        </h3>
      ),
      locale: { skip: <strong>SKIP</strong> },
      placement: "center",
      target: ".main",
    },

    {
      content: (
        <div className="flex flex-col  gap-2">
          <h3 className="text-center font-semibold ">Dashboard</h3>
          <p>
            Welcome to dashboard, Here, you can view experience analytics,
            including total views and new views"
          </p>

          <video
            width="700"
            height="500"
            autoPlay
            controls
            className="rounded-lg"
          >
            <source src={step2} type="video/mp4" />
          </video>
        </div>
      ),
      locale: { skip: <strong>SKIP</strong> },
      placement: "right",
      target: ".Dashboard",
    },
    {
      content: (
        <div className="flex flex-col  gap-2">
          <h3 className="text-center font-semibold ">Template</h3>
          <p>Create an experience in minutes by using our templates</p>

          <video
            width="700"
            height="500"
            autoPlay
            controls
            className="rounded-lg"
          >
            <source src={step3} type="video/mp4" />
          </video>
        </div>
      ),
      locale: { skip: <strong>SKIP</strong> },
      placement: "right",
      target: ".Template",
    },
    {
      content: (
        <div className="flex flex-col  gap-2">
          <h3 className="text-center font-semibold ">AR Experience</h3>
          <p>
            Immarsify editor, Here, you can create your experiences from scratch
            and also access your experiences history
          </p>

          <video
            width="700"
            height="500"
            autoPlay
            controls
            className="rounded-lg"
          >
            <source src={step4} type="video/mp4" />
          </video>
        </div>
      ),
      locale: { skip: <strong>SKIP</strong> },
      placement: "right",
      target: ".AR ",
    },
    {
      content: (
        <div className="flex flex-col  gap-2">
          <h3 className="text-center font-semibold ">Branding</h3>
          <p>Click here to add branding of your card by uploading GIF,</p>

          <video
            width="700"
            height="500"
            autoPlay
            controls
            className="rounded-lg"
          >
            <source src={step5} type="video/mp4" />
          </video>
        </div>
      ),
      locale: { skip: <strong>SKIP</strong> },
      placement: "right",
      target: ".Branding ",
    },
  ];

  const updateJoyRide = async () => {
    try {
      const response = await instance.post(
        `/api/user/update_user_details/${userData._id}`,
        { isHomeTourDone: true },
        {
          headers: {
            authorization: `Bearer ${authToken}`,
          },
        }
      );
      if (response.status === 200) {
        dispatch(SET_USERDATA(response?.data?.data));
      }
    } catch (error) {
      toast.error(error?.response?.data?.message ?? error.message);
    }
  };

  const handleJoyrideCallback = (data) => {
    const { status } = data;
    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      setRun(false);
      updateJoyRide();
    }
  };

  const joyrideOptions = {
    styles: {
      options: {
        borderRadius: "0.5rem",
        zIndex: 10000,
        overlay: {
          backgroundColor: "rgba(0, 0, 0, 0.5)",
        },
      },
      buttonBack: {
        color: "#222",
      },
      buttonNext: {
        backgroundColor: "#40A2D8",
        color: "#fff",
        borderRadius: "0.5rem",
        padding: "10px",
        outline: "none",
        border: "none",
        "&:hover": {
          border: "none",
        },
      },
      buttonSkip: {
        backgroundColor: "#6c757d",
        color: "#fff",
        borderRadius: "0.5rem",
        padding: "10px",
      },
    },
  };


  
  const showJoyride =
    location.pathname !== "/admin/form" &&
    location.pathname !== "/auth/sign-in" &&
    location.pathname !== "/auth/sign-up" &&
    location.pathname !== "/auth/password" &&
    location.pathname !== "/auth/resetpassword" &&
    location.pathname !== "/auth/otp" &&
    location.pathname !== "/admin/upgrade_plan";

  return (
    <div className="main">
      <Routes>
        <Route path="auth/*" element={<AuthLayout />} />
        <Route path="admin/*" element={<AdminLayout />} />
        <Route
          path="/"
          element={
            isAuthenticated ? (
              <Navigate to="/admin" replace />
            ) : (
              <Navigate to="auth/signUp" replace />
            )
          }
        />
      </Routes>
      {userData?.isSubscriptionActive &&
        authToken &&
        checkActive !== true &&
        showJoyride && (
          <Joyride
            steps={Homesteps}
            run={run}
            continuous={true}
            scrollToFirstStep={true}
            showProgress={true}
            showSkipButton={true}
            disableOverlayClose={true}
            callback={handleJoyrideCallback}
            styles={joyrideOptions.styles}
            locale={{
              next: "Next",
              back: "Back",
              skip: "Skip",
            }}
          />
        )}
      {showJoyride && <SignoutModal open={tokenexpiredLoader} />}
    </div>
  );
};

const SignoutModal = ({ open }) => {
  const dispatch = useDispatch();
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 300,
    bgcolor: "background.paper",
    border: "0",
    boxShadow: 24,
    p: 4,
    outline: "none",
    borderRadius: "10px",
  };
  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div className="flex flex-col items-center justify-center gap-4">
          <h4 className="text-md text-center">
            Your session has expired, &nbsp;Please login to continue
          </h4>
          <button
            onClick={() => {
              dispatch(SET_TOKENEXPIRED_LOADER(false));
              dispatch(LOGOUT());
              dispatch(SET_HISTORY_TOTAL_PAGE(0))
              dispatch(SET_HISTORY_CURRENT_PAGE(1))
              dispatch(SET_SIGNUP_EMAIL(""));
              dispatch(SET_TEMPLATE_DATA())
              
            }}
            className="text-md mt-2 rounded-lg border bg-brand-700 px-4 py-2 text-white"
          >
            Okay
          </button>
        </div>
      </Box>
    </Modal>
  );
};

export default App;
