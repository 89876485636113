// src/components/ProductCard.js
import React, { useState } from "react";
import { CiMenuKebab } from "react-icons/ci";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { IconButton } from "@mui/material";
import EditProductModal from "./Modal/EditProductModal";
import DeleteModal from "./Modal/DeleteModal";
import toast from "react-hot-toast";
import { instance } from "common/Instance";
import { useSelector } from "react-redux";
import "./ProductCard.css";
const ProductCard = ({ product, getImmersifyProducts }) => {
  const [showEditProductModal, setShowEditProductModal] = useState(false);
  const [showOpenDeleteModal, setShowOpenDeleteModal] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const userToken = useSelector((state) => state.auth.auth.token);

  const open = Boolean(anchorEl);
  const handleClickAnchor = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const HandleOpenEditProductModal = () => {
    setShowEditProductModal(true);
    setAnchorEl(null);
  };

  const opneDeleteModalHandler = () => {
    setShowOpenDeleteModal(true);
    setAnchorEl(null);
  };
  const handleCloseAnchor = () => {
    setShowOpenDeleteModal(false);

    setAnchorEl(null);
  };

  const truncateText = (text, maxLength) => {
    if (text?.length > maxLength) {
      return text.substring(0, maxLength) + "...";
    }
    return text;
  };

  const deleteProductHandler = async () => {
    try {
      const response = await instance.delete(
        `api/products/delete_product/${product?._id}`,

        {
          headers: {
            authorization: `Bearer ${userToken}`,
          },
        }
      );
      console.log(response, "this is repsonse");
      if (response.status === 200) {
        toast.success(response?.data?.message);
        getImmersifyProducts();
        setTimeout(() => {
          setShowOpenDeleteModal(false);
        }, 500);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || "Something went wrong");
      console.log("Dashboard Error: ", error);
    }
  };
  return (
    <div className="min-w-56 max-w-60 overflow-hidden rounded-md bg-white shadow-lg">
      <div className="page-inner">
        <div className="el-wrapper">
          <div className="box-up">
            <img
              className="img h-48 w-full object-cover"
              src={product?.mainImage || "https://via.placeholder.com/150"}
              alt={product?.name || "Default product image"}
            />
            <div className="img-info">
              <div className="info-inner">
                <span className="p-name">
                  {product?.name || "Product Name not availabe"}
                </span>
                <span className="p-company">
                  {truncateText(product?.description, 100) ||
                    "Description not available"}
                </span>
              </div>
              <div
                className={`a-size ${
                  product?.isProductAvailable
                    ? "text-green-400"
                    : "text-red-400"
                }`}
              >
                {product?.isProductAvailable ? "Available" : "Out of Stock"}
              </div>
            </div>
          </div>

          <div className="box-down flex items-center justify-between px-6 pb-2">
            <span className="price text-lg font-bold text-gray-700">
              ${product?.price || "0.00"}
            </span>
            <div className="mt-2">
              <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={open ? "long-menu" : undefined}
                aria-expanded={open ? "true" : undefined}
                aria-haspopup="true"
                onClick={handleClickAnchor}
              >
                <CiMenuKebab className="text-white" />
              </IconButton>
              <Menu
                id="long-menu"
                MenuListProps={{
                  "aria-labelledby": "long-button",
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleCloseAnchor}
              >
                <MenuItem onClick={HandleOpenEditProductModal}>Edit</MenuItem>
                <MenuItem onClick={opneDeleteModalHandler}>Delete</MenuItem>
              </Menu>
            </div>
          </div>
        </div>
      </div>

      <EditProductModal
        isOpen={showEditProductModal}
        setIsOpen={setShowEditProductModal}
        product={product}
        getImmersifyProducts={getImmersifyProducts}
      />
      <DeleteModal
        isOpen={showOpenDeleteModal}
        setIsOpen={setShowOpenDeleteModal}
        content="Please confirm to delete this product!!!"
        handler={deleteProductHandler}
      />
    </div>
  );
};

export default ProductCard;
