import React, { useEffect, useState } from "react";
import ProductCard from "./ProductCard";
import ConnectWoocommerceModal from "./Modal/ConnectWoocommerceModal";
import GuideModal from "./Modal/GuideModal";
import { AWS_STATIC_PREFIX, instance } from "../../../../common/Instance";
import DeleteModal from "./Modal/DeleteModal";
import { useSelector } from "react-redux";
import WooCategorySection from "./WooCategorySection";
import toast from "react-hot-toast";

const woocomerceGuide = [
  {
    id: 1,
    image: `${AWS_STATIC_PREFIX}Immarsify+-+woocommerce/1.png`,

    description:
      "Welcome to the first step of our guide. Here, we will introduce you to the basic concepts and prepare you for the journey ahead.",
  },
  {
    id: 2,
    image: `${AWS_STATIC_PREFIX}Immarsify+-+woocommerce/2.png`,

    description:
      "In this step, we delve deeper into the process, providing you with essential information and practical tips.",
  },
  {
    id: 3,
    image: `${AWS_STATIC_PREFIX}Immarsify+-+woocommerce/3.png`,
    description:
      "Now that you are familiar with the basics, we move on to more advanced techniques and strategies.",
  },
  {
    id: 4,
    image: `${AWS_STATIC_PREFIX}Immarsify+-+woocommerce/4.png`,

    description:
      "In this final step, we review everything you have learned and offer guidance for further improvement and mastery.",
  },
  {
    id: 5,
    image: `${AWS_STATIC_PREFIX}Immarsify+-+woocommerce/5.png`,

    description:
      "In this final step, we review everything you have learned and offer guidance for further improvement and mastery.",
  },
  {
    id: 6,
    image: `${AWS_STATIC_PREFIX}Immarsify+-+woocommerce/6.png`,

    description:
      "In this final step, we review everything you have learned and offer guidance for further improvement and mastery.",
  },
  {
    id: 7,
    image: `${AWS_STATIC_PREFIX}Immarsify+-+woocommerce/image.png`,

    description:
      "In this final step, we review everything you have learned and offer guidance for further improvement and mastery.",
  },
];

const WooCommerceProducts = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isGuideOpen, setIsGuideOpen] = useState(false);
  const [loadingSyncWoocommerce, setLoadingWoocommerce] = useState(false);
  const [showSyncOpenWoocommerce, setShowSyncOpenWoocommerce] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [categories, setCategories] = useState([]);
  const [searchParam, setSearchParam] = useState("");
  const [syncProductKey,setSyncProductKey]=useState(null)
  const [isLoading, setIsLoading] = useState(false);
  const [searchString, setSearchString] = useState("");
  const [timeoutId, setTimeoutId] = useState(null);
  console.log("isGuideOpen: ", isGuideOpen);
  const product = {
    image: "https://via.placeholder.com/300x200",
    title: "Sample Product Title That is Quite Long",
    description:
      "This is a sample product description that is intended to be long enough to demonstrate the truncation functionality.",
    available: true,
    price: "29.99",
  };
  const userToken = useSelector((state) => state.auth.auth.token);
  const getCategorieshandler = async () => {
    try {
      setIsLoading(true);
      const response = await instance.get("api/categories/get_categories_wooCommerce", {
        headers: {
          authorization: `Bearer ${userToken}`,
        },
      });
      if (response.status === 200 && response?.data?.data) {
        console.log(response.data);
        setIsLoading(false);
       
        setCategories([{_id:'',category:'All'},...response?.data?.data]);
      }
    } catch (error) {
      console.log("Dashboard Error: ", error);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    setSelectedCategory({_id:'all',category:'All'});
    getCategorieshandler();
  }, []);


  const syncProductsHandler = async () => {
    try {
      setShowSyncOpenWoocommerce(false);
      setLoadingWoocommerce(true);
      const response = await instance.get(`api/products/fetch_products`, {
        headers: {
          authorization: `Bearer ${userToken}`,
        },
      });
      if (response.status === 200 && response?.data) {
        console.log(response.data, "this is response");
        setShowSyncOpenWoocommerce(false)
        toast.success("Product Synced succesfully!")
        setSyncProductKey(Math.random())
        setLoadingWoocommerce(false);
      }
      else{
        toast.error('Something went wrong!')
      }
    } catch (error) {
      console.log("Dashboard Error: ", error);
      toast.error(error?.message)
      setLoadingWoocommerce(false);
    }
  };
  const categoriesChangeHandler = (e) => {
    const category = categories.find(
      (category) => category?._id === e.target.value
    );
    console.log(category, "category found");
    if (category) {
      setSelectedCategory(category);
    }
  };
  const searchChangeHandler = (e) => {
    const inputValue = e.target.value;
    setSearchString(inputValue);

    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    const newTimeoutId = setTimeout(() => {
      if (!inputValue || inputValue.trim() === "") {
        setSearchParam("");
      } else {
        setSearchParam(inputValue.trim());
      }
    }, 500);

    setTimeoutId(newTimeoutId);
  };
  return (
    <div>
      <GuideModal
        isOpen={isGuideOpen}
        setIsOpen={setIsGuideOpen}
        guide={woocomerceGuide}
        title="Guide for Woocommerce"
      />
      <div className="connectShopifySection  sm:block flex sm:flex-row flex-col mt-[-3px] gap-2">
        <button
          className="   text-center cursor-pointer rounded-lg   border border-none border-gray-300 bg-brand-main  px-4 py-2 text-sm text-white outline-none  transition duration-300 ease-in-out"
          onClick={() => {
            setIsOpen(true);
          }}
        >
          Connect Woocommerce Products
        </button>
        <button
          className=" sm:ml-4  text-center  cursor-pointer  rounded-lg border border-none border-gray-300  bg-brand-main px-4 py-2 text-sm text-white outline-none transition duration-300 ease-in-out"
          onClick={() => {
            setIsGuideOpen(true);
          }}
        >
          Guide
        </button>

        <button
          className=" sm:ml-4  text-center  cursor-pointer  rounded-lg border border-none border-gray-300  bg-brand-main px-4 py-2 text-sm text-white outline-none transition duration-300 ease-in-out"
          onClick={() => {
            setShowSyncOpenWoocommerce(true);
          }}
        >
          {loadingSyncWoocommerce ? "Loading" : "Sync Products"}
        </button>
      </div>
      <div className="mb-8 mt-8">
        <hr />
      </div>
      <div className="flex w-full gap-2">
      <input
              onChange={searchChangeHandler}
              value={searchString}
              className="block h-10 w-full    appearance-none rounded border border-gray-200 px-4 py-3 leading-tight text-gray-500 focus:border-gray-500 focus:bg-white focus:outline-none"
              id="grid-last-name"
              type="text"
              placeholder="Search the products"
            />
         <select
              value={selectedCategory?._id}
              onChange={categoriesChangeHandler}
              name=""
              id=""
              className="border-1 appearance-none rounded-md border-gray-200 bg-gray-200 px-2 outline-none "
            >
              {categories.map((category,index) => {
                return (
                  <>  
                  <option value={category?._id}>{category?.category}</option>           
                  </>
                );
              })}
            </select>
      </div>

      {/* <div className="categoryName mt-8 h-14 rounded-md  ">
        <p className="flex h-full items-center rounded-md bg-brand-main  text-2xl text-white">
          <span className="ml-4">categoryname</span>
        </p>
      </div> */}
      {selectedCategory && (
            <WooCategorySection
              category={selectedCategory}
              search={searchParam}
              setOpenEditCategoryModal={false}
              setOpenDeleteCateogryModal={false}
           syncProductKey={syncProductKey}
              />
          )}

      {/* <div className="products mt-8">
        <ProductCard />
      </div> */}

      <ConnectWoocommerceModal isOpen={isOpen} setIsOpen={setIsOpen} />

      <DeleteModal
        isOpen={showSyncOpenWoocommerce}
        setIsOpen={setShowSyncOpenWoocommerce}
        content={"Are you sure to sync with woocommerce?"}
        description="If you have existing products, may lost if deleted from woocommerce"
        handler={syncProductsHandler}
      />
    </div>
  );
};

export default WooCommerceProducts;
