import { Button } from "@mui/material";
import Card from "components/card";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import moment from "moment";

const General = () => {
  const navigate = useNavigate();
  const user = useSelector((state) => state.auth.auth.user);
  return (
    <Card extra={"w-full h-6/12 p-3 flex flex-col gap-4 p-4 "}>
      <div>
        <h4 className="text mb-2 text-xl font-semibold ">My Subscription</h4>
        <hr />
      </div>

      <div className=" rounded-md border bg-gray-50 p-4">
        <h4 className=" text-md mb-2 font-semibold ">Current Plan</h4>
        <p className="text-sm text-gray-900 ">{ user?.subscriptionType? ` Immarsify ${user?.subscriptionType} Annual Plan `:'No plan yet'}</p>
      </div>

      {
        user?.subscriptionType &&  <div className="rounded-md border bg-gray-50 p-4">
        <h3 className="text-md mb-2 font-semibold">{ ` Immarsify ${user?.subscriptionType} Expiry Date`}</h3>
        <p className="text-sm text-gray-900 ">
          
          {moment(user?.subscriptionsExpired).format("lll")}
        </p>
      </div>
      }

     

      <div className=" rounded-md border bg-gray-50 p-4">
        <h3 className="text-md mb-2 font-semibold"> Status</h3>

        <div className="flex  justify-between  gap-2  ">
          <h3 className={` mt-2 text-sm ${ user?.subscriptionType?`text-green-700`:`text-red-700`} text-green-700`}>{ user?.subscriptionType ? `Subscription ${user?.isSubscriptionActive ?? 'inactive'} `:'Inactive'}</h3>

          <div className="flex-start flex gap-3">
          
            <button
              className=" cursor-pointer rounded-md  bg-[#3d2fa9] px-4  py-2 text-sm text-white"
              onClick={() => navigate("/admin/upgrade_plans")}
            >
              Upgrade Plan
            </button>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default General;
