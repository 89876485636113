import React from "react";
import { FaDownload, FaPrint } from "react-icons/fa";
import ReactToPrint from "react-to-print";
import logo from "../../../../assets/svg/logo1.svg";
const BillingDetails = ({ componentRef, invoiceDetails }) => {
  console.log(invoiceDetails);
  return (
    <div>
      <div className="lg:hidden flex justify-center">
        <img src={logo} alt="" className="md:mt-[-10px] w-44 "  />
      </div>
      <div className="flex justify-between flex-row">
        <div className="text-start">
          <h2 className=" font-bold lg:text-4xl  ">INVOICE</h2>

       
        </div>
        <div className="hidden lg:block ">
          <img src={logo} alt="" className="md:mt-[-10px]" width={200} />
        </div>
        <div className="">
          <h3 className="text-center font-bold sm:text-end lg:text-xl break-all">
            {invoiceDetails?.fullName}
          </h3>
        </div>

        {/* <div className="mt-4 text-sm">
        <p>
          <span className="font-semibold">Purchased by: </span>{" "}
          <span>John doe</span>
        </p>
        <p>
          <span className="font-semibold">Email: </span>{" "}
          <span>test@gmail.com</span>
        </p>
        <p>
          <span className="font-semibold">Purchase date: </span>{" "}
          <span>10/02/2024</span>
        </p>
        <p>
          <span className="font-semibold">Invoice id: </span>{" "}
          <span>s5fd656gd56fds56gdfsdfds</span>
        </p>
        <p>
          <span className="font-semibold">Payment status: </span>{" "}
          <span className="text-green-600">success</span>
        </p>
      </div> */}

      
      </div>
      <div>
      <p className="text-xs md:text-base">{invoiceDetails?.email}</p>
      </div>
      <div className="md:text-base flex-col  my-2 flex gap-2 text-sm sm:text-base  sm:flex-row">
        <p>User Id: </p>
        <p className="break-all">{invoiceDetails?.planDetails[0]?.userId}</p>
      </div>

      {/* <div className="my-2 flex gap-2  sm:flex-row ">
        <p>Subscription Id: </p>
        <p className="">{invoiceDetails?.subscriptionDetails[0]?._id}</p>
      </div> */}
    </div>
  );
};

export default BillingDetails;
