import { Backdrop, CircularProgress } from "@mui/material";
import CircularWithValueLabel from "components/circularProgress";
import React from "react";

const CommonBackdrop = ({
  loading,
  value,
  title,
  description,
  loadingColor,
}) => {
  return (
    <Backdrop sx={{ color: "#fff", zIndex: 9999999 }} open={loading}>
      <div className="flex flex-col items-center justify-center gap-2">
        {title && <h1 className="text-lg font-bold ">{title}</h1>}
        {description && <h1 className="text-sm font-bold ">{description}</h1>}
        {value ? (
          <CircularWithValueLabel color={loadingColor} value={value} />
        ) : (
          <CircularProgress
            style={{ color: loadingColor ?? "" }}
            color="inherit"
          />
        )}
      </div>
    </Backdrop>
  );
};

export default CommonBackdrop;
