import moment from "moment";
import React from "react";

const PlanDetails = ({ invoiceDetails, planAmount }) => {
  return (
    <div className="my-4 flex   w-full flex-col items-center border-b border-t text-sm sm:flex-row">
      <div className="w-full border-b py-2  sm:w-1/2 sm:border-b-0 sm:border-r  ">
        {/* <h3 className="text-center text-xl font-bold">Subscription</h3> */}
        <div className="my-2 pb-6">
          <label htmlFor="subscription_date" className="font-bold">
            Subscription start date
          </label>
          <p>
            {moment(invoiceDetails?.planDetails[0]?.createdAt).format(
              "DD/MM/YYYY"
            )}
          </p>
        </div>

        

        <div className="my-2 pt-6">
          <label htmlFor="subscription_date" className="font-bold">
            Status
          </label>
          <p className="text-green-500">
            {invoiceDetails?.isSubscriptionActive}
          </p>
        </div>
      </div>
      <div className="w-full border-b py-2 sm:w-1/3 sm:border-b-0 sm:border-r sm:px-4">
        {/* <h3 className="text-center text-xl font-bold">Address details</h3> */}
        <div className="my-2">
          <label htmlFor="subscription_date" className="font-bold">
            Billed to{" "}
          </label>
          <p>{invoiceDetails?.fullName}</p>
        </div>

        <div className="my-2">
          <label htmlFor="subscription_date" className="font-bold">
            User name{" "}
          </label>
          <p>{invoiceDetails?.username}</p>
        </div>

        <div className="my-2">
          <label htmlFor="subscription_date" className="font-bold">
            Contact Number{" "}
          </label>
          <p>{invoiceDetails?.phoneNumber}</p>
        </div>
        {/* <p>
          <span className="font-medium">City: </span> <span>Lakhnau</span>
        </p>

        <p>
          <span className="font-medium">Pin code: </span>{" "}
          <span className="text-green-600">505202</span>
        </p> */}
      </div>
      <div className="w-full py-2 sm:w-1/3 sm:px-4">
        {/* <h3 className="text-center text-xl font-bold">Payment details</h3> */}
        <div className="my-2">
          <label htmlFor="subscription_date" className="font-bold">
            Payment info{" "}
          </label>

          <div className="mt-2 space-y-2">
            <div className="flex justify-between">
              <p>Subscription type</p>
              <p>{invoiceDetails?.subscriptionType}</p>
            </div>

            <div className="flex justify-between">
              <p>Payment status</p>
              <p className="text-green-500">{invoiceDetails?.payment}</p>
            </div>
            <div className="flex justify-between  ">
              <p>Paid Amount</p>
              <p className="text-right">
                ₹
                {planAmount} /-
              </p>
            </div>

            {/* <div className="flex justify-between ">
              <p>Subscription Id</p>
              <p className="text-wrap ">
                {invoiceDetails?.subscriptionDetails[0]?._id}
              </p>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlanDetails;
