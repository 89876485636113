import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Tooltip,
  IconButton,
} from "@mui/material";
import Slide from "@mui/material/Slide";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { instance } from "../../../../../common/Instance";
import { CiCircleInfo } from "react-icons/ci";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const validationSchema = Yup.object().shape({
  appId: Yup.string().required("App Id is required"),
  appSecret: Yup.string().required("App Secret is required"),
  shopName: Yup.string().required("Shop Name is required"),
});

const ConnectShopifyModal = ({ isOpen, setIsOpen }) => {
  const handleClose = () => {
    setIsOpen(false);
  };
  const authToken = useSelector((state) => state.auth.auth.token);

  const handleSubmit = async (values, actions) => {
    try {
      const response = await instance.get(`api/products/install`, {
        params: {
          appId: values.appId,
          appSecret: values.appSecret,
          shop: values.shopName,
        },
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });

      console.log("API Response:", response);

      if (response.status === 200) {
        setIsOpen(false);
        toast.success("Shopify connected successfully");
        window.open(response.data.installUrl);
        actions.resetForm();

        handleClose();
      } else {
        toast.error("Failed to connect to Shopify");
      }
    } catch (error) {
      console.error("API Error:", error);
      toast.error(error.response.data.message);

      actions.setSubmitting(false);
    }
  };

  return (
    <Dialog
      open={isOpen}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      fullWidth={true}
      aria-describedby="alert-dialog-slide-description"
      sx={{ margin: 0 }}
    >
      <DialogTitle className="font-bold">{"Connect to Shopify"}</DialogTitle>
      <DialogContent sx={{ padding: 0 }}>
        <DialogContentText id="alert-dialog-slide-description">
          <div className="w-full text-black">
            <Formik
              initialValues={{
                appId: "",
                appSecret: "",
                shopName: "",
              }}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {(formik) => (
                <Form>
                  <div className="rounded bg-white px-8 py-4 shadow-md">
                    <div className="mb-4">
                      <label htmlFor="appId" className="mb-2 block text-sm">
                        App id
                      </label>
                      <Field
                        type="text"
                        id="appId"
                        
                        name="appId"
                        className="focus:shadow-outline w-full appearance-none rounded border px-3 py-2 leading-tight text-gray-800 shadow focus:border-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-200"
                      />
                      <ErrorMessage
                        name="appId"
                        component="div"
                        className="mt-1 text-xs text-red-500"
                      />
                    </div>
                    <div className="mb-4">
                      <label htmlFor="appSecret" className="mb-2 block text-sm">
                        App secret
                      </label>
                      <Field
                        type="text"
                        id="appSecret"
                        name="appSecret"
                        className="focus:shadow-outline w-full appearance-none rounded border px-3 py-2 leading-tight text-gray-800 shadow focus:border-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-200"
                      />
                      <ErrorMessage
                        name="appSecret"
                        component="div"
                        className="mt-1 text-xs text-red-500"
                      />
                    </div>
                    <div className="mb-4">
                      <label htmlFor="shopName" className="mb-2 block text-sm">
                        Shop name
                        <Tooltip
                          title="Your shop name for example: immersify.myshopify.com"
                          arrow
                        >
                          <IconButton>
                            <CiCircleInfo size={15} />
                          </IconButton>
                        </Tooltip>
                      </label>
                      <Field
                        type="text"
                        id="shopName"
                        name="shopName"
                        className="focus:shadow-outline w-full appearance-none rounded border px-3 py-2 leading-tight text-gray-800 shadow focus:border-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-200"
                      />
                      <ErrorMessage
                        name="shopName"
                        component="div"
                        className="mt-1 text-xs text-red-500"
                      />
                    </div>
                    <div className="flex items-center justify-end">
                      <button
                        type="submit"
                        disabled={!formik.isValid || formik.isSubmitting}
                        className="ml-4 cursor-pointer rounded-lg border border-none border-gray-300 bg-brand-main px-4 py-1.5 text-sm text-white outline-none transition duration-300 ease-in-out"
                      >
                        Connect
                      </button>
                      <button
                        type="button"
                        onClick={handleClose}
                        className="ml-4 cursor-pointer rounded-lg border border-none border-gray-300 bg-deleteBackground px-4 py-1.5 text-sm text-white outline-none transition duration-300 ease-in-out"
                      >
                        Close
                      </button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

export default ConnectShopifyModal;
