import { createSlice } from "@reduxjs/toolkit";

const INITIAL_STATE = {
  data: {
    resellerData: [],
    subResellerData: [],
    userDataTable: [],
    recycleBinData: [],
  },
};

const tableDataSlice = createSlice({
  name: "tableData",
  initialState: INITIAL_STATE,
  reducers: {
    SET_RESELLER_DATA: (state, action) => {
      state.data.resellerData = action.payload;
    },
    SET_SUBRESELLER_DATA: (state, action) => {
      state.data.subResellerData = action.payload;
    },
    SET_USER_DATA: (state, action) => {
      console.log(action.payload, "this is payload");
      state.data.userDataTable = action.payload;
    },
    SET_RECYCLE_BIN_DATA: (state, action) => {
      state.data.recycleBinData = action.payload;
    },
  },
});

export const {
  SET_RESELLER_DATA,
  SET_SUBRESELLER_DATA,
  SET_USER_DATA,
  SET_RECYCLE_BIN_DATA,
} = tableDataSlice.actions;
export default tableDataSlice.reducer;
