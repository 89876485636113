import React, { useState, useEffect, useCallback, useRef } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import InputField from "components/fields/InputField";
import { instance } from "common/Instance";
import toast from "react-hot-toast";
import { Link, useNavigate, useLocation } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import debounce from "lodash.debounce";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { names } from "common/Category";
import { toJpeg } from "html-to-image";
import { loadTexture } from "views/admin/arExperience/tabcomponent/EditorUtils";
import { cardStaticData } from "../admin/Reseller/static/staticData";
import * as mind from "mind-ar/dist/mindar-image.prod";
import queryString from "query-string";
import { useDispatch } from "react-redux";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import CommonBackdrop from "common/CommonBackdrop";
import CardOne from "views/admin/arExperience/tabcomponent/Cardtemplates/CardOne";

import { auth } from "../../common/firebaseConfig";
import { SET_SIGNUP_EMAIL } from "../../redux/reducerSlice/authSlice";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { AWS_STATIC_PREFIX } from "common/Instance";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  border: "none",
  outline:'none',
  p: 4,
  borderRadius:2,
};

const SignUp = () => {
  const { search } = useLocation();
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [usernameAvailable, setUsernameAvailable] = useState(true);
  const [resellerAvailable, setResellerAvailable] = useState(true);
  const cardRef = useRef(null);
  const [dynamicTitle, setDynamicTitle] = useState("Creating user");
  const [user, setUser] = useState();
  const [token, setToken] = useState();
  const [loading1,setLoading1]=useState(false)
  const [arData, setArData] = useState(cardStaticData);
  const [compliningProgress, setCompilingProgress] = useState(0);

 
  const [open, setOpen] = React.useState(false);

  const handleClose = () => setOpen(false);
  const compiler = new mind.Compiler();

  const values = queryString.parse(search);

  const Usernamevalue = values.refer;

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const checkUsernameAvailability = async (value) => {
    try {
      const response = await instance.post("api/auth/check_username", {
        username: value.trim(),
      });
      return response.data.data;
    } catch (error) {
      return false;
    }
  };

  const debouncedCheckUsername = useCallback(
    debounce(async (value) => {
      const isAvailable = await checkUsernameAvailability(value);
      setUsernameAvailable(isAvailable);
    }, 500),
    []
  );

  const checkResellerUsernameAvailability = async (value) => {
    try {
      const response = await instance.post("api/auth/check_reseller_username", {
        username: value.trim(),
      });
      return response.data.data;
    } catch (error) {
      return false;
    }
  };

  const debouncedCheckReseller = useCallback(
    debounce(async (value) => {
      const isAvailable = await checkResellerUsernameAvailability(value);
      setResellerAvailable(isAvailable);
    }, 1000),
    []
  );


  const createARExperience = async (token, userData, imageUrl, mindUrl) => {
    console.log(userData, "userData");
    setLoading1(true);
    const texture = await loadTexture(imageUrl);
    const textureWidth = texture.image.width;
    const textureHeight = texture.image.height;
    let targetImage;
    let aspectRatio;

    if (textureWidth >= textureHeight) {
      aspectRatio = textureWidth / textureHeight;
      targetImage = {
        src: imageUrl,
        scale: { x: 1.959, y: 1.098, z: 0.8 },
      };
    }
    if (textureHeight > textureWidth) {
      aspectRatio = textureHeight / textureWidth;
      targetImage = {
        src: imageUrl,
        scale: { x: 1.959, y: 1.098, z: 0.8 },
      };
    }

    const texture1 = await loadTexture(
      AWS_STATIC_PREFIX + "images/dummyProfile.jpg"
    );
    const textureWidthforPhoto = texture1.image.width;
    const textureHeightforPhoto = texture1.image.height;
    let profilePhoto;
    let aspectRatio1;
    let baseSize = 0.8;
    if (textureWidthforPhoto >= textureHeightforPhoto) {
      aspectRatio1 = textureWidth / textureHeight;
      profilePhoto = {
        src: AWS_STATIC_PREFIX + "images/dummyProfile.jpg",
        scale: { x: 1.959, y: 1.098, z: 0.8 },
      };
    }
    if (textureHeightforPhoto > textureWidthforPhoto) {
      aspectRatio1 = textureWidth / textureHeight;
      profilePhoto = {
        src: AWS_STATIC_PREFIX + "images/dummyProfile.jpg",
        scale: { x: baseSize, y: aspectRatio * baseSize, z: baseSize },
      };
    }

    try {
      const newIconLink = `https://wa.me/${userData?.phoneNumber}`;
      const newIconsArray = (arData?.icons ?? []).map((icon, index) => {
        if (index === 3) {
          return {
            ...icon,
            iconLink: `tel:+${userData?.phoneNumber}`,
            isLink: true,
          };
        }
        if (index === 4) {
          return {
            ...icon,
            vCardJson: {
              ...icon.vCardJson,
              firstName: userData?.fullName.split(" ")[0],
              lastName: userData?.fullName.split(" ")[1],
              photo: AWS_STATIC_PREFIX + "images/dummyProfile.jpg" ?? "",
              cellPhone: userData?.phoneNumber ?? "",
              email: userData?.email ?? "",
              workUrl: `https://ar.immarsify.com/${userData?.username}/${userData?.username}`,
              note: userData?.category?.join(", "),
            },
          };
        }
        if (index === 5 || index === 2) {
          return {
            ...icon,
            iconLink: `mailto:${userData?.email}`,
            isLink: true,
          };
        }
        if (index === 6) {
          return {
            ...icon,
            iconLink: newIconLink,
            isLink: true,
          };
        }
        return icon;
      });
      console.log("New icons array:", newIconsArray);

      let data = {
        ...arData,
        userId: userData._id,
        userName: userData?.username,
        arexperienceName: userData?.fullName,
        name: userData?.username,
        targetImage: targetImage,
        mind: {
          src: mindUrl,
        },
        text: [{ ...arData?.text[0], body: userData?.fullName }],
        photos: [{ ...arData?.photos[0], ...(profilePhoto ?? "") }],
        icons: newIconsArray,
      };
      const response = await instance.post(
        `/api/ar/create_experience`,
        { ...data },
        {
          headers: {
            authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        setArData(cardStaticData);
        toast.success(response?.data?.message ?? "Card created successfully");
        setLoading1(false);
        handleClose();
        navigate('/auth/otp')
       
      }
    } catch (error) {
      setLoading(false);

      toast.error(error?.response?.data?.message ?? error.message);
    }
  };

  const uploadFile = async (file, assetType, token,data) => {
    
    const formData = new FormData();
    formData.append("file", file);
    formData.append("assetType", assetType);

    try {
      const response = await instance.post(
        `api/ar/upload/${data?.username}/${data?.username}`,
        formData,
        {
          headers: {
            authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return response.data.url;
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  const createTarget = async (file, type, blobPreview, token, data) => {
   
    setLoading1(true);
    setDynamicTitle("Creating Target image");
    try {
      const imageUrl =
        type === "upload"
          ? await uploadFile(file[0], "image", token,data)
          : await uploadFile(file, "image", token,data);

      const dataList = await compiler.compileImageTargets(
        blobPreview,
        (progress) => {
          setCompilingProgress(Math.round(progress));
        }
      );
      setCompilingProgress(0);
      const exportedBuffer = await compiler.exportData();
      const blob = new Blob([exportedBuffer]);
      const mindFile = new File([blob], "target.mind", { type: blob.type });
      const mindUrl = await uploadFile(mindFile, "mind", token,data);

      toast.success("Target Image Uploaded Successfully");
      setLoading1(false);

      
      await createARExperience(token, data, imageUrl, mindUrl);
    } catch (error) {
      toast.error(error?.response?.data?.message ?? error.message);
      setLoading1(false);
    }
  };

  const handleTarget = (token, userData) => {
   
    setDynamicTitle("Generating card for Experience");
    setLoading1(true)
 
  
    if (cardRef.current === null) {
      return;
    }
    
    toJpeg(cardRef.current)
      .then((dataUrl) => {
        return fetch(dataUrl).then((res) => res.blob());
      })
      .then((blob) => {
        setLoading1(true);
        const image = new Image();
        image.src = URL.createObjectURL(blob);
        setTimeout(async () => {
          await createTarget(blob, "create", [image], token, userData);
        }, 500);
      })

      .catch((error) => {
        console.error("Error:", error);
      });
  };

 

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const [parentCategoryValue, setParentCatgoryValue] = useState([]);

  const handleChange = (event) => {
    const { value } = event.target;
    setParentCatgoryValue(value);
    const selectedOptions = names.find(
      (item) => Object.keys(item)[0] === value
    );

    if (selectedOptions) {
      formik.setFieldValue("category", selectedOptions[value]);
    } else {
      formik.setFieldValue("category", []);
    }
  };
  const validationSchema = Yup.object({
    username: Yup.string()
      .min(3, "Username must be at least 3 characters")
      .required("Username is required")
      .matches(
        /^[a-zA-Z0-9_]*$/,
        "Only letters, numbers, and underscores are allowed"
      )
      .test("username", "Username already exists", async () => {
        return usernameAvailable;
      }),

    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    fullName: Yup.string().required("Full name is required"),
    password: Yup.string()
      .min(4, "Password must be at least 4 characters")
      .required("Password is required"),
    phoneNumber: Yup.string()
      .test(
        "is-10-digits",
        "Phone number must be exactly 10 digits",
        function (value) {
          const numericValue = value.replace(/\D/g, "");
          return numericValue.length === 12;
        }
      )
      .required("Phone number is required"),

    referedBy: Yup.string()
      .notRequired()
      .test("uniqueUsername", "Referral is invalid", async () => {
        if (formik.values.referedBy) {
          return !resellerAvailable;
        }
        return true;
      }),
    category: Yup.array(),
  });

  const formik = useFormik({
    initialValues: {
      username: "",
      email: "",
      password: "",
      fullName: "",
      phoneNumber: "",
      referedBy: "",
      category: [],
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      dispatch(SET_SIGNUP_EMAIL(""));
      let data = {
        ...values,
        type: "user",
        parentCategory: parentCategoryValue,
      };
      if (Usernamevalue) {
        data = {
          ...data,
          referedBy: Usernamevalue,
          type: "user",
          parentCategory: parentCategoryValue,
        };
      }

     setLoading(true)
     setOpen(true)

      try {
        const response = await instance.post("api/auth/registration", data);
        if (response.status === 200) {
         
          toast.success("Successfully Signup!");
          setLoading(false)
          

          let token = response?.data?.token;
          let userData = response?.data?.newData;
          setUser(userData);
          setToken(token);
          handleTarget(token, userData);


          dispatch(SET_SIGNUP_EMAIL(values.email));

         

         
        }
      } catch (error) {
        console.log(error);
        toast.error(error?.response?.data?.message ?? error.message);
        setOpen(false)
        
        setLoading(false)
      }
    },
  });

  useEffect(() => {
    if (Usernamevalue !== undefined) {
      const checkUsername = async () => {
        try {
          const response = await instance.post(
            "api/auth/check_reseller_username",
            {
              username: Usernamevalue,
            }
          );
          if (response.data.data === false) {
          } else {
            navigate("/auth/sign-up");
          }
        } catch (error) {
          console.log(error);
          navigate("/auth/sign-up");
        }
      };

      checkUsername();
    }
  }, [Usernamevalue, navigate]);

  return (
    <div className="mb-16 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-start  ">
      <div className="mt-16 w-full max-w-full flex-col items-center overflow-y-auto md:pl-4 lg:pl-0 xl:max-w-[420px]">
        <h4 className="mb-2.5 text-4xl font-bold text-navy-700 dark:text-white">
          Sign Up
        </h4>
        <p className="mb-4 ml-1 text-base text-gray-600">
          Enter your details to sign up
        </p>

        <InputField
          variant="auth"
          extra="mb-3"
          label="Fullname*"
          placeholder="Alex smith"
          id="fullName"
          type="text"
          onChange={(event) => {
            formik.handleChange(event);
          }}
          value={formik.values.fullName}
          onBlur={formik.handleBlur}
          error={formik.touched.fullName && formik.errors.fullName}
        />

        <InputField
          variant="auth"
          extra="mb-3"
          label="Username*"
          placeholder="Alex001"
          id="username"
          type="text"
          onChange={(event) => {
            formik.handleChange(event);
            debouncedCheckUsername(event.target.value);
          }}
          value={formik.values.username}
          onBlur={formik.handleBlur}
          error={formik.touched.username && formik.errors.username}
        />

        <InputField
          variant="auth"
          extra="mb-3"
          label="Email*"
          placeholder="mail@simple.com"
          id="email"
          type="email"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.email}
          error={formik.touched.email && formik.errors.email}
        />
        {/* <InputField
          variant="auth"
          extra="mb-3"
          label="Phone Number*"
          placeholder="Phone Number"
          id="phoneNumber"
          type="tel"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.phoneNumber}
          error={formik.touched.phoneNumber && formik.errors.phoneNumber}
        /> */}
        <div className="my-2 flex flex-col gap-1">
          <label className="text-sm">Phone Number*</label>
          <PhoneInput
            country={"in"}
            inputStyle={{
              width: "100%",
              height: "40px",
            }}
            name="phoneNumber"
            id="phoneNumber"
            onChange={(e) => {
              formik.setFieldValue("phoneNumber", e);
            }}
            onBlur={formik.handleBlur}
            value={formik.values.phoneNumber}
          />
          {formik.touched.phoneNumber && formik.errors.phoneNumber ? (
            <div className="text-sm text-red-500">
              {formik.errors.phoneNumber}
            </div>
          ) : null}
        </div>
        {Usernamevalue ? (
          <></>
        ) : (
          <InputField
            variant="auth"
            extra="mb-3"
            label="Referral Code"
            placeholder="Optional"
            id="referedBy"
            type="text"
            onChange={(event) => {
              formik.handleChange(event);

              debouncedCheckReseller(event.target.value);
            }}
            onBlur={formik.handleBlur}
            value={formik.values.referedBy}
            error={formik.touched.referedBy && formik.errors.referedBy}
          />
        )}

        <div className="relative">
          <InputField
            variant="auth"
            extra="mb-3"
            label="Password*"
            placeholder="Min. 4 characters"
            id="password"
            type={showPassword ? "text" : "password"}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.password}
            error={formik.touched.password && formik.errors.password}
          />

          <button
            type="button"
            onClick={() => setShowPassword(!showPassword)}
            className="absolute right-3 top-[54px] -translate-y-1/2 transform"
          >
            {showPassword ? <FaEyeSlash size={16} /> : <FaEye size={16} />}
          </button>
        </div>

        <div className="w-full gap-2">
          <label
            id="demo-select-small-label"
            className="text-sm font-medium text-navy-700"
          >
            Business Category
          </label>
          <Select
            labelId="demo-select-small-label"
            id="demo-select-small"
            value={parentCategoryValue}
            onChange={handleChange}
            input={<OutlinedInput label="Tag" />}
            // renderValue={(selected) => selected.join(", ")}
            MenuProps={MenuProps}
            style={{ width: "100%", height: "38px" }}
          >
            {names.map((item, index) => (
              <MenuItem key={index} value={Object.keys(item)[0]}>
                {Object.keys(item)[0]}
              </MenuItem>
            ))}
          </Select>
        </div>

        <button
          className={
            "linear mt-2 w-full rounded-xl bg-brand-500 px-10 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
          }
          type="submit"
          onClick={formik.handleSubmit}
          disabled={loading}
        >
          {loading ? (
            <>
              <CircularProgress sx={{ color: "white" }} size={30} />
            </>
          ) : (
            "Sign up"
          )}
        </button>
        <div className="mt-4">
          <span className="text-sm font-medium text-navy-700 dark:text-gray-600">
            Already registered?
          </span>
          <Link
            to="/auth/sign-in"
            className="ml-1 text-sm font-medium text-brand-main hover:text-brand-600 dark:text-white"
          >
            Sign In
          </Link>
        </div>
      </div>

      <ExperienceCard
        open={open}
        handleClose={handleClose}
        cardRef={cardRef}
        username={formik.values.username}
        fullName={formik.values.fullName}
      />
      <CommonBackdrop
        loading={loading1}
        title={dynamicTitle}
        loadingColor={"white"}
      />
    </div>
  );
};

const ExperienceCard = ({ open, handleClose, cardRef, username, fullName }) => {
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
        <div className='flex justify-center items-center'>
        <CardOne
            ref={cardRef}
            username={username}
            experiencename={username}
            name={fullName}
          />
        </div>
        
        </Box>
      </Modal>
    </div>
  );
};

export default SignUp;