import { createSlice } from "@reduxjs/toolkit";

const INITIAL_STATE = {
    data: {
        getVisitor: {},
        getWeeklyVisitor: {},
        getYearlyVisitor: {},
        getRegionwiseVistor:[],
        getUniqueAndReturnVistor:[],
    }

};

const analyticsSlice = createSlice({
    name: "analytics",
    initialState: INITIAL_STATE,
    reducers: {
        SET_INITIAL_STATE_ANALYTICS: (state, action) => {
            state.data.getVisitor = action.payload;
        },
        SET_INITIAL_STATE_ANALYTICS_WEEKLY: (state, action) => {
            state.data.getWeeklyVisitor = action.payload;
        },
        SET_INITIAL_STATE_ANALYTICS_YEARLY: (state, action) => {
            state.data.getYearlyVisitor = action.payload;
        },
        SET_INITIAL_STATE_ANALYTICS_REGION_WISE: (state, action) => {
            state.data.getRegionwiseVistor= action.payload;
        },
        SET_INITIAL_STATE_ANALYTICS_UNIQUE_REGION_WISE: (state, action) => {
            state.data.getUniqueAndReturnVistor= action.payload;
        },
    
    },
});

export const {
    SET_INITIAL_STATE_ANALYTICS,
    SET_INITIAL_STATE_ANALYTICS_WEEKLY,
    SET_INITIAL_STATE_ANALYTICS_YEARLY,
    SET_INITIAL_STATE_ANALYTICS_REGION_WISE,
    SET_INITIAL_STATE_ANALYTICS_UNIQUE_REGION_WISE

} = analyticsSlice.actions;

export default analyticsSlice.reducer;
