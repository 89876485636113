import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { useState } from "react";
import { forwardRef } from "react";
import { instance } from "common/Instance";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AddCategoryModal({ isOpen, setIsOpen, handler }) {
  const handleClickOpen = () => {
    setIsOpen(true);
  };
  const userToken = useSelector((state) => state.auth.auth.token);

  const [category, setCategory] = useState("");

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleChange = (e) => {
    setCategory(e.target.value);
  };

  return (
    <React.Fragment>
      <Dialog
        open={isOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Add category for Immersify product"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <div className="mb-4">
              <label className="mb-2  block text-sm text-black" for="name">
                Name
              </label>
              <input
                value={category}
                onChange={handleChange}
                className="focus:shadow-outline w-full appearance-none rounded border px-3 py-2 leading-tight text-gray-800 shadow focus:border-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-200"
                id="name"
                type="text"
                placeholder="Enter category name"
              />
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <div className=" flex items-center justify-end">
            <buttton
              onClick={() => {
                handler(category);
              }}
              className="   ml-4 cursor-pointer  rounded-lg border border-none border-gray-300  bg-brand-main px-4 py-1.5 text-sm text-white outline-none transition duration-300 ease-in-out"
              type="submit"
            >
              Create
            </buttton>
            <buttton
              onClick={() => {
                setIsOpen(false);
              }}
              className="  ml-4 cursor-pointer  rounded-lg border border-none border-gray-300  bg-deleteBackground px-4 py-1.5 text-sm text-white outline-none transition duration-300 ease-in-out"
            >
              Cancel
            </buttton>
          </div>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
