import axios from "axios";
import { store } from "../redux/store";
import { SET_TOKENEXPIRED_LOADER } from "../redux/reducerSlice/authSlice";

const url = "https://api.immarsify.com/";
export const RZOR_KEY = "rzp_live_0aofQR7DYlgN28"; // live key
export const AWS_DATA_PREFIX = "https://assets.immarsify.com/";
export const AWS_STATIC_PREFIX = "https://static.immarsify.com/";

const instance = axios.create({
  baseURL: url,
});

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      store.dispatch(SET_TOKENEXPIRED_LOADER(true));
    }
    return Promise.reject(error);
  }
);

export { instance };
