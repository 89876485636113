import React, { useEffect, useState } from "react";
import ArImage from "../../../assets/Branding/ARbranding.jpg";
import { Switch } from "@mui/material";
import styled from "@emotion/styled";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import { instance } from "common/Instance";
import Card from "components/card";
import CommonBackdrop from "common/CommonBackdrop";
import { SET_BRANDING_DATA } from "../../../redux/reducerSlice/arSlice";
import { useDispatch } from "react-redux";
import { Skeleton } from "@mui/material";

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 11,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 6,
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,

    boxSizing: "border-box",
  },
}));

const INTITIAL_DATA = [
  {
    name: "Custom Welcome Screen",
    enabled: false,
    url: "",
    description:
      "Upload an image that will be displayed as a welcome screen when your AR exprience launced",
    descripttext: "",
  },
  {
    name: "Loading Screen",
    enabled: false,
    url: "",
    description:
      "Upload an image that will be displayed as a Loading screen when your AR exprience launced",
    descripttext: "",
  },
  {
    name: "Camera Watermark",
    enabled: false,
    url: "",
    description:
      "Uploadan image that will be displayed as a Camera Watermark  when your AR exprience launced",
    descripttext: "",
  },
  {
    name: "Helper Message and Icon",
    enabled: false,
    url: "",
    description:
      "Uplaod an image that will be displayed as a Helper Message and Icon when your AR exprience launced",
    descripttext: "",
  },
];

const Branding = () => {
  const userData = useSelector((state) => state.auth.auth.user);
  const userToken = useSelector((state) => state.auth.auth.token);
  const [isBrandingChanged, setIsBrandingChanged] = useState(false);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const branding = useSelector((state) => state.ar.brandingData);

  const handleFileChange = (event, key, name) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      const reader = new FileReader();
      reader.onloadend = () => {
        updateBranding(key, name, reader.result);
      };
      reader.readAsDataURL(selectedFile);
    }
  };

  const updateBranding = (key, name, value) => {
    let data = [...branding];
    let singleData = { ...data[key] };
    singleData[name] = value;
    data[key] = singleData;
    dispatch(SET_BRANDING_DATA(data));
    setIsBrandingChanged(true);
  };

  const handleVideoUpload = async (key, name, file) => {
    try {
      const files = file;
      if (files) {
        const imageFiles = files;
        const allowedTypes = [
          "image/jpg",
          "image/svg",
          "image/png",
          "image/jpeg",
          "image/gif",
        ];
        if (!allowedTypes.includes(imageFiles?.type)) {
          toast.error(
            "Invalid image file type. Only JPG, SVG, PNG, JPEG and  Gif files are allowed."
          );
          return;
        }
        const formData = new FormData();
        formData.append("file", imageFiles);
        formData.append("assetType", "branding");
        const response = await instance.post(
          `/api/ar/upload/${userData.username}/platformData`,
          formData,
          {
            headers: {
              authorization: `Bearer ${userToken}`,
              "Content-Type": "multipart/form-data",
            },
          }
        );
        if (response.status === 200) {
          toast.success("Successfully uploaded");
          updateBranding(key, name, response?.data?.url);
        }
      }
    } catch (error) {
      toast.error(error?.response?.data?.message ?? error.message);
    }
  };

  const fetchData = async () => {
    try {
      const response = await instance.get(
        `/api/user/user_details/${userData._id}`,
        {
          headers: {
            authorization: `Bearer ${userToken}`,
          },
        }
      );
      dispatch(SET_BRANDING_DATA(INTITIAL_DATA));
      if (response.status === 200) {
        if (response?.data?.data?.branding) {
          dispatch(
            SET_BRANDING_DATA(
              response?.data?.data?.branding?.length > 0
                ? response?.data?.data?.branding
                : INTITIAL_DATA
            )
          );
        }
      }
    } catch (error) {
      toast.error(error?.response?.data?.message ?? error.message);
      dispatch(SET_BRANDING_DATA(INTITIAL_DATA));
    }
  };

  const saveAllData = async () => {
    setLoading(true);
    try {
      const response = await instance.post(
        `/api/user/update_user_details/${userData._id}`,
        { branding },
        {
          headers: {
            authorization: `Bearer ${userToken}`,
          },
        }
      );
      if (response.status === 200) {
        toast.success(response.data?.message);
        setIsBrandingChanged(false);
        fetchData();
        setLoading(false);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message ?? error.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [userData]);

  return (
    <div className="mb-2 p-4">
      {isBrandingChanged && (
        <div className="flex items-center justify-end rounded-md bg-[#3d2fa9] p-2">
          <button
            onClick={saveAllData}
            className="rounded-lg border border-gray-400 bg-white px-4 py-1 font-semibold text-gray-900"
          >
            Save
          </button>
        </div>
      )}
      {branding && branding?.length > 0 ? (
        <div className=" mt-3 grid grid-cols-1 gap-4 md:grid-cols-3 lg:grid-cols-3 2xl:grid-cols-4 3xl:grid-cols-4">
          {branding?.map((item, key) => {
            return (
              <Card
                extra={
                  "w-full m-1 h-[full]  cursor-pointer   rounded-2xl bg-white p-0 pb-4 shadow-8xl   "
                }
              >
                <div className="mt-0 flex  w-[100%] items-center justify-start gap-4 px-4  py-2">
                  <AntSwitch
                    checked={item?.enabled}
                    name="enabled"
                    onChange={(e) => {
                      console.log("check", e.target.checked);
                      updateBranding(key, "enabled", e.target.checked);
                    }}
                    inputProps={{ "aria-label": "ant design" }}
                  />
                  <p className="text-sm font-bold">{item?.name}</p>
                </div>

                <div className=" flex items-center justify-between p-2">
                  <div className="-mt-4 flex flex-col items-start justify-center gap-8">
                    <p className="text-xs font-bold">{item?.description}</p>

                    <p className="text-sm ">
                      Supported Format :{" "}
                      <span className="font-semibold ">GIF</span>
                    </p>
                    <div className="  mt-6 flex h-[5px] w-[40px] items-start justify-start">
                      <label className="text-blue border-blue hover:bg-blue flex w-64 cursor-pointer flex-col items-center rounded-lg border bg-white px-4  py-4  tracking-wide hover:text-gray-500">
                        <svg
                          className="h-6 w-6"
                          fill="currentColor"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                        >
                          <path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" />
                        </svg>
                        <input
                          className="hidden"
                          type="file"
                          accept={
                            "image/gif" ||
                            "image/svg" ||
                            "image/png" ||
                            "image/jpg" ||
                            "image/jpeg"
                          }
                          onChange={(e) => {
                            handleFileChange(e, key, "url");
                            const selectedFile = e.target.files[0];
                            const reader = new FileReader();
                            console.log("reader", reader);
                            reader.onloadend = () => {
                              updateBranding(key, "url", reader.result);
                            };
                            reader.readAsDataURL(selectedFile);
                            handleVideoUpload(key, "url", e.target.files[0]);
                          }}
                        />
                      </label>
                    </div>
                  </div>

                  <div className="relative h-[200px]  min-w-[100px]  rounded-2xl   border-[5px]  border-[#000]  ">
                    <div className="absolute left-1/2 top-0 h-[6px] w-[40%] -translate-x-1/2 transform rounded-b-md border-solid border-black bg-[#000]">
                      {" "}
                    </div>
                    {item?.url ? (
                      <img
                        src={item?.url}
                        alt="Preview"
                        className="h-full w-full rounded-lg  object-cover "
                      />
                    ) : (
                      <img
                        src={ArImage}
                        alt="Preview"
                        className="h-full w-full rounded-lg  object-cover"
                      />
                    )}
                  </div>
                </div>

                <div className="mt-4 w-full flex-col items-center justify-start gap-2 px-2">
                  <label className="text-xs font-semibold ">Note</label>
                  <input
                    className="w-full rounded-md border-2 border-gray-200 px-1 py-2 text-xs "
                    value={item?.descripttext}
                    placeholder="Enter the Note here"
                    name="descripttext"
                    onChange={(e) => {
                      updateBranding(key, "descripttext", e.target.value);
                    }}
                  />
                </div>
              </Card>
            );
          })}
          <CommonBackdrop
            loading={loading}
            title={"Uploading Loading Screen"}
            description={"Please do not refresh the page"}
          />
        </div>
      ) : (
        <div>
          {/* {!branding && ( */}
          <div className=" mt-3 grid grid-cols-1 gap-4 md:grid-cols-3 lg:grid-cols-3 2xl:grid-cols-4 3xl:grid-cols-4">
            {[...Array(4)].map((item) => {
              return (
                <Skeleton
                  variant="rectangular w-[270px]"
                  height={300}
                  className="rounded-md  "
                  animation="wave"
                />
              );
            })}
          </div>
          {/* )} */}
        </div>
      )}
    </div>
  );
};

export default Branding;
