import * as React from 'react';
import Checkbox from '@mui/material/Checkbox';
import { instance } from 'common/Instance';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import {
    
    SET_USERDATA,
  } from "../../../redux/reducerSlice/authSlice";
const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

export default function SendToWhatsapp() {
    const user = useSelector((state) => state.auth.auth.user);
    const userToken = useSelector((state) => state.auth.auth.token);
const dispatch = useDispatch()
const updateHandler  = async( checked)=>{
    try {
        const response = await instance.post(
          `/api/user/update_user_details/${user._id}`,
          {...user, isAllowedWhatsapp: checked},
          {
            headers: {
              authorization: `Bearer ${userToken}`,
            },
          }
        );
        if (response.status === 200) {
          toast.success("Profile updated successfully");
          console.log(response?.data?.data)
          dispatch(SET_USERDATA(response?.data?.data));
        }
      } catch (error) {

        console.log(error)
        toast.error("Failed to update profile");
      } 
}


  return (<span>

     <span>Auto message</span> <Checkbox checked={user?.isAllowedWhatsapp ? true : false} onChange={(e)=>{updateHandler(e.target.checked)}} {...label} />

  </span>
    
  );
}
