import React from "react";
import Card from "./components/Card";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Modal from "../modal/index";

const arroftemplate = [
  // {
  //   src: "https://content.jdmagicbox.com/comp/hyderabad/q7/040pxx40.xx40.151005203946.l7q7/catalogue/peth-puja-restaurants-attapur-hyderabad-fast-food-4ec3aqp.jpg",
  //   name: "Peth Pooja",
  // },
  {
    src: "https://images.unsplash.com/photo-1517248135467-4c7edcad34c4?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8cmVzdGF1cmFudHN8ZW58MHx8MHx8fDA%3D",
    name: "Restaurant",
  },
];
const Restaurant = ({ name }) => {
  const navigate = useNavigate();
  return (
    <div className="mt-8 flex  flex-col justify-center  gap-6">
      <div className="border-b-2"></div>
      <div className="mt-6 flex h-fit flex-wrap items-center justify-center gap-4">
        {arroftemplate.map((item) => (
          <Card {...item} />
        ))}
      </div>

      {/* <Modal /> */}
    </div>
  );
};

export default Restaurant;
