import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Radio from "@mui/material/Radio";

import { Backdrop, CircularProgress, TextField } from "@mui/material";
import {
  SET_TARGET_HIDDEN_VALUE,
  SET_ALTERNATE_TARGET_IMG,
  DELETE_ALTERNATE_TARGET_IMG,
  SET_MIND_FORMULTIPLE_TARGET_IMG,
  SET_INITIAL_ALTERNATE_TARGET_IMG,
  SET_TARGET_IMG,
  UPDATE_TAP_TO_VIEW,
} from "../../../../../redux/reducerSlice/arSlice";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";

import * as mind from "mind-ar/dist/mindar-image.prod";
import { MdDeleteForever } from "react-icons/md";
import CommonBackdrop from "common/CommonBackdrop";
import { v4 as uuidv4 } from "uuid";

import Box from "@mui/material/Box";

import Modal from "@mui/material/Modal";

import { MdOutlineClear } from "react-icons/md";
import toast from "react-hot-toast";
import { loadTexture } from "../EditorUtils";
import { instance } from "common/Instance";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  outline: "none",
  borderRadius: 2,
  border: "none",
};
const styleDelete = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  height: 180,
  bgcolor: "background.paper",
  borderRadius: "10px",
  boxShadow: 24,
  outline: "none",
  border: "none",
};

function TargetEditorSection({ whatsClicked }) {
  const dispatch = useDispatch();
  const ardata = useSelector((state) => state.ar.data);

 
  const [open, setOpen] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedTarget, setSelectedTarget] = useState(null)
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleCloseDeleteModal = () => setOpenDeleteModal(false);

  const [allImagesData, setAllImagesData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [deleteUrl, setDeleteUrl] = useState("");
  const compiler = new mind.Compiler();
  const [compliningProgress, setCompilingProgress] = useState(0);
  const userToken = useSelector((state) => state.auth.auth.token);
  const user = useSelector((state) => state.auth.auth.user);

  let AllImagesURl = ardata?.multiTarget?.Targetimages?.map(
    (element) => element?.src + "?not-from-cache-please"
  );

  const uploadFile = async (file, assetType) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("assetType", assetType);
    try {
      const response = await instance.post(
        `/api/ar/upload/${user?.username}/${ardata?.name}`,
        formData,
        {
          headers: {
            authorization: `Bearer ${userToken}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return response.data.url;
    } catch (error) {
      console.error("Error uploading file:", error);
      setLoading(false);
      setLoading1(false);
    }
  };

  const createMindForAllImages = async (currentImageUrl) => {
    const initialDataofArray =
      ardata?.targetImage?.src + "?not-from-cache-please";
    if (!AllImagesURl) {
      AllImagesURl = [];
    }

    if (AllImagesURl.length === 0) {
      AllImagesURl.unshift(initialDataofArray);
    }

    const allImages = [];

    if (AllImagesURl?.includes(`${currentImageUrl}`)) {
      let newImageURL = AllImagesURl.filter(
        (element) => element !== currentImageUrl
      );

      AllImagesURl = [...newImageURL];
    } else {
      AllImagesURl?.push(currentImageUrl);
    }

    const fetchPromises = AllImagesURl?.map(async (url) => {
      const response = await fetch(url);
      const blob = await response.blob();
      const file = new File([blob], "targetImage." + blob?.type.split("/")[1], {
        type: blob?.type,
      });

      const image = new Image();
      image.src = URL.createObjectURL(file);

      await new Promise((resolve) => {
        image.onload = () => {
          allImages.push(image);
          resolve();
        };
      });
    });

    await Promise.all(fetchPromises);

    setAllImagesData(allImages);

    // const checkImagesDataAndCompile = async () => {
    //   while (allImagesData.length === 0) {
    //     // Wait for a short period before checking again
    //     await new Promise((resolve) => setTimeout(resolve, 100)); // 100ms delay
    //   }
    //   // Once the condition is met, compile the images
    //   await compileImageForMind();
    // };

    // // Start checking the condition
    // checkImagesDataAndCompile();
  };

  useEffect(() => {
    const compileImages = async () => {
      console.log(allImagesData, "allimageData");
      if (allImagesData.length > 0) {
        await compileImageForMind();
      }
    };

    compileImages();
  }, [allImagesData]);

  const compileImageForMind = async () => {
    const dataList = await compiler.compileImageTargets(
      allImagesData,
      (progress) => {
        setCompilingProgress(Math.round(progress));
      }
    );
    setCompilingProgress(0);

    const exportedBuffer = await compiler.exportData();
    const blob = new Blob([exportedBuffer]);
    const mindFile = new File([blob], "target.mind", { type: blob.type });

    const mindUrl = await uploadFile(mindFile, "mind");

    console.log(mindUrl, "mindUrl");
    dispatch(SET_MIND_FORMULTIPLE_TARGET_IMG(mindUrl));

    toast.success("Mind Created Successfully");
    setLoading1(false);
    setLoading(false);
    
    handleClose();
    handleCloseDeleteModal();
  };




  useEffect(() => {
    if (ardata?.multiTarget?.Targetimages) {
      const initialSelected = ardata?.multiTarget?.Targetimages.find(element => element?.isTaptoView)?.id;
      setSelectedTarget(initialSelected);
    }
  }, [ardata]);

  const handleRadioChange = (data,id) => {
    setSelectedTarget(id); 

    dispatch(UPDATE_TAP_TO_VIEW(id));
    dispatch(SET_TARGET_IMG(data));
  };

  return (
    <div>
      {whatsClicked?.userData?.type === "target_img" && (
        <>
          <div className="mt-2 flex flex-col justify-center gap-2">
         
            {whatsClicked?.userData?.type === "target_img" && (
              <div className=" mx-2 flex flex-col rounded-md border-2  bg-white ">
                <div className="flex flex-col ">
                  <p className="text-md  bg-violet-400 bg-gradient-to-r from-indigo-500 from-10% via-blue-500 via-50% to-green-500 to-90% pt-1 text-center font-bold text-white ">
                    {" "}
                    MULTI TARGET{" "}
                  </p>

                  <span className="my-1 p-2 text-center  text-xs font-medium text-brand-main">
                    By uploading alternate's target images, you will be able to
                    view the same AR on different target images
                  </span>

                  {ardata?.multiTarget?.Targetimages?.length > 0 && (
                    <p className="px-2 pb-2 pt-1 text-center text-sm font-bold text-gray-700">
                      Target Images
                    </p>
                  )}

                  <div className="flex flex-col  gap-2 px-2">
                    {ardata?.multiTarget?.Targetimages?.map(
                      (element) => (
                        <div className="flex  items-center justify-between">
                          <img
                            src={element?.src}
                            alt="Hello"
                            className="h-full w-[100px] rounded-md  object-cover"
                          />

                          <div className="flex justify-center gap-4 items-center ">
                            {/* <FormControlLabel
                            labelPlacement="start"
                             label={<span style={{ fontSize: '12px' }}>Main Target</span>}
                              control={
                                <Radio
                                checked={selectedTarget === element?.id}
                                onChange={() => handleRadioChange(element, element?.id)}
                              />
                              }
                            
                            /> */}

                            <div
                              className="cursor-pointer"
                              onClick={() => {
                                setDeleteUrl(element?.src);
                                setOpenDeleteModal(true);
                              }}
                            >
                              <MdDeleteForever color="orange" size={26} />
                            </div>
                          </div>
                        </div>
                      )
                    )}
                  </div>
                </div>

                <div className="mb-1 mt-4 flex  flex-col items-center py-1 ">
                  <button
                    className="rounded-md bg-brand-main px-4 py-2 text-white  "
                    onClick={() => handleOpen()}
                  >
                    Add Target Image
                  </button>
                </div>
              </div>
            )}
          </div>
        </>
      )}

      <UploadTarget
        open={open}
        handleClose={handleClose}
        createMindForAllImages={createMindForAllImages}
        uploadFile={uploadFile}
        loading={loading}
        setLoading={setLoading}
        compliningProgress={compliningProgress}
      />

      <DeleteTarget
        open={openDeleteModal}
        handleClose={handleCloseDeleteModal}
        createMindForAllImages={createMindForAllImages}
        deleteUrl={deleteUrl}
        setLoading1={setLoading1}
        loading1={loading1}
        compliningProgress={compliningProgress}
      />
    </div>
  );
}

export default TargetEditorSection;

function UploadTarget({
  open,
  handleClose,
  createMindForAllImages,
  uploadFile,
  loading,
  setLoading,
  compliningProgress,
}) {
  const dispatch = useDispatch();

  const [preview, setPreview] = useState([]);
  const [fileData, setFileData] = useState();
  const arData = useSelector((state) => state.ar.data);

  useEffect(() => {
    setPreview([]);
    setFileData();
  }, [open]);

  // const handleFileUpload = async (e) => {
  //   const files = e?.target?.files;
  //   if (files) {
  //     const allowedTypes = ["image/jpeg", "image/png", "image/jpg"];
  //     if (!allowedTypes.includes(files[0]?.type)) {
  //       toast.error("Please select a .jpg, .jpeg, or .png file");
  //       return;
  //     }

  //     const fileSizeInMB = files[0].size / (1024 * 1024);
  //     if (fileSizeInMB > 2) {
  //       toast.error("File size should be less than 2 MB");
  //       return;
  //     }

  //     const imageFiles = Array.from(files).filter((file) =>
  //       file.type.startsWith("image/")
  //     );
  //     const imageElements = imageFiles.map((file) => {
  //       const image = new Image();

  //       image.src = URL.createObjectURL(file);
  //       return image;
  //     });

  //     setPreview(imageElements);

  //     setFileData(files);
  //     console.log(preview);
  //   }
  // };

  const handleFileUpload = async (e) => {
    const files = e?.target?.files;
    if (files) {
      const allowedTypes = ["image/jpeg", "image/png", "image/jpg"];
      if (!allowedTypes.includes(files[0]?.type)) {
        toast.error("Please select a .jpg, .jpeg, or .png file");
        return;
      }

      const fileSizeInMB = files[0].size / (1024 * 1024);
      if (fileSizeInMB > 2) {
        toast.error("File size should be less than 2 MB");
        return;
      }

      const imageFiles = Array.from(files).filter((file) =>
        file.type.startsWith("image/")
      );

      const imageElements = await Promise.all(
        imageFiles?.map((file) => {
          return new Promise((resolve) => {
            const image = new Image();
            image.src = URL.createObjectURL(file);
            image.onload = () => {
              resolve(image);
            };
          });
        })
      );

      setPreview(imageElements);
      setFileData(files);
    }
  };

  const createTarget = async (blobPreview) => {
    setLoading(true);

    try {
      const currentImageUrl = await uploadFile(fileData[0], "image");

      const texture = await loadTexture(currentImageUrl);
      const textureWidth = texture.image.width;
      const textureHeight = texture.image.height;

      let aspectRatio;

      if (textureWidth >= textureHeight) {
        aspectRatio = textureWidth / textureHeight;
        dispatch(
          SET_ALTERNATE_TARGET_IMG({
            id: uuidv4(),
            src: currentImageUrl,
            scale: { x: aspectRatio * 0.8, y: 0.75, z: 0.8 },
            isHidden: false,
            isTaptoView:false,
          })
        );
      }
      if (textureHeight > textureWidth) {
        aspectRatio = textureHeight / textureWidth;
        dispatch(
          SET_ALTERNATE_TARGET_IMG({
            id: uuidv4(),
            src: currentImageUrl,
            scale: { x: 0.8, y: aspectRatio * 0.75, z: 0.8 },
            isHidden: false,
            isTaptoView:false,

          })
        );
      }

      toast.success("Target Image Uploaded Successfully");

      const newCurrentImageIUrl = currentImageUrl + "?not-from-cache-please";

      createMindForAllImages(newCurrentImageIUrl);
    } catch (error) {
      toast.error(error?.response?.data?.message ?? error.message);
      setLoading(false);
    }
  };

  return (
    <div>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="flex items-center justify-between rounded-t-lg  bg-gray-200  p-2">
            <p className="text-sm font-bold ">Immarsify</p>
            <span onClick={handleClose} className="cursor-pointer">
              <MdOutlineClear size={20} />
            </span>
          </div>

          <div className=" flex h-full w-full flex-col items-center justify-center gap-2 py-6  ">
            {preview.length > 0 && (
              <img
                src={preview[0]?.src}
                alt="imageAlt"
                className="  h-[150px] w-[200px] object-contain  px-2"
              />
            )}

            <label
              htmlFor="fileInput"
              className="flex  items-center justify-center rounded-md    px-2 pb-2 text-white  dark:!border-navy-700 lg:pb-0"
            >
              <input
                type="file"
                id="fileInput"
                accept="image/png, image/jpeg, image/jpg"
                className="hidden"
                onChange={(e) => {
                  handleFileUpload(e);
                }}
              />

              {preview.length === 0 ? (
                <button
                  type="button"
                  onClick={() => document.getElementById("fileInput").click()}
                  className="rounded-md bg-[#3d2fa9]  px-3 py-2  text-sm text-white"
                >
                  Select Image
                </button>
              ) : (
                <button
                  type="button"
                  onClick={() => {
                    if (
                      arData?.multiTarget?.Targetimages[0]?.src !==
                      arData?.targetImage?.src
                    ) {
                      dispatch(
                        SET_INITIAL_ALTERNATE_TARGET_IMG(arData?.targetImage)
                      );
                    }

                    createTarget();
                  }}
                  className="rounded-md bg-[#3d2fa9] px-3 py-2 text-sm text-white"
                >
                  Upload Image
                </button>
              )}
            </label>

            <p className="text-md mt-2 font-bold">
              Supported Format :- JPEG, JPG, PNG
            </p>
            <span className=" text-xs ">
              Image Should be less than or equal to 2 mb
            </span>
          </div>

          <CommonBackdrop
            title="Uploading Target Image"
            description={"Please don't referesh page, creating target image"}
            value={compliningProgress}
            loading={loading}
          />
        </Box>
      </Modal>
    </div>
  );
}

function DeleteTarget({
  open,
  handleClose,
  createMindForAllImages,
  deleteUrl,
  setLoading1,
  loading1,
  compliningProgress,
}) {
  const dispatch = useDispatch();
  const DeleteTargetImage = (url) => {
    setLoading1(true);
    dispatch(DELETE_ALTERNATE_TARGET_IMG(url));

    toast.success("Target Image Deleted Successfully");

    const newUrl = url + "?not-from-cache-please";
    createMindForAllImages(newUrl);
  };

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={styleDelete} className="rounded-lg">
        <div className="flex items-center justify-between rounded-t-lg  bg-gray-200 p-2">
          <p className="text-sm font-bold ">Immarsify</p>
          <span onClick={handleClose} className="cursor-pointer">
            <MdOutlineClear size={20} />
          </span>
        </div>
        <p className="text-md p-4">Are you sure, you want to delete ?</p>

        <div className="absolute bottom-2 right-2 mt-2 flex items-center justify-center gap-2 p-2 ">
          <button
            className="rounded-lg border bg-[#3d2fa9] px-4 py-2 text-white "
            onClick={() => DeleteTargetImage(deleteUrl)}
          >
            yes
          </button>
          <button
            className="rounded-lg border border-gray-500 px-4 py-2 text-gray-900 "
            onClick={handleClose}
          >
            No
          </button>
        </div>

        <CommonBackdrop
          title="Deleting Target Image"
          description={"Please don't referesh page, Deleting target image"}
          value={compliningProgress}
          loading={loading1}
        />
      </Box>
    </Modal>
  );
}
