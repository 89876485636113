import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { Formik, Form, Field, ErrorMessage, useFormik } from "formik";
import * as Yup from "yup";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import Modal from "@mui/material/Modal";
import { instance } from "common/Instance";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import { IconButton, Tooltip } from "@mui/material";
import { CiCircleInfo } from "react-icons/ci";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  borderRadius: 2,
  boxShadow: 24,
};

export default function ConnectShopfiyModal({ isOpen, setIsOpen }) {
  const handleOpen = () => setIsOpen(true);
  const handleClose = () => setIsOpen(false);
  const authToken = useSelector((state) => state.auth.auth.token);
  const fettchProducts = async (storeUrl) => {
    try {
      const response = await instance.get(`api/products/fetch_products`, {
        params: {
          url: storeUrl,
        },
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });

      console.log("API Response:", response);

      if (response.status === 200) {
        setIsOpen(false);
        toast.success("Shopify connected successfully");
        // window.open(response.data.installUrl);

        handleClose();
      } else {
        toast.error("Failed to connect to Shopify");
      }
    } catch (error) {
      console.error("API Error:", error);
      toast.error(error?.response?.data?.message);
    }
  };
  const handleSubmit = async (values, resetForm) => {
    try {
      const response = await instance.get(`api/products/auth_store_link`, {
        params: {
          appName: values.appName,
          consumerKey: values.cosumerKey,
          consumerSecret: values.consumerSecret,
          url: values.storeUrl,
        },
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });

      console.log("API Response:", response);

      if (response.status === 200) {
        setIsOpen(false);
        toast.success("Shopify connected successfully");
        // window.open(response.data.installUrl);
        resetForm();
        fettchProducts(values.storeUrl);

        handleClose();
      } else {
        toast.error("Failed to connect to Shopify");
      }
    } catch (error) {
      console.error("API Error:", error);
      toast.error(error.response.data.message);
    }
  };
  const validationSchema = Yup.object().shape({
    appName: Yup.string().required("App Id is required"),
    storeUrl: Yup.string().required("App url is required"),
    consumerSecret: Yup.string().required("App Secret is required"),
    cosumerKey: Yup.string().required("Shop Name is required"),
  });

  const formik = useFormik({
    initialValues: {
      appName: "",
      cosumerKey: "",
      consumerSecret: "",
      storeUrl: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => {
      await handleSubmit(values, resetForm);
      console.log(values, "form is sumitted");
    },
  });
  return (
    <Dialog
      open={isOpen}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      fullWidth={true}
      aria-describedby="alert-dialog-slide-description"
      sx={{ margin: 0 }}
    >
      <DialogTitle className="font-bold">
        {"Connect to Woocommerce"}
      </DialogTitle>
      <DialogContent sx={{ padding: 0 }}>
        <DialogContentText id="alert-dialog-slide-description">
          <div className="w-full text-black">
            <form className="rounded bg-white px-8 py-4  shadow-md ">
              <div className="mb-4">
                <label className="mb-2 block text-sm" for="appurl">
                  App name
                  <Tooltip title="Your wordpress app name" arrow>
                    <IconButton>
                      <CiCircleInfo size={15} />
                    </IconButton>
                  </Tooltip>
                </label>
                <input
                  onChange={formik.handleChange}
                  value={formik.values.appName}
                  onBlur={formik.handleBlur}
                  className="focus:shadow-outline w-full appearance-none rounded border px-3 py-2 leading-tight text-gray-700 shadow focus:border-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-200"
                  id="appName"
                  type="text"
                  name="appName"
                  placeholder="Enter app name"
                />
                {formik.touched.appName && formik.errors.appName ? (
                  <div className="text-sm text-red-500">
                    {formik.errors.appName}
                  </div>
                ) : null}
              </div>
              <div className="mb-4">
                <label className="mb-2 block text-sm" for="appid">
                  Consumer Key
                </label>
                <input
                  onChange={formik.handleChange}
                  value={formik.values.cosumerKey}
                  onBlur={formik.handleBlur}
                  className="focus:shadow-outline w-full appearance-none rounded border px-3 py-2 leading-tight text-gray-700 shadow focus:border-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-200"
                  id="cosumerKey"
                  type="text"
                  name="cosumerKey"
                  placeholder="App id"
                />
                {formik.touched.cosumerKey && formik.errors.cosumerKey ? (
                  <div className="text-sm text-red-500">
                    {formik.errors.cosumerKey}
                  </div>
                ) : null}
              </div>
              <div className="mb-4">
                <label className="mb-2  block  text-sm" for="appsecret">
                  Consumer Secret
                </label>
                <input
                  onChange={formik.handleChange}
                  value={formik.values.consumerSecret}
                  onBlur={formik.handleBlur}
                  className="focus:shadow-outline w-full appearance-none rounded border px-3 py-2 leading-tight text-gray-700 shadow focus:border-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-200"
                  id="consumerSecret"
                  name="consumerSecret"
                  type="text"
                  placeholder="Enter app secret"
                />
                {formik.touched.consumerSecret &&
                formik.errors.consumerSecret ? (
                  <div className="text-sm text-red-500">
                    {formik.errors.consumerSecret}
                  </div>
                ) : null}
              </div>
              <div className="mb-4">
                <label className="mb-2 block   text-sm" for="shopname">
                  App Url
                  <Tooltip title="Your wordpress app url" arrow>
                    <IconButton>
                      <CiCircleInfo size={15} />
                    </IconButton>
                  </Tooltip>
                </label>
                <input
                  onChange={formik.handleChange}
                  value={formik.values.storeUrl}
                  onBlur={formik.handleBlur}
                  className="focus:shadow-outline w-full appearance-none rounded border px-3 py-2 leading-tight text-gray-700 shadow focus:border-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-200"
                  id="storeUrl"
                  type="text"
                  name="storeUrl"
                  placeholder="Enter shop url"
                />{" "}
                {formik.touched.storeUrl && formik.errors.storeUrl ? (
                  <div className="text-sm text-red-500">
                    {formik.errors.storeUrl}
                  </div>
                ) : null}
              </div>
              <div className=" flex items-center justify-end"></div>
            </form>
          </div>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <div className=" flex items-center justify-end">
          <buttton
            className="   ml-4 cursor-pointer  rounded-lg border border-none border-gray-300  bg-brand-main px-4 py-1.5 text-sm text-white outline-none transition duration-300 ease-in-out"
            type="submit"
            onClick={formik.handleSubmit}
          >
            Connect
          </buttton>
          <buttton
            onClick={() => {
              setIsOpen(false);
            }}
            className="  ml-4 cursor-pointer  rounded-lg border border-none border-gray-300  bg-deleteBackground px-4 py-1.5 text-sm text-white outline-none transition duration-300 ease-in-out"
          >
            Close
          </buttton>
        </div>
      </DialogActions>
    </Dialog>
  );
}
