import React from "react";

const CompanyDetails = () => {
  return (
    <div className="mt-12">
      <p className='font-bold'>Best Regards</p>
      <p className='font-bold'> Team Immarsify</p>
    </div>
  );
};

export default CompanyDetails;
