import React, { useState, useEffect } from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, Box, Chip } from "@mui/material";
import { FaPlus, FaEdit, FaTrash } from "react-icons/fa";
import { IoMdClose } from "react-icons/io";
// import { errorToaster, successToaster } from "../../ReactToastify";

import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import toast from "react-hot-toast";
import { instance } from "common/Instance";
import { SET_LABLES } from "../../../../../../redux/reducerSlice/enquiry";
// ConfirmationModal Component
const defaultLables = {
  importance: [{ _id: 1, name: "Lead", type: "importance", color: "#FF0000", default: true },],
  status: [{ _id: 2, name: "Check", type: "status", color: "#00FF00", default: true },],
  source: [{ _id: 3, name: "Facebook", type: "source", color: "#0000FF", default: true },]
}

const ConfirmationModal = ({ open, onClose, onConfirm, labelName }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Confirm Deletion</DialogTitle>
      <DialogContent>
        <Typography>Are you sure you want to delete the label "{labelName}"?</Typography>
      </DialogContent>
      <DialogActions>
        <button onClick={onClose} className="px-4 py-1 bg-gray-200 rounded-md hover:bg-gray-300">
          Cancel
        </button>
        <button
          onClick={() => {
            onConfirm();
            onClose();
          }}
          className="px-4 py-1 bg-red-600 text-white rounded-md hover:bg-red-400"
       
        >
          Delete
        </button>
      </DialogActions>
    </Dialog>
  );
};
// LabelManager Component
const LabelManager = ({
  openLabel,
  setOpenLabel,
  
  

}) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [labels, setLabels] = useState([])
  const [editingLabel, setEditingLabel] = useState(null);
  const [newLabel, setNewLabel] = useState({
    name: "",
    type: "status",
    color: "#000000",
  });
  const [selectedType, setSelectedType] = useState("All");
  const [filteredLabels, setFilteredLabels] = useState([]);
  const [labelToDelete, setLabelToDelete] = useState(null);

  const authToken = useSelector((state) => state?.auth?.auth?.token);
  const dispatch  = useDispatch()
  const fetchLabels = async () => {
    try {
      const response = await instance.get('api/inquiries/get_inquiry_labels',    {
        headers: {
          authorization: `Bearer ${authToken}`,
        },
      });
      if(response?.data?.data){
        const data = response?.data?.data;
        console.log(data, "this isdata")
        setLabels(data);


        
       const importanceLables =  data.filter((data)=>data.type==="importance")
       const statusLables =  data.filter((data)=>data.type==="status")
       const sourceLables =  data.filter((data)=>data.type==="source")

        const updatedLables  = {
          status:[...defaultLables.status,...statusLables], 
          importance:[...defaultLables.importance,  ...importanceLables], 
          source:[ ...defaultLables.source, ...sourceLables]
        }
console.log(updatedLables, 'got data from api')

dispatch(SET_LABLES(updatedLables))


      }else{
        setLabels([]);
        dispatch(SET_LABLES(defaultLables))
      }

      console.log(response)
    } catch (error) {
      setLabels([]);
dispatch(SET_LABLES(defaultLables))

      console.error('Failed to fetch inquiry labels:', error);
    }
  };


  useEffect(()=>{
    fetchLabels()
  },[])
  useEffect(() => {
    if (selectedType === "All") {
      setFilteredLabels(labels);
      return;
    }
    setFilteredLabels(labels.filter((label) => label.type === selectedType));
  }, [labels, selectedType]);
  const handleOpenDialog = (label = null) => {
    if (label) {
      setEditingLabel(label);
      setNewLabel({
        name: label.name,
        type: label.type,
        color: label.color,
      });
    } else {
      setEditingLabel(null);
      setNewLabel({ name: "", type: "status", color: "#000000" });
    }
    setDialogOpen(true);
  };
  const handleCloseDialog = () => {
    setDialogOpen(false);
    setEditingLabel(null);
    setNewLabel({ name: "", type: "status", color: "#000000" });
  };
  const handleSaveLabel = async () => {
    if (newLabel.name.trim() === "") {
      // errorToaster("Label cannot be empty!");
      toast.error("Label can not be empty")
      return;
    }
    const config = {
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      },
    };
    try {
      if (editingLabel) {
        // Update existing label
        await instance.post(
          `api/inquiries/update_inquiry_labels/${editingLabel._id}`,
          newLabel,
          config
        );
        // successToaster("Label updated successfully!");
toast.success("Label updated successfully!")
      
      } else {
        // Create new label
        await instance.post(
          `api/inquiries/create_inquiry_labels`,
          newLabel,
          config
        );

        toast.success("Label added successfully!")
        // successToaster("Label added successfully!");
      }
      // Fetch updated labels
      fetchLabels();

      handleCloseDialog();
    } catch (error) {
      toast.error("Error saving label. Please try again.");
      console.log("Error saving label:", error);
    }
  };
  const handleOpenDeleteDialog = (label) => {
    setLabelToDelete(label);
    setDeleteDialogOpen(true);
  };
  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false);
    setLabelToDelete(null);
  };
  const handleConfirmDelete = async () => {
    if (labelToDelete) {
      try {
        await instance.delete(`api/inquiries/delete_inquiry_labels/${labelToDelete._id}`, {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        });
        toast.success("Label deleted successfully!");
        fetchLabels();
        // setLabels(labels.filter((label) => label.id !== labelToDelete._id));
      } catch (error) {
        toast.error("Error deleting label. Please try again.");
        console.error("Error deleting label:", error);
      }
    }
  };
  return (
    <>
      <Dialog open={openLabel} fullWidth>
        <div className="flex justify-between items-center p-4">
          <h2 className="text-xl font-bold">Labels</h2>
          <button
            onClick={() => setOpenLabel(false)}
            className="text-gray-500 hover:text-gray-700"
          >
            <IoMdClose size={24} />
          </button>
        </div>
        <hr className="border-t border-gray-200" />
        <div className="p-4">
          <div className="mb-4 flex items-center justify-between">
            <select
              className="p-2 border rounded-md"
              value={selectedType}
              onChange={(e) => setSelectedType(e.target.value)}
            >
              <option value="All">All</option>
              <option value="status">Status</option>
              <option value="source">Source</option>
              <option value="importance">Importance</option>
            </select>
            <button
              className="flex items-center bg-brand-main text-white px-3 py-1 rounded-lg hover:bg-brand-400"
              onClick={() => handleOpenDialog()}
            >
              <FaPlus className="mr-2" /> Add Label
            </button>
          </div>
          <div className="flex flex-col gap-4">

            {
           ( selectedType === "All" || selectedType === "status") &&  defaultLables.status.map((label)=>{
                return  <div
                key={label.id}
                className="flex items-center justify-between p-2 rounded-lg shadow-lg"
              >
                <Chip
                  label={label.name}
                  style={{ backgroundColor: label.color, color: "#fff" }}
                />
                {!label?.default && (
                  <div className="flex items-center space-x-2">
                    <button
                      className="p-1 bg-white rounded-full hover:bg-gray-100"
                      onClick={() => handleOpenDialog(label)}
                    >
                      <FaEdit className="text-blue-500" />
                    </button>
                    <button
                      className="p-1 bg-white rounded-full hover:bg-gray-100"
                      onClick={() => handleOpenDeleteDialog(label)}
                    >
                      <FaTrash className="text-red-500" />
                    </button>
                  </div>
                )}
              </div>
              })

              
            }

            
{
        ( selectedType === "All" || selectedType === "source") &&       defaultLables.source.map((label)=>{
                return  <div
                key={label.id}
                className="flex items-center justify-between p-2 rounded-lg shadow-lg"
              >
                <Chip
                  label={label.name}
                  style={{ backgroundColor: label.color, color: "#fff" }}
                />
                {!label?.default && (
                  <div className="flex items-center space-x-2">
                    <button
                      className="p-1 bg-white rounded-full hover:bg-gray-100"
                      onClick={() => handleOpenDialog(label)}
                    >
                      <FaEdit className="text-blue-500" />
                    </button>
                    <button
                      className="p-1 bg-white rounded-full hover:bg-gray-100"
                      onClick={() => handleOpenDeleteDialog(label)}
                    >
                      <FaTrash className="text-red-500" />
                    </button>
                  </div>
                )}
              </div>
              })

              
            }

            
{
          ( selectedType === "All" || selectedType === "importance") &&           defaultLables.importance.map((label)=>{
                return  <div
                key={label.id}
                className="flex items-center justify-between p-2 rounded-lg shadow-lg"
              >
                <Chip
                  label={label.name}
                  style={{ backgroundColor: label.color, color: "#fff" }}
                />
                {!label?.default && (
                  <div className="flex items-center space-x-2">
                    <button
                      className="p-1 bg-white rounded-full hover:bg-gray-100"
                      onClick={() => handleOpenDialog(label)}
                    >
                      <FaEdit className="text-blue-500" />
                    </button>
                    <button
                      className="p-1 bg-white rounded-full hover:bg-gray-100"
                      onClick={() => handleOpenDeleteDialog(label)}
                    >
                      <FaTrash className="text-red-500" />
                    </button>
                  </div>
                )}
              </div>
              })

              
            }
            { filteredLabels && filteredLabels.map((label) => (
              <div
                key={label.id}
                className="flex items-center justify-between p-2 rounded-lg shadow-lg"
              >
                <Chip
                  label={label.name}
                  style={{ backgroundColor: label.color, color: "#fff" }}
                />
                {!label?.default && (
                  <div className="flex items-center space-x-2">
                    <button
                      className="p-1 bg-white rounded-full hover:bg-gray-100"
                      onClick={() => handleOpenDialog(label)}
                    >
                      <FaEdit className="text-blue-500" />
                    </button>
                    <button
                      className="p-1 bg-white rounded-full hover:bg-gray-100"
                      onClick={() => handleOpenDeleteDialog(label)}
                    >
                      <FaTrash className="text-red-500" />
                    </button>
                  </div>
                )}
              </div>
            ))}
          </div>
          {/* Label Dialog */}
          <Dialog open={dialogOpen} onClose={handleCloseDialog}>
            <div className="p-6">
              <h2 className="text-xl font-bold mb-4">
                {editingLabel ? "Edit Label" : "Add New Label"}
              </h2>
              <input
                type="text"
                className="w-full p-2 border rounded-md mb-4 focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="Label Name"
                value={newLabel.name}
                onChange={(e) =>
                  setNewLabel({ ...newLabel, name: e.target.value })
                }
              />
              <select
                className="w-full p-2 border rounded-md mb-4 focus:outline-none focus:ring-2 focus:ring-blue-500"
                value={newLabel.type}
                onChange={(e) =>
                  setNewLabel({ ...newLabel, type: e.target.value })
                }
              >
                <option value="status">Status</option>
                <option value="source">Source</option>
                <option value="importance">Importance</option>
              </select>
              <div className="flex items-center mb-4">
                <input
                  type="color"
                  value={newLabel.color}
                  onChange={(e) =>
                    setNewLabel({ ...newLabel, color: e.target.value })
                  }
                  className="mr-2"
                />
                <span className="text-gray-700">{newLabel.color}</span>
              </div>
              <div className="flex justify-end space-x-2">
                <button
                  className="px-4 py-1 bg-gray-200 rounded-md hover:bg-gray-300"
                  onClick={handleCloseDialog}
                >
                  Cancel
                </button>
                <button
                  className="px-4 py-1 bg-brand-main text-white rounded-md hover:bg-brand-400"
                  onClick={handleSaveLabel}
                >
                  {editingLabel ? "Update" : "Add"}
                </button>
              </div>
            </div>
          </Dialog>
          {/* Confirmation Modal */}
          <ConfirmationModal
            open={deleteDialogOpen}
            onClose={handleCloseDeleteDialog}
            onConfirm={handleConfirmDelete}
            labelName={labelToDelete ? labelToDelete.name : ''}
          />
        </div>
      </Dialog>
    </>
  );
};
export default LabelManager;
