import { createSlice } from "@reduxjs/toolkit";

// below slice is used for future enhancement and state management right now there is no use of product slice
const INITIAL_STATE = {};

const productSlice = createSlice({
  name: "product",
  initialState: INITIAL_STATE,
  reducers: {},
});

export const {  } = productSlice.actions;
export default productSlice.reducer;
