import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { useState } from "react";
import { forwardRef } from "react";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function DeleteModal({
  isOpen,
  setIsOpen,
  content,
  handler,
  description = "",
}) {
  const handleClose = () => {
    console.log("handel close");
    setIsOpen(false);
  };

  return (
    <React.Fragment>
      <Dialog
        disableBackdropClick
        open={isOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{content}</DialogTitle>
        <DialogContent>{description}</DialogContent>
        <DialogActions>
          <div className=" flex items-center justify-end">
            <buttton
              onClick={handler}
              className="   ml-4 cursor-pointer  rounded-lg border border-none border-gray-300  bg-brand-main px-4 py-1.5 text-sm text-white outline-none transition duration-300 ease-in-out"
              type="submit"
            >
              Confirm
            </buttton>
            <buttton
              onClick={() => {
                setIsOpen(false);
              }}
              className="  ml-4 cursor-pointer  rounded-lg border border-none border-gray-300  bg-deleteBackground px-4 py-1.5 text-sm text-white outline-none transition duration-300 ease-in-out"
            >
              Cancel
            </buttton>
          </div>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
