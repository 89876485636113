import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { BsThreeDots } from "react-icons/bs";
import { Divider } from "@mui/material";
import { FaEye } from "react-icons/fa";
import { CiEdit } from "react-icons/ci";
import { RiDeleteBin7Fill } from "react-icons/ri";
import { IoChevronForwardSharp } from "react-icons/io5";
import { ConfirmDeleteModal } from "./modal/DeleteLead";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { instance } from "common/Instance";
import { useSelector } from "react-redux";
import { MdOutlinePayment } from "react-icons/md";
export default function ActionMenu({ invoice, fetchInvoice, setCurrentPage }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [drawerType, setDrawerType] = React.useState("view");
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const userToken = useSelector((state) => state.auth.auth.token);

  const navigate = useNavigate();
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleOpenDrawer = (type) => {
    setAnchorEl(null);

    setDrawerType(type);
    setOpenDrawer(true);
  };

  const deleteInvoice = async () => {
    try {
      setIsLoading(true);
      const response = await instance.delete(
        `api/invoices/delete_invoice/${invoice?._id}`,
        {
          headers: {
            authorization: `Bearer ${userToken}`,
            // "Content-Type": "multipart/form-data",
          },
        }
      );

      console.log(response, "This is resposne");
      if (response.status === 200) {
        toast.success("Invoice deleted");
        setOpenDeleteModal(false);
        fetchInvoice();
        setCurrentPage(1);
      } else {
        toast.error("Something went wrong");
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);

      toast.error("Something went wrong");
    }
  };

  return (
    <div className="flex justify-center">
      <BsThreeDots
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        className="cursor-pointer"
      />

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleOpenDrawer}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <MenuItem
          onClick={() => {
            navigate(`/admin/invoice/view?id=${invoice?._id}`);
          }}
          sx={{ fontSize: "14px" }}
        >
          <FaEye className="mr-2" />
          View{" "}
        </MenuItem>

        <MenuItem
          onClick={() => {
            navigate(`/admin/invoice/edit?id=${invoice?._id}`);
          }}
          sx={{ fontSize: "14px" }}
        >
          <CiEdit className="mr-2" />
          Edit
        </MenuItem>

        <MenuItem
          onClick={() => {
            navigate(`/admin/invoice/record?id=${invoice?._id}`);
          }}
          sx={{ fontSize: "14px" }}
        >
          <MdOutlinePayment className="mr-2" />
          Record Payment
        </MenuItem>
        <hr />
        <MenuItem
          onClick={() => setOpenDeleteModal(true)}
          sx={{ fontSize: "14px" }}
        >
          <RiDeleteBin7Fill className="mr-2" />
          Delete{" "}
        </MenuItem>
      </Menu>

      <ConfirmDeleteModal
        open={openDeleteModal}
        onClose={() => {
          setOpenDeleteModal(false);
        }}
        isLoading={isLoading}
        onConfirm={deleteInvoice}
      />
    </div>
  );
}
