import React, { useEffect, useState } from "react";
import ProductCard from "./ProductCard";
import ConnectShopfiyModal from "./Modal/ConnectShopifyModal";
import GuideModal from "./Modal/GuideModal";
import DeleteModal from "./Modal/DeleteModal";
import { instance } from "common/Instance";
import { useSelector } from "react-redux";
import { AWS_STATIC_PREFIX } from "../../../../common/Instance";
import ShopifyCategorySection from "./ShopifyCategorySection";
import toast from "react-hot-toast";

const shopifyGuide = [
  {
    id: 1,
    image: `${AWS_STATIC_PREFIX}Shopifyguide/step-1.png`,

    description:
      'To create a new user in shopify go to the URL https://www.shopify.com/in/partners and click "join now"',
  },
  {
    id: 2,
    image: `${AWS_STATIC_PREFIX}Shopifyguide/step-2.png`,

    description:
      "Kindly select one of authentication method mentioned in the screen shot to proceed further for signup. (I am using Gmail for guide perspective)",
  },
  {
    id: 3,
    image: `${AWS_STATIC_PREFIX}Shopifyguide/step-3.png`,
    description:
      "While sign up you need to select options to create your account successfully, kindly select as per your interest, and keep clicking <next> button.",
  },
  {
    id: 4,
    image: `${AWS_STATIC_PREFIX}Shopifyguide/step-4.png`,

    description:
      "Once you submit all the required details , at last you will <Submit> button page , file the details accordingly and submit.",
  },
  {
    id: 5,
    image: `${AWS_STATIC_PREFIX}Shopifyguide/step-5.png`,

    description:
      "After completing all the required formalities, you will land up to shopify partners home page.",
  },
  {
    id: 6,
    image: `${AWS_STATIC_PREFIX}Shopifyguide/step-6.png`,

    description:
      "Click on Generate Api key ,then you can generate the Consumer key and Consumer secret ,Copy and store it in a your system",
  },
  {
    id: 7,
    image: `${AWS_STATIC_PREFIX}Shopifyguide/step-7.png`,

    description:
      'On the "Create App" page, locate and click on "Create app manually" as highlighted in the screenshot, then follow the next step as instructed.',
  },
  {
    id: 8,
    image: `${AWS_STATIC_PREFIX}Shopifyguide/step-8.png`,

    description:
      'As highlighted in the screenshot, enter the "app name" and click on the "create" button to proceed.',
  },
  {
    id: 9,
    image: `${AWS_STATIC_PREFIX}Shopifyguide/step-9.png`,

    description:
      'After creating the app, you will be directed to the "All Apps" page. From there, copy the "Client ID" and "Client Secret" as shown in the screenshot. Once copied, click on "<select store>" as highlighted in the screenshot.',
  },

  {
    id: 10,
    image: `${AWS_STATIC_PREFIX}Shopifyguide/step-10.png`,

    description:
      'After clicking on "<select store>", you will be redirected to the "Create New Store" page. Click on the "new store" link as highlighted in the screenshot, and proceed with the next steps as instructed.',
  },
  {
    id: 11,
    image: `${AWS_STATIC_PREFIX}Shopifyguide/step-11.png`,

    description:
      'Clicking the "new_store" button will take you to a new page where you can provide the required information according to your preferences. Then, click on the "<Create development store>" link to proceed.',
  },
  {
    id: 12,
    image: `${AWS_STATIC_PREFIX}Shopifyguide/step-12.png`,

    description: "Please select the account you used for signup.",
  },
  {
    id: 13,
    image: `${AWS_STATIC_PREFIX}Shopifyguide/step-13.png`,

    description:
      'Now that you have logged into your store account, proceed with operations according to the setup guide. For example, I am performing the "add_product" operation.',
  },
  {
    id: 14,
    image: `${AWS_STATIC_PREFIX}Shopifyguide/step-14.png`,

    description:
      'Navigate to the home page. You will find the "online store" icon. Hover over it to reveal the "eye" shaped icon as highlighted in the screenshot. Click on it to be redirected to a new webpage.',
  },
  {
    id: 15,
    image: `${AWS_STATIC_PREFIX}Shopifyguide/step-15.png`,

    description:
      "Upon landing on the store page, copy the store URL as indicated in the screenshot. This URL is required to connect through VRCommerce. Here, you can review all added products and explore other store features",
  },
  {
    id: 16,
    image: `${AWS_STATIC_PREFIX}Shopifyguide/step-16.png`,

    description:
      'Log in to "partners.shopify.com". Go to "Apps". Select your app.',
  },

  {
    id: 17,
    image: `${AWS_STATIC_PREFIX}Shopifyguide/step-17.png`,

    description:
      'Navigate to the "Configuration" tab (as shown in the screenshot) and fill in the following details: App URL: https://api.vrcommerce.io Allowed redirection URL(s): https://api.vrcommerce.io/api/product/auth After updating, click on "Save and Release".',
  },
  {
    id: 18,
    image: `${AWS_STATIC_PREFIX}Shopifyguide/step-18.png`,

    description: 'Click on "Overview" and then click on "Select Store".',
  },
  {
    id: 19,
    image: `${AWS_STATIC_PREFIX}Shopifyguide/step-19.png`,

    description:
      'After clicking on "Select Store", you will see a list of stores. Click on "Install App" for the store you choose (as shown in the screenshot). Upon successful installation, you will be redirected to the VRCommerce site.',
  },
  {
    id: 20,
    image: `${AWS_STATIC_PREFIX}Shopifyguide/step-20.png`,

    description:
      'To connect your Shopify shop in VRCommerce, start by logging into your VRCommerce account. Navigate to the product section and choose "Shopify Product". Then, click on "Connect Shopify Shop". You will need to provide all the required details as displayed in the screenshot. Once everything is filled out correctly, click on the "Connect with Shopify" button to complete the connection process.',
  },
  {
    id: 21,
    image: `${AWS_STATIC_PREFIX}Shopifyguide/step-21.png`,

    description:
      'After clicking the "Connect Shopify Shop" button, you will be redirected to a new page. Simply click on the "Install" button, and you will then be redirected to the product page of VRCommerce to continue with setup and configuration. After clicking the "Connect Shopify Shop" button, you will be redirected to a new page. Simply click on the "Install" button, and you will then be redirected to the product page of VRCommerce to continue with setup and configuration.',
  },

  {
    id: 22,
    image:
      "https://images.unsplash.com/photo-1718809070374-e373e7c41414?q=80&w=987&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",

    description:
      'After installing the app, you will be redirected back to VRCommerce. Click on "Fetch Shop Product" to retrieve the products currently available in your store.',
  },

  {
    id: 22,
    image:
      "https://images.unsplash.com/photo-1718809070374-e373e7c41414?q=80&w=987&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",

    description:
      "If you have followed the steps correctly, you will finally receive the products as expected.",
  },
];
const ShopfiyProducts = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isGuideOpen, setIsGuideOpen] = useState(false);
  const [showSyncShopifyProducts, setShowSyncShopifyProducts] = useState(false);
  const [loadingSyncShopify, setLoadingSyncShopify] = useState(false);
  const userToken = useSelector((state) => state.auth.auth.token);
  const user = useSelector((state) => state.auth.auth);
  console.log(user, "this is user");
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [searchParam, setSearchParam] = useState("");
  const [openDeleteCategoryModal, setOpenDeleteCateogryModal] = useState(false);
  const [opneEditCategoryModal, setOpenEditCategoryModal] = useState(false);
  const [syncProductKey, setSyncProductKey] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [searchString, setSearchString] = useState("");
  const [categories, setCategories] = useState([]);
  const [timeoutId, setTimeoutId] = useState(null);
  const product = {
    image: "https://via.placeholder.com/300x200",
    title: "Sample Product Title That is Quite Long",
    description:
      "This is a sample product description that is intended to be long enough to demonstrate the truncation functionality.",
    available: true,
    price: "29.99",
  };

  const getCategorieshandler = async () => {
    try {
      setIsLoading(true);
      const response = await instance.get(
        "api/categories/get_categories_shopify",
        {
          headers: {
            authorization: `Bearer ${userToken}`,
          },
        }
      );
      if (response.status === 200 && response?.data?.data) {
        console.log(response.data);
        setIsLoading(false);

        setCategories([{ _id: "", category: "All" }, ...response?.data?.data]);
      }
    } catch (error) {
      console.log("Dashboard Error: ", error);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    setSelectedCategory({ _id: "all", category: "All" });
    getCategorieshandler();
  }, []);

  const syncShopifyHandler = async () => {
    try {
      // setShowSyncShopifyProducts(false)
      setLoadingSyncShopify(true);
      const response = await instance.get(`api/products/product_list`, {
        headers: {
          authorization: `Bearer ${userToken}`,
        },
      });
      console.log(response, "this is response");

      if (response.status === 200 && response?.data) {
        console.log(response.data, "this is response");
        setShowSyncShopifyProducts(false);
        toast.success("Product Synced succesfully!");
        setLoadingSyncShopify(false);
        setSyncProductKey(Math.random());
      } else {
        toast.error("Something went wrong!");
      }
    } catch (error) {
      toast.error(error?.message);

      console.log("Dashboard Error: ", error);
      setLoadingSyncShopify(false);
    }
  };
  const categoriesChangeHandler = (e) => {
    const category = categories.find(
      (category) => category?._id === e.target.value
    );
    console.log(category, "category found");
    if (category) {
      setSelectedCategory(category);
    }
  };

  const searchChangeHandler = (e) => {
    const inputValue = e.target.value;
    setSearchString(inputValue);

    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    const newTimeoutId = setTimeout(() => {
      if (!inputValue || inputValue.trim() === "") {
        setSearchParam("");
      } else {
        setSearchParam(inputValue.trim());
      }
    }, 500);

    setTimeoutId(newTimeoutId);
  };

  return (
    <div>
      <GuideModal
        isOpen={isGuideOpen}
        setIsOpen={setIsGuideOpen}
        guide={shopifyGuide}
        title="Guide for Shopify"
      />

      <div className="connectShopifySection  sm:block flex sm:flex-row flex-col gap-2">
        <buttton
          className="  h-10  cursor-pointer text-center rounded-lg   border border-none border-gray-300 bg-brand-main  px-4 py-2 text-sm text-white outline-none  transition duration-300 ease-in-out"
          onClick={() => {
            setIsOpen(true);
          }}
        >
          Connect Shopify Products
        </buttton>
        <buttton
          className="  sm:ml-4  h-10 cursor-pointer text-center rounded-lg border border-none border-gray-300  bg-brand-main px-4 py-2 text-sm text-white outline-none transition duration-300 ease-in-out"
          onClick={() => {
            setIsGuideOpen(true);
          }}
        >
          Guide
        </buttton>
        <buttton
          className=" sm:ml-4 h-10 cursor-pointer text-center rounded-lg border border-none border-gray-300  bg-brand-main px-4 py-2 text-sm text-white outline-none transition duration-300 ease-in-out"
          onClick={() => {
            setShowSyncShopifyProducts(true);
          }}
        >
          Sync Products
        </buttton>
      </div>
      <div className="mb-8 mt-8">
        <hr />
      </div>
      <div className="flex w-full gap-2">
        <input
          onChange={searchChangeHandler}
          value={searchString}
          className="block h-10 w-full    appearance-none rounded border border-gray-200 px-4 py-3 leading-tight text-gray-500 focus:border-gray-500 focus:bg-white focus:outline-none"
          id="grid-last-name"
          type="text"
          placeholder="Search the products"
        />
        <select
          value={selectedCategory?._id}
          onChange={categoriesChangeHandler}
          name=""
          id=""
          className="border-1 appearance-none rounded-md border-gray-200 bg-gray-200 px-2 outline-none "
        >
          {categories.map((category, index) => {
            return (
              <>
                <option value={category?._id}>{category?.category}</option>
              </>
            );
          })}
        </select>
      </div>

      {/* <div className="categoryName mt-8 h-14 rounded-md  ">
        <p className="flex h-full items-center rounded-md bg-productCategory  text-2xl text-white">
          <span className="ml-4">categoryname</span>
        </p>
      </div> */}

      {selectedCategory && (
        <ShopifyCategorySection
          category={selectedCategory}
          search={searchParam}
          setOpenEditCategoryModal={setOpenEditCategoryModal}
          setOpenDeleteCateogryModal={setOpenDeleteCateogryModal}
          syncProductKey={syncProductKey}
        />
      )}

      {/* <div className="products mt-8">
        <ProductCard product={product} />
      </div> */}
      <ConnectShopfiyModal isOpen={isOpen} setIsOpen={setIsOpen} />

      <DeleteModal
        isOpen={showSyncShopifyProducts}
        setIsOpen={setShowSyncShopifyProducts}
        content={"Are you sure to sync with shopify?"}
        description="If you have existing products, may lost if deleted from shopify"
        handler={syncShopifyHandler}
      />
    </div>
  );
};

export default ShopfiyProducts;
