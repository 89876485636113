

import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { instance } from "common/Instance";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
const validationSchema = Yup.object().shape({
  companyName: Yup.string(),
  name: Yup.string(),
  address: Yup.string(),
  email: Yup.string(),
  Phone: Yup.string(),
  logo: Yup.mixed().nullable(),
  visitingCardImage: Yup.mixed().nullable(),
  websiteLink: Yup.string().url("Invalid URL"),
  ownerPhoto: Yup.mixed().nullable(),
  facebook: Yup.string().url("Invalid URL"),
  linkedIn: Yup.string().url("Invalid URL"),
  youtube: Yup.string().url("Invalid URL"),
  googleMaps: Yup.string().url("Invalid URL"),
  googleReview: Yup.string().url("Invalid URL"),
  twitter: Yup.string().url("Invalid URL"),
  instagram: Yup.string().url("Invalid URL"),
});
const CardFormTest = ({formData, setFormData}) => {
console.log(formData, "this is form data")
  
  const authToken = useSelector((state) => state?.auth?.auth?.token);
  const [verify, setVerify] = useState(false);
  const user = useSelector((state) => state.auth.auth.user);
  const [visitingCardPreview, setVisitingCardPreview] = useState( null);
     const [ownerPhotoPreview, setOwnerPhotoPreview] = useState(null);
   const [logoPreview, setLogoPreview] = useState( null);



   const getInitialImagePreview = (imageData) => {
    if (typeof imageData === 'string') {
      return imageData;
    } else if (imageData && imageData?.buffer) {
      
      const blob = new Blob([new Uint8Array(imageData.buffer)], { type: imageData.mimetype });
      return URL.createObjectURL(blob);
    }
    return null;
  };

  useEffect(() => {
    setLogoPreview(getInitialImagePreview(formData?.logo));
    setVisitingCardPreview(getInitialImagePreview(formData?.visitingCardImage));
    setOwnerPhotoPreview(getInitialImagePreview(formData?.ownerPhoto));
  }, [formData]);
  function compressImage(file, maxWidth = 800, maxHeight = 800, quality = 0.7) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        const img = new Image();
        img.onload = () => {
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");
          let width = img.width;
          let height = img.height;
          if (width > height) {
            if (width > maxWidth) {
              height *= maxWidth / width;
              width = maxWidth;
            }
          } else {
            if (height > maxHeight) {
              width *= maxHeight / height;
              height = maxHeight;
            }
          }
          canvas.width = width;
          canvas.height = height;
          ctx.drawImage(img, 0, 0, width, height);
          canvas.toBlob(
            (blob) => {
              if (blob) {
                resolve(blob);
              } else {
                reject(new Error("Failed to convert canvas to Blob."));
              }
            },
            "image/jpeg",
            quality
          );
        };
        img.onerror = (error) => {
          reject(error);
        };
        img.src = event.target.result;
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsDataURL(file);
    });
  }

  return (
    <>
     
        <div className="mx-auto max-w-4xl rounded-lg bg-white px-8 pb-8 pt-6 shadow-lg">
          <h2 className="mb-8 text-center text-lg font-semibold">
            Business Card{" "}
          </h2>
          <Formik
            initialValues={{
              userId:user?._id,
              name:formData?.name,
              email:formData?.email,
              Phone:formData?.Phone,
              companyName: formData?.companyName || "",
              address: formData?.address || "",
              logo: formData?.logo|| "",
              visitingCardImage: formData?.visitingCardImage || null,
              websiteLink: formData?.websiteLink || "",
              ownerPhoto: formData?.ownerPhoto || null,
              facebook: formData?.facebook || "",
              linkedIn: formData?.linkedIn ||"",
              youtube: formData?.youtube ||  "",
              googleMaps:formData?.googleMaps ||  "",
              googleReview: formData?.googleReview || "",
              twitter: formData?.twitter || "",
              instagram:formData?.instagram || "",
             
            }}
            validationSchema={validationSchema}
            onSubmit={async (values) => {
              const formData = new FormData();
        
              for (let key in values) {
                if (values[key] !== null && values[key] !== undefined) {
                  if (key === 'logo' || key === 'visitingCardImage' || key === 'ownerPhoto') {
                    if (values[key] instanceof File) {
                      // Compress the image
                      const compressedBlob = await compressImage(values[key]);
                      formData.append(key, compressedBlob, values[key].name);
                    } else {
                      formData.append(key, values[key]);
                    }
                  } else {
                    formData.append(key, values[key]);
                  }
                }
              }
          
              try {
                const response = await instance.put(`/api/card/update_card_info`, formData, {
                  headers: {
                    authorization: `Bearer ${authToken}`,
                    'Content-Type': 'multipart/form-data',
                  },
                });
                setFormData(values);
                toast.success("Form submitted successfully!");
              } catch (error) {
                setFormData(values)
                toast.error("Failed to submit the form. Please try again.");
              }
            }}
          >
            {({ setFieldValue, values }) => (
              <Form className="space-y-6">
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <label className="block text-gray-700">Name</label>
                  <Field
                    name="name"
                    type="text"
                  value={formData?.name}
                    className="mt-1 block w-full rounded border px-3 py-2"
                    onChange={(e) => {
                      setFieldValue("name", e.target.value);
                      setFormData({ ...values, name: e.target.value });
                    }}
                  />
                  <ErrorMessage
                    name="name"
                    component="div"
                    className="mt-1 text-sm text-red-500"
                  />
                </div>
                <div>
                  <label className="block text-gray-700">Phone</label>
                  <Field
                    name="Phone"
                    type="text"
                    value={formData?.Phone}
                    className="mt-1 block w-full rounded border px-3 py-2"
                    onChange={(e) => {
                      setFieldValue("Phone", e.target.value);
                      setFormData({ ...values, Phone: e.target.value });
                    }}
                  />
                  <ErrorMessage
                    name="Phone"
                    component="div"
                    className="mt-1 text-sm text-red-500"
                  />
                </div>
              </div>
              <div>
                <label className="block text-gray-700">Email</label>
                <Field
                  name="email"
                  type="email"
                  value={formData?.email}
                  className="mt-1 block w-full rounded border px-3 py-2"
                  onChange={(e) => {
                    setFieldValue("email", e.target.value);
                    setFormData({ ...values, email: e.target.value });
                  }}
                />
                <ErrorMessage
                  name="email"
                  component="div"
                  className="mt-1 text-sm text-red-500"
                />
              </div>
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <label className="block text-gray-700">Company Name</label>
                  <Field
                    name="companyName"
                    type="text"
                    value={formData?.companyName}
                    className="mt-1 block w-full rounded border px-3 py-2"
                    onChange={(e) => {
                      setFieldValue("companyName", e.target.value);
                      setFormData({ ...values, companyName: e.target.value });
                    }}
                  />
                  <ErrorMessage
                    name="companyName"
                    component="div"
                    className="mt-1 text-sm text-red-500"
                  />
                </div>
                <div>
                  <label className="block text-gray-700">Address</label>
                  <Field
                    name="address"
                    type="text"
                    value={formData?.address}

                    className="mt-1 block w-full rounded border px-3 py-2"
                    onChange={(e) => {
                      setFieldValue("address", e.target.value);
                      setFormData({ ...values, address: e.target.value });
                    }}
                  />
                  <ErrorMessage
                    name="address"
                    component="div"
                    className="mt-1 text-sm text-red-500"
                  />
                </div>
              </div>
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <label className="block text-gray-700">Logo</label>
                  <input
                    name="logo"
                    type="file"
                      accept="image/*"
                    className="mt-1 block w-full rounded border px-3 py-2"
                    onChange={(event) => {
                      const file = event.currentTarget.files[0];
                      if(file){
                        setFieldValue("logo", event.currentTarget.files[0]);
                        // setFormData({ ...values, logo: event.currentTarget.files[0] });
                        const reader = new FileReader();
        reader.onloadend = () => {
          setLogoPreview(reader.result);
        };
        reader.readAsDataURL(file);
                      }
                   
                    }}
                  />
                  {logoPreview && (
                  <img src={logoPreview} alt="logo Preview" className="mt-2 h-20 w-20 object-cover" />
                )}
                  <ErrorMessage
                    name="logo"
                    component="div"
                    className="mt-1 text-sm text-red-500"
                  />
                </div>
                <div>
                  <label className="block text-gray-700">
                    Visiting Card Image
                  </label>
                  <input
                    name="visitingCardImage"
                    type="file"
                      accept="image/*"
                    className="mt-1 block w-full rounded border px-3 py-2"
                    onChange={(event) => {
                      // setFormData({ ...values, visitingCardImage: event.currentTarget.files[0] });



                      const file = event.currentTarget.files[0];
                      if(file){
                        setFieldValue("visitingCardImage", event.currentTarget.files[0]);
                        // setFormData({ ...values, logo: event.currentTarget.files[0] });
                        const reader = new FileReader();
        reader.onloadend = () => {
          setVisitingCardPreview(reader.result);
        };
        reader.readAsDataURL(file);
                      }
                    }}
                  />

{visitingCardPreview && (
                  <img src={visitingCardPreview} alt="visitingCard Preview" className="mt-2 h-20 w-20 object-cover" />
                )}
                  <ErrorMessage
                    name="visitingCardImage"
                    component="div"
                    className="mt-1 text-sm text-red-500"
                  />
                </div>
              </div>
              <div>
                <label className="block text-gray-700">Website Link</label>
                <Field
                  name="websiteLink"
                  type="url"
                  value={formData?.websiteLink}
                  className="mt-1 block w-full rounded border px-3 py-2"
                  onChange={(e) => {
                    setFieldValue("websiteLink", e.target.value);
                    setFormData({ ...values, websiteLink: e.target.value });
                  }}
                />
                <ErrorMessage
                  name="websiteLink"
                  component="div"
                  className="mt-1 text-sm text-red-500"
                />
              </div>
              <div>
                <label className="block text-gray-700">
                  Owner Photo (Optional)
                </label>
                <input
                  name="ownerPhoto"
                  type="file"
                    accept="image/*"
                  className="mt-1 block w-full rounded border px-3 py-2"
                  onChange={(event) => {
                    // setFormData({ ...values, ownerPhoto: event.currentTarget.files[0] });


                    const file = event.currentTarget.files[0];
                    if(file){
                      setFieldValue("ownerPhoto", event.currentTarget.files[0]);
                      // setFormData({ ...values, logo: event.currentTarget.files[0] });
                      const reader = new FileReader();
      reader.onloadend = () => {
        setOwnerPhotoPreview(reader.result);
      };
      reader.readAsDataURL(file);
                    }
                  }}
                />
                  {ownerPhotoPreview && (
                  <img src={ownerPhotoPreview} alt="ownerPhoto Preview" className="mt-2 h-20 w-20 object-cover" />
                )}
                <ErrorMessage
                  name="ownerPhoto"
                  component="div"
                  className="mt-1 text-sm text-red-500"
                />
              </div>
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <label className="block text-gray-700">Facebook</label>
                  <Field
                    name="facebook"
                    type="url"
                    value={formData?.facebook}
                    className="mt-1 block w-full rounded border px-3 py-2"
                    onChange={(e) => {
                      setFieldValue("facebook", e.target.value);
                      setFormData({ ...values, facebook: e.target.value });
                    }}
                  />
                  <ErrorMessage
                    name="facebook"
                    component="div"
                    className="mt-1 text-sm text-red-500"
                  />
                </div>
                <div>
                  <label className="block text-gray-700">LinkedIn</label>
                  <Field
                    name="linkedIn"
                    type="url"
                    value={formData?.linkedIn}
                    className="mt-1 block w-full rounded border px-3 py-2"
                    onChange={(e) => {
                      setFieldValue("linkedIn", e.target.value);
                      setFormData({ ...values, linkedIn: e.target.value });
                    }}
                  />
                  <ErrorMessage
                    name="linkedIn"
                    component="div"
                    className="mt-1 text-sm text-red-500"
                  />
                </div>
                <div>
                  <label className="block text-gray-700">YouTube</label>
                  <Field
                    name="youtube"
                    type="url"
                    value={formData?.youtube}
                    className="mt-1 block w-full rounded border px-3 py-2"
                    onChange={(e) => {
                      setFieldValue("youtube", e.target.value);
                      setFormData({ ...values, youtube: e.target.value });
                    }}
                  />
                  <ErrorMessage
                    name="youtube"
                    component="div"
                    className="mt-1 text-sm text-red-500"
                  />
                </div>
                <div>
                  <label className="block text-gray-700">Google Maps</label>
                  <Field
                    name="googleMaps"
                    type="url"
                    value={formData?.googleMaps}
                    className="mt-1 block w-full rounded border px-3 py-2"
                    onChange={(e) => {
                      setFieldValue("googleMaps", e.target.value);
                      setFormData({ ...values, googleMaps: e.target.value });
                    }}
                  />
                  <ErrorMessage
                    name="googleMaps"
                    component="div"
                    className="mt-1 text-sm text-red-500"
                  />
                </div>
                <div>
                  <label className="block text-gray-700">Google Review</label>
                  <Field
                    name="googleReview"
                    type="url"
                    value={formData?.googleReview}

                    className="mt-1 block w-full rounded border px-3 py-2"
                    onChange={(e) => {
                      setFieldValue("googleReview", e.target.value);
                      setFormData({ ...values, googleReview: e.target.value });
                    }}
                  />
                  <ErrorMessage
                    name="googleReview"
                    component="div"
                    className="mt-1 text-sm text-red-500"
                  />
                </div>
                <div>
                  <label className="block text-gray-700">Twitter</label>
                  <Field
                    name="twitter"
                    type="url"
                    value={formData?.twitter}

                    className="mt-1 block w-full rounded border px-3 py-2"
                    onChange={(e) => {
                      setFieldValue("twitter", e.target.value);
                      setFormData({ ...values, twitter: e.target.value });
                    }}
                  />
                  <ErrorMessage
                    name="twitter"
                    component="div"
                    className="mt-1 text-sm text-red-500"
                  />
                </div>
                <div>
                  <label className="block text-gray-700">Instagram</label>
                  <Field
                    name="instagram"
                    type="url"
                    value={formData?.instagram}
                    className="mt-1 block w-full rounded border px-3 py-2"
                    onChange={(e) => {
                      setFieldValue("instagram", e.target.value);
                      setFormData({ ...values, instagram: e.target.value });
                    }}
                  />
                  <ErrorMessage
                    name="instagram"
                    component="div"
                    className="mt-1 text-sm text-red-500"
                  />
                </div>
              </div>
              <div>
                <button
                  type="submit"
                  className="block w-full rounded bg-blue-500 px-4 py-2 text-white"
                >
                  Submit
                </button>
              </div>
            </Form>
            )}
          </Formik>
        </div>
      
    </>
  );
};
export default CardFormTest;