import React, { useState } from "react";
import { useParams } from "react-router-dom";
// import Coffee from "../../../assets/img/restaurant/coffee-removebg-preview.png";
import Rasgulla from "../../../assets/restaurant/rasgulla--removebg-preview.png";
import GulabJamun from "../../../assets/restaurant/Gulab_Jamun-removebg-preview.png";
import Jalebi from "../../../assets/restaurant/Jalebi-removebg-preview.png";
import Barfi from "../../../assets/restaurant/barfi-removebg-preview.png";
import Ladoo from "../../../assets/restaurant/Motichur-ladu-WS-removebg-preview.png";
import Peda from "../../../assets/restaurant/peda-removebg-preview.png";
import KajuKatli from "../../../assets/restaurant/Kaju-Katli2-1024x576-removebg-preview.png";
import MaysorePak from "../../../assets/restaurant/crumbly-mysore-pak-removebg-preview.png";
import Sandesh from "../../../assets/restaurant/Sandesh-590x436_0-removebg-preview.png";
import Rasmalai from "../../../assets/restaurant/rasmalai-removebg-preview.png";

// import Samosa from "../../../assets/restaurant/Samosas-removebg-preview.png";
// import Beverages from "../../../assets/restaurant/beverages----removebg-preview.png";
// import Indian from "../../../assets/restaurant/stock-photo-indian-food--removebg-preview.png";
import Dosa from "../../../assets/restaurant/thumb__1200_0_0_0_auto-removebg-preview.png";
import Idli from "../../../assets/restaurant/image_15__3_-removebg-preview (1).png";
import Vada from "../../../assets/restaurant/vada-removebg-preview.png";
import Shambar from "../../../assets/restaurant/kerala-sambhar.1024x1024-removebg-preview.png";
import Rasam from "../../../assets/restaurant/rasam-recipe-swasthis-1-removebg-preview.png";
import Upma from "../../../assets/restaurant/Upma-870x470-removebg-preview.png";
import Pongal from "../../../assets/restaurant/Ven-Pongal-3-removebg-preview.png";
import Uttapam from "../../../assets/restaurant/Tomato-Uttapam-removebg-preview.png";
import MasalaDosa from "../../../assets/restaurant/26911291874_9c7c661b79_z-removebg-preview.png";

import ButterChicken from "../../../assets/restaurant/Butter-Chicken-removebg-preview.png";
import RoganJosh from "../../../assets/restaurant/rogan_josh-removebg-preview.png";
import CholeBhature from "../../../assets/restaurant/chhole-bhature-removebg-preview.png";
import PannerTikka from "../../../assets/restaurant/Paneer-Tikka--removebg-preview.png";

import { MdOutlineRemoveCircle } from "react-icons/md";

const RestaurantMenu = ({ item, idx }) => {
  const { categoryName } = useParams();

  const southIndianFoods = [
    "Dosa",
    "Idli",
    "Vada",
    "Sambhar",
    "Rasam",
    "Upma",
    "Pongal",
    "Bisi Bele Bath",
    "Masala Dosa",
    "Uttapam",
  ];
  const sweetList = [
    "GulabJamun",
    "Rasgulla",
    "Jalebi",
    "Barfi",
    "Ladoo",
    "Peda",
    "KajuKatli",
    "MysorePak",
    "Sandesh",
    "Rasmalai",
    "Cham Cham",
    "Ghevar",
    "Malpua",
    "Modak",
  ];

  const northIndia = [
    "ButterChicken",
    "RoganJosh",
    "CholeBhature",
    "PannerTikka",
    "Dal Makhani",
    "Aloo Paratha",
    "Rajma Chawal",
    "Tandoori Chicken",
    "Palak Paneer",
    "Chaat ",
  ];

  const indianSnacks = [
    "Samosa",
    "Pakora (Bhajiya/Bhajji)",
    "Pani Puri (Golgappa)",
    "Dhokla",
    "Aloo Tikki",
    "Vada Pav",
    "Bhel Puri",
    "Masala Dosa",
    "Pav Bhaji",
    "Kachori",
  ];
  const beverages = [
    "Coffee",
    "Tea",
    "Lemonade",
    "Soda",
    "Juice",
    "Smoothie",
    "Hot Chocolate",
    "Iced Tea",
    "Milkshake",
    "Water",
  ];

  const beveragesList = [
    "Coffee",
    "Tea",
    "Lemonade",
    "Soda",
    "Juice",
    "Smoothie",
    "Hot Chocolate",
    "Iced Tea",
    "Milkshake",
    "Water",
  ];

  const [selectedImage, setSelectedImage] = useState(null);
  console.log(selectedImage, "selectedImage");
  const [Id, setId] = useState(null);
  const [idxs, setIdx] = useState(null);
  const [itemName, setItemName] = useState("");

  const foodImages = {
    Dosa: Dosa,
    Idli: Idli,
    Vada: Vada,
    Sambhar: Shambar,
    Rasam: Rasam,
    Upma: Upma,
    Pongal: Pongal,
    // BisiBhaat: BisiBhaat,
    Uttapam: Uttapam,
    MasalaDosa: MasalaDosa,
    Rasgulla: Rasgulla,
    GulabJamun: GulabJamun,
    Jalebi: Jalebi,
    Barfi: Barfi,
    Ladoo: Ladoo,
    KajuKatli: KajuKatli,
    Peda: Peda,
    MysorePak: MaysorePak,
    Sandesh: Sandesh,
    Rasmalai: Rasmalai,
    ButterChicken: ButterChicken,
    RoganJosh: RoganJosh,
    CholeBhature: CholeBhature,
    PannerTikka: PannerTikka,
  };
  const selectedImageSrc = selectedImage ? selectedImage : foodImages[itemName];

  const showButton = (i, item) => {
    setIdx(i);
    setItemName(item);
  };

  const handleImageUpload = (key, event) => {
    setId(key);
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const clearImage = () => {
    setSelectedImage(null);
    handleImageUpload();
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        textAlign: "center",
        gap: "10px",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          textAlign: "center",
          gap: "10px",
          height: "600px",
          paddingTop: "25px",
        }}
        className="... overflow-auto"
      >
        {categoryName === "south indian" &&
          southIndianFoods.map((item, i) => {
            return (
              <div
                key={item.id}
                style={{ display: "flex", justifyContent: "flex-start" }}
              >
                {" "}
                {/* Adding a unique key for each mapped item */}
                <button
                  onClick={() => showButton(i, item)}
                  style={{
                    borderRadius: "13px",
                    color: "balck",
                    backgroundColor: idxs === i ? "#1b3067" : "",
                  }}
                  className={`hover:text-white-600 w-60 rounded border border-[#ebf9fc] bg-[#EBF9FC] px-4 py-2 shadow-2xl hover:bg-[#1b3067] hover:text-[#fff] ${
                    idxs === i ? "bg-[#1b3067] text-white" : ""
                  }`}
                >
                  {item}
                </button>
              </div>
            );
          })}

        {categoryName === "Sweets" &&
          sweetList.map((item, i) => {
            return (
              <div
                key={item.id}
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  backgroundColor: idxs === i ? "#1b3067" : "",
                }}
              >
                {" "}
                <button
                  style={{ borderRadius: "13px" }}
                  onClick={() => showButton(i, item)}
                  className={`hover:text-white-600 w-60 rounded border border-[#ebf9fc] bg-[#EBF9FC] px-4 py-2 shadow-2xl hover:bg-[#1b3067] hover:text-[#fff] ${
                    idxs === i ? "bg-[#1b3067] text-white" : ""
                  }`}
                >
                  {item}
                </button>
              </div>
            );
          })}

        {categoryName === "North Indian" &&
          northIndia.map((item, i) => {
            return (
              <div
                key={item.id}
                style={{ display: "flex", justifyContent: "flex-start" }}
              >
                {" "}
                <button
                  onClick={() => showButton(i, item)}
                  style={{
                    borderRadius: "13px",
                    backgroundColor: idxs === i ? "#1b3067" : "",
                  }}
                  className={`hover:text-white-600 w-60 rounded border border-[#ebf9fc] bg-[#EBF9FC] px-4 py-2 shadow-2xl hover:bg-[#1b3067] hover:text-[#fff] ${
                    idxs === i ? "bg-[#1b3067] text-white" : ""
                  }`}
                >
                  {item}
                </button>
              </div>
            );
          })}

        {categoryName === "Snacks" &&
          indianSnacks.map((item, i) => {
            return (
              <div
                key={item.id}
                style={{ display: "flex", justifyContent: "flex-start" }}
              >
                {" "}
                <button
                  onClick={() => showButton(i)}
                  style={{
                    borderRadius: "13px",
                    backgroundColor: idxs === i ? "#1b3067" : "",
                  }}
                  className={`hover:text-white-600 w-60 rounded border border-[#ebf9fc] bg-[#EBF9FC] px-4 py-2 shadow-2xl hover:bg-[#1b3067] hover:text-[#fff] ${
                    idxs === i ? "bg-[#1b3067] text-white" : ""
                  }`}
                >
                  {item}
                </button>
              </div>
            );
          })}

        {categoryName === "Beverages" &&
          beveragesList.map((item, i) => {
            return (
              <div
                key={item.id}
                style={{ display: "flex", justifyContent: "flex-start" }}
              >
                {" "}
                <button
                  onClick={() => showButton(i)}
                  style={{
                    borderRadius: "13px",
                    backgroundColor: idxs === i ? "#1b3067" : "",
                  }}
                  className={`hover:text-white-600 w-60 rounded border border-[#ebf9fc] bg-[#EBF9FC] px-4 py-2 shadow-2xl hover:bg-[#1b3067] hover:text-[#fff] ${
                    idxs === i ? "bg-[#1b3067] text-white" : ""
                  }`}
                >
                  {item}
                </button>
              </div>
            );
          })}
      </div>

      <div style={{ paddingTop: "20px" }}>
        <img src={selectedImageSrc} alt="" />

        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            width: "96%",
            paddingTop: "5px",
          }}
        >
          <div className="mx-auto max-w-2xl">
            {selectedImageSrc && (
              <input
                className="block w-48 cursor-pointer rounded-lg border border-gray-300 bg-gray-50 text-sm text-gray-900 focus:outline-none dark:border-gray-600 dark:bg-gray-700 dark:text-gray-400 dark:placeholder-gray-400"
                id="file_input"
                type="file"
                onChange={(event) => handleImageUpload(item?.id, event)}
              />
            )}
          </div>

          {idx === item?.id && selectedImage && (
            <p onClick={() => clearImage()}>
              <MdOutlineRemoveCircle />
            </p>
          )}
        </div>
      </div>

      {/* <div style={{ paddingRight: "40px" }}>
        <div className="mx-auto mt-5 w-[240px] max-w-xl">
          <div className="overflow-hidden rounded-lg bg-white shadow-lg">
            <div className="flex items-center justify-between bg-[#ebf9fc] px-4 py-3">
              <h1 className="text-lg font-bold">Order List</h1>
              <span className="text-gray-600">(3 Food)</span>
            </div>
            <div className="p-4">
              <div className="mb-4 flex items-center">
                <img
                  className="mr-4 h-16 w-16 rounded-lg object-contain"
                  src={idx === item.id ? selectedImage : item.src}
                  alt="Product"
                />
                <div className="flex-1">
                  <h2 className=" font-bold">Coffee</h2>
                  <span className="text-gray-600"> ₹29.99</span>
                </div>
                <button className="text-gray-600 hover:text-red-500">
                  <svg className="fill-current h-6 w-6" viewBox="0 0 24 24">
                    <path d="M19 13H5v-2h14v2z" />
                  </svg>
                </button>
              </div>
              <div className="mb-4 flex items-center">
                <img
                  className="mr-4 h-16 w-16 rounded-lg object-contain"
                  src={Samosa}
                  alt="Product"
                />
                <div className="flex-1">
                  <h2 className=" font-bold">Samosa</h2>
                  <span className="text-gray-600"> ₹19.99</span>
                </div>
                <button className="text-gray-600 hover:text-red-500">
                  <svg className="fill-current h-6 w-6" viewBox="0 0 24 24">
                    <path d="M19 13H5v-2h14v2z" />
                  </svg>
                </button>
              </div>
              <div className="flex items-center">
                <img
                  className="mr-4 h-16 w-16 rounded-lg object-contain"
                  src={Rasgulla}
                  alt="Product"
                />
                <div className="flex-1">
                  <h2 className="font-bold">Rasgulla</h2>
                  <span className="text-gray-600"> ₹24.99</span>
                </div>
                <button className="text-gray-600 hover:text-red-500">
                  <svg className="fill-current h-6 w-6" viewBox="0 0 24 24">
                    <path d="M19 13H5v-2h14v2z" />
                  </svg>
                </button>
              </div>
            </div>
            <div className="bg-[#ebf9fc] px-4 py-3">
              <div className="flex items-center justify-between">
                <span className="text-lg font-bold">Total:</span>
                <span className="text-lg font-bold"> ₹74.97</span>
              </div>
              <button className="mt-4 block w-full rounded bg-blue-500 py-2 px-4 font-bold text-white hover:bg-blue-600">
                Checkout
              </button>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default RestaurantMenu;
