import { AWS_DATA_PREFIX } from "common/Instance";
import { AWS_STATIC_PREFIX } from "common/Instance";

const cardStaticData = {
  mind: {
    src: `${
      AWS_STATIC_PREFIX +
      "templates/BusinessCard/Immarsify-AR1/mind/target.mind"
    }`,
  },
  targetImage: {
    src: `${
      AWS_STATIC_PREFIX +
      "templates/BusinessCard/Immarsify-AR1/image/Frame%20161.png"
    }`,
    scale: {
      x: 2.0,
      y: 1.6,
      z: 1.255,
    },
  },
  sceneScale: {
    changeScale: true
  },
  model3d: { src: "" },
  photos: [
    {
      id: "1e2fe854-5aad-403c-b0d7-869e3046a003",
      type: "photo",
      src: `${
        AWS_STATIC_PREFIX +
        "templates/BusinessCard/Immarsify-AR1/image/IMG-20221207-WA0032%20-%20Copy.jpg"
      }`,
      position: {
        x: 0,
        y: 0.0005,
        z: -1.306,
      },
      rotation: {
        x: -1.5707963267948966,
        y: 0,
        z: 0,
      },
      scale: {
        x: 1.959,
        y: 1.098,
        z: 0.8,
      },
      isAnimation: true,
      geometry: "plane",
      defaultImage:
        "https://image.shutterstock.com/image-photo/natural-stone-concrete-podium-green-260nw-2156212789.jpg",
      animation: { duration: 1000, delay: 400, direction: "leftToRight" },
    },
  ],
  icons: [
    {
      id: "858b6559-f83d-4c0c-a89d-1a6ad95ff438",
      type: "icons",
      src: `${
        AWS_DATA_PREFIX +
        "website+data/models+(1)/linkedin_modified2/scene.gltf"
      }`,
      preview: `${AWS_DATA_PREFIX + "3d+icons/linkedin.svg"}`,
      position: {
        x: 0.354,
        y: 0,
        z: -2.510915350872862,
      },
      rotation: {
        x: 3,
        y: 0,
        z: 0,
      },
      scale: {
        x: 0.2,
        y: 0.2,
        z: 0.2,
      },
      isAnimation: true,
      iconName: "Linkedin",
      linkType: "RedirectLink",
      isLink: true,
      iconLink: "",
      animation: { duration: 1000, delay: 500, direction: "topToBottom" },
      isvCard: false,
      vCard: "",
      vCardJson: {
        firstName: "",
        lastName: "",
        photo: "",
        organization: "",
        email: "",
        workPhone: "",
        title: "",
        url: "",
        note: "",
        cellPhone: "",
        socialUrls: { custom: "", facebook: "", linkedIn: "", twitter: "" },
        homeAddress: { street: "", city: "", state: "" },
        workAddress: { street: "", city: "", state: "" },
        workUrl: "",
      },
    },
    {
      id: "f986794b-f41e-4868-8114-ce2367738208",
      type: "icons",
      src: `${
        AWS_DATA_PREFIX +
        "website+data/models+(1)/instagram_modified2/scene.gltf"
      }`,
      preview: `${AWS_STATIC_PREFIX + "3d+icons/Instagram.svg"}`,
      position: {
        x: -0.179,
        y: 0,
        z: -2.4943910172559276,
      },
      rotation: {
        x: 3,
        y: 0,
        z: 0,
      },
      scale: {
        x: 0.2,
        y: 0.2,
        z: 0.2,
      },
      isAnimation: true,
      iconName: "Instagram",
      linkType: "RedirectLink",
      isLink: true,
      iconLink: "",
      animation: { duration: 1000, delay: 500, direction: "topToBottom" },
      isvCard: false,
      vCard: "",
      vCardJson: {
        firstName: "",
        lastName: "",
        photo: "",
        organization: "",
        email: "",
        workPhone: "",
        title: "",
        url: "",
        note: "",
        cellPhone: "",
        socialUrls: { custom: "", facebook: "", linkedIn: "", twitter: "" },
        homeAddress: { street: "", city: "", state: "" },
        workAddress: { street: "", city: "", state: "" },
        workUrl: "",
      },
    },
    {
      id: "f752fe46-8093-4cf1-a2be-b46d57c76012",
      type: "icons",
      src: `${
        AWS_STATIC_PREFIX +
        "templates/BusinessCard/Immarsify-AR1/image/Group%20395%20%282%29.png"
      }`,
      position: {
        x: -0.063,
        y: 0,
        z: 1.488,
      },
      rotation: {
        x: -1.5,
        y: 0,
        z: 0,
      },
      scale: {
        x: 1.736,
        y: 0.528,
        z: 0.8,
      },
      isAnimation: true,
      linkType: "RedirectLink",
      isLink: false,
      iconLink: "",
      animation: { duration: 1000, delay: 500, direction: "leftToRight" },
      isvCard: false,
      vCard: "",
      vCardJson: {
        firstName: "",
        lastName: "",
        photo: "",
        organization: "",
        email: "",
        workPhone: "",
        title: "",
        url: "",
        note: "",
        cellPhone: "",
        socialUrls: { custom: "", facebook: "", linkedIn: "", twitter: "" },
        homeAddress: { street: "", city: "", state: "" },
        workAddress: { street: "", city: "", state: "" },
        workUrl: "",
      },
    },
    {
      id: "07488319-d9d9-48de-9a66-eb0f90e91c02",
      type: "icons",
      src: `${
        AWS_STATIC_PREFIX +
        "templates/BusinessCard/Immarsify-AR1/image/Group%20397.png"
      }`,

      position: {
        x: -0.135,
        y: 0,
        z: 0.978,
      },
      rotation: {
        x: -1.5,
        y: 0,
        z: 0,
      },
      scale: {
        x: 1.6,
        y: 0.527,
        z: 0.4,
      },
      isAnimation: true,
      linkType: "RedirectLink",
      isLink: false,
      iconLink: "",
      animation: { duration: 1000, delay: 400, direction: "leftToRight" },
      isvCard: false,
      vCard: "",
      vCardJson: {
        firstName: "",
        lastName: "",
        photo: "",
        organization: "",
        email: "",
        workPhone: "",
        title: "",
        url: "",
        note: "",
        cellPhone: "",
        socialUrls: { custom: "", facebook: "", linkedIn: "", twitter: "" },
        homeAddress: { street: "", city: "", state: "" },
        workAddress: { street: "", city: "", state: "" },
        workUrl: "",
      },
    },
    {
      id: "43345eba-8755-45c5-b052-392b0d755ce8",
      type: "icons",
      src: `${
        AWS_STATIC_PREFIX +
        "templates/BusinessCard/Immarsify-AR1/image/Group%20399%20%281%29.png"
      }`,
      position: {
        x: 0.053,
        y: 0.005,
        z: 2.016,
      },
      rotation: {
        x: -1.5,
        y: 0,
        z: 0,
      },
      scale: {
        x: 1.974,
        y: 0.538,
        z: 0.48,
      },
      isAnimation: true,
      linkType: "RedirectLink",
      isLink: false,
      iconLink: "",
      animation: { duration: 1000, delay: 300, direction: "leftToRight" },
      isvCard: true,
      vCard: "",
      vCardJson: {
        firstName: "",
        lastName: "",
        photo: "",
        organization: "",
        email: "",
        workPhone: "",
        title: "",
        url: "",
        note: "",
        cellPhone: "",
        socialUrls: { custom: "", facebook: "", linkedIn: "", twitter: "" },
        homeAddress: { street: "", city: "", state: "" },
        workAddress: { street: "", city: "", state: "" },
        workUrl: "",
      },
    },
    {
      id: "6fa73c81-af42-493b-b3e7-35c6afbf82fa",
      type: "icons",
      src: `${AWS_STATIC_PREFIX + "3dmodels/mail3DIcon.glb"}`,
      preview: `${AWS_DATA_PREFIX + "3d+icons/image%2016.svg"}`,
      position: {
        x: 0.893,
        y: 0,
        z: -2.494223617042713,
      },
      rotation: {
        x: 0.086,
        y: 0,
        z: 0,
      },
      scale: {
        x: 0.2,
        y: 0.2,
        z: 0.2,
      },
      isAnimation: true,
      iconName: "Email",
      linkType: "RedirectLink",
      isLink: true,
      iconLink: "",
      animation: { duration: 1000, delay: 400, direction: "topToBottom" },
      isvCard: false,
      vCard: "",
      vCardJson: {
        firstName: "",
        lastName: "",
        photo: "",
        organization: "",
        email: "",
        workPhone: "",
        title: "",
        url: "",
        note: "",
        cellPhone: "",
        socialUrls: { custom: "", facebook: "", linkedIn: "", twitter: "" },
        homeAddress: { street: "", city: "", state: "" },
        workAddress: { street: "", city: "", state: "" },
        workUrl: "",
      },
    },
    {
      id: "21ffdef0-5b6c-4c6b-a35c-011f822edee8",
      type: "icons",
      src: `${AWS_STATIC_PREFIX + "3dmodels/whatsApp3DIcon.glb"}`,
      preview: `${AWS_DATA_PREFIX + "3d+icons/WhatsApp.svg"}`,
      position: {
        x: -0.711,
        y: 0.026471008480248193,
        z: -2.4914778032003264,
      },
      rotation: {
        x: 0.086,
        y: 0,
        z: 0,
      },
      scale: {
        x: 0.2,
        y: 0.2,
        z: 0.2,
      },
      isAnimation: true,
      iconName: "Whatsapp",
      linkType: "RedirectLink",
      isLink: true,
      iconLink: "",
      animation: { duration: 1000, delay: 400, direction: "topToBottom" },
      isvCard: false,
      vCard: "",
      vCardJson: {
        firstName: "",
        lastName: "",
        photo: "",
        organization: "",
        email: "",
        workPhone: "",
        title: "",
        url: "",
        note: "",
        cellPhone: "",
        socialUrls: { custom: "", facebook: "", linkedIn: "", twitter: "" },
        homeAddress: { street: "", city: "", state: "" },
        workAddress: { street: "", city: "", state: "" },
        workUrl: "",
      },
    },
  ],
  buttons: [],
  videos: [],
  text: [
    {
      id: "56a9aec0-9819-4b46-ac2b-bfbfbdd0a676",
      color: "#ffffff",
      backgroundColor: "#000000",
      fontSize: "12",
      font: "",
      align: "left",
      weight: "normal",
      type: "text",
      style: "normal",
      body: "Arpit shah",
      position: {
        x: 0.024897940493796678,
        y: 0,
        z: -2.0864793257928196,
      },
      rotation: {
        x: -1.66,
        y: 0.006,
        z: 0,
      },
      scale: {
        x: 1,
        y: 1,
        z: 1,
      },
      isAnimation: true,
      animation: { duration: 1000, delay: 500, direction: "leftToRight" },
    },
  ],
  centerPosition: {
    type: "centerPosition",
    position: {
      x: -0.02924666837904555,
      y: 0,
      z: 0,
    },
    rotation: {
      x: 0,
      y: 0,
      z: 0,
    },
    scale: {
      x: 1,
      y: 1,
      z: 1,
    },
  },
  name: "",
  userId: "",
  userName: "",
  type: "BusinessCard",
  templateName: "Immarsify AR1",
  status: "in-progress",
  isActive: true,
};

export { cardStaticData };
