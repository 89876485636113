import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import CustomerName from "./components/AutoComplete";
import { useSelector } from "react-redux";
import { instance } from "common/Instance";
import Userlist from "../../userlist";
import toast from "react-hot-toast";
import { setQuaternionFromProperEuler } from "three/src/math/MathUtils";

const InvoiceForm = () => {
  const navigate = useNavigate();
  const [selectedCustomer, setSelectedCustomer] = useState(null); // New state for Autocomplete value
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingUsers, setIsLoadingUsers] = useState(false);
  const [applyValidationCheck, setApplyValidationCheck] = useState(false);
  const [formData, setFormData] = useState({
    userId: "",
    currency: "INR",
    status: "Pending",
    date: "",
    expireDate: "",
    note: "",
    extras: 0,
    sent: false,
    paid: 0,
    remaining: 0,
  });
  const userToken = useSelector((state) => state.auth.auth.token);
  const [searchParams, setSearchParams] = useSearchParams();

  const [items, setItems] = useState([
    { id: 1, name: "", description: "", quantity: 1, price: 0, total: 0 },
  ]);

  const getInvoiceDetails = async () => {
    try {
      const response = await instance.get(
        `/api/invoices/get_invoice/${searchParams.get("id")}`,
        {
          headers: {
            authorization: `Bearer ${userToken}`,
          },
        }
      );
      console.log(response, "this is user of the user");
      if (response.status === 200) {
        setIsLoadingUsers(false);
        console.log(response?.data, "this is response ");
        const { createdAt, _id, resellerId, ...excluded } = response?.data;
        setFormData(excluded);
        setSelectedCustomer(excluded?.userDetails);
        setItems(excluded?.items);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    getInvoiceDetails();
  }, []);

  const handleInputChange = (e) => {
    let { name, value } = e.target;
    if (name === "extras") {
      value = parseInt(value) || 0;
    }
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleItemChange = (id, field, value) => {
    setItems((prevItems) =>
      prevItems.map((item) => {
        if (item.id === id) {
          const updatedItem = { ...item, [field]: value };

          // Ensure quantity is at least 1 and is an integer
          updatedItem.quantity = Math.max(
            1,
            parseInt(updatedItem.quantity) || 1
          );

          // Ensure price is a valid integer
          updatedItem.price = parseInt(updatedItem.price) || 0;

          // Calculate total
          updatedItem.total = updatedItem.quantity * updatedItem.price;

          return updatedItem;
        }
        return item;
      })
    );
  };

  const addItem = () => {
    setItems((prevItems) => [
      ...prevItems,
      {
        id: Date.now(),
        name: "",
        description: "",
        quantity: 1,
        price: 0,
        total: 0,
      },
    ]);
  };

  const removeItem = (id) => {
    setItems((prevItems) => prevItems.filter((item) => item.id !== id));
  };

  const calculateTotals = () => {
    const subTotal = items.reduce(
      (sum, item) => sum + parseInt(item.total || 0),
      0
    );
    const total = subTotal + parseInt(formData.extras || 0);
    return { subTotal, total };
  };

  const { total, subTotal } = calculateTotals();

  const handleSave = async () => {
    console.log(formData, "This is fromdata");
    setApplyValidationCheck(true);
    setSelectedCustomer(null);
    try {
      if (!formData.userId || !formData.date || !formData.expireDate) {
        return toast.error("Please fill required fields");
      }

      let error = false;

      items.forEach((item) => {
        if (!item.name) {
          error = true;
        }
      });

      if (error) {
        return toast.error("You have not filled item name");
      }

      // if(!formData.userId || !items || !formData.date || !formData.expireDate){
      //   return
      // }
      setIsLoading(true);

      const response = await instance.put(
        `api/invoices/update_invoice/${searchParams.get("id")}`,
        {
          ...formData,
          total,
          subTotal,
          items,

          fullName: selectedCustomer?.fullName || "",
          email: selectedCustomer?.email || "",
        },
        {
          headers: {
            authorization: `Bearer ${userToken}`,
            // "Content-Type": "multipart/form-data",
          },
        }
      );

      console.log(response, "This is resposne");
      if (response.status === 200) {
        toast.success("Invoice updated");

        setApplyValidationCheck(false);
      } else {
        toast.error("Something went wrong");
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);

      toast.error("Something went wrong");
    }
  };

  const calculateSubTotal = () => {
    return items
      .reduce((sum, item) => sum + parseFloat(item.total || 0), 0)
      .toFixed(2);
  };

  return (
    <div className="mx-auto max-w-4xl rounded-lg bg-white p-4 shadow-lg sm:p-6">
      <div className="mb-6 flex flex-col items-start justify-between sm:flex-row sm:items-center">
        <div className="mb-4 flex items-center sm:mb-0">
          <button
            onClick={() => {
              navigate("/admin/invoice");
            }}
            className="mr-4"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M10 19l-7-7m0 0l7-7m-7 7h18"
              />
            </svg>
          </button>
          <h1 className="text-2xl font-semibold">Edit</h1>
          {/* <span className="ml-2 px-2 py-1 bg-gray-200 text-gray-700 text-sm rounded">Draft</span> */}
        </div>
        <div className="flex">
          <button
            className="mr-2 rounded-lg border  border-gray-300 px-4 py-1 "
            onClick={() => {
              navigate("/admin/invoice");
            }}
          >
            Cancel
          </button>
          <button
            className="rounded-lg bg-brand-main px-4 py-1 text-white "
            disabled={isLoading}
            onClick={handleSave}
          >
            {isLoading ? "Saving" : "Save"}
          </button>
        </div>
      </div>

      <div className="mb-6 grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3">
        <div>
          <label className="mb-1 block text-sm font-medium text-gray-700">
            Client <span className="text-red-500">*</span>
          </label>
          <CustomerName
            selectedCustomer={selectedCustomer}
            setSelectedCustomer={setSelectedCustomer}
            setFormData={setFormData}
            isLoadingUsers={isLoadingUsers}
            setIsLoadingUsers={setIsLoadingUsers}
          />
          {applyValidationCheck && (
            <span className="text-sm text-red-600">
              {!formData.userId ? "User is required" : null}
            </span>
          )}{" "}
        </div>
        <div>
          <label className="mb-1 block text-sm font-medium text-gray-700">
            Currency <span className="text-red-500">*</span>
          </label>
          <select
            name="currency"
            value={formData.currency}
            onChange={handleInputChange}
            className="w-full rounded-md border border-gray-300 px-3 py-2"
          >
            <option>INR</option>
            <option>USD</option>
          </select>
        </div>
        <div>
          <label className="mb-1 block text-sm font-medium text-gray-700">
            Status
          </label>
          <select
            name="status"
            value={formData.status}
            onChange={handleInputChange}
            className="w-full rounded-md border border-gray-300 px-3 py-2"
          >
            <option value="Pending">Pending</option>
            <option value="Accepted">Accepted</option>
            <option value="Rejected">Rejected</option>
          </select>
        </div>
      </div>

      <div className="mb-6 grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3">
        <div>
          <label className="mb-1 block text-sm font-medium text-gray-700">
            Date <span className="text-red-500">*</span>
          </label>
          <input
            type="date"
            name="date"
            value={formData.date}
            onChange={handleInputChange}
            className="w-full rounded-md border border-gray-300 px-3 py-2"
          />
          {applyValidationCheck && (
            <span className="text-sm text-red-600">
              {" "}
              {!formData.date ? "Date is required" : null}
            </span>
          )}{" "}
        </div>
        <div>
          <label className="mb-1 block text-sm font-medium text-gray-700">
            Expire Date <span className="text-red-500">*</span>
          </label>
          <input
            type="date"
            name="expireDate"
            value={formData.expireDate}
            onChange={handleInputChange}
            className="w-full rounded-md border border-gray-300 px-3 py-2"
          />
          {applyValidationCheck && (
            <span className="text-sm text-red-600">
              {!formData.expireDate ? "expired date is required" : null}
            </span>
          )}{" "}
        </div>
        <div>
          <label className="mb-1 block text-sm font-medium text-gray-700">
            Note
          </label>
          <textarea
            name="note"
            value={formData.note}
            onChange={handleInputChange}
            className="w-full rounded-md border border-gray-300 px-3 py-2"
            rows="1"
          ></textarea>
        </div>
      </div>

      <div className="mb-6 overflow-x-auto">
        <table className="w-full">
          <thead>
            <tr className="border-b">
              <th className="pb-2 text-left">Item</th>
              <th className="pb-2 text-left">Description</th>
              <th className="pb-2 text-left">Quantity</th>
              <th className="pb-2 text-left">Price</th>
              <th className="pb-2 text-left">Total</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {items.map((item) => (
              <tr key={item.id} className=" ">
                <td className="py-2  ">
                  <input
                    type="text"
                    value={item.name}
                    onChange={(e) =>
                      handleItemChange(item.id, "name", e.target.value)
                    }
                    className="w-full min-w-44 rounded-md border border-gray-300 px-2 py-1"
                    placeholder="Item Name"
                  />
                </td>
                <td className="py-2  ">
                  <textarea
                    type="text"
                    value={item.description}
                    onChange={(e) =>
                      handleItemChange(item.id, "description", e.target.value)
                    }
                    className="mt-1 w-full min-w-52 rounded-md border  border-gray-300 px-2 py-[4px]  "
                    placeholder="Description Name"
                    rows="1"
                  />
                </td>
                <td className="py-2 ">
                  <input
                    type="number"
                    value={item.quantity}
                    onChange={(e) =>
                      handleItemChange(item.id, "quantity", e.target.value)
                    }
                    className="w-full rounded-md border border-gray-300 px-2 py-1"
                    min={1}
                  />
                </td>
                <td className="py-2 ">
                  <div className="flex items-center">
                    <span className="mr-1">₹</span>
                    <input
                      // min={0}
                      type="text"
                      value={item.price}
                      onChange={(e) =>
                        handleItemChange(item.id, "price", e.target.value)
                      }
                      className="w-full rounded-md border border-gray-300 px-2 py-1"
                    />
                  </div>
                </td>
                <td className="py-2 ">
                  <div className="flex items-center">
                    <span className="mr-1">₹</span>
                    <input
                      type="text"
                      value={item.total}
                      readOnly
                      className="w-full rounded-md border border-gray-300 px-2 py-1"
                    />
                  </div>
                </td>
                <td className="py-2 ">
                  <button
                    onClick={() => removeItem(item.id)}
                    className="text-gray-500 hover:text-gray-700"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <button
        onClick={addItem}
        className="mb-6 w-full border border-dashed border-gray-300 py-2 text-gray-500"
      >
        + Add Field
      </button>

      {items.length === 0 ? (
        <span className="text-sm text-red-600">
          At least one item is required
        </span>
      ) : null}
      <div className="mb-4 flex flex-col items-start justify-between sm:flex-row sm:items-center">
        <button
          onClick={() => {
            navigate("/admin/invoice");
          }}
          className="mb-4 rounded-lg bg-brand-main px-4 py-1 text-white  sm:mb-0"
        >
          Go Back
        </button>
        <div className="w-full space-y-2 sm:w-auto">
          <div className="flex items-center justify-end">
            <span className="mr-4">Sub Total :</span>
            <div className="flex items-center">
              <span className="mr-1">₹</span>
              <input
                type="text"
                className="w-32 rounded-md border border-gray-300 px-2 py-1"
                value={subTotal}
                readOnly
              />
            </div>
          </div>
          <div className="flex items-center justify-end">
            <span className="mr-4">Extras: </span>
            <div className="flex items-center">
              <span className="mr-1">₹</span>
              <input
                type="text"
                // min={0}
                onChange={handleInputChange}
                name="extras"
                className="w-32 rounded-md border border-gray-300 px-2 py-1"
                value={formData.extras}
              />
            </div>
          </div>
          <div className="flex items-center justify-end font-semibold">
            <span className="mr-4">Total :</span>
            <div className="flex items-center">
              <span className="mr-1">₹</span>
              <input
                type="text"
                className="w-32 rounded-md border border-gray-300 px-2 py-1"
                value={total}
                readOnly
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InvoiceForm;
