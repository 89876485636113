import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { SET_INITIAL_STATE_ANALYTICS_YEARLY } from "../../../../redux/reducerSlice/analytics";
import {
  MdArrowDropUp,
  MdArrowDropDown,
  MdOutlineCalendarToday,
  MdBarChart,
} from "react-icons/md";
import Card from "components/card";
import {
  lineChartOptionsTotalSpent,
} from "variables/charts";
import LineChart from "components/charts/LineChart";
import axios from 'axios';
import { instance } from "common/Instance";

const TotalSpent = ({experience,isMultiscene}) => {
  const userToken = useSelector((state) => state.auth.auth.token);
  const getYearlyAnalytics = useSelector((state) => state?.analytics?.data?.getYearlyVisitor);
  const [dailyVisitor, setDailyVisitor] = useState();
  const [visitorMonthlyList, setVisitorMonthlyList] = useState([]);
  const [percentageChange, setPercentageChange] = useState('');
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    if (getYearlyAnalytics) {
      setDailyVisitor(getYearlyAnalytics?.visitor_count);
      setVisitorMonthlyList(getYearlyAnalytics?.visitor_monthly_list);
      setLoading(false);
    }
  }, [getYearlyAnalytics, dispatch,experience,isMultiscene]);

  useEffect(() => {
    getDashboardData();
  }, [dispatch, userToken,experience,isMultiscene]);

  const getDashboardData = async () => {
    setLoading(true)
    try {
      const response = await instance.get(`api/visitors/get_yearly_dashboard?experienceName=${experience}${isMultiscene?`&multiScene=true`:''}`, {
        headers: {
          authorization: `Bearer ${userToken}`,
        },
      });

      if (response.status === 200) {
        dispatch(SET_INITIAL_STATE_ANALYTICS_YEARLY(response?.data?.data));
        setLoading(false)
      }
    } catch (error) {
      console.log("Dashboard Error: ", error);
      setLoading(false)
    }
  };

  useEffect(() => {
    if (visitorMonthlyList && visitorMonthlyList.length >= 2) {
      const valData = visitorMonthlyList?.map(item => item?.totalCount);
      const lastTwo = valData?.slice(-2);

      if (lastTwo[0] !== 0) {
        const percentageChanges = ((lastTwo[1] - lastTwo[0]) / lastTwo[0]) * 100;
        setPercentageChange(percentageChanges);
      }
    }
  }, [visitorMonthlyList,experience,isMultiscene]);

  const chartOptions = {
    ...lineChartOptionsTotalSpent,
    xaxis: {
      ...lineChartOptionsTotalSpent.xaxis,
      categories: visitorMonthlyList?.map(item => item?.month?.slice(0, 3)),
    },
  };

  const lineChartDataTotalSpent = [
    { name: 'Total Traffic', data: visitorMonthlyList?.map(item => item?.totalCount) },
    { name: 'Desktop', data: visitorMonthlyList?.map(item => item?.desktopCount) },
    { name: 'Mobile', data: visitorMonthlyList?.map(item => item?.mobileCount) },
  ];

  
  return (
    <Card extra="!p-[20px] text-center">
      <div className="flex h-full w-full flex-row justify-between sm:flex-wrap lg:flex-nowrap 2xl:overflow-hidden">
        <div className="flex flex-col">
          {loading ? (
            <div className="mt-[20px] rounded-lg">
              <div className="animate-pulse flex py-2 justify-center items-center">
                <div className="rounded-xl bg-gray-500 h-6 w-12"></div>
              </div>
            </div>
          ) : (
            <p className="mt-[20px] text-3xl font-bold text-navy-700 dark:text-white">
              {dailyVisitor ? dailyVisitor : 0}
            </p>
          )}
          <div className="flex flex-col items-start">
            <p className="mt-2 text-sm text-gray-600">Total Visitors</p>
            {!loading && (
              <div className={`flex items-center text-sm ${percentageChange >= 0 ? " font-bold text-green-500" : "font-bold text-red-500"}`}>
                {percentageChange >= 0 ? <MdArrowDropUp className="h-5 w-5" /> : <MdArrowDropDown className="h-5 w-5" />}
                <p>{percentageChange >= 0 ? "+" : "-"}{Math.abs(percentageChange).toFixed(2)}%</p>
              </div>
            )}
          </div>
        </div>
        <div className="h-full w-full">
          {loading ? (
            <div className="rounded-lg w-full h-full">
              <div className="animate-pulse flex w-full h-full">
                <div className="rounded-xl bg-gray-500 w-full h-full"></div>
              </div>
            </div>
          ) : (
            <LineChart
              options={chartOptions}
              series={lineChartDataTotalSpent}
            />
          )}
        </div>
      </div>
    </Card>
  );
};

export default TotalSpent;
