import React, { useEffect, useState } from "react";
import { Chip } from "@mui/material";
import { useSelector } from "react-redux";
// Temporary static data (to be replaced with API call in the future)

const ViewLead = ({ leadId, enquiry }) => {
  const [leadData, setLeadData] = useState({
    viewerName: "",
    status: "",
    source: "",
    importance: "",
    address: "",
    city: "",
    state: "",
    pincode: "",
    phone: "",
    alternatePhone: "",
    email: "",
    note: "",
    experienceName: "",
    type: "",
    userId: "",
    createdAt: "",
  });
  const staticOptions  =  useSelector((state) => state?.enquiry?.lables);

  
  useEffect(() =>{
    setLeadData(enquiry);
  },[enquiry])
  

  return (
    <div className="mx-auto max-w-screen rounded-lg bg-white p-6 shadow-md">
      <h2 className="mb-4 text-2xl font-bold">Enquiry Details</h2>
      
      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700">Viewer Name</label>
        <p className="mt-1">{leadData?.viewerName}</p>
      </div>
      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700">Status</label>
        <div className="mt-1">    {enquiry?.status ? <Chip
         label={enquiry?.status}
        sx={{ backgroundColor: staticOptions?.status?.find((status)=>status?.name?.toLowerCase()===enquiry?.status?.toLowerCase())?.color, color:"#fff"}}
      /> : "-"}</div>
      </div>
      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700">Source</label>
        <div className="mt-1">     {enquiry?.source ? <Chip
        label={enquiry?.source}
        sx={{ backgroundColor: staticOptions?.source?.find((source)=>source?.name?.toLowerCase()===enquiry?.source?.toLowerCase())?.color, color:"#fff"}}
      /> :"-"}</div>
      </div>
      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700">Importance</label>
        <div className="mt-1">     {enquiry?.importance ? <Chip
         label={enquiry?.importance}
        sx={{ backgroundColor: staticOptions?.importance?.find((importance)=>importance?.name?.toLowerCase()===enquiry?.importance?.toLowerCase())?.color, color:"#fff"}}
      /> : "-"}</div>
      </div>
      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700">Phone</label>
        <p className="mt-1">{leadData?.phone || "-"}</p>
      </div>
      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700">Alternate Phone</label>
        <p className="mt-1">{leadData?.alternatePhone || "-"}</p>
      </div>
      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700">Email</label>
        <p className="mt-1">{leadData?.email || "-"}</p>
      </div>
      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700">Website</label>
        <p className="mt-1">{leadData?.website || "-"}</p>
      </div>
      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700">Address</label>
        <p className="mt-1">{leadData?.address  || "-"}</p>
      </div>
      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700">City</label>
        <p className="mt-1">{leadData?.city || "-"}</p>
      </div>
      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700">State</label>
        <p className="mt-1">{leadData?.state || "-"}</p>
      </div>
      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700">Pincode</label>
        <p className="mt-1">{leadData?.pincode || "-"}</p>
      </div>
      
      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700">Note</label>
        <p className="mt-1">{leadData?.note || "-"}</p>
      </div>
      {/* <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700">Experience Name</label>
        <p className="mt-1">{leadData.experienceName}</p>
      </div> */}
      {/* <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700">Type</label>
        <p className="mt-1">{leadData.type}</p>
      </div> */}
      {/* <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700">User ID</label>
        <p className="mt-1">{leadData.userId}</p>
      </div> */}
      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700">Created At</label>
        <p className="mt-1">{new Date(leadData?.createdAt).toLocaleString()}</p>
      </div>
    </div>
  );
};
export default ViewLead;