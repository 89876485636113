import { Box, Modal } from "@mui/material";
import React from "react";

const PaymentProcess = ({ open, loadingSuccess }) => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    width: 500,
    height: 290,
    p: 1,
    backgroundColor: "background.paper",
    boxShadow: "0 0 24px rgba(0, 0, 0, 0.25)",
    transform: "translate(-50%, -50%)",
    borderRadius: "0.5rem",
    border: "none",
    outline: "none",
  };

  return (
    <Modal open={open}>
      <Box sx={style}>
        {loadingSuccess ? (
          <div className="  flex flex-col  items-center justify-center">
            <div className=" p-6 text-center md:mx-auto">
              <svg
                viewBox="0 0 24 24"
                className="mx-auto my-6 h-16 w-16 animate-bounce text-green-600"
              >
                <path
                  fill="currentColor"
                  d="M12,0A12,12,0,1,0,24,12,12.014,12.014,0,0,0,12,0Zm6.927,8.2-6.845,9.289a1.011,1.011,0,0,1-1.43.188L5.764,13.769a1,1,0,1,1,1.25-1.562l4.076,3.261,6.227-8.451A1,1,0,1,1,18.927,8.2Z"
                />
              </svg>
              <h3 className="text-center text-base font-semibold text-gray-900 md:text-2xl">
                Payment Done!
              </h3>
            </div>
            <p classname="text-gray-600 pt-10 mb-2">
              Thank you for completing your secure online payment.
            </p>
          </div>
        ) : (
          <div className="payment-loader ">
            <div className="pad">
              <div className="chip"></div>
              <div className="linen1 line11"></div>
              <div className="linen1 line21"></div>
            </div>
            <div className="mt-5 text-center text-lg  font-semibold text-blue-900 ">
              Payment Processing...
            </div>
            <p className="mt-10 text-center text-sm text-gray-900">
              Please do not refresh the page, we are processing your payment
            </p>
          </div>
        )}
      </Box>
    </Modal>
  );
};

export default PaymentProcess;
