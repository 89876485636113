import { QRCodeSVG } from "qrcode.react";
import React from "react";
import { LuNfc } from "react-icons/lu";
import NfcImmarsify from "assets/svg/immarsifyM.svg";
import Logo from "./logo/Logo";
import { IoLocationSharp } from "react-icons/io5";
import { SiGooglemaps } from "react-icons/si";
import { TbWorldWww } from "react-icons/tb";
import { MdEmail, MdOutlineMail } from "react-icons/md";
import { FaFacebook, FaInstagramSquare, FaLinkedin, FaMobileAlt, FaYoutube } from "react-icons/fa";
import { BsTwitterX } from "react-icons/bs";
import { FaPhoneFlip } from "react-icons/fa6";
const CardSeven = React.forwardRef(
  ({ username, experiencename, name, user }, ref) => {
    return (
      <div
      ref={ref}
      className="h-[250px] w-[400px] relative overflow-hidden bg-gradient-to-r from-gray-700 to-gray-900 shadow-lg"
    >
      <div className="absolute right-4 top-1 z-10 flex items-center justify-end">
        <LuNfc size={25} color="#FFFFFF" className="top-3" />
        <Logo color={"#FFFFFF"} height={35} width={35} />
      </div>
      <div className="relative flex items-center justify-center">
        {/* Left Side - Content */}
        <div className="p-6">
          {/* Abstract Background */}
          <svg
            className="absolute inset-0 h-full w-full opacity-30"
            viewBox="0 0 800 400"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="200" cy="100" r="100" fill="rgba(255, 255, 255, 0.3)" />
            <rect
              x="250"
              y="150"
              width="150"
              height="150"
              fill="rgba(255, 255, 255, 0.3)"
            />
            <polygon
              points="100,300 300,400 100,500"
              fill="rgba(255, 255, 255, 0.3)"
            />
          </svg>

          <div className="relative z-10 h-full w-full text-white">
            <h2
              className={`mb-2 flex mx-w-[100px] break-all items-center justify-center break-words capitalize ${
                name.length > 20 ? "text-2xl" : "text-3xl"
              } font-bold`}
            >
                 {name.split(' ')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
            .join(' ')}
            </h2>
          </div>
        </div>

        {/* Right Side - QR Code and Profile Photo */}
        <div className="flex h-[250px] w-1/2 flex-col items-center justify-center  p-6 py-12 space-y-4">
          {/* Profile Photo */}
          {/* <div className="w-[80px] h-[80px] rounded-full overflow-hidden border-4 border-white">
            <img src={"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQqGxX6JYHRzK-BErO3ZM9Da0sfJ9ZS7SU0bg&s"} alt="Profile Photo" className="w-full h-full object-cover" />
          </div> */}
          {/* QR Code */}
          <QRCodeSVG
            value={`https://ar.immarsify.com/${username}/${experiencename}`}
            size={100}
            bgColor="#ffffff"
            fgColor="#000000"
            level="Q"
            includeMargin={false}
          />
        </div>
      </div>
    </div>
    );
  }
);

export default CardSeven;



export const CardSevenBack =  React.forwardRef(
  ({ username, experiencename, name, user, cardData }, ref) => {
    return  (
      <div
        ref={ref}
        className=" h-[250px] w-[400px] overflow-hidden bg-gradient-to-r from-gray-700 to-gray-900 shadow-lg text-white"
      >
       <div className="relative  p-4  text-sm break-all text-white">
        <div className="mt-8">

    
        <h2 className={` font-bold mb-2 text-center ${cardData?.companyName?.length > 20 ? `text-2xl` : `text-3xl`} }`}>
            {cardData?.companyName?.split(' ')
              .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
              .join(' ')}
          </h2>

<div className="flex justify-center ">

<div className="flex flex-col gap-2 mt-4">


{cardData?.address && <p className="text-sm  flex gap-2"><IoLocationSharp className="min-w-5 min-h-5" />
{cardData?.address}</p>}

{cardData?.email && <p className="text-sm  flex gap-2"><MdOutlineMail className="min-w-5 min-h-5" />
{cardData?.email}</p>}

{cardData?.Phone && <p className="text-sm  flex gap-2"><FaMobileAlt className="min-w-5 min-h-5"/>
{cardData?.Phone}</p>}

</div>


</div>







</div>
        </div>
      </div>
    );
  }
);
